import { AfterViewInit, Component, ElementRef, ViewChild } from "@angular/core";
import { AgEditorComponent } from "ag-grid-angular";

@Component({
    selector: "grid-cell-number-editor.component",
    styleUrls: ["./grid-cell-number-editor.component.scss"],
    templateUrl: "./grid-cell-number-editor.component.html"
})
export class GridCellNumberEditorComponent implements AgEditorComponent, AfterViewInit
{
    @ViewChild("inNumber")
    inNumber: ElementRef;

    public minValue = 0;
    public maxValue = Number.MAX_VALUE;
    public numValue;
    public stepValue = 1;
    public mainParams;

    constructor()
    {
    }

    public agInit(params: any): void
    {
        this.numValue = params.value;
        this.mainParams = params;

        if (params.hasOwnProperty("max"))
        {
            let max = params.max;

            if (typeof max == "object" && max.hasOwnProperty("data"))
            {
                max = params.data[max.data];
            }

            this.maxValue = max;
        }

        if (params.hasOwnProperty("min"))
        {
            let min = params.min;

            if (typeof min == "object" && min.hasOwnProperty("data"))
            {
                min = params.data[min.data];
            }

            this.minValue = min;
        }

        if (params.hasOwnProperty("step"))
        {
            this.stepValue = params.step;
        }
    }

    public getValue()
    {
        //  The ag-grid does not call the blur event when the user hit enter or tab, so call blur just in case.
        this.onBlur();

        return this.numValue;
    }

    public onBlur()
    {
        if (this.numValue > this.maxValue)
        {
            this.numValue = this.maxValue;
        }

        if (this.numValue < this.minValue)
        {
            this.numValue = this.minValue;
        }

        //  LameO workaround!
        //  getValue() has to be called to assign the new value into the data object, however if the User uses the mouse to click away without pressing TAB or ENTER, getValue() isn't called.
        //  This will plug the new value directly into the data object.
        let field = this.mainParams.colDef.field;

        this.mainParams.data[field] = this.numValue;
    }

    public ngAfterViewInit()
    {
        setTimeout(() => this.inNumber.nativeElement.focus());
    }
}