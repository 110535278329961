import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ApiService } from "../api/api.service";
import { Profile } from "./profile";

@Injectable({
    providedIn: "root"
})
export class ProfileService extends ApiService<Profile> {

    get editorForm()
    {
        let form = new FormBuilder();
        let grpFields = form.group({
            uid: [, Validators.required],
            lastName: [, Validators.required],
            firstName: [, Validators.required],
            countryUID: [, Validators.required],
            languageUID: [, Validators.required],
            timezoneUID: []
        });

        return grpFields;
    }

    constructor(httpClient: HttpClient)
    {
        super(httpClient, "profile");
    }

    emailConfirm(data: Profile)
    {
        return this.post("email-confirm", data);
    }

    signup(data: Profile)
    {
        return this.post("signup", data);
    }

    info()
    {
        return this.get("info");
    }

    resetPassword(data: Profile)
    {
        return this.post("reset-password", data);
    }

    public getProfileList(restrictList: boolean = false)
    {
        let obby = this.getList("lookup", { isBillingRole: restrictList }).subscribe(
            retRes =>
            {
                let data = retRes.results;
                let users = [];

                for (let dude of data)
                {
                    let addMe =
                    {
                        id: dude.id,
                        uid: dude.uid,
                        name: `${dude.lastName}, ${dude.firstName}`
                    };

                    users.push(addMe);
                }

                this.lookupList = users;
            });

        return obby;
    }
}