import { Component, ElementRef, ViewChild } from "@angular/core";
import { PurchaserHistoryListComponent } from '@app/admin/purchaser/purchaser-history-list/purchaser-history-list.component';
import { BaseComponent, Purchaser, PurchaserService, PurchaserType } from "@app/data";
import { EntityType } from "@app/data/enums/entity-type.enum";
import { PurchaserStatusType } from "@app/data/enums/purchaser-status-type.enum";
import { TaskEntity } from "@app/data/enums/task-entity.enum";
import { CommonSettings } from "@app/shared/common-settings";
import { PurchaserDocumentListComponent } from "@app/shared/purchaser-document-list/purchaser-document-list.component";
import { Util } from "@app/utils/util";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { PurchaserReviewEditComponent } from "../purchaser-review-edit/purchaser-review-edit.component";
import { PurchaserViewComponent } from "../purchaser-view/purchaser-view.component";

@Component({
    selector: "purchaser-dialog",
    templateUrl: "./purchaser-dialog.component.html"
})
export class PurchaserDialogComponent extends BaseComponent<Purchaser>
{
    @ViewChild("dialogReview")
    dialogReview: PurchaserReviewEditComponent;

    @ViewChild("dialogHistory")
    dialogHistory: PurchaserHistoryListComponent;

    @ViewChild("purchaserDialog")
    purchaserDialog: ElementRef;

    @ViewChild("purchaserDetail")
    purchaserDetail: PurchaserViewComponent;

    public readonly docListType = PurchaserDocumentListComponent.ListType_Admin;
    public readonly purchaserTypes = PurchaserType;
    public readonly noteEntityType = EntityType.Purchaser;
    public readonly taskEntityType = TaskEntity.Purchaser;
    public readonly purchaserStatus = PurchaserStatusType;

    public purchName: string;
    public newStatus?: number;
    public availStatues;

    constructor(
        toastrService: ToastrService,
        public modalService: NgbModal,
        private purchaserService: PurchaserService)
    {
        super(toastrService);

        this.title = "Purchaser - ";
        this.apiService = purchaserService;
        this.modalService = modalService;
    }

    public openPurchaser(purchUID: string): void
    {
        this.newStatus = undefined;

        this.open(this.purchaserDialog, null, CommonSettings.DialogSize_XLarge);
        this.loadPurchaser(purchUID);
    }

    public onApprove(): void
    {
        this.dialogReview.openReview(this.data, PurchaserStatusType.Approved, false, true);
    }

    public onStatusChange(): void
    {
        if (!this.newStatus)
        {
            return;
        }

        this.dialogReview.openReview(this.data, this.newStatus);

        setTimeout(() =>
        {
            this.newStatus = undefined;
        }, 200);
    }

    public onClose(): void
    {
        this.close();
    }

    public onRefresh()
    {
        this.loadPurchaser(this.data.uid);

        if (this.update)
        {
            this.update.emit();
        }
    }

    public onReviewUpdate(): void
    {
        this.close();

        if (this.update)
        {
            this.update.emit();
        }
    }

    public onHistoryOpen(): void
    {
        this.dialogHistory.openHistory(this.data);
    }

    private loadPurchaser(purchUID: string): void
    {
        let sub = this.detail({ uid: purchUID });

        sub.add(() => this.doneDidLoad());
    }

    private doneDidLoad(): void
    {
        if (this.data.purchaserType == PurchaserType.Individual)
        {
            this.purchName = Util.MakeName(this.data);
        }
        else
        {
            this.purchName = this.data.displayName;
        }

        this.availStatues = [{ id: 0, name: "" }];

        if (this.data.statusType == PurchaserStatusType.New)
        {
            this.availStatues.push({ id: -2, name: "Delete" });
            return;
        }

        if (this.data.statusType == PurchaserStatusType.Approved)
        {
            this.availStatues.push({ id: 11, name: "Hold (Suspend New Orders)" });
            this.availStatues.push({ id: 9, name: "Inactive (Suspend All Orders)" });
            return;
        }

        this.availStatues.push({ id: 1, name: "Approve" });
        this.availStatues.push({ id: 2, name: "Pending (Purchaser can change)" });
        this.availStatues.push({ id: 11, name: "Hold(Suspend New Orders)" });
        this.availStatues.push({ id: 9, name: "Inactive(Suspend All Orders)" });
        this.availStatues.push({ id: 12, name: "Deny" });
    }
}