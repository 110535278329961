export enum GridFilterOperator
{
    or = "OR",
    and = "AND"
}

export enum GridFilterDataType
{
    date = "date",
    text = "text",
    number = "number"
}

export enum GridFilterCondiction
{
    equals = "equals",
    inRange = "inRange",
    contains = "contains",
    endsWith = "endsWith",
    lessThan = "lessThan",
    notEqual = "notequal",
    startsWith = "startsWith",
    greaterThan = "greaterThan",
    notContains = "notContains",
    lessThanOrEqual = "lessThanOrEqual",
    greaterThanOrEqual = "greaterThanOrEqual"
}

export class GridFilter
{
    type: string;
    filter: string;
    dateTo?: string;
    dateFrom?: string;
    filterType: string;
    filterField: string;

    constructor(value, dataType: string | GridFilterDataType = GridFilterDataType.text, condition: GridFilterCondiction = GridFilterCondiction.equals)
    {
        this.type = condition;
        this.filterType = dataType;

        if (dataType == GridFilterDataType.date)
        {
            this.dateTo = "";
            this.dateFrom = value;
        }
        else
        {
            this.filter = value;
        }
    }
}

export class GridFilterStorage
{
    multiple = null;
    gridFilter?: any;
    filterField: string;

    constructor(field?: string, filter?: any)
    {
        this.gridFilter = filter;
        this.filterField = field;
    }

    public add(field, filter)
    {
        if (this.multiple == null)
        {
            this.multiple = {};
        }

        this.multiple[field] = filter;
    }
}