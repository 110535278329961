import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { AppService } from "@app/app.service";
import { AuthService, BaseComponent } from "@app/data";
import { WikiType } from "@app/data/enums/wiki-type.enum";
import { WikiMod } from "@app/data/wiki/wiki.model";
import { WikiService } from "@app/data/wiki/wiki.service";
import { WikiTextComponent } from "@app/shared/misc-components/wiki-renderer/wiki-renderer.component";
import { Util } from "@app/utils/util";
import { ToastrService } from "ngx-toastr";

@Component(
    {
        styleUrls: ["./release-notes.component.scss", "../../styles/ee-wiki.scss"],
        templateUrl: "./release-notes.component.html",
        encapsulation: ViewEncapsulation.None
    })
export class RelaseNotesComponent extends BaseComponent<WikiMod> implements OnInit
{
    @ViewChild("wikiElm")
    wikiElm: WikiTextComponent;

    public versions;

    constructor(toastrService: ToastrService,
        private wikiServ: WikiService,
        private appService: AppService,
        private authService: AuthService)
    {
        super(toastrService);

        this.title = "EasyExport - Release Notes";
        this.dataForm = wikiServ.editorForm;
        this.apiService = wikiServ;
        this.appService.pageTitle = this.title;
    }

    ngOnInit(): void
    {
        let sub = this.wikiServ.post("versions", { wikiType: WikiType.releaseNotes }).subscribe(
            retRes => this.loadVersions(retRes),
            errRes => this.error(errRes));
    }

    public onVersionChange(evtArg)
    {
        this.loadNote(evtArg.uid);
    }

    private loadVersions(retRes)
    {
        let vers = retRes?.results;

        if (Util.isEmpty(vers))
        {
            this.setValue("version", null);

            this.versions = [];
            this.wikiElm.parseWiki(null);
            return;
        }

        let ver = vers[0];

        this.versions = vers;

        this.setValue("version", ver.uid);
        this.loadNote(ver.uid);
    }

    private loadNote(wikiUID)
    {
        this.blockOn("Loading...");

        let filt: WikiMod = { uid: wikiUID };
        let subs = this.wikiServ.post("detail", filt).subscribe(
            retRes => this.doneDidLoad(retRes),
            errRes => this.error(errRes));

        subs.add(() => this.blockOff());
    }

    private doneDidLoad(data)
    {
        this.wikiElm.parseWiki(data.wikiText);
    }
}