import { Util } from "@app/utils/util";
import { GridContactFilter } from "../filters/grid-contact-filter/grid-contact-filter.component";
import { GridCellContactRenderer } from "../renderers/grid-cell-contact/grid-cell-contact";
import { GridColumn } from "./grid-column.model";

export class GridColumnContact extends GridColumn
{
    constructor(contactField: any, header: string = "Contact", showPhone: boolean = true, showTitle: boolean = true, showEmail: boolean = true)
    {
        super(contactField, header);

        let rendOpts =
        {
            field: contactField,
            showEmail: showEmail,
            showPhone: showPhone,
            showTitle: showTitle
        };

        this.filter = "gridContFilter";
        this.minWidth = 200;
        this.comparator = this.sorter;
        this.filterParams = { field: contactField };
        this.cellRendererParams = rendOpts;
        this.cellRendererFramework = GridCellContactRenderer;

        this.registerComponents.push({ gridContFilter: GridContactFilter });
    }

    private sorter(val1, val2): number
    {
        if (Util.isEmpty(val1) && Util.isEmpty(val2))
        {
            return 0;
        }

        let name1 = Util.MakeName(val1, false);
        let name2 = Util.MakeName(val2, false);

        name1 = name1.toLowerCase();
        name2 = name2.toLowerCase();

        if (name1 == name2)
        {
            return 0;
        }

        return (name1 > name2 ? 1 : -1);
    }
}