import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { AuthService, BaseComponent, PurchaserDealerConnect, PurchaserDealerConnectService } from "@app/data";
import { DealerConnectTradeStatus } from "@app/data/enums/dealer-conntact-trade-status.enum";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: "purchaser-dealer-connect-details",
    templateUrl: "./purchaser-dealer-connect-details.component.html"
})
export class PurchaserDealerConnectDetailsComponent extends BaseComponent<PurchaserDealerConnect> implements OnInit
{
    @ViewChild("dialogDealer")
    dialogDealer: ElementRef;

    public readonly tradeStatuses = DealerConnectTradeStatus;

    public userRole;
    public formDisplay: FormGroup;

    constructor(
        toastrService: ToastrService,
        private modalServ: NgbModal,
        private authService: AuthService,
        private dealConnServ: PurchaserDealerConnectService)
    {
        super(toastrService);

        this.title = "DealerConnect";
        this.apiService = this.dealConnServ;
        this.formDisplay = this.makeDisplayForm();
        this.modalService = this.modalServ;
    }

    ngOnInit(): void
    {
        this.userRole = this.authService.profile?.profileType;
    }

    public openDetail(data)
    {
        this.open(this.dialogDealer);

        this.uid = data.uid;

        this.formDisplay.patchValue(data.address);
        this.formDisplay.patchValue(data.purchaser);

        this.formDisplay.controls.state.setValue(data.state?.name);
        this.formDisplay.controls.currency.setValue(data.purchaser.annualSalesCurrency?.name);
        this.formDisplay.controls.language.setValue(data.language?.name);
        this.formDisplay.controls.customerName.setValue(data.customer.displayName);
        this.formDisplay.controls.purchaserName.setValue(data.purchaser.displayName);
        this.formDisplay.controls.purchaserContactName.setValue(data.purchaserContact?.displayName);
    }

    public onClose()
    {
        this.close();
    }

    public onStatusChange(statusType: number): void
    {
        let data =
        {
            uid: this.uid,
            statusType: statusType
        };

        this.apiService.save(data).subscribe(() => this.saveComplete());
    }

    private makeDisplayForm()
    {
        let fb = new FormBuilder();
        let grp = fb.group(
            {
                zip: [],
                city: [],
                state: [],
                street1: [],
                street2: [],
                currency: [],
                language: [],
                cellPhone1: [],
                customerUID: [],
                contactName: [],
                annualSales: [],
                websiteLink: [],
                customerName: [],
                emailAddress: [],
                purchaserName: [],
                annualSalesYear: [],
                productsOfInterest: [],
                bestSellingProducts: [],
                businessDescription: [],
                purchaserContactName: []
            });

        return grp;
    }

    private saveComplete()
    {
        this.close();

        if (this.update)
        {
            this.update.emit();
        }
    }
}