import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiService } from "../api/api.service";
import { FreightForwarderAddress } from "./freight-forwarder-address";

@Injectable({
    providedIn: "root"
})
export class FreightForwarderAddressService extends ApiService<FreightForwarderAddress>
{
    constructor(httpClient: HttpClient)
    {
        super(httpClient, "freight-forwarder-address");
    }
}
