import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { CustomerService, ProfileType } from "@app/data";
import { CustomerSearch } from "@app/data/customer-search/customer-search";
import { PurchaserAuthorizationService } from "@app/data/purchaser-authorization/purchaser-authorization.service";
import { GlobalIcons } from "@app/shared/GlobalIcons";
import { CustomerSearchUserStatementComponent } from "./customer-search-user-statement/customer-search-user-statement.component";

@Component({
    selector: "customer-search-item",
    styleUrls: ["customer-search-item.component.scss"],
    templateUrl: "./customer-search-item.component.html"
})
export class CustomerSearchItemComponent implements OnInit
{
    @ViewChild("dialogStatement")
    dialogStatement: CustomerSearchUserStatementComponent;

    @Input()
    merchantData: CustomerSearch;

    @Input()
    canAuthorize: boolean;

    public readonly Icons = GlobalIcons;
    public readonly profileTypes = ProfileType;

    public logoImg;

    public get authIcon()
    {
        return this.merchantData.authUID ? this.Icons.Lock : this.Icons.Unlock;
    }

    public get authText()
    {
        return this.merchantData.authUID ? "Do not allow merchant to make purchases on my behalf" : "Authorize to make purchases";
    }

    constructor(private custServ: CustomerService, private purchaseAuthServ: PurchaserAuthorizationService)
    {
    }

    ngOnInit()
    {
        this.custServ.getImage(this.merchantData.logoImageURL).subscribe(
            retRes => this.logoImg = retRes.safeURL);
    }

    public onPurchaseAuth()
    {
        if (this.merchantData.authUID)
        {
            this.purchaseAuthServ.delete("delete", { uid: this.merchantData.authUID }).subscribe();

            this.merchantData.authUID = null;
        }
        else
        {
            this.dialogStatement.openStatement(this.merchantData.uid);
        }
    }

    public onMerchAuthed(evtArg)
    {
        this.merchantData.authUID = evtArg.uid;
    }
}