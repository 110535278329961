import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { PurchaserStatusType } from '@app/data/enums/purchaser-status-type.enum';
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { ApiService } from "../api/api.service";
import { Purchaser } from "./purchaser";

@Injectable({
    providedIn: "root"
})
export class PurchaserService extends ApiService<Purchaser>
{
    public customsBrokers;

    constructor(httpClient: HttpClient)
    {
        super(httpClient, "purchaser");
    }

    private purchaserProfileStatus$: BehaviorSubject<string> = new BehaviorSubject(null);

    save(body): Observable<any>
    {
        return super.save(body);
    }

    signup(data: Purchaser)
    {
        return this.post("signup", data);
    }

    captchaGetImage(): Observable<Blob>
    {
        return this.httpClient.get(this.buildUrl("get-captcha-image"), { responseType: "blob" });
    }

    captchaIsValid(captchaText: string): Observable<any>
    {
        return this.httpClient.post(this.buildUrl("get-captcha-valid"), { captchaText: captchaText });
    }

    getCurrentProfileStatusName(): Observable<string>
    {
        return this.purchaserProfileStatus$.asObservable();
    }
    setCurrentStatusName(statusName)
    {
        this.purchaserProfileStatus$.next(statusName);
    }

    public findByEmail(email: string)
    {
        return this.post("find-email", { emailAddress: email });
    }

    public getCustomsBrokers(purchaserUID: string): Subscription
    {
        let sub = this.getList("customs-brokers", { purchaserUID: purchaserUID })
            .subscribe(retRes => this.customsBrokers = retRes.results);

        return sub;
    }

    public getPurchaserLookup(activeOnly: boolean = true): Subscription
    {
        let filt = null;

        if (activeOnly)
        {
            filt = { statusType: PurchaserStatusType.Approved };
        }

        return this.getLookup(filt);
    }
}