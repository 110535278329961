import { Util } from "@app/utils/util";
import { GridColumn } from "./grid-column.model";

export class GridColumnBigText extends GridColumn
{
    constructor(renderField: string, header: string, width: number = 200)
    {
        super(renderField, header);

        this.minWidth = width;
        this.cellRenderer = this.renderBigText;
    }

    private renderBigText(params: any): string
    {
        let text = params.value;

        if (Util.isEmpty(text))
        {
            return Util.DOM_Space;
        }

        return `<span title="${text}">${text}</span>`;
    }
}