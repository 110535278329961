import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import createAuth0Client, { GetTokenSilentlyVerboseResponse } from "@auth0/auth0-spa-js";
import Auth0Client from "@auth0/auth0-spa-js/dist/typings/Auth0Client";
import { environment } from "@env/environment";
import { BehaviorSubject, combineLatest, from, Observable, of, throwError } from "rxjs";
import { catchError, concatMap, map, mergeMap, shareReplay, tap } from "rxjs/operators";
import { AntiforgeryService } from "../profile/antiforgery.service";
import { Profile } from "../profile/profile";
import { ProfileService } from "../profile/profile.service";

@Injectable({
    providedIn: "root"
})
export class AuthService
{
    get isAdmin()
    {
        return (this.profile && this.profile.profileType === 3);
    }

    // Create subject and public observable of user profile data
    private userProfileSubject$ = new BehaviorSubject<any>(null);

    // Create a local property for login status
    loggedIn: boolean = null;

    // Create subject and public observable of profile info
    private profileInfoSubject$ = new BehaviorSubject<boolean>(false);

    profileInfo$ = this.profileInfoSubject$.asObservable();
    profile: Profile;

    // Create an observable of Auth0 instance of client
    auth0Client$ = (from(
        createAuth0Client({
            domain: environment.auth0.domain,
            client_id: environment.auth0.clientId,
            redirect_uri: `${window.location.origin}`,
            audience: environment.apiUrl,
            useRefreshTokens: true
        })
    ) as Observable<Auth0Client>).pipe(
        shareReplay(1), // Every subscription receives the same shared value
        catchError(err => throwError(err))
    );

    handleRedirectCallback$ = this.auth0Client$.pipe(concatMap((client: Auth0Client) => from(client.handleRedirectCallback())));

    constructor(private router: Router, private antiforgeryService: AntiforgeryService, private profileService: ProfileService)
    {
        // On initial load, check authentication state with authorization server
        // Set up local auth streams if user is already authenticated
        this.localAuthSetup();
        // Handle redirect from Auth0 login
        this.handleAuthCallback();
    }

    // Define observables for SDK methods that return promises by default
    // For each Auth0 SDK method, first ensure the client instance is ready
    // concatMap: Using the client instance, call SDK method; SDK returns a promise
    // from: Convert that resulting promise into an observable
    isAuthenticated$ = this.auth0Client$.pipe(
        concatMap((client: Auth0Client) => from(client.isAuthenticated())),
        tap(res => this.loggedIn = res)
    );

    getTokenSilently$(options?): Observable<GetTokenSilentlyVerboseResponse>
    {
        return this.auth0Client$.pipe(
            concatMap((client: Auth0Client) => from(client.getTokenSilently(options)))
        );
    }

    // When calling, options can be passed if desired
    // https://auth0.github.io/auth0-spa-js/classes/auth0client.html#getuser
    getUser$(options?): Observable<any>
    {
        return this.auth0Client$.pipe(
            concatMap((client: Auth0Client) => from(client.getUser(options))),
            tap(user => this.userProfileSubject$.next(user))
        );
    }

    getProfile$(): Observable<Profile>
    {
        // Don't cache profile because parts of the app pull it to update the state
        return this.antiforgeryService.profileToken().pipe(
            map(d1 =>
            {
                return d1;
            }),
            mergeMap(d2 =>
            {
                return this.profileService.info();
            }),
            tap(p =>
            {
                this.profile = p;
                this.profileInfoSubject$.next(true);
            })
        );
    }

    private localAuthSetup()
    {
        // This should only be called on app initialization
        // Set up local authentication streams
        const checkAuth$ = this.isAuthenticated$.pipe(
            concatMap((loggedIn: boolean) =>
            {
                if (loggedIn)
                {
                    // If authenticated, get user and set in app
                    // NOTE: you could pass options here if needed
                    return this.getUser$();
                }
                // If not authenticated, return stream that emits 'false'
                return of(loggedIn);
            })
        );
        checkAuth$.subscribe();
    }

    login(redirectPath: string = "/")
    {
        // A desired redirect path can be passed to login method
        // (e.g., from a route guard)
        // Ensure Auth0 client instance exists
        this.auth0Client$.subscribe((client: Auth0Client) =>
        {
            // Call method to log in
            client.loginWithRedirect({
                redirect_uri: `${window.location.origin}`,
                appState: { target: redirectPath }
            });
        });
    }

    private handleAuthCallback()
    {
        // Call when app reloads after user logs in with Auth0
        const params = window.location.search;
        if (params.includes("code=") && params.includes("state="))
        {
            let targetRoute: string; // Path to redirect to after login processsed
            const authComplete$ = this.handleRedirectCallback$.pipe(
                // Have client, now call method to handle auth callback redirect
                tap(cbRes =>
                {
                    // Get and set target redirect route from callback results
                    targetRoute = cbRes.appState && cbRes.appState.target ? cbRes.appState.target : "/";
                }),
                concatMap(() =>
                {
                    // Redirect callback complete; get user and login status
                    return combineLatest([
                        this.getUser$(),
                        this.isAuthenticated$,
                        this.getProfile$()
                    ]);
                })
            );
            // Subscribe to authentication completion observable
            // Response will be an array of user and login status
            authComplete$.subscribe(([user, loggedIn]) =>
            {
                // Redirect to target route after callback processing
                if (targetRoute === "/")
                {
                    targetRoute = `${targetRoute}${this.profile.redirectUrl}`;
                }

                this.router.navigateByUrl(targetRoute);
            });
        }
    }

    logout()
    {
        // Ensure Auth0 client instance exists
        this.auth0Client$.subscribe((client: Auth0Client) =>
        {
            // Call method to log out
            client.logout({
                client_id: environment.auth0.clientId,
                returnTo: window.location.origin
            });
        });
    }

    resetPassword(data: Profile)
    {
        return this.profileService.resetPassword(data);
    }

    public async myProfile()
    {
        if (this.profile)
        {
            return this.profile;
        }

        return await this.getProfile$().toPromise();
    }
}