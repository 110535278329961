import { DateFormatter } from './../../pipes/date-formatter.pipe';
import { Component, Input } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { GlobalIcons } from "@app/shared/GlobalIcons";
import { DateHelper } from "@app/utils/date-helper";
import { Util } from "@app/utils/util";

@Component({
    selector: "date-time-input",
    styleUrls: ["./date-time-input.component.scss"],
    templateUrl: "./date-time-input.component.html",
    providers:
        [
            {
                multi: true,
                provide: NG_VALUE_ACCESSOR,
                useExisting: DateTimeInputComponent
            }
        ]
})
export class DateTimeInputComponent implements ControlValueAccessor
{
    @Input()
    readonly: boolean = false;

    @Input()
    placeHolder: string = "Date";

    public readonly Icons = GlobalIcons;

    public inDate: any;
    public inTime: string;

    onChange: any = () => { };
    onTouched: any = () => { };

    registerOnChange(execFun)
    {
        this.onChange = execFun;
    }

    registerOnTouched(execFun)
    {
        this.onTouched = execFun;
    }

    writeValue(inValue)
    {
        if (Util.isEmpty(inValue))
        {
            this.inDate = null;
            this.inTime = null;

            this.onDateChange();
            return;
        }

        let derDate: Date;

        if (inValue instanceof Date)
        {
            derDate = inValue;
        }
        else
        {
            derDate = DateHelper.makeDate(inValue);

            if (derDate == null)
            {
                this.inDate = null;
                this.inTime = null;

                this.onDateChange();
                return;
            }
        }

        let hor = derDate.getHours().toString();
        let min = derDate.getMinutes().toString();

        if (hor.length == 1)
        {
            hor = "0" + hor;
        }

        if (min.length == 1)
        {
            min = "0" + min;
        }

        this.inDate = DateHelper.makeNGBDate(derDate);
        this.inTime = Util.substitute("{0}:{1}:{2}", hor, min, "00");

        this.onDateChange();
    }

    public onDateChange()
    {
        this.onChange(this.makeDateTime());
    }

    public onTimeChange()
    {
        this.onChange(this.makeDateTime());
    }

    private makeDateTime(): string
    {
        if (Util.isEmpty(this.inDate))
        {
            return null;
        }

        let derDate: Date = DateHelper.makeDate(this.inDate);

        if (derDate == null)
        {
            return null;
        }

        if (Util.isEmpty(this.inTime))
        {
            derDate.setHours(0, 0, 0);

            return DateHelper.formatDate(derDate, DateFormatter.FormatDateTimeMil);
        }

        let dasTime = this.inTime.split(":");
        let dieHour = parseInt(dasTime[0]);
        let dieMint = parseInt(dasTime[1]);

        derDate.setHours(dieHour, dieMint, 0);

        return DateHelper.formatDate(derDate, DateFormatter.FormatDateTimeMil);
    }
}