import { Component, OnInit } from "@angular/core";
import { AppService } from "@app/app.service";
import { BaseComponent, Faq, FaqService } from "@app/data";
import { FAQCommon } from "@app/data/faq/faq-common";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: "app-purchaser-faq",
    styleUrls: ["faq-list.component.scss"],
    templateUrl: "./faq-list.component.html"
})
export class FaqListComponent extends BaseComponent<Faq> implements OnInit
{
    public faqList;

    constructor(
        toastrService: ToastrService,
        private appService: AppService,
        private faqService: FaqService)
    {
        super(toastrService);

        this.title = "FAQ\'s";
        this.apiService = faqService;
        this.appService.pageTitle = this.title;
    }

    ngOnInit(): void
    {
        this.postGetlist().add(() => this.fixAnswers());
    }

    private fixAnswers()
    {
        FAQCommon.fixLinks(this.dataList);

        this.faqList = this.dataList;
    }
}