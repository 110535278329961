export enum LookupType
{
    country = 2,
    countryState = 3,
    language = 4,
    timezone = 5,
    customerAddressType = 6,
    customerContactType = 7,
    customerAccountType = 8,
    productCategory = 9,
    productSubCategory = 10,
    productAction = 11,
    productCaliber = 12,
    productAmmunition = 13,
    productExportClassification = 14,
    productImportClassification = 15,
    PurchaserDocumentType = 16,
    TransactionAction = 17,
    PurchaserContactType = 18,
    BillingChargeType = 19,
    BillingFrequency = 20,
    BillingUOM = 21,
    BillingRateConstant = 22,
    TransactionDocumentType = 23,
    PurchaserSubCategoryType = 24,
    TaskCategory = 25,
    CommodityShipmentType = 26,
    ShippingMethod = 27,
    CurrencyCode = 29,
    MerchantSource = 30,
    TaskEntity = 31,
    ProductSellerCategory = 32,
    ProductSellerSubCategory = 33,
    ExportType = 34,
    ProductSellerGroup = 35,
    LicenseDocumentType = 36
}