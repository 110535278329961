import { IAfterGuiAttachedParams, ICellRendererParams } from "@ag-grid-enterprise/all-modules";
import { Component } from "@angular/core";
import { GridCellIcon } from "@app/shared/ag-grid-helpers/models/grid-cell-icon.model";
import { GlobalIcons } from "@app/shared/GlobalIcons";
import { AgRendererComponent } from "ag-grid-angular";

@Component(
    {
        templateUrl: "./grid-cell-icon-group.html"
    })
export class GridCellIconGroup implements AgRendererComponent
{
    public readonly Icons = GlobalIcons;

    public iconList: Array<GridCellIcon>;
    private cellParams: ICellRendererParams;

    agInit(params): void
    {
        this.iconList = params.Icons;
        this.cellParams = params;
    }

    refresh(params): boolean
    {
        this.cellParams = params;
        return true;
    }

    afterGuiAttached?(params?: IAfterGuiAttachedParams): void
    {
    }

    public onClick(idx: number)
    {
        let who = this.iconList[idx];

        if (who.onClick instanceof Function)
        {
            who.onClick(this.cellParams.data);
        }
    }

    public checkIsVisible(item)
    {
        if (!item.hasOwnProperty("isVisible"))
        {
            return true;
        }

        if (typeof item.isVisible == "function")
        {
            return item.isVisible(this.cellParams.data);
        }

        return item.isVisible;
    }
}