import { ContactFormatter } from "@app/utils/contact-formatter";
import { Util } from '@app/utils/util';
import { GridAddressFilter } from "../filters/grid-address-filter/grid-address-filter.component";
import { GridCellAddressRenderer } from "../renderers/grid-cell-address/grid-cell-address";
import { GridColumn } from "./grid-column.model";

export class GridColumnAddress extends GridColumn
{
    constructor(addressField: any, header: string = "Address", prefixField: boolean = false)
    {
        super(addressField, header);

        this.filter = "gridAddyFilter";
        this.minWidth = 300;
        this.comparator = this.sorter.bind(this);
        this.filterParams = { field: addressField, prefixField: prefixField };
        this.cellRendererParams = { field: addressField };
        this.cellRendererFramework = GridCellAddressRenderer;

        this.registerComponents.push({ gridAddyFilter: GridAddressFilter });
    }

    private sorter(val1, val2): number
    {
        if (Util.isEmpty(val1) && Util.isEmpty(val2))
        {
            return 0;
        }

        let conFormat = new ContactFormatter();

        conFormat.contactData = val1;
        conFormat.noValueReturn = "&nbsp;";

        let addy1 = conFormat.getContact();

        conFormat.contactData = val2;

        let addy2 = conFormat.getContact();

        if (addy1 == addy2)
        {
            return 0
        }

        return (addy1 > addy2 ? 1 : -1);
    }
}