import { Component, OnInit, ViewChild } from "@angular/core";
import { TaskStatus } from "@app/data/enums/task-status.enum";
import { TaskCommon } from "@app/data/tasks/task-common";
import { TaskDocument } from "@app/data/tasks/task-document.model";
import { TaskDocumentService } from "@app/data/tasks/task-document.service";
import { GridBase } from "@app/shared/ag-grid-helpers/ag-grid-base";
import { GridHelpers } from "@app/shared/ag-grid-helpers/grid-helper";
import { GridColumnDocumentType } from "@app/shared/ag-grid-helpers/models/grid-column-document-type";
import { GridColumnIcon } from "@app/shared/ag-grid-helpers/models/grid-column-icon.model";
import { GridColumnStatus } from '@app/shared/ag-grid-helpers/models/grid-column-status';
import { GridColumn } from "@app/shared/ag-grid-helpers/models/grid-column.model";
import { ConfirmationService, IConfirmationOptions } from "@app/shared/confirmation/confirmation.service";
import { DocumentPreviewService } from "@app/shared/document-preview/document-preview.service";
import { TaskDocumentEditComponent } from "@app/shared/users/my-tasks/my-task-edit/my-task-doc-edit/my-task-doc-edit.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FileSaverService } from "ngx-filesaver";
import { ToastrService } from "ngx-toastr";
import { TaskDocumentPaste } from "../my-task-doc-paste/my-task-doc-paste";

@Component({
    selector: "my-task-docs",
    templateUrl: "./my-task-docs.component.html"
})
export class MyTaskDocumentsComponent extends GridBase<TaskDocument> implements OnInit
{
    @ViewChild("dialogDoc")
    dialogDoc: TaskDocumentEditComponent;

    @ViewChild("dialogPaste")
    dialogPaste: TaskDocumentPaste;

    constructor(
        toastrService: ToastrService,
        public taskDocServ: TaskDocumentService,
        public modalService: NgbModal,
        public confirmService: ConfirmationService,
        private docViewService: DocumentPreviewService,
        private fileSaverService: FileSaverService)
    {
        super(toastrService, taskDocServ);

        this.title = "Tasks";
        this.setupGrid();
    }

    ngOnInit(): void
    {
        this.filterAdd("taskUID", this.data.uid);
    }

    public onAdd(): void
    {
        this.dialogDoc.openAdd(this.data.uid);
    }

    public onPaste(): void
    {
        this.dialogPaste.openPaster(this.data.uid);
    }

    public canEdit(): boolean
    {
        return (this.data.statusType != TaskStatus.Complete);
    }

    private setupGrid()
    {
        let gridIcons =
            [
                GridHelpers.MakeEditIcon(this.onEdit.bind(this), this.canEdit.bind(this)),
                GridHelpers.MakeDeleteIcon(this.onDelete.bind(this), this.canEdit.bind(this)),
                GridHelpers.MakeDownloadIcon(this.onDownload.bind(this)),
                GridHelpers.MakeViewIcon(this.onViewDoc.bind(this))
            ];

        this.dataPath = "doc-list";
        this.storageKey = "MyTaskDocuments";
        this.gridOpts.domLayout = GridHelpers.DOMLayout_Normal;
        this.gridOpts.paginationPageSize = 6;

        this.addColumn(new GridColumnStatus(TaskCommon.DocumentStatuses));
        this.addColumn(new GridColumn("name", "Name"));
        this.addColumn(new GridColumnDocumentType("documentType"));
        this.addColumn(new GridColumnIcon(gridIcons));

        this.rowDoubleClick(this.onEdit.bind(this));
    }

    private onViewDoc(agData: TaskDocument): void
    {
        let path = this.taskDocServ.buildUrl("pdf-download");
        let fileName = `${path}?fileNameUID=${agData.fileNameUID}`;

        this.docViewService.openConvert(fileName, this.title);
    }

    private onEdit(agData: TaskDocument)
    {
        this.dialogDoc.readOnly = (this.data.statusType == TaskStatus.Complete);
        this.dialogDoc.openEdit(agData, this.data.uid);
    }

    private onDelete(selDoc: TaskDocument)
    {
        this.dataForm = this.makeForm(selDoc);

        let opts: IConfirmationOptions =
        {
            FunctionConfirm: () => this.deleteIt()
        };

        this.confirmService.Delete(`Are you sure you want to delete ${selDoc.name}?`, opts);
    }

    private deleteIt()
    {
        this.delete("doc-delete").add(() => this.gridRefresh());
    }

    private onDownload(agData: any)
    {
        this.taskDocServ.getFile("doc-download", agData.fileNameUID).subscribe(
            retRes =>
            {
                this.fileSaverService.save(retRes, agData.fileName);
            },
            errRes => { this.error("Failed to download document"); }
        );
    }
}