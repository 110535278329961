import { Component } from "@angular/core";
import { GridFilter, GridFilterCondiction, GridFilterDataType, GridFilterStorage } from "@app/shared/ag-grid-helpers/models/grid-filter.model";
import { Util } from '@app/utils/util';
import { AgFilterComponent } from "ag-grid-angular";
import { IDoesFilterPassParams } from "ag-grid-community";
import { GridFilterCustom } from "../grid-filter-custom";

@Component({
    styleUrls: ["../../../styles/gridfilter.scss"],
    templateUrl: "./grid-contact-filter.component.html"
})
export class GridContactFilter extends GridFilterCustom implements AgFilterComponent
{
    public readonly fieldMid = "middleName";
    public readonly fieldLast = "lastName";
    public readonly fieldFirst = "firstName";

    public inName: string;

    private field;

    agInit(params: any): void
    {
        this.initFilter(params);

        this.field = this.getParam("field");
    }

    isFilterActive(): boolean
    {
        return !Util.isEmpty(this.inName);
    }

    doesFilterPass(params: IDoesFilterPassParams): boolean
    {
        if (this.isServerSide)
        {
            return true;
        }

        if (Util.isEmpty(this.inName))
        {
            return false;
        }

        let contact = (this.field == null ? params.data : params.data[this.field]);

        if (Util.isEmpty(contact))
        {
            return false;
        }

        let passMid: boolean;
        let passLast: boolean;
        let passFirst: boolean;

        let name = this.inName.toLowerCase();
        let nameMid = contact[this.fieldMid];
        let nameLast = contact[this.fieldLast];
        let nameFirst = contact[this.fieldFirst];

        if (Util.isEmpty(nameMid))
        {
            passMid = false;
        }
        else
        {
            passMid = nameMid.toLowerCase().indexOf(name) != -1;
        }

        if (Util.isEmpty(nameLast))
        {
            passLast = false;
        }
        else
        {
            passLast = nameLast.toLowerCase().indexOf(name) != -1;
        }

        if (Util.isEmpty(nameFirst))
        {
            passFirst = false;
        }
        else
        {
            passFirst = nameFirst.toLowerCase().indexOf(name) != -1;
        }

        return (passFirst || passLast || passMid);
    }

    getModel()
    {
        if (Util.isEmpty(this.inName))
        {
            return null;
        }

        let filt = new GridFilter(this.inName, GridFilterDataType.text, GridFilterCondiction.contains);

        return new GridFilterStorage(this.field, filt);
    }

    setModel(model: any)
    {
        this.inName = model?.gridFilter?.filter;
    }

    public onFilterClear(): void
    {
        this.inName = null;

        this.applyAndClose();
    }

    public onFilterApply(): void
    {
        this.applyAndClose();
    }
}