import { Component, Input } from "@angular/core";
import { Address } from "@app/data/misc/address";
import { CommonSettings } from "@app/shared/common-settings";
import { GlobalIcons } from "@app/shared/GlobalIcons";
import { ContactFormatter } from "@app/utils/contact-formatter";
import { Util } from "@app/utils/util";

@Component({
    selector: "app-address",
    styleUrls: ["address.component.scss"],
    templateUrl: "address.component.html"
})
export class AddressComponent
{
    @Input()
    address: Address;

    public readonly Icons = GlobalIcons;

    public get addressTooltip()
    {
        if (Util.isEmpty(this.address))
        {
            return Util.DOM_Space;
        }

        let contFor = new ContactFormatter(this.address);

        contFor.delimiter = "\n";

        return contFor.getContact();
    }

    public onMap(): void
    {
        let contFor = new ContactFormatter(this.address);

        CommonSettings.openAddressWindow(contFor.googleLink());
    }
}