export class GridCellIcon
{
    public icon: string;
    public toolTip: string;
    public onClick: any;
    public isVisible: any;

    constructor(icon?: string)
    {
        this.icon = icon;
        this.isVisible = true;
    }
}