import { Component } from "@angular/core";
import { ProductSearch } from "@app/data/product/product-search/product-search.model";
import { ProductSearchesService } from "@app/data/product/product-search/product-searches.service";
import { GridBase } from "@app/shared/ag-grid-helpers/ag-grid-base";
import { GridColumnCustom } from "@app/shared/ag-grid-helpers/models/grid-column-custom.model";
import { GridColumnMerchant } from '@app/shared/ag-grid-helpers/models/grid-column-merchant';
import { GridColumnPartNumber } from "@app/shared/ag-grid-helpers/models/grid-column-part-number";
import { GridColumnPurchaser } from '@app/shared/ag-grid-helpers/models/grid-column-purchaser';
import { GridColumn, GridColumnType } from "@app/shared/ag-grid-helpers/models/grid-column.model";
import { ToastrService } from "ngx-toastr";

@Component({
    templateUrl: "product-searches.html"
})
export class ProductSearches extends GridBase<ProductSearch>
{
    constructor(
        toastrService: ToastrService,
        private searchServ: ProductSearchesService)
    {
        super(toastrService, searchServ);

        this.setupGrid();
    }

    private setupGrid()
    {
        this.storageKey = "ProductSearches";
        this.addColumn(new GridColumnCustom("textSearch", "Searched", { minWidth: 150 }));
        this.addColumn(new GridColumn("createdDate", "Date", GridColumnType.DateTime));
        this.addColumn(new GridColumn("ipAddress", "From"));
        this.addColumn(new GridColumnPartNumber());
        this.addColumn(new GridColumn("country.name", "Country"));
        this.addColumn(new GridColumnMerchant("customer.displayName", "customer.uid"));
        this.addColumn(new GridColumnPurchaser("purchaser.displayName", "purchaser.uid"));
        this.addColumn(new GridColumn("sellerCategory.name", "Category"));
        this.addColumn(new GridColumn("sellerGroup.name", "Group"));
    }
}