import { Transaction } from "@app/data";
import { ShipmentProduct } from "@app/data/shipment-product/shipment-product";
import { ShipmentCommercialInvoice } from "@app/data/shipment/shipment-commercial-invoice.model";
import { PDFBuilder, WordWrapMethods } from "@app/shared/reporting/pdfBuilder";
import { ContactFormatter } from "@app/utils/contact-formatter";
import { DateHelper } from "@app/utils/date-helper";
import { Util } from "@app/utils/util";

export class CommercialInvoiceGenerator
{
    public logo;
    public products: Array<ShipmentProduct>;
    public orderDetails: Transaction;
    public commercialInvoice: ShipmentCommercialInvoice;

    private readonly pageTitle = "Commercial Invoice";
    private readonly heightContact = 110;
    private readonly heightContactName = 30;
    private readonly heightProductHeader = 20;

    private docPDF: PDFBuilder;
    private contBuild: ContactFormatter;
    private pageNumber: number;

    constructor()
    {
        this.contBuild = new ContactFormatter();
        this.contBuild.delimiter = "\n";
    }

    public generate()
    {
        this.docPDF = new PDFBuilder();
        this.docPDF.fontReset();
        this.docPDF.addCursiveFont();

        let currY = this.pageHeader();
        let forth = this.docPDF.pageWidth() / 4;
        let workH = 0;

        workH = 30;

        this.docPDF.dataBox(0, currY, forth, workH, "Order #", this.orderDetails.poNumber);
        this.docPDF.dataBox(forth, currY, forth, workH, "Shipping Method", this.commercialInvoice.shipment?.shipmentMethod?.name);
        this.docPDF.dataBox(forth * 2, currY, forth, workH, "Payment Terms", this.commercialInvoice.paymentTerm);
        this.docPDF.dataBox(forth * 3, currY, forth, workH, "Shipment #", this.commercialInvoice.shipmentNumber);

        currY += workH;
        workH = 80;

        this.finePrint(0, currY, PDFBuilder.WidthMax, workH, "These items are controlled by the U.S. government and authorized for export only to the country of ultimate destination for use by the ultimate consignee or end-user(s) herein identified. They may not be resold, transferred, or otherwise disposed of, to any other country or to any person other than the authorized ultimate consignee or end-user(s), either in their original form or after being incorporated into other items, without first obtaining approval from the U.S. government or as otherwise authorized by U.S. law and regulations.\n\nThe items in this shipment may not be sold or transferred to individuals identified on the Department of Treasury, Office of Foreign Assets Controls(OFAC) Specially Designated Nationals(SDN) List with the bracketed suffixes[SDNT] or[SDNTK].The SDN List is available at: https://www.treasury.gov/resource-center/sanctions/SDN-List/Pages/sdn_data.aspx.");

        currY += workH;

        if (!Util.isEmpty(this.commercialInvoice.comments))
        {
            workH = 60;

            this.docPDF.dataBox(0, currY, PDFBuilder.WidthMax, workH, "Comments", this.commercialInvoice.comments, { wrap: WordWrapMethods.Wrap });

            currY += workH;
        }

        this.outProducts(currY);

        this.docPDF.putTotalPages(PDFBuilder.PageExpression);

        return this.docPDF.output(PDFBuilder.Output_Blob);
    }

    private pageHeader(firstPage: boolean = true, addProductHeader: boolean = true): number
    {
        let currY = 0;
        let workH = 35;
        let third = this.docPDF.pageWidth() / 3;
        let noBord = { border: false };
        let dateOpts =
        {
            align: PDFBuilder.Align_Right,
            border: false,
            boxPadding: false
        }

        if (firstPage)
        {
            this.pageNumber = 1;
        }
        else
        {
            this.docPDF.addPage();
            this.pageNumber++;
        }

        this.docPDF.fontSet(14, PDFBuilder.FontWeight_Bold);

        let maxX = this.docPDF.pageWidth();

        this.docPDF.text(this.pageTitle, maxX / 2, this.docPDF.pageMargins.top, { align: PDFBuilder.Align_Center });
        this.docPDF.fontReset();

        if (this.logo)
        {
            this.docPDF.addImage(this.logo, PDFBuilder.ImageFormatJPEG, this.docPDF.pageMargins.left, this.docPDF.pageMargins.top, 0, 50);
        }

        // Can't right align cause the "totalPages" isn't taken care of until final rendering
        this.docPDF.text(`Page ${this.pageNumber} of ${PDFBuilder.PageExpression}`, maxX - 40, this.docPDF.pageMargins.top);

        currY = 50;

        this.docPDF.dataBox(0, currY, third, workH, "Invoice #", this.commercialInvoice.invoiceNumber, noBord);
        this.docPDF.dataBox(third * 2, currY, third, workH, "Invoice Date", DateHelper.formatDate(this.commercialInvoice.invoiceDate), dateOpts);

        currY += workH;
        this.contBuild.contactData = this.commercialInvoice;

        workH = this.makeShipperBox(currY);
        workH = this.makeReceiverBox(currY);

        currY += workH;

        if (!firstPage && addProductHeader)
        {
            currY = this.makeProductHeader(currY);
        }

        return currY;
    }

    private makeShipperBox(posY: number): number
    {
        let title = "Exporter / Shipper";
        let width = this.docPDF.pageWidth() / 2;

        if (this.pageNumber != 1)
        {
            this.docPDF.dataBox(0, posY, width, this.heightContactName, title, this.commercialInvoice.companyName);

            return this.heightContactName;
        }

        this.contBuild.fieldPrefix = "customer";

        let merchData = this.commercialInvoice.companyName + this.contBuild.delimiter + this.contBuild.getContact();

        this.docPDF.dataBox(0, posY, width, this.heightContact, title, merchData, { wrap: WordWrapMethods.Wrap });

        let myY = (posY + this.heightContact) - 30;

        this.docPDF.dataBox(0, myY, width, 30, "Tel", this.commercialInvoice.customerPhone, { border: false });

        return this.heightContact;
    }

    private makeReceiverBox(posY: number): number
    {
        let half = this.docPDF.pageWidth() / 2;
        let title = "Ship to / Consignee";

        if (this.pageNumber != 1)
        {
            this.docPDF.dataBox(half, posY, half, this.heightContactName, title, this.commercialInvoice.purchaserName);

            return this.heightContactName;
        }

        this.contBuild.fieldPrefix = "purchaser";

        this.docPDF.dataBox(half, posY, half, this.heightContact, title, this.contBuild.getContact(), { wrap: WordWrapMethods.Wrap });

        let myY = (posY + this.heightContact) - 30;
        let phonW = 85;
        let mailW = (half - phonW);
        let phonX = (half * 2) - phonW;

        this.docPDF.dataBox(half, myY, mailW, 30, "Email", this.commercialInvoice.purchaserEmailAddress, { border: false });
        this.docPDF.dataBox(phonX, myY, phonW, 30, "Tel", this.commercialInvoice.purchaserPhone, { border: false });

        return this.heightContact;
    }

    private finePrint(posX: number, posY: number, width: number, height: number, text: string)
    {
        let rect = this.docPDF.drawBox(posX, posY, width, height);
        let broken = this.docPDF.splitTextToSize(text, rect.w - 2);

        this.docPDF.outText(rect.x, rect.y, broken);
    }

    private outProducts(posY: number): void
    {
        let maxY = this.docPDF.pageHeight();
        let posX = this.docPDF.pageMargins.left + this.docPDF.Box_Padding;
        let currY = this.makeProductHeader(posY);
        let width = posX + this.docPDF.Box_Padding + (this.docPDF.pageWidth() / 7);
        let workH = 10;
        let total = 0;
        let lineSize = 22;

        for (let prod of this.products)
        {
            this.docPDF.drawBox(0, currY, PDFBuilder.DrawTo_End, lineSize);

            let amt = Util.roundTo(prod.transactionProduct.amount, 2);
            let hts = prod.productCountry?.importHTS;
            let lineY = (currY + 2 + this.docPDF.pageMargins.top);
            let amtExt = Util.roundTo(prod.transactionProduct.extendedAmount, 2);

            if (!Util.isEmpty(hts))
            {
                hts = hts.substring(0, 7);
            }

            this.docPDF.outText(posX, lineY, prod.transactionProduct.partNumber);
            this.docPDF.outText(width, lineY, hts);
            this.docPDF.outText(width * 2, lineY, prod.transactionProduct.licenseNumber);
            this.docPDF.outText(width * 3, lineY, prod.originCountry?.code);
            this.docPDF.outText(width * 4, lineY, prod.quantityShipped, { align: PDFBuilder.Align_Right });
            this.docPDF.outText(width * 5, lineY, "$" + amt, { align: PDFBuilder.Align_Right, width: width });
            this.docPDF.outText(width * 6, lineY, "$" + amtExt, { align: PDFBuilder.Align_Right, width: width });

            lineY += workH;
            total += prod.transactionProduct.extendedAmount;

            this.docPDF.setBold();
            this.docPDF.outText(posX, lineY, prod.transactionProduct.customerDescription);
            this.docPDF.setBold(false);

            currY += lineSize;

            if ((currY + lineSize) > maxY)
            {
                currY = this.pageHeader(false);
            }
        }

        this.outTotals(currY, total);
    }

    private makeProductHeader(posY: number): number
    {
        let currY = posY + 5;
        let width = this.docPDF.pageWidth() / 7;

        this.docPDF.fontSet(this.docPDF.Font_DefaultSize - 2);
        this.docPDF.setTextColor(this.docPDF.Color_Label);

        this.headerBox(0, currY, width, "Part #\nDescription");
        this.headerBox(width, currY, width, "Harmonized Tariff\nSchedule (HTS)");
        this.headerBox(width * 2, currY, width, "Export License / Exception");
        this.headerBox(width * 3, currY, width, "Country of Origin");
        this.headerBox(width * 4, currY, width, "Quantity");
        this.headerBox(width * 5, currY, width, "Unit Price (USD)");
        this.headerBox(width * 6, currY, width, "Ext Price (USD)");

        this.docPDF.setTextColor(this.docPDF.Color_Data);
        this.docPDF.fontReset();

        return (currY + this.heightProductHeader);
    }

    private headerBox(posX: number, posY: number, width: number, label: string): void
    {
        let rect = this.docPDF.drawBox(posX, posY, width, this.heightProductHeader);

        rect.x += (rect.w / 2);

        this.docPDF.text(label, rect.x, rect.y, { baseline: PDFBuilder.Baseline_Top, align: PDFBuilder.Align_Center });
    }

    private outTotals(posY: number, total: number): void
    {
        let height: number = 50;

        if (!Util.isEmpty(this.commercialInvoice.otherCharge1))
        {
            height += 10;
        }

        if (!Util.isEmpty(this.commercialInvoice.otherCharge2))
        {
            height += 10;
        }

        if (posY + height > this.docPDF.pageHeight())
        {
            this.pageHeader(false, false);
        }

        let val = "$ " + Util.roundTo(total, 2);
        let maxX = this.docPDF.pageWidth();
        let labX = maxX - 80;
        let valX = maxX - 10;
        let currY = this.docPDF.pageHeight() - (height + this.docPDF.pageMargins.bottom);
        let rectPos = this.docPDF.drawBox(0, currY, maxX, height);

        currY = rectPos.y;

        this.docPDF.outText(labX, currY, "Sub Total", { align: PDFBuilder.Align_Right });
        this.docPDF.outText(valX, currY, val, { align: PDFBuilder.Align_Right });

        val = "$ " + Util.roundTo(this.commercialInvoice.shippingCharge, 2);
        currY += 10;
        total += this.commercialInvoice.shippingCharge;

        this.docPDF.outText(labX, currY, "Shipping", { align: PDFBuilder.Align_Right });
        this.docPDF.outText(valX, currY, val, { align: PDFBuilder.Align_Right });

        val = "$ " + Util.roundTo(this.commercialInvoice.handlingCharge, 2);
        currY += 10;
        total += this.commercialInvoice.handlingCharge;

        this.docPDF.outText(labX, currY, "Handling/Processing", { align: PDFBuilder.Align_Right });
        this.docPDF.outText(valX, currY, val, { align: PDFBuilder.Align_Right });

        if (!Util.isEmpty(this.commercialInvoice.otherCharge1))
        {
            val = "$ " + Util.roundTo(this.commercialInvoice.otherCharge1, 2);
            currY += 10;
            total += this.commercialInvoice.otherCharge1;

            this.docPDF.outText(labX, currY, this.commercialInvoice.otherCharge1Description, { align: PDFBuilder.Align_Right });
            this.docPDF.outText(valX, currY, val, { align: PDFBuilder.Align_Right });
        }

        if (!Util.isEmpty(this.commercialInvoice.otherCharge2))
        {
            val = "$ " + Util.roundTo(this.commercialInvoice.otherCharge2, 2);
            currY += 10;
            total += this.commercialInvoice.otherCharge2;

            this.docPDF.outText(labX, currY, this.commercialInvoice.otherCharge2Description, { align: PDFBuilder.Align_Right });
            this.docPDF.outText(valX, currY, val, { align: PDFBuilder.Align_Right });
        }

        val = "$ " + Util.roundTo(total, 2);
        maxX -= this.docPDF.Box_Padding;
        currY += 10;

        this.docPDF.line(this.docPDF.pageMargins.left * 2, currY, maxX , currY);

        currY += 2;

        this.docPDF.outText(labX, currY, "Total Invoice", { align: PDFBuilder.Align_Right });
        this.docPDF.outText(valX, currY, val, { align: PDFBuilder.Align_Right });
    }
}