import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { Freight, ProfileType } from "@app/data";
import { FreightForwarderAddress } from "@app/data/freight-forwarder-address/freight-forwarder-address";
import { FreightForwarderAddressService } from "@app/data/freight-forwarder-address/freight-forwarder-address.service";
import { GridBase } from "@app/shared/ag-grid-helpers/ag-grid-base";
import { GridHelpers } from "@app/shared/ag-grid-helpers/grid-helper";
import { GridColumnAddress } from "@app/shared/ag-grid-helpers/models/grid-column-address";
import { GridColumnCustom } from "@app/shared/ag-grid-helpers/models/grid-column-custom.model";
import { GridColumnIcon } from "@app/shared/ag-grid-helpers/models/grid-column-icon.model";
import { GridColumnStatus } from "@app/shared/ag-grid-helpers/models/grid-column-status";
import { ConfirmationService, IConfirmationOptions } from "@app/shared/confirmation/confirmation.service";
import { FreightForwarderCommon } from "@app/shared/freight-forwarder/freight-forwarder-common";
import { AddressDialogComponent } from "@app/shared/misc-components/address-dialog/address-dialog.component";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: "freight-forwarder-address-list",
    styleUrls: ["./freight-forwarder-address-list.component.scss"],
    templateUrl: "./freight-forwarder-address-list.component.html",
    encapsulation: ViewEncapsulation.None
})
export class FreightForwarderAddressListComponent extends GridBase<FreightForwarderAddress> implements OnInit
{
    @Input()
    userRole: number;

    @Input()
    recordType: number;

    @Input()
    ffData: Freight;

    @ViewChild("dialogAddress")
    dialogAddress: AddressDialogComponent;

    public get canModify()
    {
        return (this.userRole == ProfileType.admin || this.userRole == ProfileType.freightForwarder);
    }

    public get addressStatus()
    {
        if (this.userRole == ProfileType.admin)
        {
            return FreightForwarderCommon.FreightForwarderStatuses;
        }

        return null;
    }

    constructor(toastrService: ToastrService,
        private ffAddressServ: FreightForwarderAddressService,
        private confirmService: ConfirmationService)
    {
        super(toastrService, ffAddressServ);
    }

    ngOnInit()
    {
        this.setupGrid();
    }

    public onMyGridReady(evtArg)
    {
        this.filterAdd("freightUID", this.ffData.uid, false);

        this.onGridReady(evtArg);
    }

    public onAdd()
    {
        this.dialogAddress.openAdd(this.ffData.countryUID);
    }

    public onAddressSave(addressData)
    {
        let saveMe = Object.assign(addressData, { freightUID: this.ffData.uid });

        this.ffAddressServ.post("save", saveMe).subscribe(
            retRes =>
            {
                this.gridRefresh();
                this.dialogAddress.close();
            },
            errRes => this.error(errRes)
        );
    }

    private setupGrid()
    {
        this.storageKey = "FreightForwarder-Address";
        this.gridOpts.rowHeight = 75;
        this.gridOpts.domLayout = GridHelpers.DOMLayout_Normal;
        this.gridOpts.paginationPageSize = 10;

        this.addColumn(new GridColumnStatus(FreightForwarderCommon.FreightForwarderStatuses));
        this.addColumn(new GridColumnAddress(null, "Address"));
        this.addColumn(new GridColumnCustom("workPhone1", "Phone 1", { width: 100 }));
        this.addColumn(new GridColumnCustom("workPhone2", "Phone 2", { width: 100 }));

        if (!this.readOnly && this.canModify)
        {
            let gridIcons =
                [
                    GridHelpers.MakeEditIcon(this.onEdit.bind(this)),
                    GridHelpers.MakeDeleteIcon(this.onDelete.bind(this)),
                ];

            this.addColumn(new GridColumnIcon(gridIcons));
        }

        this.rowDoubleClick(this.onEdit.bind(this));
    }

    private onEdit(agData)
    {
        this.dialogAddress.openEdit(agData);
    }

    private onDelete(agData)
    {
        this.dataForm = this.makeForm(agData);

        let opts: IConfirmationOptions =
        {
            FunctionConfirm: () => this.deleteIt()
        };

        this.confirmService.Delete(`Are you sure you want to delete this Address?`, opts);
    }

    private deleteIt()
    {
        this.delete().add(() => this.gridRefresh());
    }
}