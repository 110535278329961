import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ProfileType } from '@app/data';
import { OrderCommon } from '@app/data/orders/order-common';
import { OrdersRemoveProduct } from "@app/data/orders/orders-remove-product";
import { OrdersRemoveProductService } from "@app/data/orders/orders-remove-product.service";
import { GridBase } from "@app/shared/ag-grid-helpers/ag-grid-base";
import { GridHelpers } from "@app/shared/ag-grid-helpers/grid-helper";
import { GridColumnCustom } from "@app/shared/ag-grid-helpers/models/grid-column-custom.model";
import { GridColumnMerchant } from "@app/shared/ag-grid-helpers/models/grid-column-merchant";
import { GridColumnPurchaser } from "@app/shared/ag-grid-helpers/models/grid-column-purchaser";
import { GridColumnStatus } from "@app/shared/ag-grid-helpers/models/grid-column-status";
import { GridColumn, GridColumnType } from "@app/shared/ag-grid-helpers/models/grid-column.model";
import { OrderDataHelper } from '@app/shared/orders/order-data.helper';
import { ToastrService } from "ngx-toastr";
import { GridCellProblemRenderer } from "./grid-cell-problem-renderer/grid-cell-problem-renderer";

@Component({
    selector: "order-reports-remove-product",
    templateUrl: "./report-remove-product.component.html",
})
export class OrderRemoveProductReportComponent extends GridBase<OrdersRemoveProduct>
{
    constructor(
        toastrService: ToastrService,
        private router: Router,
        private activeRoute: ActivatedRoute,
        private removeProdService: OrdersRemoveProductService)
    {
        super(toastrService, removeProdService);

        OrderDataHelper.userRole = this.activeRoute.snapshot.data.userType;

        this.setupGrid();
    }

    private setupGrid()
    {
        let colDate = new GridColumn("createdDateEST", "Created", GridColumnType.DateTime);
        let colProb = new GridColumn("product.status.name", "Problem");

        colDate.sort = GridHelpers.SortDir_Decending;
        colProb.cellRendererFramework = GridCellProblemRenderer;

        this.storageKey = "OrderRemoveProductReport";
        this.addColumn(new GridColumnStatus(OrderCommon.OrderStatuses));
        this.addColumn(colDate);

        if (OrderDataHelper.userRole == ProfileType.admin)
        {
            this.addColumn(new GridColumnMerchant("customer.displayName", "customer.uid"));
            this.addColumn(new GridColumnPurchaser("purchaser.displayName", "purchaser.uid"));
        }

        this.addColumn(new GridColumnCustom("poNumber", "Order #", { width: 100 }));
        this.addColumn(new GridColumn("destinationCountry", "Country"));
        this.addColumn(new GridColumn("product.customerDescription", "Description"));
        this.addColumn(new GridColumn("partNumber", "Part #"));
        this.addColumn(colProb);

        this.setServerPath("remove-product");
        this.rowDoubleClick(this.onGotoOrder.bind(this));
    }

    private onGotoOrder(agData)
    {
        this.router.navigate(["/admin/order-details", agData.uid]);
    }
}