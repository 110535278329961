import { Component, Input } from "@angular/core";

@Component({
    selector: "email-link",
    templateUrl: "./email-link.component.html"
})
export class EmailLinkComponent
{
    @Input()
    emailAddress: string;

    constructor()
    {
    }
}