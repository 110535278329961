import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ApiService } from "@app/data/api/api.service";
import { ProductOverrideImportHTS } from "./product-override-import-hts.model";

@Injectable({
    providedIn: "root"
})
export class ProductOverrideImportHTSService extends ApiService<ProductOverrideImportHTS>
{
    public get editForm()
    {
        let form = new FormBuilder();
        let grpFields = form.group(
            {
                uid: [],
                countryUID: [, Validators.required],
                productUID: [, Validators.required],
                overrideImportHTS: [, Validators.required],
            });

        return grpFields;
    }

    constructor(httpClient: HttpClient)
    {
        super(httpClient, "product-override-importhts");
    }
}