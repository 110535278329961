import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { Purchaser } from '@app/data';
import { AgRendererComponent } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";
import { PurchaserCellOptions } from "../../models/grid-column-purchaser";

@Component({
    templateUrl: "./grid-cell-purchaser.html",
})
export class GridCellPurchaserRenderer implements AgRendererComponent
{
    public showType: boolean;
    public purchaser: Purchaser;

    private cellParams: ICellRendererParams;

    constructor(private router: Router)
    {
    }

    agInit(params)
    {
        let opts = params.options as PurchaserCellOptions;

        this.showType = opts.showType;
        this.cellParams = params;

        if (params.node.data.purchaser)
        {
            this.purchaser = params.node.data.purchaser;
        }
        else
        {
            this.purchaser = { displayName: params.value };
        }
    }

    refresh(params): boolean
    {
        this.cellParams = params;
        return true;
    }
}