import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { FixedImage } from "@app/data/misc/fixed-image";
import { ContactFormatter } from "@app/utils/contact-formatter";
import { Util } from "@app/utils/util";
import { environment } from "@env/environment";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { GoogleConsts } from "./google-conts";
import { GoogleMapsCoordinates } from "./models/google-maps-coordinates.model";
import { GoogleMapsMapOption } from "./models/google-maps-map-option.model";
import { GoogleMapsStreetOption } from "./models/google-maps-street-option.model";

@Injectable()
export class GoogleMapsHelperService
{
    constructor(private webClient: HttpClient, private sanitizer: DomSanitizer)
    {
    }

    public makeGeoCodeURL(address: any): string
    {
        let url = GoogleConsts.GeoCodeURL;
        let contForm = new ContactFormatter(address);

        url += contForm.googleLink();
        url += `&key=${environment.goolgeMaps.key}`;

        return url;
    }

    public makeAddress(address: any): string
    {
        if (address instanceof GoogleMapsCoordinates)
        {
            return `${address.latitude},${address.longitude}`;
        }

        let contForm = new ContactFormatter(address);

        return contForm.googleLink();
    }

    public makeMapURL(address: any): string
    {
        let url = GoogleConsts.MapURL;

        url += this.makeAddress(address);
        url += `&key=${environment.goolgeMaps.key}`;

        return url;
    }

    public makeStreetURL(address: any): string
    {
        let url = GoogleConsts.StreetURL;

        url += this.makeAddress(address);
        url += `&key=${environment.goolgeMaps.key}`;

        return url;
    }

    public getCoordinates(address: any): Observable<GoogleMapsCoordinates>
    {
        if (Util.isEmpty(address))
        {
            return;
        }

        let url = this.makeGeoCodeURL(address);
        let obby = this.webClient.get(url).pipe(map(retRes =>
        {
            let vals = retRes["results"][0].geometry.location;

            return new GoogleMapsCoordinates(vals.lat, vals.lng);
        }));

        return obby;
    }

    public getMapImage(address: any, opts: GoogleMapsMapOption = new GoogleMapsMapOption()): Observable<FixedImage>
    {
        if (Util.isEmpty(address))
        {
            return;
        }

        let gotoURL = this.makeMapURL(address);

        gotoURL += `&size=${opts.Size}x${opts.Size}`;
        gotoURL += "&zoom=" + opts.Zoom;
        gotoURL += "&scale=" + opts.Scale;
        gotoURL += "&style=feature:poi|visibility:off";
        gotoURL += "&markers=scale:2|" + this.makeAddress(address);
        gotoURL += "&maptype=" + opts.MapType;

        let obby = this.webClient.get(gotoURL, { observe: "response", responseType: "blob" })
            .pipe(map(retRes =>
            {
                let fixImg = new FixedImage();

                fixImg.rawImage = new Blob([retRes.body]);

                fixImg.url = URL.createObjectURL(fixImg.rawImage);
                fixImg.safeURL = this.sanitizer.bypassSecurityTrustUrl(fixImg.url);
                fixImg.sourcesURL = gotoURL;

                return fixImg;
            }));

        return obby;
    }

    public getStreetImage(address: any, opts: GoogleMapsStreetOption = new GoogleMapsStreetOption()): Observable<FixedImage>
    {
        if (Util.isEmpty(address))
        {
            return;
        }

        let gotoURL = this.makeStreetURL(address);

        gotoURL += "&fov=" + opts.FOV;
        gotoURL += `&size=${opts.Size}x${opts.Size}`;
        gotoURL += "&pitch=" + opts.Pitch;

        if (!Util.isEmpty(opts.Heading))
        {
            gotoURL += "&heading=" + opts.Heading;
        }

        let obby = this.webClient.get(gotoURL, { observe: "response", responseType: "blob" })
            .pipe(map(retRes =>
            {
                let fixImg = new FixedImage();

                fixImg.rawImage = new Blob([retRes.body]);

                fixImg.url = URL.createObjectURL(fixImg.rawImage);
                fixImg.safeURL = this.sanitizer.bypassSecurityTrustUrl(fixImg.url);
                fixImg.sourcesURL = gotoURL;

                return fixImg;
            }));

        return obby;
    }
}