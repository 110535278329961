export class TaskRequestDTO
{
    public static readonly TaskRequest_Save = 1;
    public static readonly TaskRequest_Update = 2;
    public static readonly TaskRequest_FormDirty = 3;
    public static readonly TaskRequest_TabChanged = 4;

    data: any;
    taskID: number;

    constructor(taskID: number = 0, data: any = null)
    {
        this.data = data;
        this.taskID = taskID;
    }
}