import { Component, ViewChild } from "@angular/core";
import { ProductOverrideImportHTSService } from "@app/data/product/product-override-import-hts.service";
import { ProductOverrideImportHTS } from "@app/data/product/product-override-import-hts.model";
import { GridBase } from "@app/shared/ag-grid-helpers/ag-grid-base";
import { GridHelpers } from "@app/shared/ag-grid-helpers/grid-helper";
import { GridColumnIcon } from "@app/shared/ag-grid-helpers/models/grid-column-icon.model";
import { GridColumn } from "@app/shared/ag-grid-helpers/models/grid-column.model";
import { ConfirmationService, IConfirmationOptions } from "@app/shared/confirmation/confirmation.service";
import { ToastrService } from "ngx-toastr";
import { ProductOverrideImportHTSEdit } from "../product-override-import-hts-edit/product-override-import-hts-edit";

@Component({
    selector: "product-override-import-hts-list",
    templateUrl: "./product-override-import-hts-list.html"
})
export class ProductOverrideImportHTSList extends GridBase<ProductOverrideImportHTS>
{
    @ViewChild("dialogImport")
    dialogImport: ProductOverrideImportHTSEdit;

    constructor(
        toastrService: ToastrService,
        private confirmService: ConfirmationService,
        private prodImportHTSServ: ProductOverrideImportHTSService)
    {
        super(toastrService, prodImportHTSServ);

        this.setupGrid();
    }

    public onMyGridReady(evtArg): void
    {
        this.filterAdd("productUID", this.parentUID);

        this.onGridReady(evtArg, false);
    }

    public onAdd(): void
    {
        this.dialogImport.openAdd();
    }

    private setupGrid(): void
    {
        let gridIcons =
            [
                GridHelpers.MakeEditIcon(this.onEdit.bind(this)),
                GridHelpers.MakeDeleteIcon(this.onDelete.bind(this)),
            ];

        this.storageKey = "ProductOverrideImportHTS";
        this.addColumn(new GridColumn("country.name", "Country"));
        this.addColumn(new GridColumn("overrideImportHTS", "Import HTS"));
        this.addColumn(new GridColumnIcon(gridIcons));

        this.setServerPath("list");
        this.rowDoubleClick(this.onEdit.bind(this));
    }

    private onEdit(agData: any): void
    {
        this.dialogImport.openEdit(agData);
    }

    private onDelete(agData)
    {
        this.dataForm = this.makeForm(agData);

        let opts: IConfirmationOptions =
        {
            FunctionConfirm: () => this.deleteIt()
        };

        this.confirmService.Delete("Are you sure you want to delete this Override record?", opts);
    }

    private deleteIt()
    {
        this.delete().add(() => this.gridRefresh());
    }
}