import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService, ProfileType } from '@app/data';
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor
{
    constructor(private router: Router, private authService: AuthService)
    {
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>>
    {
        return next.handle(request).pipe(catchError(evtArg =>
        {
            if ([401, 403].indexOf(evtArg.status) !== -1)
            {
                console.error(evtArg);
                this.goToDashboard();
            }

            let error = (evtArg.error || evtArg.statusText);

            return throwError(error);
        }));
    }

    private goToDashboard()
    {
        let path;

        switch (this.authService.profile.profileType)
        {
            case ProfileType.admin:
                path = "/admin/mydashboard";
                break;

            case ProfileType.customer:
                path = "/customer/mydashboard";
                break;

            case ProfileType.freightForwarder:
                path = "/ff/mydashboard";
                break;

            case ProfileType.purchaser:
                path = "/purchaser/orders";
                break;
        }

        this.router.navigate([path]);
    }
}
