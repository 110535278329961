import { Component, ElementRef, ViewChild } from "@angular/core";
import { PurchaserService } from "@app/data";
import { GridFilter, GridFilterStorage } from "@app/shared/ag-grid-helpers/models/grid-filter.model";
import { GlobalIcons } from "@app/shared/GlobalIcons";
import { Util } from "@app/utils/util";
import { AgFilterComponent } from "ag-grid-angular";
import { IDoesFilterPassParams } from "ag-grid-community";
import { GridFilterCustom } from "../grid-filter-custom";

@Component({
    styleUrls: ["../../../styles/gridfilter.scss"],
    templateUrl: "./grid-purchaser-filter.component.html"
})
export class GridPurchaserFilter extends GridFilterCustom implements AgFilterComponent
{
    @ViewChild("listPurchasers")
    listPurchasers: ElementRef;

    public readonly Icons = GlobalIcons;

    public purchaserUID;

    private subObj: string;
    private subUID: string;
    private filterField;

    constructor(public purchaserService: PurchaserService)
    {
        super();
    }

    agInit(params: any): void
    {
        this.initFilter(params);
        this.filterField = this.getParam("field");

        this.purchaserService.getPurchaserLookup(false);

        if (this.filterField.indexOf(".") == -1)
        {
            this.subObj = null;
            this.subUID = null;
            return;
        }

        let broken = this.filterField.split(".");

        this.subObj = broken[0];
        this.subUID = broken[1];
    }

    isFilterActive(): boolean
    {
        return !Util.isEmpty(this.purchaserUID);
    }

    doesFilterPass(params: IDoesFilterPassParams): boolean
    {
        if (this.isServerSide)
        {
            return true;
        }

        if (this.subObj == null)
        {
            return this.purchaserUID == params.data[this.filterField];
        }

        let merch = params.data[this.subObj];

        if (!merch)
        {
            return false;
        }

        return merch[this.subUID] == this.purchaserUID;
    }

    getModel()
    {
        if (this.purchaserUID == null)
        {
            return null;
        }

        return new GridFilterStorage(this.filterField, new GridFilter(this.purchaserUID));
    }

    setModel(model: any)
    {
        this.purchaserUID = model?.gridFilter?.filter;
    }

    public onFilterClear(): void
    {
        this.purchaserUID = null;

        this.applyFilter();
        this.closeFilter();
    }

    public onFilterApply(): void
    {
        this.applyFilter();
        this.closeFilter();
    }
}