import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ApiService } from "../api/api.service";
import { PurchaserOwner } from "./purchaser-owner";

@Injectable({
    providedIn: "root"
})
export class PurchaserOwnerService extends ApiService<PurchaserOwner>
{
    public get editorForm()
    {
        let form = new FormBuilder();
        let grpFields = form.group(
            {
                uid: [],
                lastName: [, Validators.required],
                firstName: [, Validators.required],
                middleName: [],
                citizenshipUID: [, Validators.required],
                residenceCountryUID: [, Validators.required],
                dualCitizenshipUID: [],
                priorCitizenshipUID: []
            });

        return grpFields;
    }

    constructor(httpClient: HttpClient)
    {
        super(httpClient, "purchaser-owner");
    }
}