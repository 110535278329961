import { Component } from "@angular/core";
import { GridFilterCustom } from "@app/shared/ag-grid-helpers/filters/grid-filter-custom";
import { GridFilter, GridFilterDataType, GridFilterOperator, GridFilterStorage } from "@app/shared/ag-grid-helpers/models/grid-filter.model";
import { Util } from "@app/utils/util";
import { AgFilterComponent } from "ag-grid-angular";
import { IDoesFilterPassParams } from "ag-grid-community";

@Component({
    styleUrls: ["../../../styles/gridfilter.scss"],
    templateUrl: "./checklist-filter.component.html"
})
export class GridCheckListFilter extends GridFilterCustom implements AgFilterComponent
{
    public listItems;

    private filterField;
    private filterDataType: string;

    agInit(params: any): void
    {
        this.initFilter(params);

        this.listItems = this.getParam("items");
        this.filterField = this.getParam("field");
        this.filterDataType = this.getParam("filterDataType", GridFilterDataType.number);

        for (let lp in this.listItems)
        {
            let item = this.listItems[lp];

            if (item.hasOwnProperty("checked"))
            {
                continue;
            }

            if (item.hasOwnProperty("default"))
            {
                item.checked = item.default;
                continue;
            }

            item.checked = false;
        }
    }

    isFilterActive(): boolean
    {
        let idx = this.listItems.findIndex(elm => elm.checked);

        return idx != -1;
    }

    doesFilterPass(params: IDoesFilterPassParams): boolean
    {
        if (this.isServerSide)
        {
            return true;
        }

        let val = params.data[this.filterField];
        let chk = this.listItems.find(elm => elm.id == val);

        return chk != null && chk.checked;
    }

    getModel()
    {
        let idx = this.listItems.findIndex(elm => elm.checked);

        if (idx == -1)
        {
            return null;
        }

        return new GridFilterStorage(this.filterField, this.buildModel())
    }

    setModel(model: any)
    {
        this.breakModel(model);
    }

    public onFilterClear()
    {
        this.listItems.forEach(elm => elm.checked = false);

        this.applyAndClose();
    }

    public onFilterApply()
    {
        this.applyAndClose();
    }

    private buildModel()
    {
        let items = this.listItems.filter(elm => elm.checked);

        if (items.length == 1)
        {
            return new GridFilter(items[0].id, this.filterDataType);
        }

        let num = 0;
        let multi =
        {
            operator: GridFilterOperator.or,
            filterType: this.filterDataType
        };

        for (let dude of items)
        {
            num++;

            let key = "condition" + num;

            multi[key] = new GridFilter(dude.id, this.filterDataType);
        }

        return multi;
    }

    private breakModel(model)
    {
        model = model?.gridFilter;

        this.listItems.forEach(elm => elm.checked = false);

        if (Util.isEmpty(model))
        {
            return;
        }

        if (model.hasOwnProperty("filter"))
        {
            let item = this.listItems.find(elm => elm.id == model.filter);

            item.checked = true;
            return;
        }

        for (let mult in model)
        {
            if (typeof model[mult] != "object")
            {
                continue;
            }

            let item = this.listItems.find(elm => elm.id == model[mult].filter);

            item.checked = true;
        }
    }
}