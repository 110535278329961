import { HttpClient } from "@angular/common/http";
import { Component, ViewEncapsulation } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { WikiCommon, WikiErrorType } from "@app/data/wiki/wiki-common";
import { WikiError } from "@app/data/wiki/wiki-error.model";
import { WikiService } from "@app/data/wiki/wiki.service";
import { GlobalIcons } from '@app/shared/GlobalIcons';
import { Util } from "@app/utils/util";
import { ShowdownConverter } from "ngx-showdown";
import { map } from "rxjs/operators";

@Component(
    {
        selector: "wiki-renderer",
        styleUrls: ["../../styles/ee-wiki.scss"],
        templateUrl: "./wiki-renderer.component.html",
        encapsulation: ViewEncapsulation.None
    })
export class WikiTextComponent
{
    public wikiText: string;

    private arAnch = [];
    private arLinks = [];
    private iconList = new Map();
    private localURL: string;
    private holdImages: Map<string, any>;

    constructor(
        private webClient: HttpClient,
        private wikiServ: WikiService,
        private sanitizer: DomSanitizer,
        private showdownConverterServ: ShowdownConverter)
    {
        this.loadIcons();
        this.showdownConverterServ.setOption("backslashEscapesHTMLTags", true);
    }

    public parseWiki(rawWiki)
    {
        if (Util.isEmpty(rawWiki))
        {
            this.wikiText = "";
            return [];
        }

        let broken = rawWiki.split("\n");
        let errors = [];
        let newWiki = [];

        this.arAnch = [];
        this.arLinks = [];
        this.localURL = window.location.href;
        this.holdImages = new Map();

        //  Just in case there's a anchor in the URL
        this.localURL = this.localURL.replace(window.location.hash, "");

        for (let line of broken)
        {
            line = this.buildIcon(line);
            line = this.buildLinkAnchor(line);
            line = this.buildLinkExternal(line);
            line = this.buildLinkInternal(line);

            this.imageExtract(line);

            newWiki.push(line);
        }

        let primHTML = this.showdownConverterServ.makeHtml(newWiki.join("\n"));

        primHTML = Util.replaceAll(primHTML, "<ul>", "<ul class='wiki-list'>");
        primHTML = Util.replaceAll(primHTML, "<ol>", "<ol class='wiki-list'>");
        primHTML = Util.replaceAll(primHTML, "<td>", "<td class='wiki-table-cell'>");
        primHTML = Util.replaceAll(primHTML, "<th id=", "<th class='wiki-table-cell' id=");
        primHTML = Util.replaceAll(primHTML, "<thead>", "<thead class='wiki-table-header'>");

        this.wikiText = primHTML;

        if (this.arLinks.length > 0)
        {
            for (let tag of this.arLinks)
            {
                let idx = this.arAnch.indexOf(tag);

                if (idx == -1)
                {
                    errors.push(new WikiError(WikiErrorType.MissingAnchor, tag));
                }
            }
        }

        if (this.arAnch.length > 0)
        {
            for (let tag of this.arAnch)
            {
                let idx = this.arLinks.indexOf(tag);

                if (idx == -1)
                {
                    errors.push(new WikiError(WikiErrorType.MissingLink, tag));
                }
            }
        }

        return errors;
    }

    private loadIcons()
    {
        let keys = Object.keys(GlobalIcons);
        let vals = Object.values(GlobalIcons);

        for (let lp = 0; lp < keys.length; lp++)
        {
            if (keys[lp].startsWith("Size_"))
            {
                continue;
            }

            this.iconList.set(keys[lp], vals[lp]);
        }
    }

    private iconText(line)
    {
        var matches = line.match(/\[\[(.*?)\]\]/);

        if (matches)
        {
            return matches[1];
        }

        return null;
    }

    private textBetweenBracket(line)
    {
        var matches = line.match(/\[(.*?)\]/);

        if (matches)
        {
            return matches[1];
        }

        return null;
    }

    private textBetweenParentheses(line)
    {
        var matches = line.match(/\((.*?)\)/);

        if (matches)
        {
            return matches[1];
        }

        return null;
    }

    private buildIcon(line)
    {
        let justInCase = 0;

        while (true)
        {
            let beg = line.indexOf(WikiCommon.Tag_Icon);

            if (beg == -1 || justInCase > 10)
            {
                break;
            }

            let icon = this.iconText(line);

            beg = line.indexOf("[[");
            icon = this.iconList.get(icon);

            if (icon)
            {
                let end = line.indexOf("]]", beg);
                let link = `<i class="${icon}"></i>`;

                line = line.substring(0, beg) + link + line.substring(end + 2);
            }

            justInCase++;
        }

        return line;
    }

    private buildLinkAnchor(line: string): string
    {
        let justInCase = 0;

        while (true)
        {
            let beg = line.indexOf(WikiCommon.Tag_InternalAnchor);

            if (beg == -1 || justInCase > 10)
            {
                break;
            }

            let end = line.indexOf("]", beg + 1);
            let key = WikiCommon.Tag_InternalAnchor;
            let tag = line.substring(beg + 3, end);

            key = `${key}${tag}]`;
            line = line.replace(key, `<a id="${tag}"></a>`);
            justInCase++;

            this.arAnch.push(tag);
        }

        return line;
    }

    private buildLinkInternal(line: string): string
    {
        let justInCase = 0;

        while (true)
        {
            let beg = line.indexOf(WikiCommon.Tag_InternalLink);

            if (beg == -1 || justInCase > 10)
            {
                break;
            }

            let end = line.indexOf(")", beg + 1);
            let tag = line.substring(beg + 3, end);

            line = line.replace(WikiCommon.Tag_InternalLink, `](${this.localURL}#`);
            justInCase++;

            this.arLinks.push(tag);
        }

        return line;
    }

    private buildLinkExternal(line: string): string
    {
        let justInCase = 0;

        while (true)
        {
            let beg = line.indexOf(WikiCommon.Tag_ExternalLink);

            if (beg == -1 || justInCase > 10)
            {
                break;
            }

            let txt = this.textBetweenBracket(line);
            let page = this.textBetweenParentheses(line);

            beg = line.indexOf("[");
            page = page.substring(1);   //  Chop off the leading $

            let end = line.indexOf(")", beg);
            let link = `<a href="${page}" target="_blank" title="${page}">${txt}</a>`;

            line = line.substring(0, beg) + link + line.substring(end + 1);
            justInCase++;
        }

        return line;
    }

    private imageExtract(line: string): void
    {
        let beg = line.indexOf(WikiCommon.Tag_AEPImage);

        if (beg != -1)
        {
            this.imageParse(beg, line);
        }
    }

    private async imageParse(beg, line)
    {
        let img;
        let url = this.wikiServ.buildUrl("image");
        let endSpace = line.indexOf(" ", beg + 1);
        let preSpace = line.indexOf(")", beg + 1);

        if (endSpace != -1 && endSpace < preSpace)
        {
            img = line.substring(beg + 3, endSpace);
        }
        else
        {
            img = line.substring(beg + 3, preSpace);
        }

        url += `/${img}`;

        this.holdImages.set(img, null);

        let test = await this.webClient
            .get(url, { responseType: "blob" })
            .pipe(map(val => this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(val))))
            .toPromise();

        let downImg = test["changingThisBreaksApplicationSecurity"];

        this.holdImages.set(img, downImg);

        setTimeout(() => this.fixImages(), 1000);
    }

    private fixImages()
    {
        for (let key of this.holdImages.keys())
        {
            let img = this.holdImages.get(key);

            this.wikiText = this.wikiText.replace("@" + key, img);

            this.holdImages.delete(key);
        }
    }
}