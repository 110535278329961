import { AfterViewInit, Component, ContentChildren, ElementRef, Input, ViewChild } from "@angular/core";
import tippy, { hideAll } from "tippy.js";
import { PopupMenuItem } from "./popup-menu-item/popup-menu-item";

@Component({
    selector: "popup-menu",
    templateUrl: "./popup-menu.html"
})
export class PopupMenu implements AfterViewInit
{
    @Input()
    link;

    @ViewChild("menuContents")
    menuContents: ElementRef;

    @ViewChild("menuTrigger")
    menuTrigger: ElementRef;

    @ContentChildren(PopupMenuItem)
    menuItems;

    private menuOptions =
        {
            arrow: false,
            trigger: "manual",
            appendTo: document.body,
            placement: "top",
            hideOnClick: true,
            interactive: true,
            onShow: (instance) =>
            {
                hideAll({ exclude: instance });
            },
            onClickOutside: (instance, event) =>
            {
                this.isOpen = false;
                instance.hide();
            }
        };

    private isOpen;
    private menuInstance;

    constructor()
    {
    }

    public ngAfterViewInit()
    {
        this.menuInstance = tippy(this.menuTrigger.nativeElement);
        this.menuInstance.setProps(this.menuOptions);
        this.menuInstance.setContent(this.menuContents.nativeElement);
    }

    public onClick(evtArg)
    {
        this.hide();

        if (evtArg.click)
        {
            evtArg.click.emit();
        }
    }

    public onShowMenu()
    {
        this.toggle();
    }

    private show()
    {
        this.isOpen = true;
        this.menuInstance.show();
    }

    private hide()
    {
        this.isOpen = false;
        this.menuInstance.hide();
    }

    private toggle()
    {
        if (this.isOpen)
        {
            this.hide();
        }
        else
        {
            this.show();
        }
    }
}