import { Component } from "@angular/core";
import { Util } from "@app/utils/util";
import { AgRendererComponent } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

@Component({
    styleUrls: ["./grid-cell-contact.scss"],
    templateUrl: "./grid-cell-contact.html",
})
export class GridCellContactRenderer implements AgRendererComponent
{
    public email: string;
    public title: string;
    public setWidth: string;
    public phoneCell: string;
    public phoneWork: string;
    public contactName: string;

    private field: string;
    private showEmail: boolean;
    private showTitle: boolean;
    private showPhone: boolean;
    private cellParams: ICellRendererParams;

    agInit(params)
    {
        this.field = params.field;
        this.showEmail = params.showEmail;
        this.showTitle = params.showTitle;
        this.showPhone = params.showPhone;
        this.cellParams = params;

        if (Util.isEmpty(params.width))
        {
            this.setWidth = "100%";
        }
        else
        {
            this.setWidth = params.width + "px";
        }

        let contact = (this.field == null ? params.data : params.data[this.field]);

        if (Util.isEmpty(contact))
        {
            return;
        }

        this.contactName = Util.MakeName(contact);

        if (this.showTitle && contact.hasOwnProperty("title") && !Util.isEmpty(contact.title))
        {
            this.contactName += ` <sup class="text-muted">(${contact.title})</sup>`;
        }

        if (this.showPhone && contact.hasOwnProperty("cellPhone"))
        {
            this.phoneCell = contact.cellPhone;
        }

        if (this.showEmail && contact.hasOwnProperty("emailAddress"))
        {
            this.email = contact.emailAddress;
        }

        if (this.showPhone && contact.hasOwnProperty("workPhone"))
        {
            this.phoneWork = contact.workPhone;
        }
    }

    refresh(params): boolean
    {
        this.cellParams = params;
        return true;
    }
}