import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { map, mergeMap } from "rxjs/operators";
import { ApiService } from "../api/api.service";
import { DispatchService } from "../dispatch/dispatch.service";
import { OrderLicenseRule } from "./order-license-rule";

@Injectable({
    providedIn: "root"
})
export class OrderLicenseRuleService extends ApiService<OrderLicenseRule>
{
    public get editorForm()
    {
        let form = new FormBuilder();
        let grpFields = form.group(
            {
                uid: [],
                unitPrice1: [, Validators.required],
                unitPrice2: [, Validators.required],
                customerUID: [, Validators.required],
                categoryUID: [],
                description: [],
                earCategory: [, Validators.required],
                purchaserType: [, Validators.required],
                earSubCategory: [],
                exportLineItem: [],
                subCategoryUID: [],
                categoryOperator: [],
                magazineCapacity1: [],
                magazineCapacity2: [],
                actionTypeUID: [],
                unitPriceOperator: [],
                descriptionOperator: [],
                earCategoryOperator: [, Validators.required],
                subCategoryOperator: [],
                earSubCategoryOperator: [],
                actionTypeOperator: [],
                magazineCapacityOperator: []
            });

        return grpFields;
    }

    constructor(
        httpClient: HttpClient,
        private dispatchService: DispatchService)
    {
        super(httpClient, "order-license-rule");
    }

    import(file: File, data: OrderLicenseRule)
    {
        return this.dispatchService.upload(file).pipe(
            map(d1 =>
            {
                data.dispatch = {
                    fileName: d1.fileName,
                    fileNameUID: d1.fileNameUID
                };

                return d1;
            }),
            mergeMap(d2 =>
            {
                return this.post("import", data);
            })
        );
    }
}