import { Component, Input } from "@angular/core";
import { CustomerService } from "@app/data";
import { MerchantBase } from "@app/shared/customer/merchant-base";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: "customer-marketing-home",
    templateUrl: "customer-marketing-home.html"
})
export class CustomerMarketingHome extends MerchantBase
{
    @Input()
    customerUID: string;

    public readonly Tab_Images = 1;
    public readonly Tab_Search = 2;
    public readonly Tab_Specials = 3;

    constructor(toastrService: ToastrService,
        private custServ: CustomerService)
    {
        super(toastrService);
    }
}