import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { ApiService } from "../api/api.service";
import { OrdersCartCheck } from "./orders-cart-check";

@Injectable({
    providedIn: "root"
})
export class OrdersCartCheckService extends ApiService<OrdersCartCheck>
{
    public get dataForm()
    {
        let fb = new FormBuilder();
        let grp = fb.group(
            {
                statusType: [],
                customerUID: [],
                purchaserUID: []
            });

        return grp;
    }

    constructor(httpClient: HttpClient)
    {
        super(httpClient, "order-reports");
    }
}