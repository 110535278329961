import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { OrderCommon } from "@app/data/orders/order-common";
import { OrdersCartCheck } from "@app/data/orders/orders-cart-check";
import { OrdersCartCheckService } from "@app/data/orders/orders-cart-check.service";
import { GridBase } from "@app/shared/ag-grid-helpers/ag-grid-base";
import { GridHelpers } from '@app/shared/ag-grid-helpers/grid-helper';
import { GridColumnCustom } from "@app/shared/ag-grid-helpers/models/grid-column-custom.model";
import { GridColumnMerchant } from "@app/shared/ag-grid-helpers/models/grid-column-merchant";
import { GridColumnPurchaser } from "@app/shared/ag-grid-helpers/models/grid-column-purchaser";
import { GridColumnStatus } from "@app/shared/ag-grid-helpers/models/grid-column-status";
import { GridColumn, GridColumnType } from "@app/shared/ag-grid-helpers/models/grid-column.model";
import { Util } from "@app/utils/util";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: "order-reports-cart-check",
    templateUrl: "./report-cart-check.component.html",
})
export class OrderCartCheckReportComponent extends GridBase<OrdersCartCheck>
{
    constructor(
        toastrService: ToastrService,
        private router: Router,
        private cartCheckService: OrdersCartCheckService)
    {
        super(toastrService, cartCheckService);

        this.setupGrid();
    }

    private setupGrid()
    {
        let colDate = new GridColumn("createdDateEST", "Created", GridColumnType.DateTime);

        colDate.sort = GridHelpers.SortDir_Decending;

        this.storageKey = "OrderCartCheckReport";
        this.addColumn(new GridColumnStatus(OrderCommon.OrderStatuses));
        this.addColumn(colDate);
        this.addColumn(new GridColumn("createdBy", "Created By"));
        this.addColumn(new GridColumnMerchant("customer.displayName", "customer.uid"));
        this.addColumn(new GridColumnPurchaser("purchaser.displayName", "purchaser.uid"));
        this.addColumn(new GridColumnCustom("poNumber", "Order #", { width: 100 }));
        this.addColumn(new GridColumnCustom("shippingAddress.state.name", "Location", { cellRenderer: this.renderLocation, filter: "text" }));
        this.addColumn(new GridColumn("distinctProductCount", "# Products", GridColumnType.Number));
        this.addColumn(new GridColumn("totalQuantity", "Qty", GridColumnType.Number));
        this.addColumn(new GridColumn("totalAmount", "Amount", GridColumnType.Money));
        this.addColumn(new GridColumn("message", "Message"));

        this.setServerPath("cart-check");
        this.rowDoubleClick(this.onGotoOrder.bind(this));
    }

    private renderLocation(params: any)
    {
        let cnt = params.node.data.shippingAddress?.country?.name;
        let state = params.node.data.shippingAddress?.state?.name;

        if (!Util.isEmpty(state))
        {
            cnt = `${state}, ${cnt}`;
        }

        return cnt;
    }

    private onGotoOrder(agData)
    {
        this.router.navigate(["/admin/order-details", agData.uid]);
    }
}