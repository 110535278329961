export enum EntityType
{
    Merchant = 1,
    License = 2,
    Order = 3,
    Product = 4,
    Purchaser = 5,
    FreightForwarder = 6,
    Task = 7,
    OrderDocument = 8
}