import { Component } from "@angular/core";
import { ProfileService } from "@app/data";
import { GridFilterCustom } from "@app/shared/ag-grid-helpers/filters/grid-filter-custom";
import { GridFilter, GridFilterStorage } from "@app/shared/ag-grid-helpers/models/grid-filter.model";
import { Util } from "@app/utils/util";
import { AgFilterComponent } from "ag-grid-angular";
import { IDoesFilterPassParams } from "ag-grid-community";

@Component({
    styleUrls: ["../../../styles/gridfilter.scss"],
    templateUrl: "./grid-user-filter.component.html"
})
export class GridUserFilter extends GridFilterCustom implements AgFilterComponent
{
    public readonly idTag_ID = "id";
    public readonly idTag_UID = "uid";

    public useID: string;
    public selectedID;

    private filterField;

    constructor(public profileServ: ProfileService)
    {
        super();
    }

    agInit(params: any): void
    {
        this.initFilter(params);

        this.filterField = this.getParam("field");

        let id = this.getParam("useUID", true);

        if (id)
        {
            this.useID = this.idTag_UID;
        }
        else
        {
            this.useID = this.idTag_ID;
        }

        this.profileServ.getProfileList();
    }

    isFilterActive(): boolean
    {
        return !Util.isEmpty(this.selectedID);
    }

    doesFilterPass(params: IDoesFilterPassParams): boolean
    {
        if (this.isServerSide)
        {
            return true;
        }

        return params.data[this.filterField] == this.selectedID;
    }

    getModel()
    {
        if (this.selectedID == null)
        {
            return null;
        }

        return new GridFilterStorage(this.filterField, new GridFilter(this.selectedID));;
    }

    setModel(model: any)
    {
        this.selectedID = model?.gridFilter?.filter;
    }

    public onFilterApply()
    {
        this.applyAndClose();
    }

    public onFilterClear()
    {
        this.selectedID = null;
        this.applyAndClose();
    }
}