import { Injectable } from "@angular/core";
import { OrderStatusType } from "@app/data/enums/order-status-type.enum";
import { ProfileType } from "@app/data/enums/profile-type.enum";
import { ExcelHelper } from "@app/data/misc/excel-helper";
import { OrderStatus } from "@app/data/misc/order-status";
import { HoldItemModel } from "@app/shared/orders/order-grid-components/hold-item.model";
import { Util } from "@app/utils/util";

@Injectable({
    providedIn: "root"
})
export class OrderDataHelper
{
    public static readonly Tab_Ship = 3;
    public static readonly Tab_Products = 1;
    public static readonly Tab_Documents = 2;

    public static readonly OrderState_Create = 1;
    public static readonly OrderState_Validate = 0;

    public static readonly Hold_AEP = new HoldItemModel("AEP", "AEP (Admin) hold");
    public static readonly Hold_EAR = new HoldItemModel("LIC", "EAR License hold");
    public static readonly Hold_ITAR = new HoldItemModel("ITAR", "ITAR License hold");
    public static readonly Hold_Import = new HoldItemModel("IMP", "Import Permit hold");

    public static readonly Status_Hold = new OrderStatus(OrderStatusType.Hold, "Hold");
    public static readonly Status_Active = new OrderStatus(OrderStatusType.Active, "Active");
    public static readonly Status_Failed = new OrderStatus(OrderStatusType.Failed, "Failed");
    public static readonly Status_Checked = new OrderStatus(OrderStatusType.OrderCheck, "Order Check");
    public static readonly Status_Cleared = new OrderStatus(OrderStatusType.Cleared, "Cleared");
    public static readonly Status_Partial = new OrderStatus(OrderStatusType.PartiallyCleared, "Partially Cleared");
    public static readonly Status_Complete = new OrderStatus(OrderStatusType.Complete, "Complete");
    public static readonly Status_Cancelled = new OrderStatus(OrderStatusType.Cancelled, "Cancelled");
    public static readonly Status_Suspended = new OrderStatus(OrderStatusType.Suspended, "Suspended");

    public static readonly ProductCategories_StorageKey = "ProdCategories";

    public static userRole: number;

    public static readonly ChargeStatuses =
        [
            { id: 1, name: "Active" },
            { id: 2, name: "Pending" },
            { id: 4, name: "Complete" },
            { id: 7, name: "New" },
            { id: 9, name: "Cancelled" },
            { id: 10, name: "Pending Review" },
        ];

    public static readonly OrderStatuses =
        [
            { id: 2, name: "Partially Cleared" },
            { id: 3, name: "Cleared" },
            { id: 4, name: "Complete" },
            { id: 10, name: "Hold" },
            { id: 11, name: "Suspended" },
            { id: 13, name: "Cancelled" }
        ];

    public static readonly NotificationRequestStatuses =
        [
            { id: 2, name: "Purchaser working on registration" },
            { id: 4, name: "Complete" },
            { id: 7, name: "Registration not started" },
            { id: 9, name: "Registration is inactive" },
            { id: 10, name: "Awaiting EasyExport approval" }
        ];

    public static readonly ShipmentStatuses =
        [
            { id: 1, name: "Active" },
            { id: 4, name: "Complete" }
        ];

    static get HoldList()
    {
        let list = new Array<HoldItemModel>();

        list.push(OrderDataHelper.Hold_AEP);
        list.push(OrderDataHelper.Hold_EAR);
        list.push(OrderDataHelper.Hold_ITAR);
        list.push(OrderDataHelper.Hold_Import);

        return list;
    }

    public static CanShip(data)
    {
        if (OrderDataHelper.userRole != ProfileType.customer)
        {
            return false;
        }

        let canDo =
            !data.isShipmentActive &&
            data.statusType != OrderStatusType.Hold &&
            data.statusType != OrderStatusType.Complete &&
            data.statusType != OrderStatusType.Cancelled &&
            data.statusType != OrderStatusType.Suspended;

        return canDo;
    }

    public static makeProductMessage(messageObj)
    {
        let img;

        if (messageObj.hasOwnProperty("node"))
        {
            messageObj = messageObj.node.data;
        }

        if (Util.isEmpty(messageObj.url))
        {
            img = "";
        }
        else
        {
            let toolTip = messageObj.product?.status?.name ?? "";

            img = `<img class='grid-message-img' src='${messageObj.url}' title='${toolTip}' />`;
        }

        return Util.substitute("<div class='grid-valid-message' title='{1}'>{0} {1}</div>", img, messageObj.message);
    }

    public static getBulkTemplate()
    {
        ExcelHelper.makeTemplate("BulkProducts", ["PartNumber", "Quantity", "Price" ]);
    }
}