import { Component, Input, OnInit } from "@angular/core";
import { AuthService, CustomerService } from "@app/data";
import { MerchantRequestDTO } from "@app/data/customer/merchant-request.dto";
import { CommonSettings } from "@app/shared/common-settings";
import { MerchantBase } from "@app/shared/customer/merchant-base";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: "customer-marketing-search",
    styleUrls: ["customer-marketing-search.scss"],
    templateUrl: "customer-marketing-search.html"
})
export class CustomerMarketingSearch extends MerchantBase implements OnInit
{
    @Input()
    customerUID: string;

    public userRole: number;
    public editorConfig = CommonSettings.CKEditorToolbar;
    public messageEditor = ClassicEditor;

    constructor(toastrService: ToastrService,
        private custServ: CustomerService,
        private authService: AuthService)
    {
        super(toastrService);

        this.dataForm = this.custServ.editorForm;
        this.apiService = this.custServ;
    }

    async ngOnInit()
    {
        let prof = await this.authService.getProfile$().toPromise();

        this.userRole = prof.profileType;

        this.loadCustomer(this.customerUID);
    }

    private handleQuestion(question: MerchantRequestDTO)
    {
        switch (question.requestID)
        {
            case MerchantRequestDTO.Request_Save:
                break;
        }
    }

    private saveIt()
    {
        let sub = this.submit();

        if (!sub)
        {
            return;
        }

        sub.add(() => this.submitComplete());
    }

    private submitComplete()
    {
        if (this.submitError)
        {
            return;
        }
    }
}