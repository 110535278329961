import { ProductRuleType } from './../enums/product-rule-type.enum';
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { map, mergeMap } from "rxjs/operators";
import { ApiService } from "../api/api.service";
import { DispatchService } from "../dispatch/dispatch.service";
import { ProductRule } from "./product-rule";

@Injectable({
    providedIn: "root"
})
export class ProductRuleService extends ApiService<ProductRule>
{
    public get uploadForm()
    {
        let form = new FormBuilder();
        let grpFields = form.group(
            {
                fileName: [, Validators.required],
                productRuleType: []
            });

        return grpFields;
    }

    public get exportForm()
    {
        let form = new FormBuilder();
        let grpFields = form.group(
            {
                uid: [],
                caliber: [{ disabled: true, value: null }, Validators.required],
                actionUID: [{ disabled: true, value: null }, Validators.required],
                categoryUID: [{ disabled: true, value: null }, Validators.required],
                description: [{ disabled: true, value: null }, Validators.required],
                barrelLength: [{ disabled: true, value: null }, Validators.required],
                description2: [{ disabled: true, value: null }, Validators.required],
                actionOperator: [],
                subCategoryUID: [{ disabled: true, value: null }, Validators.required],
                caliberOperator: [],
                productRuleType: [],
                categoryOperator: [],
                ammunitionTypeUID: [{ disabled: true, value: null }, Validators.required],
                magazineCapacity1: [{ disabled: true, value: null }, Validators.required],
                magazineCapacity2: [{ disabled: true, value: null }, Validators.required],
                subClassification: [],
                mainClassification: [, Validators.required],
                descriptionOperator: [],
                subCategoryOperator: [],
                barrelLengthOperator: [],
                descriptionOperator2: [],
                isBusinessExportable: [],
                classificationTypeUID: [, Validators.required],
                ammunitionTypeOperator: [],
                isGovernmentExportable: [],
                isIndividualExportable: [],
                cJorCCATSClassification: [{ disabled: true, value: null }, Validators.required],
                magazineCapacityOperator: [],
                cJorCCATSClassificationOperator: []
            });

        return grpFields;
    }

    public get importForm()
    {
        let form = new FormBuilder();
        let grpFields = form.group(
            {
                uid: [],
                caliber: [{ disabled: true, value: null }, Validators.required],
                stateUID: [],
                actionUID: [{ disabled: true, value: null }, Validators.required],
                isLithium: [],
                sortOrder: [],
                countryUID: [],
                categoryUID: [{ disabled: true, value: null }, Validators.required],
                description: [{ disabled: true, value: null }, Validators.required],
                barrelLength: [{ disabled: true, value: null }, Validators.required],
                description2: [{ disabled: true, value: null }, Validators.required],
                isImportable: [],
                actionOperator: [],
                subCategoryUID: [{ disabled: true, value: null }, Validators.required],
                caliberOperator: [],
                productRuleType: [ProductRuleType.Import],
                categoryOperator: [],
                ammunitionTypeUID: [{ disabled: true, value: null }, Validators.required],
                magazineCapacity1: [{ disabled: true, value: null }, Validators.required],
                magazineCapacity2: [{ disabled: true, value: null }, Validators.required],
                mainClassification: [, Validators.required],
                descriptionOperator: [],
                subCategoryOperator: [],
                barrelLengthOperator: [],
                descriptionOperator2: [],
                classificationTypeUID: [, Validators.required],
                ammunitionTypeOperator: [],
                magazineCapacityOperator: []
            });

        return grpFields;
    }

    constructor(
        httpClient: HttpClient,
        private dispatchService: DispatchService)
    {
        super(httpClient, "product-rule");
    }

    import(file: File, data: ProductRule)
    {
        return this.dispatchService.upload(file).pipe(
            map(d1 =>
            {
                data.dispatch = {
                    fileName: d1.fileName,
                    fileNameUID: d1.fileNameUID
                };

                return d1;
            }),
            mergeMap(d2 =>
            {
                return this.post("import", data);
            })
        );
    }
}