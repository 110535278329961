import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { AuthService, CustomerService } from "@app/data";
import { MerchantRequestDTO } from "@app/data/customer/merchant-request.dto";
import { MerchantBase } from "@app/shared/customer/merchant-base";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: "customer-marketing-images",
    styleUrls: ["customer-marketing-images.scss"],
    templateUrl: "customer-marketing-images.html"
})
export class CustomerMarketingImages extends MerchantBase implements OnInit, OnDestroy
{
    @Input()
    customerUID: string;

    public currentLogo;
    public currentBanner;

    public imgLogo: File;
    public imgBanner: File;

    public isLogoOver: boolean = false;
    public isBannerOver: boolean = false;

    constructor(toastrService: ToastrService,
        public custServ: CustomerService,
        private authService: AuthService)
    {
        super(toastrService);

        this.dataForm = this.custServ.editorForm;
        this.apiService = this.custServ;
        this.subQuestion = this.custServ.merchantQuestion.subscribe((request) => { this.handleQuestion(request) });
    }

    ngOnInit(): void
    {
        let sub = this.loadCustomer(this.customerUID);

        if (sub == null)
        {
            return;
        }

        sub.add(() =>
        {
            this.custServ.getImage(this.data.logoImageURL).subscribe(retRes => this.currentLogo = retRes.safeURL);
            this.custServ.getImage(this.data.bannerImageURL).subscribe(retRes => this.currentBanner = retRes.safeURL);
        });
    }

    ngOnDestroy(): void
    {
        this.subQuestion.unsubscribe();
    }

    public onDragEnter(imgType: number): void
    {
        switch (imgType)
        {
            case this.custServ.ImgType_Logo:
                this.isLogoOver = true;
                break;

            case this.custServ.ImgType_Banner:
                this.isBannerOver = true;
                break;
        }
    }

    public onDragLeave(imgType: number): void
    {
        switch (imgType)
        {
            case this.custServ.ImgType_Logo:
                this.isLogoOver = false;
                break;

            case this.custServ.ImgType_Banner:
                this.isBannerOver = false;
                break;
        }
    }

    private handleQuestion(question: MerchantRequestDTO)
    {
        switch (question.requestID)
        {
            case MerchantRequestDTO.Request_Save:
                //this.checkLogo();
                break;
        }
    }

    public onLogoUpload(evtArg: any): void
    {
        this.blockOn("Uploading...");

        let sub = this.custServ.uploadImage(this.customerUID, this.custServ.ImgType_Logo, evtArg.target.files[0]).subscribe(
            {
                next: (retRes: string) => this.imageDone(this.custServ.ImgType_Logo, retRes),
                error: (errRes) => this.error(errRes)
            });

        sub.add(() => this.blockOff());
    }

    public onBannerUpload(evtArg: any): void
    {
        this.blockOn("Uploading...");

        let sub = this.custServ.uploadImage(this.customerUID, this.custServ.ImgType_Banner, evtArg.target.files[0]).subscribe(
            {
                next: (retRes: string) => this.imageDone(this.custServ.ImgType_Banner, retRes),
                error: (errRes) => this.error(errRes)
            });

        sub.add(() => this.blockOff());
    }

    private imageDone(imgType: number, imgName: string): void
    {
        this.custServ.getImage(imgName).subscribe(
            {
                next: (retRes) =>
                {
                    switch (imgType)
                    {
                        case this.custServ.ImgType_Logo:
                            this.currentLogo = retRes.safeURL;
                            break;

                        case this.custServ.ImgType_Banner:
                            this.currentBanner = retRes.safeURL;
                            break;
                    }
                }
            });
    }
}