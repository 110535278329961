import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { BaseComponent, Lookup, ProfileService } from "@app/data";
import { Priority } from "@app/data/enums/priority.enum";
import { TaskStatus } from "@app/data/enums/task-status.enum";
import { TaskCommon } from "@app/data/tasks/task-common";
import { TaskRequestDTO } from "@app/data/tasks/task-request.dto";
import { TaskWork } from '@app/data/tasks/taskwork.model';
import { TaskService } from "@app/data/tasks/taskwork.service";
import { CommonSettings } from '@app/shared/common-settings';
import { DateFormatter } from '@app/shared/pipes/date-formatter.pipe';
import { DateHelper } from '@app/utils/date-helper';
import { Util } from "@app/utils/util";
import { NgbDateAdapter } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";

@Component({
    selector: "my-task-detail",
    templateUrl: "./my-task-detail.component.html"
})
export class MyTaskDetailComponent extends BaseComponent<TaskWork> implements OnInit, OnDestroy
{
    @Input()
    entityUID: string;

    @Input()
    entityType: number;

    public get haveChanges(): boolean
    {
        return this.dataForm.dirty;
    }

    public get haveDueDate(): boolean
    {
        let dd = this.getValue("dueDate");

        return !Util.isEmpty(dd);
    }

    public readonly priorities = CommonSettings.Priorities;
    public readonly taskStatus = TaskCommon.TaskStatuses;

    public hoursList: number[] = [1, 2, 4, 6, 8, 12, 24, 36, 48];
    public categories: Lookup[];

    private taskSub: Subscription;
    private ogDueDate: Date;

    constructor(
        toastrService: ToastrService,
        public profileServ: ProfileService,
        private taskServ: TaskService,
        private dateAdapter: NgbDateAdapter<Date>)
    {
        super(toastrService);

        this.taskSub = this.taskServ.taskQuestion.subscribe((request) => { this.handleQuestions(request) });
        this.dataForm = this.taskServ.editorForm;
        this.ogDueDate = null;
        this.apiService = this.taskServ;
    }

    ngOnInit(): void
    {
        this.taskServ.getCategories(this.entityType).subscribe((retRes) => this.categories = retRes.results);

        this.profileServ.getProfileList();
        this.setupUI();
    }

    ngOnDestroy()
    {
        this.taskSub.unsubscribe();
    }

    public onAssignedChanged()
    {
        this.setValue("statusType", TaskStatus.New);
    }

    public onStatusChange()
    {
        let stat = this.getValue("statusType");

        if (stat == TaskStatus.Complete)
        {
            this.setValue("completeDate", DateFormatter.Now);
        }
        else
        {
            this.setValue("completeDate", null);
        }
    }

    public onDueHoursAdd(evtArg): void
    {
        let currDate =  moment(this.getValue("taskDate"));

        currDate.add(evtArg, "hours");

        this.setValue("dueDate", currDate.format(DateFormatter.FormatDateTime));
    }

    public onDueClear(): void
    {
        this.setValue("dueDate", null);
    }

    private setupUI()
    {
        if (!this.data)
        {
            this.setValue("taskDate", DateHelper.formatDate(DateFormatter.Now, DateFormatter.FormatDateTime));
            this.setValue("entityUID", this.entityUID);
            this.setValue("entityType", this.entityType);
            this.setValue("statusType", TaskStatus.New);
            this.setValue("priorityType", Priority.Medium);
            return;
        }

        this.patchValue();

        this.disable(["taskDate"]);
        this.setValue("taskDate", DateHelper.formatDate(this.data.taskDate, DateFormatter.FormatDateTime));

        this.readOnly = (this.data.statusType == TaskStatus.Complete);
    }

    private handleQuestions(request: TaskRequestDTO)
    {
        switch (request.taskID)
        {
            case TaskRequestDTO.TaskRequest_Save:
                this.saveTask();
                break;
        }
    }

    private saveTask()
    {
        let note = [];

        if (this.data?.uid == null)
        {
            note.push("Task created");
        }
        else
        {
            if (this.fieldDiff("assignedToUID"))
            {
                let ass = this.getValue("assignedToUID");
                let dude = this.profileServ.lookupList.find(elm => elm.uid == ass);

                note.push("Task assigned to: " + dude.name);
            }

            if (this.fieldDiff("priorityType"))
            {
                let pri = this.getValue("priorityType");
                let dude = this.priorities.find(elm => elm.id == pri);

                note.push("Priority changed to " + dude.name);
            }

            if (this.fieldDiff("completeDate"))
            {
                note.push("Task marked as complete");
            }
        }

        this.setValue("note", note.join("\n"));

        let obby = this.submit();

        if (obby != null)
        {
            obby.add(() => this.saveDone());
        }
    }

    private fieldDiff(who: string)
    {
        return this.data[who] != this.getValue(who);
    }

    private saveDone()
    {
        if (this.submitError)
        {
            return;
        }

        this.readOnly = (this.data.statusType == TaskStatus.Complete);

        // The readOnly set above is causing the form to be dirty.  Why??
        this.dataForm.markAsPristine();

        let ans = new TaskRequestDTO(TaskRequestDTO.TaskRequest_Save, this.data);

        this.taskServ.sendAnswer(ans);
    }
}