import { ProfileType } from '@app/data';
import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AppService } from "@app/app.service";
import { GlobalIcons } from '@app/shared/GlobalIcons';
import { OrderDataHelper } from "@app/shared/orders/order-data.helper";
import { OrderRemoveProductReportComponent } from "../report-remove-product/report-remove-product.component";
import { OrderCartCheckReportComponent } from "../report-cart-check/report-cart-check.component";

@Component({
    selector: "order-reports-home",
    styleUrls: ["order-reports-home.component.scss"],
    templateUrl: "./order-reports-home.component.html",
})
export class OrderReportsComponent implements OnInit
{
    @ViewChild("repCheck")
    repCheck: OrderCartCheckReportComponent;

    @ViewChild("repRemove")
    repRemove: OrderRemoveProductReportComponent;

    public readonly Icons = GlobalIcons;
    public readonly Report_CartCheck = { id: 1, name: "Cart Check" };
    public readonly Report_RemovedProduct = { id: 2, name: "Removed Products" };

    public title: string;
    public selectedReport: number;

    public reports: Array<any> = [];

    constructor(private appService: AppService, private activeRoute: ActivatedRoute)
    {
        this.title = "Order Reports";
        OrderDataHelper.userRole = this.activeRoute.snapshot.data.userType;
        this.appService.pageTitle = this.title;

        if (OrderDataHelper.userRole == ProfileType.admin)
        {
            this.reports.push(this.Report_CartCheck);
        }

        this.reports.push(this.Report_RemovedProduct);
    }

    ngOnInit(): void
    {
        this.selectedReport = this.reports[0].id;
    }

    public onRefresh(): void
    {
        switch (this.selectedReport)
        {
            case this.Report_CartCheck.id:
                this.repCheck.gridRefresh();
                break;

            case this.Report_RemovedProduct.id:
                this.repRemove.gridRefresh();
                break;
        }
    }
}