import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { AuthService, CustomerService, ProfileType } from "@app/data";
import { MerchantRequestDTO } from "@app/data/customer/merchant-request.dto";
import { EntityType } from "@app/data/enums/entity-type.enum";
import { TaskEntity } from "@app/data/enums/task-entity.enum";
import { CommonSettings } from "@app/shared/common-settings";
import { ConfirmationService, IConfirmationOptions } from "@app/shared/confirmation/confirmation.service";
import { CustomerBillingComponent } from "@app/shared/customer/customer-billing/customer-billing.component";
import { CustomerDetailsEditComponent } from "@app/shared/customer/customer-details-edit/customer-details-edit.component";
import { GlobalIcons } from "@app/shared/GlobalIcons";
import { Subscription } from "rxjs";
import { CustomerMarketingHome } from "../customer-marketing/customer-marketing-home/customer-marketing-home";

@Component({
    selector: "customer-tabs",
    templateUrl: "./customer-tabs.component.html"
})
export class CustomerTabsComponent implements OnInit, OnDestroy
{
    @ViewChild("custNav")
    custNav;

    @ViewChild("custDetail")
    custDetail: CustomerDetailsEditComponent;

    @ViewChild("custBilling")
    custBilling: CustomerBillingComponent;

    @ViewChild("custPromo")
    custPromo: CustomerMarketingHome;

    @Input()
    customerUID: string;

    @Output()
    logoChanged = new EventEmitter();

    @Output()
    customerUpdated = new EventEmitter();

    public readonly Icons = GlobalIcons;
    public readonly Tab_Notes = 1;
    public readonly Tab_Promo = 2;
    public readonly Tab_Tasks = 3;
    public readonly Tab_Member = 4;
    public readonly Tab_ShipTo = 5;
    public readonly Tab_Account = 6;
    public readonly Tab_Address = 7;
    public readonly Tab_Billing = 8;
    public readonly Tab_Contact = 9;
    public readonly Tab_Details = 10;
    public readonly Tab_Purchasers = 11;
    public readonly profileTypes = ProfileType;
    public readonly noteEntityType = EntityType.Merchant;
    public readonly taskEntityType = TaskEntity.Merchant;

    public setTab;
    public userRole;
    public subAnswer: Subscription;
    public subQuestion: Subscription;

    constructor(
        private authService: AuthService,
        private confirmService: ConfirmationService,
        private customerService: CustomerService)
    {
        this.subAnswer = this.customerService.merchantAnswer.subscribe((request) => { this.handleAnswer(request) });
        this.subQuestion = this.customerService.merchantQuestion.subscribe((request) => { this.handleQuestions(request) });
    }

    async ngOnInit()
    {
        let prof = await this.authService.getProfile$().toPromise();

        this.userRole = prof.profileType;
    }

    ngOnDestroy(): void
    {
        this.subAnswer.unsubscribe();
        this.subQuestion.unsubscribe();
    }

    public handleAnswer(answer: MerchantRequestDTO)
    {
        switch (answer.requestID)
        {
            case MerchantRequestDTO.Request_Save:
                if (this.customerUpdated)
                {
                    this.customerUpdated.emit(answer.data);
                }
                break;
        }
    }

    public handleQuestions(question: MerchantRequestDTO)
    {
        switch (question.requestID)
        {
            case MerchantRequestDTO.Request_DirtyNav:
            case MerchantRequestDTO.Request_DirtyClose:
                this.checkDirty(question.requestID);
                break;

            case MerchantRequestDTO.Request_LogoChanged:
                if (this.logoChanged)
                {
                    this.logoChanged.emit(question.data);
                }
                break;
        }
    }

    public onTabChanged(evtArg)
    {
        let dirty = false;

        switch (evtArg.activeId)
        {
            case this.Tab_Promo:
                dirty = this.custPromo.haveChanges;
                break;

            case this.Tab_Details:
                dirty = this.custDetail.haveChanges;
                break;

            case this.Tab_Billing:
                dirty = this.custBilling.haveChanges;
                break;
        }

        if (!dirty)
        {
            this.sendTabChanged(evtArg.nextId);
            return;
        }

        evtArg.preventDefault();

        let opts: IConfirmationOptions =
        {
            FunctionConfirm: () =>
            {
                this.setTab = evtArg.nextId;
                this.sendTabChanged(evtArg.nextId);
            }
        };

        this.confirmService.YesNo(CommonSettings.DirtyFormConfirmationMessage, opts);
    }

    public checkDirty(requestID)
    {
        let dirty;

        switch (this.setTab)
        {
            case this.Tab_Promo:
                dirty = this.custPromo.haveChanges;
                break;

            case this.Tab_Details:
                dirty = this.custDetail.haveChanges;
                break;

            case this.Tab_Billing:
                dirty = this.custBilling.haveChanges;
                break;
        }

        if (!dirty)
        {
            if (requestID == MerchantRequestDTO.Request_DirtyClose)
            {
                this.sendClose();
            }
            else
            {
                this.sendNav(true);
            }

            return;
        }

        let opts: IConfirmationOptions =
        {
            FunctionConfirm: () =>
            {
                if (requestID == MerchantRequestDTO.Request_DirtyClose)
                {
                    this.sendClose();
                }
                else
                {
                    this.sendNav(true);
                }
            }
        };

        if (requestID == MerchantRequestDTO.Request_DirtyNav)
        {
            opts.FunctionClose = () => this.sendNav(false);
        }

        this.confirmService.YesNo(CommonSettings.DirtyFormConfirmationMessage, opts);
    }

    public onLogoChanged(logoImg)
    {
        if (this.logoChanged)
        {
            this.logoChanged.emit(logoImg);
        }
    }

    private sendNav(allowNav)
    {
        let ans = new MerchantRequestDTO(MerchantRequestDTO.Request_DirtyNav, allowNav);

        setTimeout(() => this.customerService.sendAnswer(ans), 250);
    }

    private sendClose()
    {
        let quest = new MerchantRequestDTO(MerchantRequestDTO.Request_Close);

        this.customerService.sendQuestion(quest);
    }

    private sendTabChanged(whatTab)
    {
        let save = (whatTab == this.Tab_Details || whatTab == this.Tab_Billing || whatTab == this.Tab_Promo);
        let quest = new MerchantRequestDTO(MerchantRequestDTO.Request_TabChanged, save);

        this.customerService.sendQuestion(quest);
    }
}