export class ProductRequestDTO
{
    public static readonly Request_Save = 1
    public static readonly Request_Close = 2;
    public static readonly Request_TabChanged = 3;

    data: any;
    requestID: number;

    constructor(requestID: number = 0, data: any = null)
    {
        this.data = data;
        this.requestID = requestID;
    }
}