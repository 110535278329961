import { Component, ElementRef, ViewChild } from "@angular/core";
import { BaseComponent, PurchaserAddress } from "@app/data";
import { PurchaserAddressImage } from "@app/data/purchaser-address/purchaser-address-image.model";
import { PurchaserAddressImageService } from "@app/data/purchaser-address/purchaser-address-image.service";
import { CommonSettings } from "@app/shared/common-settings";
import { ContactFormatter } from "@app/utils/contact-formatter";
import { Util } from '@app/utils/util';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { PurchaserAddressVerificationCapture } from "../purchaser-address-verification-capture/purchaser-address-verification-capture.component";
import { PurchaserAddressVerificationEdit } from "../purchaser-address-verification-edit/purchaser-address-verification-edit.component";
import { PurchaserAddressVerificationPaste } from "../purchaser-address-verification-paste/purchaser-address-verification-paste";

@Component({
    selector: "purchaser-address-verification-list",
    styleUrls: ["purchaser-address-verification-list.component.scss"],
    templateUrl: "./purchaser-address-verification-list.component.html"
})
export class PurchaserAddressVerificationList extends BaseComponent<PurchaserAddressImage>
{
    @ViewChild("dialogList")
    dialogList: ElementRef;

    @ViewChild("dialogEdit")
    dialogEdit: PurchaserAddressVerificationEdit;

    @ViewChild("dialogPaste")
    dialogPaste: PurchaserAddressVerificationPaste;

    @ViewChild("dialogCapture")
    dialogCapture: PurchaserAddressVerificationCapture;

    public displayAddress: string;

    private purchaserAddy: PurchaserAddress;

    constructor(
        toastrService: ToastrService,
        public modalService: NgbModal,
        private purchaserImgServ: PurchaserAddressImageService)
    {
        super(toastrService);

        this.dataForm = this.purchaserImgServ.editorForm;
        this.apiService = purchaserImgServ;
        this.modalService = this.modalService;
    }

    public openVerification(purchaserAddy: PurchaserAddress): void
    {
        this.open(this.dialogList, null, CommonSettings.DialogSize_XLarge);

        let contForm = new ContactFormatter(purchaserAddy);

        this.loading = true;
        this.searchForm = this.makeForm({ purchaserAddressUID: purchaserAddy.uid });
        this.purchaserAddy = purchaserAddy;
        this.displayAddress = contForm.getContact();

        let sub = this.list();

        sub.add(() =>
        {
            this.loading = false

            if (Util.isEmpty(this.dataList))
            {
                this.onCapture();
            }
        });
    }

    public onCapture(): void
    {
        this.dialogCapture.openCapture(this.purchaserAddy);
    }

    public onPasteNew(): void
    {
        this.dialogPaste.openPaster(this.purchaserAddy);
    }

    public onCustom(): void
    {
        this.dialogEdit.openAdd(this.purchaserAddy);
    }

    public onImageDelete(addyImageUID: string): void
    {
        this.dataForm = this.makeForm({ uid: addyImageUID });

        let sub = this.delete();

        sub.add(() => this.onRefresh());
    }

    public onImageEdit(imageAddy: PurchaserAddressImage): void
    {
        this.dialogEdit.openEdit(this.purchaserAddy, imageAddy);
    }

    public onRefresh(): void
    {
        this.list();
    }

    public onClose(): void
    {
        this.close();
    }
}