export class PurchaserCommon
{
    public static readonly DocCode_TradeLicense = "TL";
    public static readonly DocCode_FirearmPermit = "FP";
    public static readonly DocCode_BusinessLicense = "BL";
    public static readonly DocCode_LetterAuthority = "LOA";

    public static readonly AddressTypes =
        [
            { id: 1, name: "Primary" },
            { id: 2, name: "Shipping" },
            { id: 3, name: "Billing" }
        ];

    public static readonly PurchaserTypes =
        [
            { id: 1, name: "Individual" },
            { id: 2, name: "Business" },
            { id: 3, name: "Government" }
        ];

    public static readonly PurchaserStatuses =
        [
            { id: -2, name: "Delete" },
            { id: -1, name: "Not Found" },
            { id: 7, name: "New" },
            { id: 2, name: "Pending" },
            { id: 10, name: "Pending Review" },
            { id: 1, name: "Approved" },
            { id: 9, name: "Inactive" },
            { id: 11, name: "On Hold" },
            { id: 12, name: "Denied" }
        ];

    public static readonly PurchaserOfficerStatuses =
        [
            { id: 1, name: "Active" },
            { id: 7, name: "New" },
            { id: 10, name: "Pending Review" }
        ];

    public static readonly PurchaserOwnerStatuses =
        [
            { id: 1, name: "Active" },
            { id: 7, name: "New" },
            { id: 10, name: "Pending Review" }
        ];

    public static readonly PurchaserContactStatuses =
        [
            { id: 1, name: "Approved" },
            { id: 7, name: "New" },
            { id: 10, name: "Pending Review" }
        ];

    public static readonly DocumentStates =
        [
            { id: 1, name: "Approved" },
            { id: 5, name: "Expired" },
            { id: 10, name: "Pending Review" }
        ];

    public static readonly DocumentTypes =
        [
            { code: PurchaserCommon.DocCode_BusinessLicense, name: "Business License" },
            { code: PurchaserCommon.DocCode_FirearmPermit, name: "Firearm Permit" },
            { code: PurchaserCommon.DocCode_LetterAuthority, name: "Letter of Authority" },
            { code: PurchaserCommon.DocCode_TradeLicense, name: "Trade License" },
            { code: "OTHER", name: "Other" }
        ];

    public static readonly PurchaserContactTypes =
        [
            { id: 1, name: "EasyExport Contact" },
            { id: 2, name: "Agency Leader" }
        ];

    public static readonly DealerRelationStatuses =
        [
            { id: 1, name: "Active" },
            { id: 7, name: "New" },
            { id: 9, name: "Inactive" },
            { id: 10, name: "Pending Review" }
        ];

    public static readonly AddressStatuses =
        [
            { id: 1, name: "Active" },
            { id: 7, name: "New" },
            { id: 10, name: "Pending Review" }
        ];

    public static readonly PurchaserAddressImageTypes =
        [
            { id: 1, name: "Street" },
            { id: 2, name: "Aerial" },
            { id: 3, name: "Distance/Direction" },
            { id: 4, name: "Postal Code" }
        ];

    public static readonly PurchaserAddressImageStatuses =
        [
            { id: 1, name: "Active" }
        ];

    public static getAddressTypeName(addressType: number): string
    {
        let addy = PurchaserCommon.AddressTypes.find(elm => elm.id == addressType);

        if (addy)
        {
            return addy.name;
        }

        return "";
    }

    public static getPurchaserContactName(contactType: number)
    {
        let cont = PurchaserCommon.PurchaserContactTypes.find(elm => elm.id == contactType);

        if (cont)
        {
            return cont.name;
        }

        return "";
    }
}