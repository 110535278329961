import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ApiService } from '../api/api.service';
import { PurchaserHistory } from './purchaser-history';

@Injectable({
  providedIn: 'root'
})
export class PurchaserHistoryService extends ApiService<PurchaserHistory> {

  constructor(httpClient: HttpClient) {
    super(httpClient, 'purchaser-history');
  }

}
