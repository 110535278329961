import { Component, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AppService } from "@app/app.service";
import { AuthService, ProfileType, PurchaserDealerConnect, PurchaserDealerConnectService } from "@app/data";
import { DealerConnectCommon } from "@app/data/purchaser-dealer-connect/purchaser-dealer-connect-common";
import { GridBase } from "@app/shared/ag-grid-helpers/ag-grid-base";
import { GridHelpers } from "@app/shared/ag-grid-helpers/grid-helper";
import { GridColumnCustom } from "@app/shared/ag-grid-helpers/models/grid-column-custom.model";
import { GridColumnIcon } from "@app/shared/ag-grid-helpers/models/grid-column-icon.model";
import { GridColumnMerchant } from "@app/shared/ag-grid-helpers/models/grid-column-merchant";
import { GridColumnPurchaser } from "@app/shared/ag-grid-helpers/models/grid-column-purchaser";
import { GridColumnStatus } from "@app/shared/ag-grid-helpers/models/grid-column-status";
import { GridColumn, GridColumnType } from "@app/shared/ag-grid-helpers/models/grid-column.model";
import { ToastrService } from "ngx-toastr";
import { PurchaserDealerConnectDetailsComponent } from "../purchaser-dealer-connect-details/purchaser-dealer-connect-details.component";

@Component({
    styleUrls: ["./purchaser-dealer-connect-list.component.scss"],
    templateUrl: "./purchaser-dealer-connect-list.component.html"
})
export class PurchaserDealerConnectListComponent extends GridBase<PurchaserDealerConnect>
{
    @ViewChild("dialogDetails")
    dialogDetails: PurchaserDealerConnectDetailsComponent;

    public readonly profileTypes = ProfileType;

    public userRole: number;

    constructor(toastrService: ToastrService,
        private appService: AppService,
        private activeRoute: ActivatedRoute,
        private authService: AuthService,
        private dealConnServ: PurchaserDealerConnectService)
    {
        super(toastrService, dealConnServ);

        this.title = "DealerConnect";
        this.userRole = this.activeRoute.snapshot.data.userType;
        this.appService.pageTitle = this.title;

        this.setupGrid();
    }

    private setupGrid()
    {
        this.setServerPath("list");

        this.addColumn(new GridColumnStatus(DealerConnectCommon.TradeStatuses));

        if (this.userRole != this.profileTypes.customer)
        {
            this.addColumn(new GridColumnMerchant("customer.displayName", "customer.uid"));
        }

        this.addColumn(new GridColumnPurchaser("purchaser.displayName", "purchaser.uid"));
        this.addColumn(new GridColumn("country.name", "Country"));

        if (this.userRole == this.profileTypes.customer)
        {
            this.addColumn(new GridColumnCustom("purchaser.productsOfInterest", "Products of Interest", { minWidth: 100 }));
        }

        if (this.userRole == ProfileType.admin)
        {
            this.addColumn(new GridColumn("createdDate", "Created", GridColumnType.Date));
            this.addColumn(new GridColumn("decisionDate", "Decision", GridColumnType.Date));
        }

        this.addColumn(new GridColumnIcon([ GridHelpers.MakeViewIcon(this.onDetail.bind(this)) ]));

        this.rowDoubleClick(this.onDetail.bind(this));
    }

    private onDetail(agData): void
    {
        this.dialogDetails.openDetail(agData);
    }
}