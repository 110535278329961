import { Model } from "../model/model";
import { TransactionDocumentType } from "./transaction-document-type";

export class TransactionDocument extends Model
{
    name?: string;
    fileName?: string;
    issuedBy?: string;
    issuedDate?: Date;
    fileNameUID?: string;
    documentType?: TransactionDocumentType;
    importPermit?: string;
    documentOther?: string;
    transactionUID: string;
    expirationDate?: Date;
    documentTypeUID?: number;
    isDocumentReadOnly?: boolean;
    documentDesignationType?: number;
}