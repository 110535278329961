import { Util } from '@app/utils/util';
import { Component, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { GlobalIcons } from "../GlobalIcons";

@Component({
    selector: "app-confirmation",
    styleUrls: ["./confirmation.component.scss"],
    templateUrl: "./confirmation.component.html"
})
export class ConfirmationComponent
{
    public Icons = GlobalIcons;

    @Input()
    Data: any;

    @Input()
    Title: string;

    @Input()
    Message: string;

    @Input()
    ButtonCloseIcon: string = this.Icons.No;

    @Input()
    ButtonCloseText: string = "Close";

    @Input()
    ButtonCloseColor: string = "light";

    @Input()
    ButtonConfirmIcon: string = this.Icons.Check;

    @Input()
    ButtonConfirmText: string = "OK";

    @Input()
    ButtonConfirmColor: string = "light";

    @Input()
    FunctionClose: () => void;

    @Input()
    FunctionConfirm: () => void;

    constructor(private activeModal: NgbActiveModal)
    {
    }

    public onClose()
    {
        if (!Util.isEmpty(this.FunctionClose))
        {
            this.FunctionClose();
        }

        this.activeModal.close(false);
    }

    public onConfirm()
    {
        if (!Util.isEmpty(this.FunctionConfirm))
        {
            this.FunctionConfirm();
        }

        this.activeModal.close(true);
    }

    public onDismiss()
    {
        this.activeModal.dismiss();
    }
}