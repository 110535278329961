import { BaseComponent } from "@app/data";
import { TaskCommon } from "@app/data/tasks/task-common";
import { TaskDocument } from "@app/data/tasks/task-document.model";

export class TaskDocumentCommon extends BaseComponent<TaskDocument>
{
    public docStatusTypes = TaskCommon.DocumentStatuses;
    public docTypeIsOther: boolean;

    public setOther(): void
    {
        if (this.docTypeIsOther)
        {
            this.makeRequired(["documentOther"]);
        }
        else
        {
            this.makeRequired(["documentOther"], false);
        }
    }

    public onDocTypeSelect(selection: any): void
    {
        let type = selection.code.toLowerCase();

        this.docTypeIsOther = (type == TaskCommon.DocumentTypeOther);

        this.setOther();
    }

    public saveComplete(): void
    {
        this.loading = false;

        if (this.submitError)
        {
            return;
        }

        this.close();
        this.update.emit();
    }
}