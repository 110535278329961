import { GridHelpers } from "../grid-helper";
import { GridCellIconGroup } from "../renderers/grid-cell-icon-group/grid-cell-icon-group";
import { GridColumn } from "./grid-column.model";

export class GridColumnIcon extends GridColumn
{
    constructor(icons: Array<any>, width: number = -1, alias: string = null)
    {
        super(null, "Action");

        // This isn't the best way, but works.  When you have time, figure out how to measure the icons
        if (width == -1)
        {
            if (icons.length == 1)
            {
                width = 90;
            }
            else
            {
                width = icons.length * 50;
            }
        }

        this.field = alias;
        this.width = width;
        this.filter = null;
        this.pinned = GridHelpers.ColumnPinned_Right;
        this.sortable = false;
        this.resizable = false;
        this.suppressSizeToFit = true;
        this.cellRendererParams = { Icons: icons };
        this.cellRendererFramework = GridCellIconGroup;
    }
}