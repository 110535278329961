import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from "@angular/forms";
import { ApiResult, ApiService } from '@app/data';
import { Lookup } from "@app/data/lookup/lookup";
import { Observable, Subject } from "rxjs";
import { TaskRequestDTO } from "./task-request.dto";
import { TaskWork } from "./taskwork.model";

@Injectable({
    providedIn: 'root'
})
export class TaskService extends ApiService<TaskWork>
{
    public get editorForm()
    {
        let form = new FormBuilder();
        let grpFields = form.group(
            {
                uid: [],
                note: [],
                dueDate: [],
                taskDate: [],
                entityUID: [, Validators.required],
                entityType: [, Validators.required],
                isBillable: [],
                statusType: [],
                completeDate: [],
                priorityType: [, Validators.required],
                assignedToUID: [],
                taskCategoryUID: [, Validators.required],
                taskDescription: [, Validators.required]
            });

        return grpFields;
    }

    public taskAnswer: Observable<any>;
    public taskQuestion: Observable<any>;

    private messageAnswer = new Subject();
    private messageQuestion = new Subject();

    constructor(httpClient: HttpClient)
    {
        super(httpClient, "tasks");

        this.taskAnswer = this.messageAnswer.asObservable();
        this.taskQuestion = this.messageQuestion.asObservable();
    }

    public sendAnswer(request: TaskRequestDTO)
    {
        this.messageAnswer.next(request);
    }

    public sendQuestion(request: TaskRequestDTO)
    {
        this.messageQuestion.next(request);
    }

    public getCategories(entityType: number)
    {
        let path = this.buildUrl("categories");

        return this.httpClient.post<ApiResult<Lookup>>(path, { entityType });
    }
}