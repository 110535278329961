import { Component, HostBinding, OnInit, ViewChild } from "@angular/core";
import { AppService } from "@app/app.service";
import { AuthService, Profile } from "@app/data";
import { PrivacyPolicyComponent } from "@app/shared/privacy-policy/privacy-policy.component";
import { PurchaserTermsComponent } from "@app/shared/purchaser-terms/purchaser-terms.component";

@Component({
    styles: [":host { display: block; }"],
    selector: "app-layout-footer",
    templateUrl: "./layout-footer.component.html"
})
export class LayoutFooterComponent implements OnInit
{
    @ViewChild("dialogTerms")
    dialogTerms: PurchaserTermsComponent;

    @ViewChild("dialogPolicy")
    dialogPolicy: PrivacyPolicyComponent;


    @HostBinding("class.layout-footer")
    hostClassMain = true;

    public currYear;
    public myProfile: Profile;

    constructor(
        private appService: AppService,
        private authService: AuthService)
    {
        this.currYear = new Date().getFullYear();
    }

    async ngOnInit()
    {
        this.myProfile = await this.authService.getProfile$().toPromise();
    }

    currentBg()
    {
        return `bg-${this.appService.layoutFooterBg}`;
    }

    public onTerms()
    {
        this.dialogTerms.open();
    }

    public onPolicy()
    {
        this.dialogPolicy.open();
    }
}