import { Customer } from "@app/data";
import { GridMerchantFilter } from "../filters/grid-merchant-filter/grid-merchant-filter.component";
import { GridCellMerchantRenderer } from "../renderers/grid-cell-merchant/grid-cell-merchant";
import { GridColumn } from "./grid-column.model";

export class GridColumnMerchant extends GridColumn
{
    constructor(renderField: string, filterField: string)
    {
        super(renderField, "Merchant");

        this.width = 175;
        this.minWidth = 100;
        this.filter = "gridMerchantFilter";
        this.minWidth = 100;
        this.comparator = this.sorter.bind(this);
        this.filterParams = { field: filterField };
        this.cellRendererFramework = GridCellMerchantRenderer;

        this.registerComponents.push({ gridMerchantFilter: GridMerchantFilter });
    }

    private sorter(merch1: Customer, merch2: Customer): number
    {
        let name1 = merch1.displayName?.toLowerCase();
        let name2 = merch2.displayName?.toLowerCase();

        if (name1 == name2)
        {
            return 0;
        }

        return (name1 > name2 ? 1 : -1);
    }
}