import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { AppService } from "@app/app.service";
import { AuthService, BaseComponent, Customer, CustomerService, ProfileType, PurchaserType } from "@app/data";
import { CustomerSearch } from "@app/data/customer-search/customer-search";
import { CustomerSearchService } from "@app/data/customer-search/customer-search.service";
import { PurchaserAuthorizationService } from "@app/data/purchaser-authorization/purchaser-authorization.service";
import { Util } from "@app/utils/util";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: "customer-search",
    styleUrls: ["customer-search.component.scss"],
    templateUrl: "./customer-search.component.html",
    encapsulation: ViewEncapsulation.None
})
export class CustomerSearchComponent extends BaseComponent<CustomerSearch> implements OnInit
{
    public canAuthorize: boolean = false;
    public customerSelected: boolean;
    public selectedProducts;

    public sortColumns =
        [
            { column: "displayName", description: "Merchant" }
        ];

    public filterProducts =
        [
            { group: null, id: "isSellingOptic", name: "Optics" },
            { group: null, id: "isSellingAccessories", name: "Accessories" },
            { group: null, id: "isSellingShotgunParts", name: "Shotgun Parts" },
            { group: null, id: "isSellingCompleteFirearms", name: "Complete firearms" },
            { group: "Rimfire", id: "isSellingRiflePartsRimfire", name: "Rimfire - Rifle Parts" },
            { group: "Rimfire", id: "isSellingPistolPartsRimfire", name: "Rimfire - Pistol Parts" },
            { group: "Centerfire", id: "isSellingRiflePartsCenterfire", name: "Centerfire - Rifle Parts" },
            { group: "Centerfire", id: "isSellingPistolPartsCenterfire", name: "Centerfire - Pistol Parts" }
        ];

    constructor(toastrService: ToastrService,
        public customerService: CustomerService,
        private appService: AppService,
        private searchServ: CustomerSearchService,
        private authService: AuthService,
        private formBuilder: FormBuilder,
        private purchaseAuthServ: PurchaserAuthorizationService)
    {
        super(toastrService);

        this.title = "Merchant Search"
        this.apiService = searchServ;
        this.appService.pageTitle = this.title;
    }

    async ngOnInit()
    {
        this.searchForm = this.formBuilder.group(
            {
                customerUID: [],
                isSellingOptic: [],
                isSellingShotgunParts: [],
                isSellingCompleteFirearms: [],
                isSellingRiflePartsRimfire: [],
                isSellingPistolPartsRimfire: [],
                isSellingRiflePartsCenterfire: [],
                isSellingAccessories: [],
                isSellingPistolPartsCenterfire: []
            });

        this.list();
        this.customerService.getLookup({ isOnYellowPage: true });

        let myProfile = await this.authService.getProfile$().toPromise();

        if (this.authService.profile.profileType == ProfileType.purchaser && (myProfile.profileType == PurchaserType.Business || myProfile.profileType == PurchaserType.Government))
        {
            this.canAuthorize = true;
            this.purchaseAuthServ.list().subscribe(retRes => this.fixAuth(retRes));
        }
    }

    public onCustomerSelect(customer: Customer): void
    {
        this.customerSelected = !Util.isEmpty(customer);

        if (this.customerSelected)
        {
            this.selectedProducts = null;
        }

        this.searchForm.get("customerUID").setValue(customer?.uid);
        this.list();
    }

    public onProducts(evtArg): void
    {
        this.customerSelected = false;
        this.searchForm.reset();

        if (Util.isEmpty(evtArg))
        {
            this.list();
            return;
        }

        for (let prod of evtArg)
        {
            this.searchForm.get(prod.id).setValue(true);
        }

        this.list();
    }

    private fixAuth(retRes): void
    {
        let data = this.dataList as Array<CustomerSearch>;
        let authMerchs = retRes.results;

        for (let lp = 0; lp < authMerchs.length; lp++)
        {
            let merch = data.find(who => who.uid == authMerchs[lp].customerUID);

            if (merch)
            {
                merch.authUID = authMerchs[lp].uid;
            }
        }
    }
}