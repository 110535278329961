import { Component } from "@angular/core";
import { GridFilterCustom } from "@app/shared/ag-grid-helpers/filters/grid-filter-custom";
import { GridHelpers } from "@app/shared/ag-grid-helpers/grid-helper";
import { GridFilter, GridFilterStorage } from '@app/shared/ag-grid-helpers/models/grid-filter.model';
import { GlobalIcons } from '@app/shared/GlobalIcons';
import { OrderDataHelper } from "@app/shared/orders/order-data.helper";
import { Util } from '@app/utils/util';
import { AgFilterComponent } from "ag-grid-angular";
import { IDoesFilterPassParams } from "ag-grid-community";

@Component({
    styleUrls: ["../../../styles/gridfilter.scss"],
    templateUrl: "./hold-filter.component.html"
})
export class OrdersHoldFilter extends GridFilterCustom implements AgFilterComponent
{
    public readonly Icons = GlobalIcons;
    public readonly NoHold = "!";

    public holdAEP;
    public holdEAR;
    public holdITAR;
    public holdNone;
    public holdImport;

    private params: any;

    agInit(params: any): void
    {
        this.initFilter(params);

        this.holdAEP = Object.assign({ checked: false }, OrderDataHelper.Hold_AEP);
        this.holdEAR = Object.assign({ checked: false }, OrderDataHelper.Hold_EAR);
        this.holdITAR = Object.assign({ checked: false }, OrderDataHelper.Hold_ITAR);
        this.holdNone = { checked: false, code: this.NoHold, description: "No holds only" };
        this.holdImport = Object.assign({ checked: false }, OrderDataHelper.Hold_Import);
    }

    isFilterActive(): boolean
    {
        return (this.holdAEP.checked || this.holdEAR.checked || this.holdNone.checked || this.holdITAR.checked || this.holdImport.checked);
    }

    doesFilterPass(params: IDoesFilterPassParams): boolean
    {
        if (this.isServerSide || (!this.holdAEP.checked && !this.holdEAR.checked && !this.holdITAR.checked && !this.holdNone.checked && !this.holdImport.checked))
        {
            return true;
        }

        if (this.holdNone.checked)
        {
            return (!params.data.isAEPHold && !params.data.isEARLicenseHold && !params.data.isITARLicenseHold && !params.data.isImportPermitHold);
        }

        let isAEP: boolean;
        let isEAR: boolean;
        let isITAR: boolean;
        let isImport: boolean;

        if (this.holdAEP.checked)
        {
            isAEP = params.data.isAEPHold;
        }
        else
        {
            isAEP = true;
        }

        if (this.holdEAR.checked)
        {
            isEAR = params.data.isEARLicenseHold;
        }
        else
        {
            isEAR = true;
        }

        if (this.holdITAR.checked)
        {
            isITAR = params.data.isITARLicenseHold;
        }
        else
        {
            isITAR = true;
        }

        if (this.holdImport.checked)
        {
            isImport = params.data.isImportPermitHold;
        }
        else
        {
            isImport = true;
        }

        return (isAEP && isEAR && isITAR && isImport);
    }

    getModel()
    {
        if (!this.holdAEP.checked && !this.holdEAR.checked && !this.holdITAR.checked && !this.holdNone.checked && !this.holdImport.checked)
        {
            return null;
        }

        let filt = new GridFilterStorage();

        if (this.holdNone.checked)
        {
            filt.add("isAEPHold", new GridFilter("false"));
            filt.add("isEARLicenseHold", new GridFilter("false"));
            filt.add("isITARLicenseHold", new GridFilter("false"));
            filt.add("isImportPermitHold", new GridFilter("false"));

            return filt;
        }

        if (this.holdAEP.checked)
        {
            filt.add("isAEPHold", new GridFilter("true"));
        }

        if (this.holdEAR.checked)
        {
            filt.add("isEARLicenseHold", new GridFilter("true"));
        }

        if (this.holdITAR.checked)
        {
            filt.add("isITARLicenseHold", new GridFilter("true"));
        }

        if (this.holdImport.checked)
        {
            filt.add("isImportPermitHold", new GridFilter("true"));
        }

        return filt;
    }

    setModel(model: any)
    {
        let filt = model?.multiple;

        if (Util.isEmpty(filt))
        {
            this.holdAEP.checked = false;
            this.holdEAR.checked = false;
            this.holdITAR.checked = false;
            this.holdNone.checked = false;
            this.holdImport.checked = false;
            return;
        }

        for (let dude in filt)
        {
            let isChk = (filt[dude].filter == "true");

            switch (dude)
            {
                case "isAEPHold":
                    this.holdAEP.checked = isChk;
                    break;

                case "isEARLicenseHold":
                    this.holdEAR.checked = isChk;
                    break;

                case "isITARLicenseHold":
                    this.holdITAR.checked = isChk;
                    break;

                case "isImportPermitHold":
                    this.holdImport.checked = isChk;
                    break;
            }
        }

        this.holdNone.checked = (!this.holdAEP.checked && !this.holdEAR.checked && !this.holdITAR.checked && !this.holdImport.checked);
    }

    public onUpdateFilter(code: string)
    {
        if (code == this.NoHold && this.holdNone.checked)
        {
            this.holdAEP.checked = false;
            this.holdEAR.checked = false;
            this.holdITAR.checked = false;
            this.holdImport.checked = false;
        }
        else
        {
            this.holdNone.checked = false;
        }
    }

    public onFilterClear()
    {
        this.holdAEP.checked = false;
        this.holdEAR.checked = false;
        this.holdNone.checked = false;
        this.holdITAR.checked = false;
        this.holdImport.checked = false;

        this.applyAndClose();
    }

    public onFilterApply()
    {
        this.applyAndClose();
    }
}