import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { NumberValidator, NumberValidatorOption } from "@app/infrastructure/form-validators/number-validator";
import { Observable, Subject } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { ApiService } from "../api/api.service";
import { DispatchService } from "../dispatch/dispatch.service";
import { Product } from "./product";
import { ProductRequestDTO } from "./product-request.dto";

@Injectable({
    providedIn: "root"
})
export class ProductService extends ApiService<Product>
{
    public get editorForm()
    {
        let valMin = new NumberValidatorOption();
        let valWhole = new NumberValidatorOption();

        valMin.minValue = 0;
        valWhole.minValue = 0;
        valWhole.allowDecimal = false;

        let form = new FormBuilder();
        let grpFields = form.group(
            {
                uid: [],
                upc: [],
                make: [],
                model: [],
                brand: [],
                ounces: [, [NumberValidator(valMin)]],
                pounds: [, [NumberValidator(valMin)]],
                caliber: [, [NumberValidator(valMin)]],
                comment: [],
                actionUID: [],
                isLithium: [],
                imageLink1: [],
                imageLink2: [],
                partNumber: [, Validators.required],
                statusType: [],
                categoryUID: [],
                customerUID: [],
                description: [],
                barrelLength: [, [NumberValidator(valMin)]],
                customerCode: [],
                manufacturer: [],
                overrideITAR: [],
                websiteLink1: [],
                websiteLink2: [],
                isSerializable: [],
                subCategoryUID: [],
                websiteCategory: [],
                weightKilograms: [],
                magazineCapacity: [, [NumberValidator(valWhole)]],
                magazineQuantity: [, [NumberValidator(valWhole)]],
                originCountryUID: [],
                ammunitionTypeUID: [],
                overrideExportHTS: [],
                sellerCategoryUID: [],
                websiteSubCategory: [],
                customerDescription: [],
                overrideEARCategory: [],
                isExcludedFromSearch: [],
                sellerSubCategoryUID: [],
                overrideEARSubCategory: [],
                cJorCCATSClassification: [],
                overrideExportScheduleB: [],
                isOverrideBusinessExportable: [],
                isOverrideGovernmentExportable: [],
                isOverrideIndividualExportable: []
            });

        return grpFields;
    }

    public prodAnswer: Observable<any>;
    public prodQuestion: Observable<any>;

    private messageAnswer = new Subject();
    private messageQuestion = new Subject();

    constructor(httpClient: HttpClient, private dispatchService: DispatchService)
    {
        super(httpClient, "product");

        this.prodAnswer = this.messageAnswer.asObservable();
        this.prodQuestion = this.messageQuestion.asObservable();
    }

    public sendAnswer(request: ProductRequestDTO)
    {
        this.messageAnswer.next(request);
    }

    public sendQuestion(request: ProductRequestDTO)
    {
        this.messageQuestion.next(request);
    }

    public import(file: File, data: Product)
    {
        return this.dispatchService.upload(file).pipe(
            map(d1 =>
            {
                data.dispatch = {
                    fileName: d1.fileName,
                    fileNameUID: d1.fileNameUID
                };

                return d1;
            }),
            mergeMap(d2 =>
            {
                return this.post("import", data);
            })
        );
    }
}