export class GoogleMapsCoordinates
{
    latitude: number;
    longitude: number;

    constructor(latitude: number = -1, longitude: number = -1)
    {
        this.latitude = latitude;
        this.longitude = longitude;
    }
}