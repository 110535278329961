import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { PurchaserService } from "@app/data";
import { GlobalIcons } from '@app/shared/GlobalIcons';

@Component({
    selector: "captcha",
    styleUrls: ["./captcha.component.scss"],
    templateUrl: "./captcha.component.html"
})
export class CaptchaComponent implements OnInit
{
    @Input()
    autoLoad: boolean = true;

    @Output() captchaChanged:
    EventEmitter<boolean> = new EventEmitter<boolean>();

    public get captchaText()
    {
        return this.captchaInput;
    }

    public readonly Icons = GlobalIcons;

    public loading: boolean = false;
    public statusIcon: string;
    public captchaImage;
    public captchaInput;

    private checkTimer = null;

    constructor(private purchaserService: PurchaserService)
    {
    }

    ngOnInit()
    {
        if (this.autoLoad)
        {
            this.refreshImage();
        }
    }

    public refreshImage()
    {
        this.loading = true;
        this.captchaInput = null;
        this.statusChange(false);

        return this.purchaserService.captchaGetImage().subscribe(retRes => { this.createImage(retRes); });
    }

    public onCaptchaKeyUp()
    {
        if (this.checkTimer != null)
        {
            clearTimeout(this.checkTimer);
        }

        this.checkTimer = setTimeout(() => this.checkValid(), 750);
    }

    private createImage(image: Blob)
    {
        let reader = new FileReader();

        reader.addEventListener("load", () =>
        {
            this.loading = false;
            this.captchaImage = reader.result;
        }, false);

        if (image)
        {
            reader.readAsDataURL(image);
        }
    }

    private checkValid()
    {
        this.purchaserService.captchaIsValid(this.captchaInput).subscribe(retRes =>
        {
            this.statusChange(retRes);
        });
    }

    private statusChange(isGood: boolean)
    {
        if (isGood)
        {
            this.statusIcon = "far fa-check-circle fa-fw status-good";
        }
        else
        {
            this.statusIcon = `${this.Icons.No} status-bad`;
        }

        this.captchaChanged.emit(isGood);
    }
}