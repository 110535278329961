import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ApiService } from "../api/api.service";
import { FreightForwarderContact } from "./freight-forwarder-contact";

@Injectable({
    providedIn: "root"
})
export class FreightForwarderContactService extends ApiService<FreightForwarderContact>
{
    public get editorForm()
    {
        let form = new FormBuilder();
        let grpFields = form.group(
            {
                uid: [],
                title: [],
                lastName: [, Validators.required],
                cellPhone: [],
                firstName: [, Validators.required],
                isDefault: [],
                workPhone: [],
                freightUID: [],
                middleName: [],
                statusType: [],
                emailAddress: []
            });

        return grpFields;
    }

    constructor(httpClient: HttpClient)
    {
        super(httpClient, "freight-forwarder-contact");
    }
}