import { Component, EventEmitter, Output, TemplateRef, ViewChild } from "@angular/core";
import { BaseComponent, PurchaserService } from "@app/data";
import { PurchaserStatusType } from "@app/data/enums/purchaser-status-type.enum";
import { OrdersNotificationRequest } from "@app/data/orders/order-notification-request.model";
import { OrderNotificationRequestService } from "@app/data/orders/order-notification-request.service";
import { CommonSettings } from "@app/shared/common-settings";
import { Util } from "@app/utils/util";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";

@Component(
    {
        selector: "purchaser-add-dialog",
        styleUrls: ["./purchaser-add-dialog.component.scss"],
        templateUrl: "./purchaser-add-dialog.component.html"
    })
export class PurchaserAddDialogComponent extends BaseComponent<OrdersNotificationRequest>
{
    @Output()
    addPurchaser = new EventEmitter<any>();

    @ViewChild("dialogPurchaser")
    dialogPurchaser: TemplateRef<any>;

    public get buttonIcon()
    {
        if (this.askNote)
        {
            return this.Icons.Save;
        }

        return this.Icons.Search;
    }

    public get buttonText()
    {
        if (this.askNote)
        {
            return "Save";
        }

        return "Find";
    }

    public readonly genericMessage = "Invalid Purchaser Email address";

    public askNote: boolean = false;
    public inEmail: string;
    public retMessage: string;
    public sumTingWong: boolean = false;
    public sendDisabled: boolean = false;
    public sendNotification: boolean = true;

    private merchantUID: string;
    private purchaserUID: string;

    constructor(
        toastrService: ToastrService,
        public modalService: NgbModal,
        private purchaserServ: PurchaserService,
        private notificationRequestServ: OrderNotificationRequestService)
    {
        super(toastrService);

        this.modalService = modalService;
    }

    public openAdd(merchantUID: string): void
    {
        this.merchantUID = merchantUID;
        this.setupUI();
    }

    public openWithSearch(merchantUID: string, email: string): void
    {
        this.setupUI();

        this.inEmail = email;
        this.merchantUID = merchantUID;
        this.onAction();
    }

    public onInputKeyUp(evtArg)
    {
        if (evtArg.keyCode == Util.KeyCode_Enter)
        {
            this.onAction();
        }
        else
        {
            this.askNote = false;
            this.sumTingWong = false;
            this.sendDisabled = false;
        }
    }

    public onSendChange()
    {
        this.sendDisabled = (this.sumTingWong && !this.sendNotification);
    }

    public onAction()
    {
        if (Util.isEmpty(this.inEmail))
        {
            this.close();
            return;
        }

        if (this.askNote && this.sendNotification)
        {
            this.saveNotification();
        }
        else
        {
            this.findPurchaser();
        }
    }

    private setupUI()
    {
        this.askNote = false;
        this.inEmail = null;
        this.retMessage = null;
        this.sumTingWong = false;
        this.sendNotification = true;

        this.open(this.dialogPurchaser, null, CommonSettings.DialogSize_Small);
    }

    private findPurchaser()
    {
        this.purchaserServ.findByEmail(this.inEmail).subscribe(
            retRes => this.checkEmail(retRes),
            errRes =>
            {
                this.askNote = false;
                this.retMessage = errRes;
                this.sumTingWong = true;
            });
    }

    private saveNotification()
    {
        let saveMe =
        {
            customerUID: this.merchantUID,
            purchaserUID: this.purchaserUID,
            purchaserEmailAddress: this.inEmail
        };

        this.notificationRequestServ.save(saveMe).subscribe(
            retRes =>
            {
                this.close();
                this.success("Notification requests sent");
            },
            errRes => this.error(errRes));
    }

    private checkEmail(retRes)
    {
        if (Util.isEmpty(retRes))
        {
            this.askNote = false;
            this.retMessage = this.genericMessage;
            this.sumTingWong = true;
            return;
        }

        if (retRes.statusType == PurchaserStatusType.Approved)
        {
            this.close();
            this.addPurchaser.emit(retRes);
            return;
        }

        this.askNote = (retRes.statusType == PurchaserStatusType.NotFound || retRes.statusType == PurchaserStatusType.New || retRes.statusType == PurchaserStatusType.Pending || retRes.statusType == PurchaserStatusType.PendingReview);
        this.retMessage = retRes.searchMessage;
        this.sumTingWong = true;
        this.purchaserUID = retRes.uid;
    }
}