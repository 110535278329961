import { Component, OnInit, ViewChild } from "@angular/core";
import { BaseComponent, PurchaserOfficer, PurchaserOfficerService } from "@app/data";
import { Util } from "@app/utils/util";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { PurchaserOfficerHistoryListComponent } from "../purchaser-officer-history-list/purchaser-officer-history-list.component";

@Component({
    selector: "purchaser-officer-list",
    templateUrl: "./purchaser-officer-list.component.html"
})
export class PurchaserOfficerListComponent extends BaseComponent<PurchaserOfficer> implements OnInit
{
    @ViewChild("dialogHistory")
    dialogHistory: PurchaserOfficerHistoryListComponent;

    constructor(
        toastrService: ToastrService,
        public modalService: NgbModal,
        private purchaserOfficerService: PurchaserOfficerService)
    {
        super(toastrService);

        this.apiService = purchaserOfficerService;
    }

    ngOnInit(): void
    {
        this.searchForm = this.makeForm({ purchaserUID: this.parentUID });
        this.postGetlist();
    }

    public getContact(contact): string
    {
        return Util.MakeName(contact);
    }

    public onHistory(uid: string): void
    {
        this.dialogHistory.openHistory(uid);
    }
}