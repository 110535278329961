import { Util } from "@app/utils/util";

export class FAQCommon
{
    public static readonly FAQStatuses =
        [
            { id: -2, name: "Deleted" },
            { id: 1, name: "Active" },
            { id: 2, name: "Pending" },
            { id: 9, name: "Inactive" },
        ];

    public static fixLinks(faqList)
    {
        for (let item of faqList)
        {
            item["answer"] = Util.replaceAll(item["answer"], "<a href", "<a target='_blank' href");
        }
    }
}