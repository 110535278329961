import { CommonSettings } from '@app/shared/common-settings';
import { Component, TemplateRef, ViewChild } from "@angular/core";
import { BaseComponent } from "@app/data";
import { PurchaserAuthorization } from "@app/data/purchaser-authorization/purchaser-authorization";
import { PurchaserAuthorizationService } from "@app/data/purchaser-authorization/purchaser-authorization.service";
import { PurchaserEndUserStatementService } from "@app/data/purchaser-authorization/purchaser-enduserstatement.service";
import { Util } from "@app/utils/util";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";

@Component(
    {
        selector: "customer-search-user-statement",
        templateUrl: "./customer-search-user-statement.component.html",
    })
export class CustomerSearchUserStatementComponent extends BaseComponent<PurchaserAuthorization>
{
    @ViewChild("dialogUserStatement")
    dialogUserStatement: TemplateRef<any>;

    public disclaimer: string;

    private merchUID;
    private userStateUID;

    constructor(
        toastrService: ToastrService,
        public modalService: NgbModal,
        private purchaseAuthServ: PurchaserAuthorizationService,
        private userStatementServ: PurchaserEndUserStatementService)
    {
        super(toastrService);

        this.modalService = modalService;
    }

    public openStatement(merchantUID)
    {
        this.merchUID = merchantUID;

        this.open(this.dialogUserStatement, null, CommonSettings.DialogSize_XLarge);
        this.userStatementServ.get("get-userstatment").subscribe((retRes) => this.plugStatement(retRes));
    }

    public onAgree()
    {
        let data =
        {
            customerUID: this.merchUID,
            purchaserEndUserStatementUID: this.userStateUID
        };

        this.purchaseAuthServ.post("save", data).subscribe(retRes =>
        {
            this.close();
            this.success("Merchant Authorized");

            if (this.update)
            {
                this.update.emit(retRes);
            }
        });
    }

    private plugStatement(statement)
    {
        this.disclaimer = statement.endUserStatement;
        this.disclaimer = Util.replaceAll(this.disclaimer, "\r\n", "<br />");
        this.userStateUID = statement.uid;
    }
}