import { Component, OnInit } from "@angular/core";
import { AppService } from "@app/app.service";
import { AuthService, BaseComponent, LookupService, Profile, ProfileService, ProfileType } from "@app/data";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: "my-profile",
    styleUrls: ["./my-profile.component.scss"],
    templateUrl: "./my-profile.component.html"
})
export class MyProfileComponent extends BaseComponent<Profile> implements OnInit
{
    constructor(
        toastrService: ToastrService,
        public lookupServ: LookupService,
        private appService: AppService,
        private authService: AuthService,
        private profileServ: ProfileService)
    {
        super(toastrService);

        this.title = "My Profile";
        this.dataForm = this.profileServ.editorForm;
        this.apiService = this.profileServ;
        this.appService.pageTitle = this.title;
    }

    ngOnInit(): void
    {
        this.lookupServ.getLanguageList();
        this.lookupServ.getTimezoneList();

        this.authService.getProfile$().subscribe(() => this.profileLoaded());
    }

    public onSave()
    {
        let sub = this.submit("save-basic");

        if (sub)
        {
            sub.add(() => this.doneDidSaved());
        }
    }

    private profileLoaded()
    {
        this.data = this.authService.profile;
        this.patchValue();

        if (this.data.profileType == ProfileType.admin)
        {
            this.lookupServ.getCountryList();

            this.makeRequired(["countryUID"]);
        }
        else
        {
            this.makeRequired(["countryUID"], false);
        }
    }

    private doneDidSaved()
    {
        if (!this.submitError)
        {
            this.success("Profile saved");
        }
    }
}