import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { PurchaserDialogComponent } from "@app/admin/purchaser/purchaser-dialog/purchaser-dialog.component";
import { AuthService, ProfileType, Purchaser, PurchaserService } from "@app/data";
import { GlobalIcons } from "@app/shared/GlobalIcons";
import { Util } from '@app/utils/util';

@Component({
    selector: "purchaser-link",
    templateUrl: "./purchaser-link.html",
})
export class PurchaserLink implements OnInit
{
    @Input()
    showType: boolean;

    @Input()
    purchaser: Purchaser;

    @Input()
    purchaserUID: string;

    @ViewChild("dialogPurchaser")
    dialogPurchaser: PurchaserDialogComponent;

    public readonly Icons = GlobalIcons;
    public readonly UserRoles = ProfileType;

    public email: string;
    public webLink: string;
    public purchUID: string;
    public userRole: number;
    public purchaserName: string;
    public purchaserType: string;

    constructor(private purchaserServ: PurchaserService, private authService: AuthService)
    {
    }

    ngOnInit(): void
    {
        this.userRole = this.authService.profile?.profileType;

        if (this.purchaserUID)
        {
            this.purchaserServ.detail({ uid: this.purchaserUID }).subscribe(retRes => this.loadPurchaser(retRes));
        }
        else
        {
            this.loadPurchaser(this.purchaser);
        }
    }

    public onDetail()
    {
        this.dialogPurchaser.openPurchaser(this.purchUID);
    }

    public onWebsite()
    {
        window.open(this.webLink, "_blank");
    }

    public onEmail()
    {
        window.location.href = "mailto:" + this.email;
    }

    private loadPurchaser(purchaser: Purchaser)
    {
        if (Util.isEmpty(purchaser))
        {
            this.email = "";
            this.webLink = "";
            this.purchUID = "";
            this.purchaserName = "";
            return;
        }

        let purName = purchaser.displayName ?? "";
        let purType = purchaser.purchaserTypeName ?? "";

        this.email = purchaser.emailAddress;
        this.webLink = purchaser.websiteLink;
        this.purchUID = purchaser.uid;
        this.purchaserName = purName;

        if (purType != "" && this.showType)
        {
            this.purchaserName += ` <sup>(${purType})</sup>`;
        }
    }
}