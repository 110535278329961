import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { BaseComponent, LicenseDocument, LicenseDocumentService, Lookup, LookupService, LookupType } from "@app/data";
import { CommonSettings } from "@app/shared/common-settings";
import { ConfirmationService, IConfirmationOptions } from "@app/shared/confirmation/confirmation.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: "app-license-document-edit",
    templateUrl: "./license-document-edit.component.html"
})
export class LicenseDocumentEditComponent extends BaseComponent<LicenseDocument> implements OnInit
{
    @ViewChild("dialogDoc")
    dialogDoc: ElementRef<any>;

    public docFile?: File;
    public docTypes: Array<Lookup>;

    constructor(
        toastrService: ToastrService,
        private modalServ: NgbModal,
        private lookupServ: LookupService,
        private confirmService: ConfirmationService,
        private licenseDocumentService: LicenseDocumentService)
    {
        super(toastrService);

        this.dataForm = this.licenseDocumentService.editorForm;
        this.apiService = this.licenseDocumentService;
        this.modalService = modalServ;
    }

    ngOnInit(): void
    {
        this.lookupServ.getByType(LookupType.LicenseDocumentType).subscribe(
            {
                next: (retRes) => this.docTypes = retRes.results
            });
    }

    public openAdd(licenseUID)
    {
        this.open(this.dialogDoc);

        this.resetForm();
        this.setValue("licenseUID", licenseUID);

        this.docFile = undefined;
    }

    public openEdit(data)
    {
        this.open(this.dialogDoc, data);

        this.docFile = undefined;
    }

    public onFileSelect(evtArg)
    {
        this.docFile = evtArg.target.files[0];

        if (!this.docFile)
        {
            return;
        }

        this.formControls["fileName"].setValue(this.docFile.name);

        this.dataForm.markAsDirty();
    }

    public onClose()
    {
        if (!this.dataForm.dirty)
        {
            this.close();
            return;
        }

        let opts: IConfirmationOptions =
        {
            FunctionConfirm: () => this.close()
        };

        this.confirmService.YesNo(CommonSettings.DirtyFormConfirmationMessage, opts);
    }

    public onSave()
    {
        if (this.invalid())
        {
            return;
        }

        this.loading = true;

        if (this.data?.uid == null)
        {
            this.licenseDocumentService.upload(this.docFile, this.dataValue).subscribe(
                retRes => this.saveComplete(),
                errRes =>
                {
                    this.loading = false;
                    this.error(errRes);
                });

            return;
        }

        this.submit().add(() => this.saveComplete());
    }

    private saveComplete()
    {
        this.loading = false;

        if (this.submitError)
        {
            return;
        }

        this.close();

        if (this.update)
        {
            this.update.emit();
        }
    }
}