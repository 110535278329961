import { Directive, EventEmitter, Output } from '@angular/core';
import { BaseComponent, Customer } from "@app/data";
import { Util } from "@app/utils/util";
import { Subscription } from "rxjs/internal/Subscription";

@Directive()
export class MerchantBase extends BaseComponent<Customer>
{
    @Output()
    detailsChanged = new EventEmitter();

    public userRole: number;
    public subAnswer: Subscription;
    public subQuestion: Subscription;

    public get haveChanges(): boolean
    {
        return this.dataForm?.dirty;
    }

    public loadCustomer(merchantUID: string): Subscription
    {
        if (Util.isEmpty(merchantUID))
        {
            return null;
        }

        let obby = this.apiService.detail({ uid: merchantUID }).subscribe(
            {
                next: (retRes) =>
                {
                    this.data = retRes;
                    this.patchValue();
                },
                error: (errRes) => this.error(errRes)
            });

        return obby;
    }
}