import { GridHelpers } from "@app/shared/ag-grid-helpers/grid-helper";

export class GridFilterCustom
{
    public readonly FilterClasses =
        {
            Body: "ag-filter-body-wrapper ag-simple-filter-body-wrapper",
            Label: "text-muted mb-1",
            Button: "ag-standard-button ag-filter-apply-panel-button",
            Wrapper: "ag-filter-wrapper ag-focus-managed",
            TextInput: "ag-input-field-input ag-text-field-input w-100",
            InputWrapper: "ag-filter-from ag-filter-filter ag-labeled ag-label-align-left ag-text-field ag-input-field d-flex flex-column align-items-start",
            SelectInput: "ag-wrapper ag-picker-field-wrapper w-100 px-1",
            ButtonWrapper: "d-flex justify-content-end border-top pt-3 mt-3"
        };

    private hideFunc;
    private filterParams: any;

    public get isServerSide()
    {
        return (this.filterParams.rowModel == GridHelpers.RowModel_Server);
    }

    afterGuiAttached(evtArg)
    {
        this.hideFunc = evtArg.hidePopup;
    }

    public getParam(paramName: string, defaultValue: any = null): any
    {
        if (this.filterParams.hasOwnProperty(paramName))
        {
            return this.filterParams[paramName];
        }

        return defaultValue;
    }

    public initFilter(filterParams: any): void
    {
        this.filterParams = filterParams;
    }

    public closeFilter(): void
    {
        if (this.hideFunc)
        {
            this.hideFunc();
        }
    }

    public applyFilter(): void
    {
        this.filterParams.filterChangedCallback();
    }

    public applyAndClose()
    {
        this.closeFilter();
        this.applyFilter();
    }
}