import { GridFilterMeter } from "../filters/grid-filter-meter/grid-filter-meter.component";
import { GridCellMeter } from "../renderers/grid-cell-meter/grid-cell-meter";
import { GridColumn } from "./grid-column.model";

export class GridColumnMeter extends GridColumn
{
    constructor(field: any, header: string)
    {
        super(field, header);

        this.filter = "meterFilter";
        this.minWidth = 100;
        this.comparator = this.sorter;
        this.resizable = true;
        this.filterParams = { field: field };
        this.suppressSizeToFit = true;
        this.cellRendererParams = { field: field };
        this.cellRendererFramework = GridCellMeter;

        this.registerComponents.push({ meterFilter: GridFilterMeter });
    }

    private sorter(val1?: number, val2?: number): number
    {
        let num1 = val1 ?? 0;
        let num2 = val2 ?? 0;

        if (num1 == num2)
        {
            return 0;
        }

        return (num1 > num2 ? 1 : -1);
    }
}