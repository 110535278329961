import { EntityType } from '@app/data/enums/entity-type.enum';
import { TaskCommon } from '@app/data/tasks/task-common';
import { Component, Input, OnDestroy, ViewChild } from "@angular/core";
import { BaseComponent } from "@app/data";
import { TaskRequestDTO } from "@app/data/tasks/task-request.dto";
import { TaskWork } from '@app/data/tasks/taskwork.model';
import { TaskService } from "@app/data/tasks/taskwork.service";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";
import { MyTaskDetailComponent } from "./my-task-detail/my-task-detail.component";

@Component({
    selector: "my-task-tabs",
    templateUrl: "./my-task-tabs.component.html"
})
export class MyTaskTabsComponent extends BaseComponent<TaskWork> implements OnDestroy
{
    @ViewChild("taskDetail")
    taskDetail: MyTaskDetailComponent;

    @Input()
    entityUID: string;

    @Input()
    entityType: number;

    public readonly tab_Docs = TaskCommon.TaskTab_Docs;
    public readonly tab_Note = TaskCommon.TaskTab_Note;
    public readonly tab_Detail = TaskCommon.TaskTab_Detail;

    public readonly myEntityType = EntityType.Task;

    public setTab;

    private taskAns: Subscription;
    private taskQuest: Subscription;

    constructor(toastrService: ToastrService,
        private taskServ: TaskService)
    {
        super(toastrService);

        this.taskAns = this.taskServ.taskAnswer.subscribe((request) => { this.handleAnswer(request) });
        this.taskQuest = this.taskServ.taskQuestion.subscribe((request) => { this.handleQuestion(request) });
    }

    ngOnDestroy()
    {
        this.taskAns.unsubscribe();
        this.taskQuest.unsubscribe();
    }

    public onTabSelect(evtArg)
    {
        let req = new TaskRequestDTO(TaskRequestDTO.TaskRequest_TabChanged, evtArg);

        this.taskServ.sendQuestion(req);
    }

    private handleAnswer(answer: TaskRequestDTO)
    {
        switch (answer.taskID)
        {
            case TaskRequestDTO.TaskRequest_TabChanged:
                this.setTab = answer.data;
                break;
        }
    }

    private handleQuestion(question: TaskRequestDTO): void
    {
        switch (question.taskID)
        {
            case TaskRequestDTO.TaskRequest_FormDirty:
                this.handleDirty(question);
                break;

            case TaskRequestDTO.TaskRequest_Update:
                this.data = question.data;
                break;
        }
    }

    private handleDirty(question: TaskRequestDTO): void
    {
        let dirty = false;

        if (this.setTab == TaskCommon.TaskTab_Detail)
        {
            dirty = this.taskDetail.haveChanges;
        }

        let ans = new TaskRequestDTO(question.taskID, dirty);

        this.taskServ.sendAnswer(ans);
    }
}