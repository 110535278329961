import { Component, OnInit, ViewChild } from "@angular/core";
import { BaseComponent, PurchaserOwner, PurchaserOwnerService } from "@app/data";
import { Util } from "@app/utils/util";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { PurchaserOwnerHistoryListComponent } from "../purchaser-owner-history-list/purchaser-owner-history-list.component";

@Component({
    selector: "purchaser-owner-list",
    templateUrl: "./purchaser-owner-list.component.html"
})
export class PurchaserOwnerListComponent extends BaseComponent<PurchaserOwner> implements OnInit
{
    @ViewChild("dialogHistory")
    dialogHistory: PurchaserOwnerHistoryListComponent;

    constructor(
        toastrService: ToastrService,
        public modalService: NgbModal,
        private purchaserOwnerService: PurchaserOwnerService)
    {
        super(toastrService);

        this.apiService = purchaserOwnerService;
    }

    ngOnInit(): void
    {
        this.searchForm = this.makeForm({ purchaserUID: this.parentUID });
        this.postGetlist();
    }

    public getContact(contact): string
    {
        return Util.MakeName(contact);
    }

    public onHistory(uid: string): void
    {
        this.dialogHistory.openHistory(uid);
    }
}