import { AfterViewInit, Component, OnDestroy } from "@angular/core";
import { LayoutService } from "../layout.service";

@Component({
    styles: [":host { display: block; }", ":host ::ng-deep .layout-loading .sidenav-link { transition: none !important; }"],
    selector: "app-layout-1",
    templateUrl: "./layout-1.component.html",
})
export class Layout1Component implements AfterViewInit, OnDestroy
{
    // Prevent "blink" effect
    public initialized = false;

    constructor(private layoutService: LayoutService)
    {
    }

    ngAfterViewInit()
    {
        setTimeout(() =>
        {
            this.initialized = true;

            this.layoutService.init();
            this.layoutService.update();
            this.layoutService.setAutoUpdate(true);
        });
    }

    ngOnDestroy()
    {
        setTimeout(() =>
        {
            this.layoutService.destroy();
        });
    }

    closeSidenav()
    {
        setTimeout(() =>
        {
            this.layoutService.setCollapsed(true);
        });
    }
}