import { ExcelHelper } from "../misc/excel-helper";

export class ProductCommon
{
    public static readonly ExportTypes =
        [
            { id: 1, name: "Domestic" },
            { id: 2, name: "Foreign" },
            { id: 3, name: "Foreign Military Sales" }
        ]

    public static readonly UnitOfMessures =
        [
            { id: 1, name: "No." },
            { id: 2, name: "kg" },
            { id: 3, name: "Pcs" },
            { id: 4, name: "Dz" },
            { id: 5, name: "Doz." },
            { id: 6, name: "Bbl" },
            { id: 7, name: "Dz prs." },
            { id: 8, name: "Dz Pcs" },
            { id: 9, name: "m2" }
        ];

    public static readonly ProductStatuses =
        [
            { id: 7, name: "New" },
            { id: 2, name: "Pending" },
            { id: 8, name: "Pending Release" },
            { id: 1, name: "Active" },
            { id: 9, name: "Inactive" },
            { id: 5, name: "Failed" },
        ];

    public static readonly ScheduleBStatuses =
        [
            { id: 1, name: "Active" },
            { id: 2, name: "Pending" },
            { id: 9, name: "Inactive" }
        ];

    public static readonly ImportStatuses =
        [
            { id: 1, name: "Active" },
            { id: 5, name: "Failed" }
        ];

    public static getTemplate(isAdmin: boolean): void
    {
        let cols =
            [
                "SellerCategory",
                "SellerSubCategory",
                "IDno",
                "PartNo",
                "UPC",
                "CustomerDescription",
                "Make",
                "Model",
                "BarrelLength",
                "Caliber",
                "MagazineQuantity",
                "MagazineCapacityRounds",
                "ActionType",
                "TypeOfAmmo",
                "Manufacturer",
                "ProductPageLink",
                "ImageLink",
                "AdditionalImageLink",
                "Brand",
                "LithiumOrLithiumIonBattery",
                "Pounds",
                "Ounces",
                "WeightInKilograms",
                "CountryOfOrigin",
                "CJorCCATSClassification"
            ];

        if (isAdmin)
        {
            let addCols = ["IsSerializable?", "AEPDescription", "Category", "SubCategory", "WebsiteCategory", "WebsiteSubCategory"];

            cols = [...cols, ...addCols];
        }

        ExcelHelper.makeTemplate("Products", cols);
    }
}