import { Component } from "@angular/core";
import { GridFilter, GridFilterDataType, GridFilterStorage } from "@app/shared/ag-grid-helpers/models/grid-filter.model";
import { DateFormatter } from "@app/shared/pipes/date-formatter.pipe";
import { DateHelper } from "@app/utils/date-helper";
import { AgFilterComponent } from "ag-grid-angular";
import { IDoesFilterPassParams } from "ag-grid-community";
import { GridFilterCustom } from './../grid-filter-custom';

@Component({
    styleUrls: ["../../../styles/gridfilter.scss"],
    templateUrl: "./grid-cycle-filter.component.html"
})
export class GridCycleFilter extends GridFilterCustom implements AgFilterComponent
{
    public selectMonth = null;

    private filterField;

    agInit(params: any): void
    {
        this.initFilter(params);

        this.filterField = params.field;
    }

    isFilterActive(): boolean
    {
        return this.selectMonth != null;
    }

    doesFilterPass(params: IDoesFilterPassParams): boolean
    {
        if (this.isServerSide)
        {
            return true;
        }

        let myDate = DateHelper.formatDate(params.data[this.filterField], DateFormatter.FormatAmericanDate);

        return this.selectMonth == myDate;
    }

    getModel()
    {
        if (this.selectMonth == null)
        {
            return null;
        }

        let filt = new GridFilter(this.selectMonth, GridFilterDataType.date);

        return new GridFilterStorage(this.filterField, filt);
    }

    setModel(model: any)
    {
        this.selectMonth = model?.gridFilter?.filter;
    }

    public onFilterClear(): void
    {
        this.selectMonth = null;
        this.applyAndClose();
    }

    public onFilterApply(): void
    {
        this.applyAndClose();
    }
}