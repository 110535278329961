import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ApiService } from "@app/data/api/api.service";
import { NoteMod } from "@app/data/notes/note.model";

@Injectable({
    providedIn: "root"
})
export class NotesService extends ApiService<NoteMod>
{
    public get editorForm()
    {
        let fb = new FormBuilder();
        let grp = fb.group(
            {
                uid: [],
                note: [, Validators.required],
                entityUID: [, Validators.required],
                isAEPOnly: [],
                entityType: [,Validators.required]
            });

        return grp;
    }

    constructor(httpClient: HttpClient)
    {
        super(httpClient, "notes");
    }
}