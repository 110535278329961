import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { Observable, Subject } from "rxjs";
import { ApiService } from "../api/api.service";
import { LicenseTemplate } from "./license-template";
import { LicenseTemplateRequestDTO } from "./license-template-request.dto";

@Injectable({
    providedIn: "root"
})
export class LicenseTemplateService extends ApiService<LicenseTemplate>
{
    public get editorForm()
    {
        let form = new FormBuilder();
        let grpFields = form.group(
            {
                uid: [],
                name: [, Validators.required],
                isBusiness: [],
                licenseType: [, Validators.required],
                customerUID: [, Validators.required],
                isGovernment: [],
                isIndividual: []
            });

        return grpFields;
    }

    public templateAnswer: Observable<any>;
    public templateQuestion: Observable<any>;

    private messageAnswer = new Subject();
    private messageQuestion = new Subject();

    constructor(httpClient: HttpClient)
    {
        super(httpClient, "license-template");

        this.templateAnswer = this.messageAnswer.asObservable();
        this.templateQuestion = this.messageQuestion.asObservable();
    }

    public sendAnswer(request: LicenseTemplateRequestDTO)
    {
        this.messageAnswer.next(request);
    }

    public sendQuestion(request: LicenseTemplateRequestDTO)
    {
        this.messageQuestion.next(request);
    }
}