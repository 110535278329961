import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FormBuilder, Validators } from '@angular/forms';
import { ApiService } from "@app/data/api/api.service";
import { Observable } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { TaskDocument } from "./task-document.model";

@Injectable({
    providedIn: "root"
})
export class TaskDocumentService extends ApiService<TaskDocument>
{
    get editorForm()
    {
        let form = new FormBuilder();
        let grpFields = form.group(
            {
                uid: [],
                name: [, Validators.required],
                taskUID: [],
                fileName: [, Validators.required],
                statusType: [],
                fileNameUID: [],
                documentText: [],
                documentOther: [],
                documentTypeUID: [, Validators.required]
            });

        return grpFields;
    }

    constructor(httpClient: HttpClient)
    {
        super(httpClient, "tasks");
    }

    public upload(file: File, data: TaskDocument)
    {
        let form = new FormData();

        form.append(file.name, file);

        return this.postFile("doc-upload", form).pipe(
            map(d1 =>
            {
                data.fileName = d1.fileName;
                data.fileNameUID = d1.fileNameUID;

                return d1;
            }),
            mergeMap(d2 =>
            {
                return this.post("doc-save", data);
            })
        );
    }

    public uploadImage(imgFile: Blob, taskDoc: TaskDocument): Observable<TaskDocument>
    {
        let name = (taskDoc.name || "ThisDoesntMatter") + ".png";
        let uploadData = new FormData();

        uploadData.append(name, imgFile);

        let obby = this.postFile("doc-upload", uploadData).pipe(
            map(retRes =>
            {
                taskDoc.fileName = name + ".png";
                taskDoc.fileNameUID = retRes.fileNameUID;

                return retRes;
            }),
            mergeMap(d2 => this.post("doc-save", taskDoc)));

        return obby;
    }

    public update(data: TaskDocument)
    {
        return this.post("doc-save", data);
    }
}