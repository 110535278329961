import { Component, Input, OnInit } from "@angular/core";
import { CustomerService } from "@app/data";
import { MerchantRequestDTO } from "@app/data/customer/merchant-request.dto";
import { MerchantBase } from "@app/shared/customer/merchant-base";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: "customer-billing",
    templateUrl: "./customer-billing.component.html"
})
export class CustomerBillingComponent extends MerchantBase implements OnInit
{
    @Input()
    customerUID: string;

    constructor(toastrService: ToastrService,
        private customerService: CustomerService)
    {
        super(toastrService);

        this.apiService = customerService;
        this.subQuestion = this.customerService.merchantQuestion.subscribe((request) => { this.handleQuestion(request) });
    }

    async ngOnInit()
    {
        this.dataForm = this.customerService.editorForm;
        this.loadCustomer(this.customerUID);
    }

    ngOnDestroy(): void
    {
        this.subQuestion.unsubscribe();
    }

    private handleQuestion(question: MerchantRequestDTO)
    {
        switch (question.requestID)
        {
            case MerchantRequestDTO.Request_Save:
                this.saveMe();
                break;
        }
    }

    private saveMe()
    {
        let sub = this.submit();

        if (!sub)
        {
            return;
        }

        sub.add(() => this.doneDidSaved());
    }

    private doneDidSaved()
    {
        if (this.submitError)
        {
            return;
        }

        let ans = new MerchantRequestDTO(MerchantRequestDTO.Request_Save, this.data);

        this.close();
        this.customerService.sendAnswer(ans);
    }
}