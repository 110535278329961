export class DealerConnectCommon
{
    public static readonly TradeStatuses =
        [
            { id: 1, name: "Approved" },
            { id: 10, name: "Pending Review" },
            { id: 12, name: "Declined" }
        ];

    public static getStatusName(status: number): string
    {
        let stat = DealerConnectCommon.TradeStatuses.find(elm => elm.id == status);

        return stat?.name;
    }
}