export class HoldItemModel
{
    code: string;
    description: string;

    constructor(abv: string, desc: string)
    {
        this.code = abv;
        this.description = desc;
    }
}