import { Component, ViewChild } from "@angular/core";
import { BaseComponent, Purchaser, PurchaserService, PurchaserType } from "@app/data";
import { InputEditViewType } from "@app/data/enums/input-edit-view-type.enum";
import { PurchaserStatusType } from "@app/data/enums/purchaser-status-type.enum";
import { ToastrService } from "ngx-toastr";
import { PurchaserContactHistoryListComponent } from "../purchaser-contact-history-list/purchaser-contact-history-list.component";
import { PurchaserSubCategoryComponent } from "../purchaser-sub-category-edit/purchaser-sub-category-edit.component";

@Component({
    selector: "purchaser-view",
    styleUrls: ["./purchaser-view.component.scss"],
    templateUrl: "./purchaser-view.component.html"
})
export class PurchaserViewComponent extends BaseComponent<Purchaser>
{
    @ViewChild("dialogSubCat")
    dialogSubCat: PurchaserSubCategoryComponent;

    @ViewChild("dialogContactHistory")
    dialogContactHistory: PurchaserContactHistoryListComponent;

    public readonly viewTypeLink = InputEditViewType.Link;
    public readonly purchaserTypes = PurchaserType;
    public readonly purchaserStatus = PurchaserStatusType;

    public isOnWatchList: boolean;
    public isLimitedBuyer: boolean;

    constructor(
        toastrService: ToastrService,
        private purchaserService: PurchaserService)
    {
        super(toastrService);

        this.apiService = purchaserService;
    }

    public ngOnChanges(): void
    {
        if (this.data)
        {
            this.isOnWatchList = this.data.isOnWatchList;
            this.isLimitedBuyer = this.data.isLimitedBuyer;
        }
    }

    public onSubCatOpen(): void
    {
        this.dialogSubCat.openEdit(this.data);
    }

    public onSubCatUpdate(): void
    {
        if (this.update)
        {
            this.update.emit();
        }

        this.detail({ uid: this.parentUID });
    }

    public onWatchListChanged(): void
    {
        this.data.isOnWatchList = this.isOnWatchList;
        this.savePurchaser();
    }

    public onLimitedBuyerChanged(): void
    {
        this.data.isLimitedBuyer = this.isLimitedBuyer;
        this.savePurchaser();
    }

    public onRefresh(): void
    {
        if (this.update)
        {
            this.update.emit();
        }
    }

    public onRefreshEOD(): void
    {
        this.loading = true;

        let sub = this.apiService.get("eod", { purchaserUID: this.data.uid }).subscribe(
            retRes => () => this.onRefresh(),
            errRes => this.error(errRes));

        sub.add(() => this.loading = false);
    }

    public onTaxIDSave(value: string): void
    {
        this.data.taxIdentification = value;
        this.savePurchaser();
    }

    public onWebsiteSave(value: string): void
    {
        this.data.websiteLink = value;
        this.savePurchaser();
    }

    private savePurchaser(): void
    {
        this.loading = true;

        let sub = this.purchaserService.save(this.data).subscribe(
            {
                next: (retRes) =>
                {
                    if (this.update)
                    {
                        this.update.emit(this.data);
                    }

                    this.success("Save complete");
                },
                error: (errRes) => this.error(errRes)
            });

        sub.add(() => this.loading = false);
    }
}