import { Component, ElementRef, ViewChild } from "@angular/core";
import { CustomerSearch } from "@app/data/customer-search/customer-search";
import { CustomerSearchService } from "@app/data/customer-search/customer-search.service";
import { CommonSettings } from "@app/shared/common-settings";
import { GlobalIcons } from '@app/shared/GlobalIcons';
import { Util } from "@app/utils/util";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: "merchant-search-dialog",
    templateUrl: "./merchant-search-dialog.component.html"
})
export class MerchantSearchDialog
{
    @ViewChild("dialogMerchant")
    dialogMerchant: ElementRef<any>;

    public readonly Icons = GlobalIcons;

    public merchantSearchData: CustomerSearch;

    private modalRef;

    constructor(
        private modalServ: NgbModal,
        private toastrService: ToastrService,
        private merchSearchService: CustomerSearchService)
    {
    }

    public open(search: string | CustomerSearch)
    {
        if (typeof search == "string")
        {
            this.merchSearchService.list({ customerUID: search }).subscribe(retRes => this.checkMerchant(retRes));
        }
        else
        {
            this.merchantSearchData = search;
            this.openDialog();
        }
    }

    public onClose()
    {
        this.modalRef.close();
    }

    private checkMerchant(retRes)
    {
        if (Util.isEmpty(retRes?.results))
        {
            this.toastrService.warning("Failed to find Merchant's data", "Merchant Detail", CommonSettings.ToastOptions);
            return;
        }

        this.merchantSearchData = retRes.results[0];
        this.openDialog();
    }

    private openDialog()
    {
        let opts = CommonSettings.DialogOptions(CommonSettings.DialogSize_XLarge);

        this.modalRef = this.modalServ.open(this.dialogMerchant, opts);
    }
}