import { NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";

export interface ListType
{
    id: number;
    name: string;
}

export class CommonSettings
{
    public static readonly DialogSize_Small = "modal-sm";
    public static readonly DialogSize_Large = "modal-lg";
    public static readonly DialogSize_XLarge = "modal-xl";

    public static readonly USAUID = "26e556cb-2de7-4e2c-85a2-84b4646c56fc";
    public static readonly GlobalUID = "f178743c-285f-4dfc-9b6d-80db6d67afc1";
    public static readonly AddressURL = "https://www.google.com/maps/place/";

    public static readonly DashboardTab_Tasks = 1;
    public static readonly DashboardTab_Profile = 2;
    public static readonly DirtyFormConfirmationMessage = "You have some unsaved changes, continue without saving?";

    public static readonly Profiles: Array<ListType> =
        [
            { id: 2, name: "Master" },
            { id: 3, name: "Admin" },
            { id: 4, name: "Purchaser" },
            { id: 5, name: "Merchant" },
            { id: 6, name: "Freight Forwarder" }
        ];

    public static readonly Genders: Array<ListType> =
        [
            { id: 1, name: "Male" },
            { id: 2, name: "Female" },
            { id: 3, name: "Choose not to say" },
            { id: 4, name: "Other" }
        ];

    public static readonly WikiTypes: Array<ListType> =
        [
            { id: 1, name: "User Manual" },
            { id: 2, name: "Release Notes" }
        ];

    public static readonly FeedbackTypes: Array<ListType> =
        [
            { id: 1, name: "Search Engine" },
            { id: 2, name: "Registration" },
            { id: 3, name: "EasyExport" }
        ];

    public static readonly ToastOptions =
        {
            allowHtml: true,
            closeButton: false,
            progressBar: true,
            tapToDismiss: true,
            positionClass: "toast-center-center"
        };

    public static readonly Priorities: Array<ListType> =
        [
            { id: 1, name: "High" },
            { id: 2, name: "Medium" },
            { id: 3, name: "Low" }
        ];

    public static readonly CKEditorToolbar = {
        toolbar:
        {
            items: [
                "heading", "|",
                "bold", "italic", "|",
                "link", "|",
                "outdent", "indent", "|",
                "bulletedList", "numberedList", "|",
                "undo", "redo"
            ],
            shouldNotGroupWhenFull: true
        }
    };

    public static DialogOptions(dialogSize = CommonSettings.DialogSize_Large): NgbModalOptions
    {
        let opts: NgbModalOptions =
        {
            backdrop: "static",
            keyboard: false,
            scrollable: true,
            windowClass: dialogSize
        };

        return opts;
    }

    public static getNameFromID(list: Array<ListType>, id: number): string
    {
        let dude = list.find(elm => elm.id == id);

        return dude?.name;
    }

    public static openAddressWindow(address: string): void
    {
        window.open(CommonSettings.AddressURL + address);
    }
}