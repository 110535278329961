import { Component, Input, OnInit } from "@angular/core";
import { Product, ProductService } from "@app/data";
import { GlobalIcons } from "@app/shared/GlobalIcons";
import { ProductDialogService } from "@app/shared/products/product-dialog/product-dialog.service";
import { Util } from "@app/utils/util";

@Component({
    selector: "product-link",
    templateUrl: "./product-link.html",
})
export class ProductLink implements OnInit
{
    @Input()
    product: Product;

    @Input()
    productUID: string;

    public readonly Icons = GlobalIcons;

    public prodUID: string;
    public webLink: string;
    public partNumber: string;

    constructor(private prodServ: ProductService, private prodDiggyServ: ProductDialogService)
    {
    }

    ngOnInit(): void
    {
        if (this.productUID)
        {
            this.prodServ.detail({ uid: this.productUID }).subscribe(retRes => this.loadProduct(retRes));
        }
        else
        {
            this.loadProduct(this.product);
        }
    }

    public onDetail(): void
    {
        this.prodDiggyServ.editByUID(this.product.uid);
    }

    public onWebsite()
    {
        window.open(this.webLink, "_blank");
    }

    private loadProduct(inProd: Product)
    {
        if (Util.isEmpty(inProd))
        {
            this.webLink = "";
            this.prodUID = "";
            this.partNumber = "";
            return;
        }

        this.webLink = inProd.websiteLink1;
        this.prodUID = inProd.uid;
        this.partNumber = inProd.partNumber;
    }
}