export enum WikiErrorType
{
    MissingLink = 1,
    MissingAnchor = 2
}

export class WikiCommon
{
    public static readonly Tag_Icon = "[[";
    public static readonly Tag_AEPImage = "](@";
    public static readonly Tag_ExternalLink = "]($";
    public static readonly Tag_InternalLink = "](#";
    public static readonly Tag_InternalAnchor = "[:#";

    public static readonly WikiTypes =
        [
            { id: 1, name: "User Manual" },
            { id: 2, name: "Release Notes" }
        ];

    public static makeTagImage(imageName, altText = ""): string
    {
        return `![${altText}](@${imageName})`;
    }

    public static makeExternalLink(text, tag)
    {
        return `[${text}]($${tag})`
    }

    public static makeInternalLink(text, tag)
    {
        return `[${text}](#${tag})`
    }

    public static makeInternalAnchor(tag)
    {
        return `[:#${tag}]`;
    }

    public static makePhoneLink(phone)
    {
        return `<a href="tel:${phone}">${phone}</a>`;
    }
}