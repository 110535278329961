export class ImageRequest
{
    width?: number;
    height?: number;
    fileName: string;

    constructor(fileName?: string, imgWidth?: number, imgHeight?: number)
    {
        this.width = imgWidth;
        this.height = imgHeight;
        this.fileName = fileName;
    }
}