import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiService } from "@app/data/api/api.service";
import { ProductSearch } from "./product-search.model";

@Injectable({
    providedIn: "root"
})
export class ProductSearchesService extends ApiService<ProductSearch>
{
    constructor(httpClient: HttpClient)
    {
        super(httpClient, "product-searches");
    }
}