export class DocumentCommon
{
    public static readonly DocumentStatuses =
        [
            { id: 1, name: "Approved", },
            { id: 2, name: "Pending" },
            { id: 7, name: "New" },
            { id: 9, name: "Expired" },
            { id: 10, name: "Under Review" },
            { id: 12, name: "Declined" }
        ];
}