import { Component, ElementRef, Input, ViewChild } from "@angular/core";
import { AuthService, BaseComponent, LookupService, PurchaserDocument, PurchaserDocumentService } from "@app/data";
import { DocumentDesignationType } from "@app/data/enums/document-designation-type.enum";
import { DocumentStatus } from "@app/data/enums/document-status-type.enum";
import { DocumentTypesEnum, OrderCommon } from '@app/data/orders/order-common';
import { TransactionDocument } from "@app/data/transaction-document/transaction-document";
import { TransactionDocumentService } from "@app/data/transaction-document/transaction-document.service";
import { TransactionProductService } from "@app/data/transaction-product/transaction-product.service";
import { CommonSettings } from "@app/shared/common-settings";
import { ConfirmationService, IConfirmationOptions } from "@app/shared/confirmation/confirmation.service";
import { DocumentCommon } from "@app/shared/documents/document-common";
import { Util } from "@app/utils/util";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: "document-edit",
    templateUrl: "./document-edit.component.html"
})
export class DocumentEditComponent extends BaseComponent<TransactionDocument | PurchaserDocument>
{
    @Input()
    noteEntityType: number;

    @Input()
    documentEditType;

    @ViewChild("dialogDoc")
    dialogDoc: ElementRef<any>;

    public static readonly EditType_Order = 1;
    public static readonly EditType_Manual = 2;
    public static readonly EditType_Purchaser = 3;

    public docFile: File;
    public docTypes;
    public docStatusType = DocumentCommon.DocumentStatuses;
    public docTypeIsOther = false;
    public allowImportPermit = false;
    public docDesignationTypes;
    public listOfImportPermits;

    private documentUpdate = false;
    private documentEditUpload = false;

    constructor(
        toastrService: ToastrService,
        public authService: AuthService,
        public lookupService: LookupService,
        private modalServ: NgbModal,
        private confirmService: ConfirmationService,
        private prodDetailsServ: TransactionProductService,
        private purchaserDocServ: PurchaserDocumentService,
        private transactionDocumentService: TransactionDocumentService)
    {
        super(toastrService);

        this.modalService = modalServ;
    }

    public openAdd()
    {
        this.open(this.dialogDoc, null, CommonSettings.DialogSize_XLarge);
        this.setupUI();
    }

    public openEdit(data)
    {
        this.open(this.dialogDoc, data, CommonSettings.DialogSize_XLarge);
        this.setupUI();
    }

    private setupUI()
    {
        this.docFile = null;
        this.documentEditUpload = false;
        this.resetForm();

        if (this.documentEditType == DocumentEditComponent.EditType_Purchaser)
        {
            this.setupForPurchaser();
        }
        else
        {
            this.allowImportPermit = true;
            this.setupForOrder();
        }

        if (this.authService.isAdmin)
        {
            this.prodDetailsServ.post("list", { transactionUID: this.parentUID }).subscribe(retRes => this.loadPermits(retRes));
        }

        this.patchValue();

        if (this.data)
        {
            this.readOnly = (this.data.statusType != DocumentStatus.New && this.authService.profile.profileType == this.profileTypes.purchaser);
            this.docTypeIsOther = !Util.isEmpty(this.data.documentOther);
            this.documentUpdate = true;

            this.setOther();

            if (!this.readOnly && this.data.hasOwnProperty("isDocumentReadOnly"))
            {
                this.readOnly = (this.data as TransactionDocument).isDocumentReadOnly;
            }
        }
        else
        {
            let stat;

            if (this.documentEditType == DocumentEditComponent.EditType_Purchaser)
            {
                this.setValue("documentDesignationType", DocumentDesignationType.UnderReview);
            }

            if (this.authService.isAdmin)
            {
                stat = DocumentStatus.Approved;
            }
            else
            {
                stat = DocumentStatus.New;
            }

            this.readOnly = false;
            this.setValue("statusType", stat);
        }
    }

    public onFileSelect(evtArg)
    {
        this.documentEditUpload = true;

        this.docFile = evtArg.target.files[0];
        this.formControls.fileName.setValue(this.docFile.name);

        let nameVal = this.docFile.name.indexOf(".") > 0 ? this.docFile.name.split(".")[0] : this.docFile.name;

        this.formControls.name.setValue(nameVal);
        this.dataForm.markAsDirty();
    }

    public onClose()
    {
        if (!this.dataForm.dirty)
        {
            this.close();
            return;
        }

        let opts: IConfirmationOptions =
        {
            FunctionConfirm: () => this.close()
        };

        this.confirmService.YesNo(CommonSettings.DirtyFormConfirmationMessage, opts);
    }

    public onSave(closeMe: boolean): void
    {
        if (this.invalid())
        {
            return;
        }

        this.loading = true;

        let newData = this.submitDatesStore();

        if (!this.docTypeIsOther)
        {
            this.setValue("documentOther", null);
        }

        if (this.documentUpdate && !this.documentEditUpload)
        {
            if (this.documentEditType == DocumentEditComponent.EditType_Purchaser)
            {
                this.purchaserDocServ.update(newData).subscribe(
                    {
                        next: (retRes) => this.saveComplete(retRes, closeMe),
                        error: (errRes) =>
                        {
                            this.loading = false;
                            this.error(errRes);
                        }
                    });
            }
            else
            {
                this.transactionDocumentService.update(newData).subscribe(
                    {
                        next: (retRes) => this.saveComplete(retRes, closeMe),
                        error: (errRes) =>
                        {
                            this.loading = false;
                            this.error(errRes);
                        }
                    });
            }
        }
        else
        {
            if (this.documentEditType == DocumentEditComponent.EditType_Purchaser)
            {
                this.purchaserDocServ.upload(this.docFile, newData).subscribe(
                    {
                        next: (retRes) => this.saveComplete(retRes, closeMe),
                        error: (errRes) =>
                        {
                            this.loading = false;
                            this.error(errRes);
                        }
                    });
            }
            else
            {
                this.transactionDocumentService.upload(this.docFile, newData).subscribe(
                    {
                        next: (retRes) => this.saveComplete(retRes, closeMe),
                        error: (errRes) =>
                        {
                            this.loading = false;
                            this.error(errRes);
                        }
                    });
            }
        }
    }

    private saveComplete(retRes: any, closeMe: boolean): void
    {
        this.uid = retRes.uid;
        this.data = retRes;
        this.loading = false;

        this.patchValue();

        if (this.update)
        {
            this.update.emit(retRes);
        }

        if (closeMe)
        {
            this.close();
            return;
        }

        this.data = retRes;

        this.patchValue();
    }

    public onDocTypeSelect(selection)
    {
        this.docTypeIsOther = (selection.code == DocumentTypesEnum.Other || selection.id == DocumentTypesEnum.Other || selection.id == DocumentTypesEnum.AEPOther);

        this.setOther();
    }

    private setupForOrder()
    {
        this.dataForm = this.transactionDocumentService.editorForm;

        this.setValue("transactionUID", this.parentUID);
        this.lookupService.getTransactionDocumentTypeList().add(() => this.plugDocTypes());
    }

    private setupForPurchaser()
    {
        this.dataForm = this.purchaserDocServ.editorForm;

        if (this.authService.isAdmin)
        {
            this.docDesignationTypes = OrderCommon.PurchaserDocumentDesignationTypes;
        }

        this.setValue("purchaserUID", this.parentUID);
        this.lookupService.getPurchaserDocumentTypeList().add(() => this.plugDocTypes());
    }

    private plugDocTypes()
    {
        if (this.documentEditType == DocumentEditComponent.EditType_Purchaser)
        {
            this.docTypes = this.lookupService.purchaserDocumentType;
        }
        else
        {
            this.docTypes = OrderCommon.PlugDocumentTypes(this.lookupService.transactionDocumentType, this.authService.profile.profileType);
        }
    }

    private setOther()
    {
        if (this.docTypeIsOther)
        {
            this.makeRequired(["documentOther"]);
        }
        else
        {
            this.makeRequired(["documentOther"], false);
        }
    }

    private loadPermits(retRes)
    {
        let transData = retRes.results;

        this.listOfImportPermits = [];

        for (let lp = 0; lp < transData.length; lp++)
        {
            for (let ss = 0; ss < 3; ss++)
            {
                let permit = transData[lp]["importPermit" + ss];

                if (Util.isEmpty(permit))
                {
                    continue;
                }

                let found = this.listOfImportPermits.find(cond => cond.name == permit);

                if (Util.isEmpty(found))
                {
                    this.listOfImportPermits.push({ name: permit });
                }
            }
        }
    }
}