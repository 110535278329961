import { IToolPanel, IToolPanelParams } from "@ag-grid-community/all-modules";
import { Component } from "@angular/core";
import { GridHelpers } from "@app/shared/ag-grid-helpers/grid-helper";
import { GlobalIcons } from "@app/shared/GlobalIcons";

@Component({
    selector: "grid-sidebar-extra",
    styleUrls: ["grid-sidebar-extras.scss"],
    templateUrl: "grid-sidebar-extras.html",
})
export class GridSidebarExtras implements IToolPanel
{
    public Icons = GlobalIcons;
    public autoSave;
    public isSaving = false;
    public haveSubGrids: boolean;

    private params: any;
    private gridBase;

    agInit(params: IToolPanelParams): void
    {
        this.params = params;
        this.gridBase = this.params.grid;
        this.haveSubGrids = this.params.api.context.contextParams.providedBeanInstances.gridOptions.masterDetail;
    }

    refresh(): void
    {
    }

    public onRefresh(): void
    {
        this.params.api.closeToolPanel();

        this.gridBase.gridRefresh();
    }

    public onExportExcel(): void
    {
        this.params.api.exportDataAsExcel();
    }

    public onFilterClear(): void
    {
        this.gridBase.mainGrid.setFilterModel(null);
    }

    public onSettingReset(): void
    {
        this.params.api.closeToolPanel();

        GridHelpers.GridStateClear(this.gridBase);
    }

    public onFixSize(): void
    {
        this.params.api.closeToolPanel();

        GridHelpers.FixSizes(this.params.api);
    }

    public onRowsExpand(): void
    {
        this.expandRows(true);
    }

    public onRowsCollapse(): void
    {
        this.expandRows(false);
    }

    private expandRows(expand: boolean): void
    {
        this.params.api.forEachNode((node) => node.setExpanded(expand));
    }
}