import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { map, mergeMap } from "rxjs/operators";
import { ApiService } from "../api/api.service";
import { PurchaserDocument } from "./purchaser-document";

@Injectable({
    providedIn: "root"
})
export class PurchaserDocumentService extends ApiService<PurchaserDocument>
{
    get editorForm()
    {
        let form = new FormBuilder();
        let grpFields = form.group({
            uid: [],
            name: [, Validators.required],
            fileName: [, Validators.required],
            issuedBy: [, Validators.required],
            issuedDate: [],
            statusType: [],
            fileNameUID: [],
            purchaserUID: [],
            documentOther: [],
            expirationDate: [],
            documentTypeUID: [, Validators.required],
            documentDesignationType: []
        });

        return grpFields;
    }

    constructor(httpClient: HttpClient)
    {
        super(httpClient, "purchaser-document");
    }

    upload(file: File, data: PurchaserDocument)
    {
        const uploadData = new FormData();
        uploadData.append(file.name, file);

        return this.postFile("upload", uploadData).pipe(
            map(d1 =>
            {
                data.fileName = d1.fileName;
                data.fileNameUID = d1.fileNameUID;

                return d1;
            }),
            mergeMap(d2 =>
            {
                return this.save(data);
            })
        );
    }

    update(data: PurchaserDocument)
    {
        return this.save(data);
    }
}