import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ApiService } from "../api/api.service";
import { ShopOrder } from "./shop-order";

@Injectable({
    providedIn: "root"
})
export class ShopOrderService extends ApiService<ShopOrder>
{
    public get dataForm()
    {
        let fb = new FormBuilder();
        let grp = fb.group(
            {
                stage: [],
                poNumber: [, Validators.required],
                customerUID: [],
                purchaseDate: [, Validators.required],
                purchaserUID: [, Validators.required],
                billingAddressUID: [, Validators.required],
                shippingAddressUID: [, Validators.required],
            });

        return grp;
    }

    constructor(httpClient: HttpClient)
    {
        super(httpClient, "order-manual-entry");
    }
}