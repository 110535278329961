import { Component, ElementRef, ViewChild } from "@angular/core";
import { HistoryListCommon } from "@app/admin/history-list-common/history-list-common";
import { ProductHistory } from "@app/data/product/product-history.model";
import { ProductHistoryService } from "@app/data/product/product-history.service";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: "product-history-list",
    templateUrl: "./product-history-list.html"
})
export class ProductHistoryList extends HistoryListCommon<ProductHistory>
{
    @ViewChild("dialogHistory")
    dialogHistory: ElementRef;

    constructor(
        toastrService: ToastrService,
        private prodHistoryServ: ProductHistoryService)
    {
        super(toastrService, prodHistoryServ);

        this.setupGrid("Product-History", "Modified");
    }

    public onMyGridReady(evtArg): void
    {
        this.onGridReady(evtArg, false);

        this.getHistory({ productUID: this.parentUID });
    }
}