import { HttpClient, HttpParams } from '@angular/common/http';
import { DomSanitizer } from "@angular/platform-browser";
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FixedImage } from "../misc/fixed-image";
import { ApiResult } from './api-result';

export class ApiService<T> {

    lookupList: T[];

    constructor(public httpClient: HttpClient, private apiResource: string)
    {
    }

    list(query?: T): Observable<ApiResult<T>>
    {
        return this.getList('list', query);
    }

    lookup(query?: T): Observable<ApiResult<T>>
    {
        return this.getList('lookup', query);
    }

    save(body: T): Observable<T>
    {
        return this.post('save', body);
    }

    release(body: T): Observable<T>
    {
        return this.post('release', body);
    }

    saveList(body: T[]): Observable<T[]>
    {
        return this.postList('save', body);
    }

    detail(query?: T): Observable<T>
    {
        return this.get('detail', query);
    }

    getList(path: string, query?: T): Observable<ApiResult<T>>
    {
        let httpParams = new HttpParams();

        if (query)
        {
            Object.keys(query).forEach((key) =>
            {
                if (key)
                {
                    //If this param is passed as true we will use it else we don't wan't to filter for 'false' instead it is unchecked and we go back to queries without looking at this field
                    if (key == 'isImportPermitHold')
                    {
                        if (query['isImportPermitHold'])
                        {

                        }
                        else
                        {
                            delete query['isImportPermitHold'];
                        }
                    }
                    if (key == 'isEARLicenseHold')
                    {
                        if (query['isEARLicenseHold'])
                        {

                        }
                        else
                        {
                            delete query['isEARLicenseHold'];
                        }
                    }
                }
            }, this);
            httpParams = this.buildQuery(httpParams, query);
        }

        return this.httpClient.get<ApiResult<T>>(
            this.buildUrl(path),
            { params: httpParams });
    }

    getLookup(query?: T)
    {
        return this.lookup(query).subscribe(retRes => this.lookupList = retRes.results);
    }

    get(path: string, query?: T): Observable<T>
    {
        let httpParams = new HttpParams();

        if (query)
        {
            httpParams = this.buildQuery(httpParams, query);
        }

        return this.httpClient.get<T>(
            this.buildUrl(path),
            { params: httpParams });
    }

    delete(path: string, query?: T): Observable<T>
    {
        let httpParams = new HttpParams();

        if (query)
        {
            httpParams = this.buildQuery(httpParams, query);
        }

        return this.httpClient.delete<T>(this.buildUrl(path), { params: httpParams });
    }

    getFile(path: string, uid: string)
    {
        path = this.buildUrl(path);

        return this.httpClient.get(`${path}?fileNameUID=${uid}`,
            {
                observe: "response",
                responseType: "blob",
            })
            .pipe(map(res =>
            {
                return new Blob([res.body]);
            }));
    }

    post(path: string, body: T): Observable<T>
    {
        return this.httpClient.post<T>(
            this.buildUrl(path),
            JSON.stringify(body));
    }

    postGetList(path: string, body?: T): Observable<ApiResult<T>>
    {
        return this.httpClient.post<ApiResult<T>>(
            this.buildUrl(path),
            JSON.stringify(body));
    }

    postList(path: string, body: T[]): Observable<T[]>
    {
        return this.httpClient.post<T[]>(
            this.buildUrl(path),
            JSON.stringify(body));
    }

    postFile(path: string, body: FormData): Observable<T>
    {
        return this.httpClient.post<T>(
            this.buildUrl(path),
            body);
    }

    public buildUrl(path: string): string
    {
        return `${environment.apiUrl}/${this.apiResource}/${path}`;
    }

    private buildQuery(httpParams: HttpParams, query: T, parent?: string): HttpParams
    {
        Object.keys(query).forEach((key) =>
        {
            if (query[key] != null)
            {
                const param: string = parent ? `${parent}.${key}` : key;

                if (typeof query[key] !== 'object' || Array.isArray(query[key]))
                {
                    httpParams = httpParams.set(param, query[key]);
                } else
                {
                    httpParams = this.buildQuery(httpParams, query[key], param);
                }
            }
        }, this);

        return httpParams;
    }
}