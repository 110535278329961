import { DateHelper } from "@app/utils/date-helper";
import { PaymentType } from "@app/data/enums/payment-type";
import { ShipmentInstruction } from "@app/data/shipment/shipmentinstruction";
import { ShipmentInstructionCommodity } from "@app/data/shipment/shipmentinstructioncommodity";
import { DateFormatter } from "@app/shared/pipes/date-formatter.pipe";
import { PDFBuilder, WordWrapMethods } from "@app/shared/reporting/pdfBuilder";
import { ContactFormatter } from "@app/utils/contact-formatter";
import { Util } from "@app/utils/util";

export class ShipperLetterInstructionsGenerator
{
    public docPDF: PDFBuilder;
    public sliData: ShipmentInstruction;
    public commodities: Array<ShipmentInstructionCommodity>;

    private contBuild: ContactFormatter;
    private pageNumber: number = 1;

    constructor()
    {
        this.contBuild = new ContactFormatter();
        this.contBuild.delimiter = "\n";
    }

    public generate()
    {
        this.docPDF = new PDFBuilder();
        this.docPDF.fontReset();
        this.docPDF.addCursiveFont();

        let formatter = new DateFormatter();

        let half = this.docPDF.pageWidth() / 2;
        let currY = 0;
        let forth = this.docPDF.pageWidth() / 4;
        let third = this.docPDF.pageWidth() / 3;
        let workH = 0;
        let workW = 0;
        let compDate = formatter.transform(this.sliData.dateSLICompleted);

        let size = this.pageTitle("SHIPPER'S LETTER OF INSTRUCTION");

        this.docPDF.drawBox(0, size.height, PDFBuilder.DrawTo_End, PDFBuilder.DrawTo_End);

        currY += size.height;
        workH = 35;

        this.contBuild.contactData = this.sliData;
        this.contBuild.fieldPrefix = "customer";

        this.sliData["customerName"] = this.sliData.companyName;

        this.docPDF.dataBox(0, currY, third, workH * 2, "USPPI / EXPORTER", this.contBuild.getContact(), { wrap: WordWrapMethods.Wrap });
        this.docPDF.dataBox(third, currY, third, workH, "INLAND CARRIER", this.sliData.inlandCarrier);
        this.docPDF.dataBox(third * 2, currY, third, workH, "BILL OF LADING / AIR WAYBILL NO.", this.sliData.billOfLading);

        currY += workH;

        this.docPDF.dataBox(third, currY, third, workH, "DATE OF EXPORTATION", DateHelper.formatDate(this.sliData.dateOfExportation));
        this.docPDF.dataBox(third * 2, currY, third, workH, "TRANSPORTATION REFERENCE NO.", this.sliData.transportationReferenceNumber);

        currY += workH;
        workH = 40;
        workW = third / 2;

        this.docPDF.dataBox(0, currY, workW, workH, "USPPI EIN (IRS) NO.", this.sliData.einNumber);
        this.relatedBlock(workW, currY, workW, workH, this.sliData.isTransactionPartiesRelated);

        currY += workH;
        workH = 110;
        this.contBuild.fieldPrefix = "purchaser";

        this.contactBox(0, currY, third, workH, "ULTIMATE CONSIGNEE", this.contBuild.getContact(), this.sliData.purchaserEmailAddress, this.sliData.purchaserPhone);

        currY += workH;
        this.contBuild.fieldPrefix = "intermediateConsignee";

        this.contactBox(0, currY, third, workH, "INTERMEDIATE CONSIGNEE  ", this.contBuild.getContact(), this.sliData.intermediateConsigneeEmailAddress, this.sliData.intermediateConsigneePhone);

        currY += workH;
        workH = 30;

        this.docPDF.dataBox(0, currY, third, workH, "FORWARDING AGENT", this.sliData.forwardingAgent);
        this.docPDF.dataBox(third, currY, third, workH, "POINT (STATE) OF ORIGIN", this.sliData.customerState);
        this.docPDF.dataBox(third * 2, currY, third, workH, "COUNTRY OF ULTIMATE DESTINATION", this.sliData.purchaserCountry);

        currY += workH;

        this.docPDF.dataBox(0, currY, forth, workH, "LOADING PIER", this.sliData.loadingPier);
        this.docPDF.dataBox(forth, currY, forth, workH, "MODE OF TRANSPORT (Specify)", this.sliData.modeOfTransport);
        this.docPDF.dataBox(forth * 2, currY, forth, workH, "CARRIER IDENTIFICATION CODE", this.sliData.carrierIdentificationCode);
        this.docPDF.dataBox(forth * 3, currY, forth, workH, "SHIPMENT REFERENCE NO.", this.sliData.shipmentReferenceNumber);

        currY += workH;

        this.docPDF.dataBox(0, currY, forth, workH, "EXPORTING CARRIER", this.sliData.exportingCarrier);
        this.docPDF.dataBox(forth, currY, forth, workH, "PORT OF EXPORT", this.sliData.portOfExport);
        this.docPDF.dataBox(forth * 2, currY, forth, workH, "ENTRY NUMBER", this.sliData.entryNumber);
        this.docPDF.yesNoBox(forth * 3, currY, forth, workH, "HAZARDOUS MATERIALS", this.sliData.isHazardousMaterial);

        currY += workH;

        this.docPDF.dataBox(0, currY, forth, workH, "PORT OF UNLOADING (Vessel and air only)", this.sliData.portOfUnloading);
        this.docPDF.yesNoBox(forth, currY, forth, workH, "CONTAINERIZED (Vessel only)", this.sliData.isContainerized);
        this.docPDF.dataBox(forth * 2, currY, forth, workH, "IN BOND CODE", this.sliData.inBondCode);
        this.docPDF.yesNoBox(forth * 3, currY, forth, workH, "ROUTED EXPORT TRANSACTION", this.sliData.isRoutedExportTransaction);

        currY += workH;
        workH = 20;

        this.shipMethodBlock(0, currY, half, workH, this.sliData.isAir);
        this.consolidateBlock(half, currY, half, workH);

        currY += workH;
        workH = 30;

        this.docPDF.dataBox(0, currY, third, workH, "LICENSE NO./LICENSE EXCEPTION SYMBOL/AUTHORIZATION", "See detail below");
        this.docPDF.dataBox(third, currY, 80, workH, "ECCN (When required)", "See below");
        this.docPDF.dataBox(third + 80, currY, 50, workH, "# PACKAGES", this.sliData.numberOfPackages);
        this.docPDF.dataBox(third + 130, currY, 75, workH, "PACKAGE TYPE", this.sliData.packageType);
        this.docPDF.dataBox(third + 205, currY, 70, workH, "GROSS WEIGHT (kg)", this.sliData.grossWeightInKG);
        this.docPDF.dataBox(third + 275, currY, PDFBuilder.DrawTo_End, workH, "TOTAL VALUE", "$" + this.sliData.totalValue);

        currY += workH;
        workH = 40;

        this.docPDF.dataBox(0, currY, third, workH, "DULY AUTHORIZED OFFICER OR EMPLOYEE", this.sliData.customerContact);
        this.finePrint(third, currY, third, workH, "The USPPI authorizes the forwarder named above  to act as forwarding agent for export control and  customs purposes.");
        this.paymentBlock(third * 2, currY, third, workH, this.sliData.paymentType);

        currY += workH;
        workH = 50;

        this.finePrint(0, currY, third * 2, workH, "I certify that all statements made and all information contained herein are true and correct.  I understand that civil and criminal penalties, including forfeiture and sale, may be imposed for making false or fraudulent statements herein, failing to provide the violation of U.S. laws on exportation (13 U.S.C. Sec. 305; 22 U.S.C. Sec. 401; 18 U.S.C. requested information or for Sec. 1001; 50 U.S.C. App 2410).");
        this.deliverBlock(third * 2, currY, third, workH, this.sliData.isContactSender, this.sliData.isReturnToSender, this.sliData.isAbandoned);

        currY += workH;
        workH = 30;
        workW = third - 25;

        this.signature(0, currY, workW, workH, this.sliData.customerContact);
        this.finePrint(workW, currY, workW + 50, workH, "Confidential - for use solely for official purposes authorized by the Secretary of Commerce (13 U.S.C. 301 (g).");
        this.docPDF.yesNoBox(third * 2, currY, third, workH, "SHIPPER REQUESTS INSURANCE",  this.sliData.isShipperInsuranceRequested);

        currY += workH;

        this.docPDF.dataBox(0, currY, workW, workH, "TITLE", this.sliData.customerTitle);
        this.finePrint(workW, currY, workW + 50, workH, "Export shipments are subject to inspection by U.S. Customs Service and/or Office of Export Enforcement.");
        this.docPDF.dataBox(third * 2, currY, third, 90, "SPECIAL INSTRUCTIONS", this.sliData.specialInstruction, { wrap: WordWrapMethods.Wrap });

        currY += workH;

        this.docPDF.dataBox(0, currY, workW, workH, "DATE", compDate);
        this.docPDF.dataBox(workW, currY, workW + 50, workH, "AUTHENTICATION (When required)");

        currY += workH;

        this.docPDF.dataBox(0, currY, workW, workH, "TELEPHONE", this.sliData.customerPhone);
        this.docPDF.dataBox(workW, currY, workW + 50, workH, "E-MAIL ADDRESS", this.sliData.customerEmail);

        currY += workH;
        workH = 45;

        this.disclaimer(currY);
        this.commodityPage();

        this.docPDF.putTotalPages(PDFBuilder.PageExpression);

        return this.docPDF.output(PDFBuilder.Output_Blob);
    }

    private commodityPage()
    {
        let marg = this.docPDF.pageMargins.left + this.docPDF.Box_Padding;
        let maxY = this.docPDF.pageHeight();
        let currY = this.packagePageHeader();
        let workH = 15;
        let lineW = this.docPDF.internal.pageSize.getWidth() - this.docPDF.pageMargins.right;
        let endText = lineW - this.docPDF.pageMargins.right;

        for (let lp = 0; lp < this.commodities.length; lp++)
        {
            let kg = Util.roundTo(this.commodities[lp].totalKilograms, 2);
            let oz = Util.roundTo(this.commodities[lp].totalOunces, 2);

            currY += workH;

            this.docPDF.line(this.docPDF.pageMargins.left, currY, lineW, currY);

            currY += 3;

            this.docPDF.outText(marg, currY, this.commodities[lp].exportType?.code);
            this.docPDF.outText(marg + 15, currY, this.commodities[lp].scheduleBandDescription, { width: 230 });
            this.makeUoM(marg + 245, currY, this.commodities[lp]);
            this.docPDF.outText(marg + 310, currY, this.commodities[lp].totalPounds);
            this.docPDF.outText(marg + 340, currY, oz);
            this.docPDF.outText(marg + 370, currY, kg);
            this.docPDF.outText(marg + 395, currY, this.commodities[lp].classification, { width: 50 });
            this.docPDF.outText(marg + 445, currY, this.commodities[lp].licenseNumber);
            this.docPDF.outText(endText, currY, "$" + this.commodities[lp].value, { align: "right" });

            if ((currY + workH + 3) > maxY)
            {
                currY = this.packagePageHeader();
            }
        }
    }

    private makeUoM(posX: number, posY: number, commodity: ShipmentInstructionCommodity)
    {
        if (!Util.isEmpty(commodity.uoM1Value))
        {
            let uom = Util.roundTo(commodity.uoM1Value, 0) + " " + commodity.uoM1;

            this.docPDF.outText(posX, posY, uom);
        }

        if (!Util.isEmpty(commodity.uoM2Value))
        {
            let uom = Util.roundTo(commodity.uoM2Value, 0) + " " + commodity.uoM2;

            this.docPDF.outText(posX + 30, posY, uom);
        }
    }

    private packagePageHeader()
    {
        this.docPDF.addPage();
        this.pageNumber++;

        let size = this.pageTitle("SHIPPER'S LETTER OF INSTRUCTION - CONTINUATION SHEET");
        let currY = size.height;
        let third = this.docPDF.pageWidth() / 3;
        let workH = 110;

        this.docPDF.drawBox(0, currY, PDFBuilder.DrawTo_End, PDFBuilder.DrawTo_End);

        this.contBuild.fieldPrefix = "customer";
        this.docPDF.dataBox(0, currY, third, workH, "USPPI / EXPORTER", this.contBuild.getContact(), { wrap: WordWrapMethods.Wrap });

        this.contBuild.fieldPrefix = "purchaser";
        this.contactBox(third, currY, third, workH, "ULTIMATE CONSIGNEE", this.contBuild.getContact(), this.sliData.purchaserEmailAddress, this.sliData.purchaserPhone);

        this.contBuild.fieldPrefix = "intermediateConsignee";
        this.contactBox(third * 2, currY, third, workH, "INTERMEDIATE CONSIGNEE  ", this.contBuild.getContact(), this.sliData.intermediateConsigneeEmailAddress, this.sliData.intermediateConsigneePhone);

        currY += workH;
        workH = 25;

        this.headerBox(0, currY, 400, workH, "SCHEDULE B NUMBER DESCRIPTION OF COMMODITIES");
        this.docPDF.dataBox(400, currY, PDFBuilder.DrawTo_End, workH, "SHIPMENT REFERENCE NO.", this.sliData.shipmentReferenceNumber);

        currY += workH;
        workH = 30;

        this.headerBox(0, currY, 15, PDFBuilder.DrawTo_End, "D/F\nor\nM");
        this.headerBox(15, currY, 230, PDFBuilder.DrawTo_End, "\n\nSCHEDULE B NUMBER / DESCRIPTION");
        this.headerBox(245, currY, 60, PDFBuilder.DrawTo_End, "\n\nQUANTITY");
        this.headerBox(305, currY, 90, PDFBuilder.DrawTo_End, "\nWEIGHT");
        this.headerBox(395, currY, 50, PDFBuilder.DrawTo_End, "ECCN\nOR\nUSML");
        this.headerBox(445, currY, 80, PDFBuilder.DrawTo_End, "EXPORT LICENSE NO\nOR\nEXEMPTION/EXCEPTION");
        this.headerBox(525, currY, PDFBuilder.DrawTo_End, PDFBuilder.DrawTo_End, "VALUE", "U.S.dollars omit cents\nSelling price or cost if not sold");

        this.docPDF.fontSet(this.docPDF.Font_DefaultSize - 2);
        this.docPDF.setTextColor(this.docPDF.Color_Label);
        this.docPDF.text("lbs", 320, currY + 35, { baseline: PDFBuilder.Baseline_Top });
        this.docPDF.text("oz", 350, currY + 35, { baseline: PDFBuilder.Baseline_Top });
        this.docPDF.text("kg", 380, currY + 35, { baseline: PDFBuilder.Baseline_Top });
        this.docPDF.setTextColor(this.docPDF.Color_Data);
        this.docPDF.fontReset();

        return currY + workH;
    }

    private pageTitle(title: string)
    {
        this.docPDF.fontSet(12, "bold");

        let maxX = this.docPDF.pageWidth();
        let textDim = this.docPDF.getTextDimensions(title);

        this.docPDF.text(title, maxX / 2, this.docPDF.pageMargins.top, { align: PDFBuilder.Align_Center });
        this.docPDF.fontReset();

        this.docPDF.text(`Page ${this.pageNumber} of {totalPages}`, maxX - 40, this.docPDF.pageMargins.top);

        return { width: maxX, height: textDim.h };
    }

    private headerBox(posX: number, posY: number, width: number, height: number, label: string, smallPrint = null)
    {
        let rect = this.docPDF.drawBox(posX, posY, width, height);

        rect.x += (rect.w / 2);

        this.docPDF.fontSet(this.docPDF.Font_DefaultSize - 2);
        this.docPDF.setTextColor(this.docPDF.Color_Label);

        this.docPDF.text(label, rect.x, rect.y, { baseline: PDFBuilder.Baseline_Top, align: PDFBuilder.Align_Center });

        let labDim = this.docPDF.getTextDimensions(label);

        this.docPDF.setTextColor(this.docPDF.Color_Data);
        this.docPDF.fontReset();

        if (smallPrint == null)
        {
            return;
        }

        let smallY = rect.y + labDim.h;

        this.docPDF.fontSet(this.docPDF.Font_DefaultSize - 4);
        this.docPDF.setTextColor(this.docPDF.Color_Label);

        this.docPDF.text(smallPrint, rect.x, smallY, { baseline: PDFBuilder.Baseline_Top, align: PDFBuilder.Align_Center });

        this.docPDF.setTextColor(this.docPDF.Color_Data);
        this.docPDF.fontReset();
    }

    private shipMethodBlock(posX: number, posY: number, width: number, height: number, isAir?: boolean)
    {
        let rect = this.docPDF.drawBox(posX, posY, width, height);

        this.docPDF.checkBox(rect.x + 10, rect.y, "Air", isAir == true);
        this.docPDF.checkBox(rect.x + 100, rect.y, "Ocean", isAir != true);
    }

    private consolidateBlock(posX: number, posY: number, width: number, height: number)
    {
        let rect = this.docPDF.drawBox(posX, posY, width, height);

        this.docPDF.checkBox(rect.x + 10, rect.y, "Consolidate");
        this.docPDF.checkBox(rect.x + 100, rect.y, "Direct");
    }

    private contactBox(posX: number, posY: number, width: number, height: number, title: string, address: any, email: string, phone: string)
    {
        this.docPDF.dataBox(posX, posY, width, height, title, address, { wrap: WordWrapMethods.Wrap });

        let myW = width - 75;
        let myY = (posY + height) - 50;

        this.docPDF.dataBox(posX, myY, width, 30, "Email", email, { border: false });
        this.docPDF.dataBox(posX, myY + 23, width, 30, "Tel", phone, { border: false });
    }

    private finePrint(posX: number, posY: number, width: number, height: number, text: string)
    {
        let rect = this.docPDF.drawBox(posX, posY, width, height);
        let broken = this.docPDF.splitTextToSize(text, rect.w - 2);

        this.docPDF.outText(rect.x, rect.y, broken);
    }

    private disclaimer(posY: number)
    {
        let posX = this.docPDF.pageMargins.left + this.docPDF.Box_Padding;
        let width = this.docPDF.pageWidth() - (this.docPDF.Box_Padding * 2);
        let broken = this.docPDF.splitTextToSize("NOTE: The Shipper or his Authorized Agent hereby authorizes the above named Company, in his name and on his behalf, to prepare any export documents, to sign and accept any documents relating to said shipment and forward this shipment in accordance with the conditions of carriage and the tariffs of the carriers employed.  The shipper guarantees payment of all collect charges in the event the consignee refuses payment.  Hereunder the sole responsibility of the Company is to use reasonable care in the selection of carriers, forwarders, agents, and others to whom it may entrust the shipment.", width);

        posY += this.docPDF.pageMargins.top + this.docPDF.Box_Padding;

        this.docPDF.outText(posX, posY, broken);
    }

    private signature(posX: number, posY: number, width: number, height: number,  name: string)
    {
        let header = this.docPDF.dataBox(posX, posY, width, height, "SIGNATURE");

        if (Util.isEmpty(name))
        {
            return;
        }

        this.docPDF.fontReset();
        this.docPDF.setFontSize(18);
        this.docPDF.setFont(PDFBuilder.Font_Cursive);
        this.docPDF.text(name, header.x, header.y + 5, { baseline: PDFBuilder.Baseline_Middle });
        this.docPDF.fontReset();
    }

    private relatedBlock(posX: number, posY: number, width: number, height: number, related: boolean)
    {
        let rect = this.docPDF.dataBox(posX, posY, width, height, "PARTIES TO TRANSACTION");
        let workX = rect.x + 25;
        let workY = rect.y - 2;

        this.docPDF.checkBox(workX, workY, "Related", related == true);
        this.docPDF.checkBox(workX, workY + 12, "Non-Related", related != true);
    }

    private paymentBlock(posX: number, posY: number, width: number, height: number, payType?: number)
    {
        let rect = this.docPDF.dataBox(posX, posY, width, height, "SELECT ONE");
        let workX = rect.x + 25;
        let workY = rect.y - 2;

        this.docPDF.checkBox(workX, workY, "Collect", payType == PaymentType.Collect);
        this.docPDF.checkBox(workX, workY + 12, "Prepaid", payType == PaymentType.Prepaid);
    }

    private deliverBlock(posX: number, posY: number, width: number, height: number, contactSender: boolean, returnToSender: boolean, abandon: boolean)
    {
        let rect = this.docPDF.dataBox(posX, posY, width, height, "SHIPPERS INSTRUCTIONS IN CASE OF INABILITY TO DELIVER");
        let workX = rect.x + 25;
        let workY = rect.y - 2;

        this.docPDF.checkBox(workX, workY, "CONTACT SENDER", contactSender);
        this.docPDF.checkBox(workX, workY + 12, "RETURN TO SENDER", returnToSender);
        this.docPDF.checkBox(workX, workY + 24, "ABANDON", abandon);
    }
}