import { Component } from "@angular/core";
import { GridFilter, GridFilterDataType, GridFilterStorage } from "@app/shared/ag-grid-helpers/models/grid-filter.model";
import { GlobalIcons } from '@app/shared/GlobalIcons';
import { DateHelper } from "@app/utils/date-helper";
import { Util } from "@app/utils/util";
import { AgFilterComponent } from "ag-grid-angular";
import { IDoesFilterPassParams } from "ag-grid-community";
import { GridFilterCustom } from "../grid-filter-custom";

@Component({
    styleUrls: ["../../../styles/gridfilter.scss"],
    templateUrl: "./grid-date-filter.component.html"
})
export class GridDateFilter extends GridFilterCustom implements AgFilterComponent
{
    public readonly Icons = GlobalIcons;

    public myDate = null;

    private fixDate: string;
    private filterField;

    agInit(params: any): void
    {
        this.initFilter(params);

        this.filterField = this.getParam("field");
    }

    isFilterActive(): boolean
    {
        return this.myDate != null;
    }

    doesFilterPass(params: IDoesFilterPassParams): boolean
    {
        if (this.isServerSide)
        {
            return true;
        }

        let fDate = params.data[this.filterField];

        if (Util.isEmpty(fDate))
        {
            return (this.myDate == null);
        }

        if (this.myDate == null)
        {
            return false;
        }

        let formDate = DateHelper.formatDate(fDate);

        return (formDate == this.fixDate);
    }

    getModel()
    {
        if (this.myDate == null)
        {
            return null;
        }

        let filt = new GridFilter(this.fixDate, GridFilterDataType.date);

        return new GridFilterStorage(this.filterField, filt);
    }

    setModel(model: any)
    {
        this.myDate = model?.gridFilter?.filter;
    }

    public onDateChange(): void
    {
        if (this.myDate == null)
        {
            this.fixDate = null;
            return;
        }

        let day = this.myDate.day.toString().padStart(2, "0");
        let mon = this.myDate.month.toString().padStart(2, "0");

        this.fixDate = `${this.myDate.year}-${mon}-${day}`;
    }

    public onFilterClear(): void
    {
        this.myDate = null;
        this.applyAndClose();
    }

    public onFilterApply(): void
    {
        this.applyAndClose();
    }
}