import { Component, ElementRef, ViewChild } from "@angular/core";
import { HistoryListCommon } from "@app/admin/history-list-common/history-list-common";
import { PurchaserContactHistory, PurchaserContactHistoryService } from "@app/data";
import { CommonSettings } from "@app/shared/common-settings";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: "purchaser-contact-history-list",
    templateUrl: "./purchaser-contact-history-list.component.html"
})
export class PurchaserContactHistoryListComponent extends HistoryListCommon<PurchaserContactHistory>
{
    @ViewChild("dialogHistory")
    dialogHistory: ElementRef;

    constructor(
        toastrService: ToastrService,
        public modalService: NgbModal,
        private purchaserContactHistoryService: PurchaserContactHistoryService)
    {
        super(toastrService, purchaserContactHistoryService);

        this.modalService = this.modalService;

        this.setupGrid("Purchaser-Contacts-History");
    }

    public openHistory(contactUID: string): void
    {
        this.open(this.dialogHistory, null, CommonSettings.DialogSize_XLarge);

        this.getHistory({ purchaserContactUID: contactUID });
    }

    public onClose(): void
    {
        this.close();
    }
}