import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiService } from "../api/api.service";
import { ShopPostMessage } from "./shop-post-message";

@Injectable({
    providedIn: "root"
})
export class ShopPostMessageService extends ApiService<ShopPostMessage>
{
    constructor(httpClient: HttpClient)
    {
        super(httpClient, "order-manual-entry");
    }
}