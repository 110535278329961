import { Component, OnDestroy, TemplateRef, ViewChild } from "@angular/core";
import { BaseComponent, License, LicenseService } from "@app/data";
import { LicenseStatus } from "@app/data/enums/license-status-type.enum";
import { TaskEntity } from '@app/data/enums/task-entity.enum';
import { LicenseCommon } from "@app/data/license/license-common";
import { QandAData } from "@app/data/misc/q-and-a-data.model";
import { CommonSettings } from '@app/shared/common-settings';
import { ConfirmationService, IConfirmationOptions } from "@app/shared/confirmation/confirmation.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";

@Component(
{
    selector: "license-dialog",
    templateUrl: "./license-dialog.component.html",
})
export class LicenseDialogComponent extends BaseComponent<License> implements OnDestroy
{
    @ViewChild("licenseDialog")
    licenseDialog: TemplateRef<any>;

    public readonly Tab_Docs = 1;
    public readonly Tab_Tasks = 2;
    public readonly Tab_Trans = 3;
    public readonly Tab_Detail = 4;

    public readonly taskEntityType = TaskEntity.License;
    public readonly licenseStatuses = LicenseStatus;

    public setTab: number;
    public licenseType: number;
    public licenseName: string;

    private holdTab: number;
    private closeMe: boolean;
    private liceAns: Subscription;

    constructor(
        toastrService: ToastrService,
        public modalService: NgbModal,
        private licenseServ: LicenseService,
        private confirmService: ConfirmationService)
    {
        super(toastrService);

        this.liceAns = this.licenseServ.answerHandeler.subscribe((request) => { this.handleAnswer(request) });
        this.modalService = modalService;
    }

    ngOnDestroy()
    {
        this.liceAns.unsubscribe();
    }

    public openAdd(licenseType: number): void
    {
        this.open(this.licenseDialog, null, CommonSettings.DialogSize_XLarge);

        this.setupUI(licenseType);
    }

    public openEdit(data)
    {
        this.open(this.licenseDialog, data, CommonSettings.DialogSize_XLarge);

        this.setupUI(data.licenseType);
    }

    public onTabChange(evtArg): void
    {
        if (this.setTab != this.Tab_Detail)
        {
            return;
        }

        this.holdTab = evtArg.nextId;
        this.closeMe = false;

        evtArg.preventDefault();

        this.licenseServ.sendQuestion(new QandAData(LicenseCommon.qaData_Dirty));
    }

    public onUpdate(): void
    {
        if (this.update)
        {
            this.update.emit();
        }
    }

    public onClose(): void
    {
        if (this.setTab != this.Tab_Detail)
        {
            this.close();
            return;
        }

        this.closeMe = true;

        this.licenseServ.sendQuestion(new QandAData(LicenseCommon.qaData_Dirty));
    }

    public onSave(closeMe: boolean): void
    {
        this.closeMe = closeMe;
        this.holdTab = null;

        this.licenseServ.sendQuestion(new QandAData(LicenseCommon.qaData_Save, closeMe));
    }

    public onRelease(closeMe: boolean): void
    {
        this.closeMe = closeMe;
        this.holdTab = null;

        this.licenseServ.sendQuestion(new QandAData(LicenseCommon.qaData_Release, closeMe));
    }

    private setupUI(licenseType: number): void
    {
        this.setTab = this.Tab_Detail;
        this.closeMe = false;
        this.licenseType = licenseType;
        this.licenseName = LicenseCommon.getLicenseName(licenseType);
    }

    private handleAnswer(answer: QandAData): void
    {
        switch (answer.taskID)
        {
            case LicenseCommon.qaData_Save:
            case LicenseCommon.qaData_Release:
                this.whatNext(answer);
                break;

            case LicenseCommon.qaData_Dirty:
                this.handleDirty(answer);
                break;
        }
    }

    private handleDirty(answer: QandAData): void
    {
        if (!answer.data)
        {
            this.whatNext();
            return;
        }

        let opts: IConfirmationOptions =
        {
            FunctionConfirm: () => this.whatNext()
        };

        this.confirmService.YesNo(CommonSettings.DirtyFormConfirmationMessage, opts);
    }

    private whatNext(answer: QandAData = null): void
    {
        if (this.closeMe)
        {
            this.close();
            return;
        }

        if (this.holdTab != null)
        {
            this.setTab = this.holdTab;
            return;
        }

        if (answer == null)
        {
            return;
        }

        this.licenseServ.detail({ uid: answer.data.uid }).subscribe(
            retRes => this.loadDetail(retRes),
            errRes => this.error(errRes));
    }

    private loadDetail(liceData: License): void
    {
        this.data = liceData;

        let ques = new QandAData(LicenseCommon.qaData_Update, liceData);

        this.licenseServ.sendQuestion(ques);
    }
}