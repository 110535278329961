export class OrderStatus
{
    public id: number;
    public description: string;

    constructor(id: number, desc: string)
    {
        this.id = id;
        this.description = desc;
    }
}