import { Component } from "@angular/core";
import { Product } from "@app/data";
import { Util } from "@app/utils/util";
import { AgRendererComponent } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

@Component({
    templateUrl: "./grid-cell-part-number.html",
})
export class GridCellPartNumberRenderer implements AgRendererComponent
{
    public product: Product;

    private cellParams: ICellRendererParams;

    constructor()
    {
    }

    agInit(params)
    {
        this.cellParams = params;

        if (Util.isEmpty(params.field))
        {
            this.product = params.data;
        }
        else
        {
            this.product = params.data[params.field];
        }
    }

    refresh(params): boolean
    {
        this.cellParams = params;
        return true;
    }
}