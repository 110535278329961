import { Util } from "@app/utils/util";
import cloneDeep from "lodash.clonedeep";
import { GridCheckListFilter } from "../filters/grid-checklist-filter/checklist-filter.component";
import { GridColumn } from "./grid-column.model";

export class GridColumnStatus extends GridColumn
{
    constructor(statuses)
    {
        super("status", "Status");

        this.width = 175;
        this.filter = "checkListFilter";
        this.comparator = this.statusSorter;
        this.cellRenderer = this.statusCellRenderer.bind(this);
        this.filterParams = { field: "statusType", items: cloneDeep(statuses) };
        this.suppressSizeToFit = true;

        this.registerComponents.push({ checkListFilter: GridCheckListFilter });
    }

    private statusCellRenderer(params: any)
    {
        let name;
        let data = params.data;

        if (data.hasOwnProperty("status"))
        {
            name = data.status?.name;
        }
        else
        {
            if (data.hasOwnProperty("statusType"))
            {
                let status = params.column.colDef.filterParams.items;
                let statObj = status.find(elm => elm.id == data.statusType);

                if (statObj)
                {
                    name = statObj.name;
                }
            }
        }

        if (Util.isEmpty(name))
        {
            return Util.DOM_Space;
        }

        let className = name.toLowerCase().replaceAll(" ", "");

        return `<div class="status-box status-box-restricted status-box-${className}" title="${name}">${name}</div>`;
    }
}