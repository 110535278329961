import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { AuthService, BaseComponent, Freight, FreightService, LookupService } from "@app/data";
import { FreightForwarderType } from "@app/data/enums/freight-forwarder-type.enum";
import { FreightForwarderRequestDTO } from "@app/data/freight/freight-forwarder-request.dto";
import { CommonSettings } from "@app/shared/common-settings";
import { FreightForwarderCommon } from "@app/shared/freight-forwarder/freight-forwarder-common";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";

@Component({
    selector: "freight-forwarder-details",
    styleUrls: ["freight-forwarder-details.component.scss"],
    templateUrl: "./freight-forwarder-details.component.html"
})
export class FreightForwarderDetailsComponent extends BaseComponent<Freight> implements OnInit, OnDestroy
{
    @Input()
    isAdmin: boolean;

    @Input()
    recordType: number;

    @Input() set freightData(freight)
    {
        this.data = freight;
        this.patchValue();
    }

    public get isFF()
    {
        return (this.recordType == FreightForwarderType.FreightFordwarder);
    }

    public get haveChanges(): boolean
    {
        return this.dataForm?.dirty;
    }

    public readonly statuses = FreightForwarderCommon.FreightForwarderStatuses;

    public userRole: number;

    private subQues: Subscription;

    constructor(toastrService: ToastrService,
        public lookupService: LookupService,
        private authService: AuthService,
        private freightServ: FreightService)
    {
        super(toastrService);

        this.subQues = freightServ.freightQuestion.subscribe((req) => { this.handleQuestion(req) });
        this.dataForm = freightServ.editorForm;
        this.apiService = freightServ;
    }

    async ngOnInit()
    {
        this.lookupService.getCountryList();

        if (this.recordType == FreightForwarderType.FreightFordwarder)
        {
            this.setValue("countryUID", CommonSettings.USAUID);
        }

        let prof = await this.authService.getProfile$().toPromise();

        this.userRole = prof.profileType;
    }

    ngOnDestroy()
    {
        this.subQues.unsubscribe();
    }

    private handleQuestion(question: FreightForwarderRequestDTO)
    {
        switch (question.taskID)
        {
            case FreightForwarderRequestDTO.FreightRequest_Save:
                this.saveFF();
                break;
        }
    }

    private saveFF()
    {
        let sub = this.submit();

        if (sub == null)
        {
            this.sendSaveFailed();
            return;
        }

        sub.add(() => this.saveDone());
    }

    private saveDone()
    {
        if (this.submitError)
        {
            this.sendSaveFailed();
            return;
        }

        let ansUp = new FreightForwarderRequestDTO(FreightForwarderRequestDTO.FreightRequest_Update, this.data);
        let ansSave = new FreightForwarderRequestDTO(FreightForwarderRequestDTO.FreightRequest_Save, this.data);

        this.success("Freight Forwarder saved");

        this.freightServ.sendAnswer(ansUp);
        this.freightServ.sendAnswer(ansSave);
    }

    private sendSaveFailed()
    {
        let ans = new FreightForwarderRequestDTO(FreightForwarderRequestDTO.FreightRequest_Save);

        this.freightServ.sendAnswer(ans);
    }
}