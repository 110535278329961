import { FormControl } from "@angular/forms";
import { DateFormatter } from "@app/shared/pipes/date-formatter.pipe";
import { Util } from "@app/utils/util";
import { NgbDate } from "@ng-bootstrap/ng-bootstrap";

export class DateHelper
{
    public static readonly RegDT = /^\d{2}\/\d{2}\/\d{4} \d{2}:\d{2}:\d{2} UTC$/;

    public static paternMatch(testDT: any): boolean
    {
        if (Util.isEmpty(testDT))
        {
            return false;
        }

        if (testDT instanceof Date)
        {
            return true;
        }

        let chg = String(testDT);
        let test = chg.match(DateHelper.RegDT);

        return (test != null);
    }

    public static isDate(testDT: any): boolean
    {
        return !isNaN(Date.parse(testDT));
    }

    public static formatDate(wannabeDate: any, displayFormat: string = DateFormatter.FormatDefault): string
    {
        let form = new DateFormatter();

        return form.transform(wannabeDate, displayFormat);
    }

    public static fromNGBDate(inDate: any): Date
    {
        if (Util.isEmpty(inDate))
        {
            return null;
        }

        if (inDate instanceof FormControl)
        {
            if (Util.isEmpty(inDate.value))
            {
                return null;
            }

            let setDate = new Date(inDate.value.year, inDate.value.month - 1, inDate.value.day)

            inDate.setValue(setDate);
            return;
        }

        return new Date(inDate.year, inDate.month - 1, inDate.day);
    }

    public static makeNGBDate(inDate: any): NgbDate
    {
        let outDate = DateHelper.makeDate(inDate);

        if (outDate == null)
        {
            return null;
        }

        return new NgbDate(outDate.getFullYear(), outDate.getMonth() + 1, outDate.getDate());
    }

    public static makeDate(wannabeDate: any, badDateOutput = null): Date
    {
        if (Util.isEmpty(wannabeDate))
        {
            return badDateOutput;
        }

        if (wannabeDate instanceof Date)
        {
            return wannabeDate;
        }

        let outDate = null;

        if (typeof wannabeDate == "string")
        {
            if (wannabeDate == DateFormatter.Now)
            {
                outDate = new Date();
            }
            else
            {
                let makeDate = wannabeDate.replace(" UTC", "");

                outDate = new Date(makeDate);
            }
        }
        else
        {
            if ("year" in wannabeDate)
            {
                outDate = new Date(wannabeDate.year, wannabeDate.month - 1, wannabeDate.day);
            }
        }

        if (outDate == null || outDate == Util.BadDate)
        {
            return badDateOutput;
        }

        return outDate;
    }

    public static endOfMonth(when: any, format: string = DateFormatter.FormatDefault)
    {
        let wDate = DateHelper.makeDate(when);

        if (wDate == null)
        {
            return "";
        }

        let form = new DateFormatter();
        let dasDate = new Date(wDate.getFullYear(), wDate.getMonth() + 1, 0);

        return form.transform(dasDate, format);
    }

    public static endOfMonthDate(when: Date)
    {
        return new Date(when.getFullYear(), when.getMonth() + 1, 0)
    }

    public static sortArray(dataArray, field: string, descending: boolean = true)
    {
        if (Util.isEmpty(dataArray))
        {
            return dataArray;
        }

        dataArray.sort((rec1, rec2) =>
        {
            let date1 = new Date(rec1[field]);
            let date2 = new Date(rec2[field]);

            if (date1.toString() == Util.BadDate || date2.toString() == Util.BadDate)
            {
                return 0;
            }

            if (descending)
            {
                return date1.getTime() - date2.getTime();
            }

            return date2.getTime() - date1.getTime();
        });
    }
}