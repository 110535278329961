import { Component, ElementRef, ViewChild } from "@angular/core";
import { HistoryListCommon } from "@app/admin/history-list-common/history-list-common";
import { PurchaserOwnerHistory, PurchaserOwnerHistoryService } from "@app/data";
import { CommonSettings } from "@app/shared/common-settings";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: "purchaser-owner-history-list",
    templateUrl: "./purchaser-owner-history-list.component.html",
})
export class PurchaserOwnerHistoryListComponent extends HistoryListCommon<PurchaserOwnerHistory>
{
    @ViewChild("dialogHistory")
    dialogHistory: ElementRef;

    constructor(
        toastrService: ToastrService,
        public modalService: NgbModal,
        private purchaserOwnerHistoryService: PurchaserOwnerHistoryService)
    {
        super(toastrService, purchaserOwnerHistoryService);

        this.modalService = modalService;

        this.setupGrid("Purchaser-Owner-History");
    }

    public openHistory(ownerUID: string): void
    {
        this.open(this.dialogHistory, null, CommonSettings.DialogSize_XLarge);
        this.getHistory({ purchaserOwnerUID: ownerUID });
    }

    public onClose(): void
    {
        this.close();
    }
}