import { GridColumn } from "@app/shared/ag-grid-helpers/models/grid-column.model";
import { FreightForwarderCommon } from '@app/shared/freight-forwarder/freight-forwarder-common';
import { Util } from "@app/utils/util";
import { GridFFRecordTypeFilter } from "./grid-ff-record-type-filter/grid-ff-record-type-filter.component";

export class GridColumnFFType extends GridColumn
{
    constructor()
    {
        super("countryUID", "Type");

        this.width = 175;
        this.filter = "recordTypeFilter";
        this.comparator = this.sorter;
        this.cellRenderer = this.renderFFType;
        this.suppressSizeToFit = true;

        this.registerComponents.push({ recordTypeFilter: GridFFRecordTypeFilter });
    }

    private renderFFType(agData: any): string
    {
        return FreightForwarderCommon.recordType(agData.data.countryUID);
    }

    private sorter(val1, val2)
    {
        if (Util.isEmpty(val1) || Util.isEmpty(val2))
        {
            return 0;
        }

        let name1 = FreightForwarderCommon.recordType(val1);
        let name2 = FreightForwarderCommon.recordType(val2);

        if (name1 == name2)
        {
            return 0;
        }

        return name1 > name2 ? 1 : -1;
    }
}