import { EventEmitter, Injectable, Output } from "@angular/core";
import { Product } from "@app/data";
import { CommonSettings } from '@app/shared/common-settings';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ProductDialogComponent } from "./product-dialog.component";

@Injectable()
export class ProductDialogService
{
    @Output()
    refresh = new EventEmitter();

    private diOpts = CommonSettings.DialogOptions(CommonSettings.DialogSize_XLarge);

    constructor(private modalService: NgbModal)
    {
    }

    public add(): void
    {
        let modalRef = this.modalService.open(ProductDialogComponent, this.diOpts);

        modalRef.closed.subscribe(() => this.refresh.emit());
    }

    public edit(prod: Product): void
    {
        let modalRef = this.modalService.open(ProductDialogComponent, this.diOpts);

        modalRef.componentInstance.product = prod;
        modalRef.closed.subscribe(() => this.refresh.emit());
    }

    public editByUID(prodUID: string): void
    {
        let modalRef = this.modalService.open(ProductDialogComponent, this.diOpts);

        modalRef.componentInstance.productUID = prodUID;
        modalRef.closed.subscribe(() => this.refresh.emit());
    }
}