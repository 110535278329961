import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiService } from "@app/data/api/api.service";
import { Dealer } from "./dealer.model";

@Injectable({
    providedIn: "root"
})
export class DealerService extends ApiService<Dealer>
{
    constructor(httpClient: HttpClient)
    {
        super(httpClient, "dealer");
    }
}