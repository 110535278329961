import { Component, Input } from "@angular/core";

@Component({
    selector: "web-link",
    templateUrl: "./web-link.html"
})
export class WebLink
{
    @Input()
    webLink: string;

    constructor()
    {
    }
}