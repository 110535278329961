import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { map, mergeMap } from "rxjs/operators";
import { ApiService } from "../api/api.service";
import { LicenseDocument } from "./license-document";

@Injectable({
    providedIn: "root"
})
export class LicenseDocumentService extends ApiService<LicenseDocument>
{
    public get editorForm()
    {
        let form = new FormBuilder();
        let grpFields = form.group(
            {
                uid: [],
                name: [, Validators.required],
                fileName: [, Validators.required],
                licenseUID: [],
                documentTypeUID: [, Validators.required],
            });

        return grpFields;
    }

    constructor(httpClient: HttpClient)
    {
        super(httpClient, "license-document");
    }

    public upload(file: File, data: LicenseDocument)
    {
        const uploadData = new FormData();
        uploadData.append(file.name, file);

        return this.postFile("upload", uploadData).pipe(
            map(d1 =>
            {
                data.fileName = d1.fileName;
                data.fileNameUID = d1.fileNameUID;

                return d1;
            }),
            mergeMap(d2 => this.save(data))
        );
    }
}