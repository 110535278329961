import { Component, Input } from "@angular/core";

@Component({
    selector: "phone-link",
    templateUrl: "./phone-link.html"
})
export class PhoneLink
{
    @Input()
    phone: string;

    constructor()
    {
    }
}