import { Component } from "@angular/core";
import { ProductCountry, ProductCountryService } from "@app/data";
import { GridBase } from "@app/shared/ag-grid-helpers/ag-grid-base";
import { GridCellBoolean } from "@app/shared/ag-grid-helpers/models/grid-cell-boolean.model";
import { GridColumnBoolean } from "@app/shared/ag-grid-helpers/models/grid-column-boolean";
import { GridColumnCustom } from "@app/shared/ag-grid-helpers/models/grid-column-custom.model";
import { GridFilter, GridFilterDataType } from "@app/shared/ag-grid-helpers/models/grid-filter.model";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: "app-product-country-list",
    templateUrl: "./product-country-list.component.html",
    styles: []
})
export class ProductCountryListComponent extends GridBase<ProductCountry>
{
    constructor(
        toastrService: ToastrService,
        public modalService: NgbModal,
        private productCountryService: ProductCountryService)
    {
        super(toastrService, productCountryService);

        this.setupGrid();
    }

    public onMyGridReady(params)
    {
        let filt = new GridFilter(this.parentUID, GridFilterDataType.text);

        this.filterAdd("productUID", filt);
        this.onGridReady(params);
    }

    private setupGrid()
    {
        let bools = new GridCellBoolean(GridCellBoolean.DefaultYesText, GridCellBoolean.DefaultNoText);

        this.storageKey = "CountryList-Admin";
        this.addColumn(new GridColumnCustom("country.name", "Country", { minWidth: 150 }));
        this.addColumn(new GridColumnCustom("importHTS", "Import HTS", { minWidth: 150 }));
        this.addColumn(new GridColumnBoolean("isImportable", "Is Importable", bools, 150));
        this.addColumn(new GridColumnCustom("individualPermit1", "Individual #1", { minWidth: 150 }));
        this.addColumn(new GridColumnCustom("governmentPermit1", "Government #1", { minWidth: 150 }));
        this.addColumn(new GridColumnCustom("businessPermit1", "Business #1", { minWidth: 150 }));
        this.addColumn(new GridColumnCustom("individualPermit2", "Individual #2", { minWidth: 150 }));
        this.addColumn(new GridColumnCustom("governmentPermit2", "Government #2", { minWidth: 150 }));
        this.addColumn(new GridColumnCustom("businessPermit2", "Business #2", { minWidth: 150 }));

        this.setServerPath("list");
    }
}
