import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { LookupService } from "@app/data";
import { TaskCommon } from "@app/data/tasks/task-common";
import { TaskDocumentService } from "@app/data/tasks/task-document.service";
import { CommonSettings } from "@app/shared/common-settings";
import { ConfirmationService, IConfirmationOptions } from "@app/shared/confirmation/confirmation.service";
import { Util } from "@app/utils/util";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { TaskDocumentCommon } from "../my-task-doc-common";

@Component({
    selector: "my-task-document-edit",
    styleUrls: ["./my-task-doc-edit.component.scss"],
    templateUrl: "./my-task-doc-edit.component.html"
})
export class TaskDocumentEditComponent extends TaskDocumentCommon implements OnInit
{
    @ViewChild("documentDialog")
    documentDialog: TemplateRef<any>;

    public file: File;

    private documentUpdate = false;
    private documentEditUpload = false;

    constructor(
        toastrService: ToastrService,
        public modalService: NgbModal,
        public lookupService: LookupService,
        private confirmService: ConfirmationService,
        private taskDocServ: TaskDocumentService)
    {
        super(toastrService);

        this.dataForm = this.taskDocServ.editorForm;
        this.modalService = this.modalService;
    }

    ngOnInit(): void
    {
        this.lookupService.getTaskDocumentTypeList();
    }

    public openAdd(taskUID): void
    {
        this.open(this.documentDialog);

        this.fixForm();
        this.setValue("taskUID", taskUID);
        this.makeRequired(["fileName"], true);
    }

    public openEdit(data, taskUID): void
    {
        this.open(this.documentDialog);

        this.fixForm();

        this.data = data;
        this.patchValue();

        this.docTypeIsOther = !Util.isEmpty(this.data.documentOther);
        this.documentUpdate = true;

        this.setValue("taskUID", taskUID);
        this.setOther();
        this.makeRequired(["fileName"], true);
    }

    public onFileSelect(evtArg): void
    {
        this.file = evtArg.target.files[0];
        this.documentEditUpload = true;

        let nameVal = this.file.name.indexOf(".") > 0 ? this.file.name.split(".")[0] : this.file.name;

        this.setValue("name", nameVal);
        this.setValue("fileName", this.file.name);
        this.dataForm.markAsDirty();
    }

    public onDocTypeSelect(selection): void
    {
        let type = selection.code.toLowerCase();

        this.docTypeIsOther = (type == TaskCommon.DocumentTypeOther);

        this.setOther();
    }

    public onClose(): void
    {
        if (!this.dataForm.dirty)
        {
            this.close();
            return;
        }

        let opts: IConfirmationOptions =
        {
            FunctionConfirm: () => this.close()
        };

        this.confirmService.YesNo(CommonSettings.DirtyFormConfirmationMessage, opts);
    }

    public onSave(): void
    {
        if (this.invalid())
        {
            return;
        }

        this.loading = true;

        if (!this.docTypeIsOther)
        {
            this.setValue("documentOther", null);
        }

        if (this.documentUpdate && !this.documentEditUpload)
        {
            this.taskDocServ.update(this.dataValue).subscribe(
                retRes =>
                {
                    this.loading = false;

                    if (this.update)
                    {
                        this.update.emit(retRes);
                    }

                    this.close();
                },
                errRes =>
                {
                    this.loading = false;
                    this.error(errRes);
                });
        }
        else
        {
            this.taskDocServ.upload(this.file, this.dataValue).subscribe(
                retRes =>
                {
                    this.loading = false;

                    if (this.update)
                    {
                        this.update.emit(retRes);
                    }

                    this.close();
                },
                errRes =>
                {
                    this.loading = false;
                    this.error(errRes);
                });
        }
    }

    private fixForm(): void
    {
        this.file = null;
        this.docTypeIsOther = false;
        this.documentUpdate = false;
        this.documentEditUpload = false;

        this.resetForm();
        this.setOther();
    }
}