export enum StatusType {
  deleted = -2,
  active = 1,
  pending = 2,
  queued = 3,
  success = 4,
  failed = 5,
  outOfSync = 6,
  new = 7,
  pendingRelease = 8,
  inactive = 9,
  pendingReview = 10,
  hold = 11,
  deny = 12
}
