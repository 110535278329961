import { Util } from "@app/utils/util";
import jsPDF, { Point, Rectangle } from "jspdf";

export enum WordWrapMethods
{
    Wrap = 0,
    Overflow = 1,
    Truncate = 2
}

export class PDFBuilder extends jsPDF
{
    public static readonly WidthMax = -1;
    public static readonly LineHeight = 1.15;
    public static readonly DrawTo_End = -1;

    public static readonly PageExpression = "{totalPages}";
    public static readonly ImageFormatJPEG = "JPEG";

    public static readonly Font_Cursive = "Parisienne";
    public static readonly FontStyle_Italic = "italic";
    public static readonly FontStyle_Normal = "normal";

    public static readonly FontWeight_Bold = "bold";
    public static readonly FontWeight_Normal = "normal";

    public static readonly Baseline_Top = "top";
    public static readonly Baseline_Bottom = "bottom";
    public static readonly Baseline_Middle = "middle";

    public static readonly Align_Left = "left";
    public static readonly Align_Right = "right";
    public static readonly Align_Center = "center";

    public static readonly Unit_Inch = "in";
    public static readonly Unit_Pixel = "px";
    public static readonly Unit_Point = "pt";
    public static readonly Unit_MiliMeter = "mm";

    public static readonly Output_Blob = "blob";

    public static readonly Orientation_Portrait = "portrait";
    public static readonly Orientation_Landscape = "landscape";

    public static readonly DataBoxDefaults =
        {
            wrap: WordWrapMethods.Truncate,
            align: PDFBuilder.Align_Left,
            border: true,
            boxPadding: true
        };

    public Color_Data = "#000";
    public Color_Label = "#999";
    public Box_Padding = 4;
    public Font_DefaultSize = 8;
    public Font_DefaultFamily = "helvetica";

    public pageMargins = { top: 15, bottom: 2, left: 5, right: 5 };

    constructor(options: any = { unit: PDFBuilder.Unit_Point, orientation: PDFBuilder.Orientation_Portrait })
    {
        super(options);
    }

    public addCursiveFont = function()
    {
        this.addFont("assets/fonts/Parisienne-Regular.ttf", PDFBuilder.Font_Cursive, PDFBuilder.FontStyle_Normal);
    }

    public pageWidth = function()
    {
        let maxX = this.internal.pageSize.getWidth();

        return maxX - (this.pageMargins.left + this.pageMargins.right);
    }

    public pageHeight = function()
    {
        let maxY = this.internal.pageSize.getHeight();

        return maxY - (this.pageMargins.top + this.pageMargins.bottom);
    }

    public setBold = function(bold: boolean = true): void
    {
        if (bold)
        {
            this.fontSet(this.getFontSize(), PDFBuilder.FontWeight_Bold);
        }
        else
        {
            this.fontSet(this.getFontSize(), PDFBuilder.FontWeight_Normal);
        }
    }

    public fontReset = function()
    {
        this.fontSet(this.Font_DefaultSize);
    }

    public fontSet = function(size: number, weight: string = PDFBuilder.FontWeight_Normal, style: string = PDFBuilder.FontStyle_Normal): void
    {
        this.setFont(this.Font_DefaultFamily, style, weight);
        this.setFontSize(size);
    }

    public outText = function(posX: number, posY: number, text: any, options = {}): void
    {
        let derText = text;

        if (Util.isEmpty(derText))
        {
            derText = "";
        }
        else
        {
            if (!Array.isArray(derText))
            {
                derText = derText.toString();

                if (options.hasOwnProperty("width"))
                {
                    let broken = this.splitTextToSize(derText, options["width"]);

                    derText = broken[0];
                }
            }
        }

        options = Object.assign({ baseline: PDFBuilder.Baseline_Top }, options);

        this.text(derText, posX, posY, options);
    }

    public drawBox = function(posX: number, posY: number, width: number, height: number): Rectangle
    {
        let myX = posX + this.pageMargins.left;
        let myY = posY + this.pageMargins.top;

        if (width == PDFBuilder.DrawTo_End)
        {
            width = this.internal.pageSize.getWidth() - (myX + this.pageMargins.right);
        }

        if (height == PDFBuilder.DrawTo_End)
        {
            height = this.pageHeight() - posY;
        }

        this.rect(myX, myY, width, height);

        myX += this.Box_Padding;
        myY += this.Box_Padding;
        width -= (this.Box_Padding * 2);
        height -= (this.Box_Padding * 2);

        return { x: myX, y: myY, w: width, h: height };
    }

    public label = function(posX: number, posY: number, label: string, align: string = PDFBuilder.Align_Left)
    {
        this.fontSet(this.Font_DefaultSize - 2);
        this.setTextColor(this.Color_Label);

        this.outText(posX, posY, label, { align: align });

        this.setTextColor(this.Color_Data);
        this.fontReset();
    }

    public yesNoBox = function(posX: number, posY: number, width: number, height: number, label: string, yesChecked?: boolean)
    {
        let rect = this.dataBox(posX, posY, width, height, label);
        let workX = rect.x + 10;

        this.checkBox(workX, rect.y, "Yes", yesChecked == true);
        this.checkBox(workX + 40, rect.y, "No", yesChecked == false);
    }

    public checkBox = function(posX: number, posY: number, label: string, checked: boolean = null): Rectangle
    {
        let xMe = checked == true ? "X" : "";

        this.rect(posX, posY, 10, 10);
        this.outText(posX + 2, posY + 1, xMe);
        this.outText(posX + 15, posY, label);

        let textDim = this.getTextDimensions(label);

        return { x: posX, y: posY, w: textDim.w + 15, h: Math.max(textDim.h, 10) };
    }

    //  The Point return is the label's position + it's height
    public dataBox = function(posX: number, posY: number, width: number, height: number, label: string, data: any = null, opts: any = null): Point
    {
        opts = Object.assign({}, PDFBuilder.DataBoxDefaults, opts);

        if (width == PDFBuilder.WidthMax)
        {
            width = this.pageWidth();
        }

        if (opts.border)
        {
            this.drawBox(posX, posY, width, height);
        }

        if (label == null)
        {
            label = "";
        }

        let myX = posX + this.pageMargins.left;
        let myY = posY + this.pageMargins.top;
        let labX = myX;
        let textDim = this.getTextDimensions(label);

        if (opts.boxPadding)
        {
            myX += this.Box_Padding;
            myY += this.Box_Padding;
            labX += this.Box_Padding;
        }

        if (width == PDFBuilder.DrawTo_End)
        {
            width = this.pageWidth() - posX;
        }
        else
        {
            if (opts.boxPadding)
            {
                width -= this.Box_Padding * 2;
            }
        }

        switch (opts.align)
        {
            case PDFBuilder.Align_Center:
                if (opts.boxPadding)
                {
                    labX = ((posX + width) - (this.Box_Padding * 2)) / 2;
                }
                else
                {
                    labX = (posX + width) / 2;
                }
                break;

            case PDFBuilder.Align_Right:
                if (opts.boxPadding)
                {
                    labX = ((posX + width) - this.Box_Padding);
                }
                else
                {
                    labX = posX + width;
                }
                break;
        }

        this.label(labX, myY, label, opts.align);

        myY += textDim.h + 2;
        height -= textDim.h + 2;

        let dataLoc = { x: myX, y: myY };

        if (data == null)
        {
            return dataLoc;
        }

        this.fontSet(this.Font_DefaultSize + 2);

        switch (opts.align)
        {
            case PDFBuilder.Align_Center:
                if (opts.boxPadding)
                {
                    myX = ((posX + width) - (this.Box_Padding * 2)) / 2;
                }
                else
                {
                    myX = (posX + width) / 2;
                }
                break;

            case PDFBuilder.Align_Right:
                if (opts.boxPadding)
                {
                    myX = ((posX + width) - this.Box_Padding);
                }
                else
                {
                    myX = posX + width;
                }
                break;
        }

        switch (opts.wrap)
        {
            case WordWrapMethods.Overflow:
                this.outText(myX, myY, data, { align: opts.align });
                break;

            case WordWrapMethods.Truncate:
                data = this.splitTextToSize(data, width);
                this.outText(myX, myY, data[0], { align: opts.align });
                break;

            case WordWrapMethods.Wrap:
                data = this.textArray(data, width, height);
                this.outText(myX, myY, data, { align: opts.align });
                break;
        }

        this.fontReset();

        return dataLoc;
    }

    public textArray = function(data: any, width: number, height: number)
    {
        data = this.splitTextToSize(data, width);

        if (data.length < 2)
        {
            return data;
        }

        let dim = this.getTextDimensions(data[0]);
        let lines = Math.trunc(height / (dim.h * PDFBuilder.LineHeight));

        return data.slice(0, lines);
    }
}