import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiService } from "../api/api.service";
import { MerchantsFreightForwarder } from "./merchants-freight-forwarder.model";

@Injectable({
    providedIn: "root"
})
export class MerchantsFreightForwarderService extends ApiService<MerchantsFreightForwarder>
{
    constructor(httpClient: HttpClient)
    {
        super(httpClient, "freight-forwarder");
    }

    public myList()
    {
        return this.postGetList("merchant-list", {});
    }

    public addList(data)
    {
        return this.httpClient.post(this.buildUrl("merchant-save-list"), JSON.stringify(data));
    }

    public deleteList(data)
    {
        return this.httpClient.post(this.buildUrl("merchant-delete-list"), JSON.stringify(data));
    }
}