import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from "@angular/core";
import { Validators } from "@angular/forms";
import { BaseComponent } from "@app/data";
import { OrderProductStatusType } from "@app/data/enums/order-product-status-type.enum";
import { ShopOrderProduct } from "@app/data/orders/shop-order-product";
import { ShopOrderProductService } from "@app/data/orders/shop-order-product.service";
import { ShopPostMessageService } from "@app/data/orders/shop-post-message.service";
import { CommonSettings } from '@app/shared/common-settings';
import { ConfirmationService, IConfirmationOptions } from "@app/shared/confirmation/confirmation.service";
import { OrderDataHelper } from '@app/shared/orders/order-data.helper';
import { Util } from "@app/utils/util";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: "order-manual-entry-product",
    styleUrls: ["./order-product.component.scss"],
    templateUrl: "./order-product.component.html"
})
export class OrderProductComponent extends BaseComponent<ShopOrderProduct>
{
    @ViewChild("dialogOrderProduct")
    dialogOrderProduct: TemplateRef<any>;

    @Input()
    merchantUID: string;

    @Output()
    productSaved = new EventEmitter();

    public get totalAmount()
    {
        let amt = this.getValue("amount") ?? 0;
        let qty = this.getValue("quantity") ?? 0;

        return "$" + (amt * qty).toFixed(2);
    }

    public minQty;
    public errorMessage = null;
    public existingOrderUID = null;
    public availableProducts;

    private bypassDirty = false;

    constructor(toastrService: ToastrService,
        public modalService: NgbModal,
        private orderProdServ: ShopOrderProductService,
        private confirmService: ConfirmationService,
        private shopPostMessageServ: ShopPostMessageService)
    {
        super(toastrService);

        this.dataForm = this.orderProdServ.dataForm;
        this.apiService = this.orderProdServ;
    }

    public openDialog(data = null)
    {
        this.bypassDirty = false;
        this.errorMessage = null;

        this.open(this.dialogOrderProduct, data, CommonSettings.DialogSize_XLarge);

        if (data && data.quantityShipped > 0)
        {
            this.minQty = data.quantityShipped + 1;
        }
        else
        {
            this.minQty = 1;
        }

        if (data)
        {
            this.setValue("partNumber", data.product.partNumber);
        }

        this.dataForm.get("quantity").setValidators([Validators.required, Validators.min(this.minQty)]);
        this.dataForm.get("quantity").updateValueAndValidity();
    }

    public onProductChange()
    {
        this.errorMessage = null;
    }

    public onClose()
    {
        if (!this.dataForm.dirty || this.bypassDirty)
        {
            this.close();
            return;
        }

        let opts: IConfirmationOptions =
        {
            FunctionConfirm: () => this.close()
        };

        this.confirmService.YesNo(CommonSettings.DirtyFormConfirmationMessage, opts);
    }

    public onProductSearch(term: string, item)
    {
        let desc = item.customerDescription?.toLowerCase();
        let part = item.partNumber?.toLowerCase();

        term = term.toLowerCase();

        return (!Util.isEmpty(desc) && desc.indexOf(term) != -1) || (!Util.isEmpty(part) && part.indexOf(term) != -1);
    }

    public onSave()
    {
        if (this.invalid())
        {
            return;
        }

        if (this.existingOrderUID == null)
        {
            this.saveToList();
        }
        else
        {
            this.saveToExistsOrder();
        }
    }

    private saveToList()
    {
        let desc = this.getValue("altDescription");
        let part = this.getValue("partNumber");
        let prodData = this.dataForm.value;

        if (Util.isEmpty(desc))
        {
            let prod = this.availableProducts.find(elm => elm.partNumber == part);

            prodData.description = prod.customerDescription ?? "";
        }
        else
        {
            prodData.description = desc;
        }

        prodData.description = `${part} - ${prodData.description}`;

        this.productSaved.emit(prodData);
        this.close();
    }

    private saveToExistsOrder()
    {
        let desc = this.getValue("altDescription");
        let prodData = this.dataForm.value;

        let saveProd: ShopOrderProduct =
        {
            amount: prodData.amount,
            quantity: prodData.quantity,
            lineNumber: this.data?.lineNumber ?? 0,
            partNumber: prodData.partNumber,
            transactionUID: this.existingOrderUID,
            isOrderModification: true
        };

        if (Util.isEmpty(desc))
        {
            saveProd.customerDescription = desc;
        }

        this.orderProdServ.postList("append-modify-product", [saveProd]).subscribe(
            retRes => this.checkSaveStatus(retRes),
            errRes => this.error(errRes));
    }

    private checkSaveStatus(retRes)
    {
        retRes = retRes[0];

        if (retRes.statusType != OrderProductStatusType.active)
        {
            this.bypassDirty = true;
            this.errorMessage = retRes.message;
            return;
        }

        let sendIt = { uid: this.existingOrderUID, stage: OrderDataHelper.OrderState_Validate };

        this.shopPostMessageServ.post("check", sendIt).subscribe(
            retRes =>
            {
                this.close();
                this.productSaved.emit();
            },
            errRes => this.error(errRes));
    }
}