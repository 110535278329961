import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiService } from "../api/api.service";
import { TransactionProductLicense } from "./transaction-product-license";

@Injectable({
  providedIn: "root"
})
export class TransactionProductLicenseService extends ApiService<TransactionProductLicense>
{
  constructor(httpClient: HttpClient)
  {
    super(httpClient, "transaction-product-license-list");
  }
}