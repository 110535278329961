import { Component, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AppService } from "@app/app.service";
import { Transaction } from "@app/data";
import { NotificationRequestStatus } from "@app/data/enums/notification-request-status-type.enum";
import { OrderNotificationRequestService } from "@app/data/orders/order-notification-request.service";
import { GridBase } from "@app/shared/ag-grid-helpers/ag-grid-base";
import { GridHelpers } from "@app/shared/ag-grid-helpers/grid-helper";
import { GridColumnCustom } from "@app/shared/ag-grid-helpers/models/grid-column-custom.model";
import { GridColumnIcon } from "@app/shared/ag-grid-helpers/models/grid-column-icon.model";
import { GridColumnMerchant } from "@app/shared/ag-grid-helpers/models/grid-column-merchant";
import { GridColumnPurchaser } from "@app/shared/ag-grid-helpers/models/grid-column-purchaser";
import { GridColumnStatus } from "@app/shared/ag-grid-helpers/models/grid-column-status";
import { GridColumn, GridColumnType } from "@app/shared/ag-grid-helpers/models/grid-column.model";
import { ConfirmationService, IConfirmationOptions } from "@app/shared/confirmation/confirmation.service";
import { OrderDataHelper } from '@app/shared/orders/order-data.helper';
import { Util } from "@app/utils/util";
import { ToastrService } from "ngx-toastr";

@Component({
    styleUrls: ["./order-notification-requests.component.scss"],
    templateUrl: "./order-notification-requests.component.html",
    encapsulation: ViewEncapsulation.None
})
export class OrderNoticiationRequestsComponent extends GridBase<Transaction>
{
    constructor(toastrService: ToastrService,
        private router: Router,
        private appService: AppService,
        private activeRoute: ActivatedRoute,
        private confirmService: ConfirmationService,
        private notificationRequestServ: OrderNotificationRequestService)
    {
        super(toastrService, notificationRequestServ);

        OrderDataHelper.userRole = this.activeRoute.snapshot.data.userType;

        this.title = "Notification Requests";
        this.appService.pageTitle = this.title;

        this.setupGrid();
    }

    private setupGrid()
    {
        let gridIcons =
            [
                GridHelpers.MakeDeleteIcon(this.onDelete.bind(this), this.canDelete.bind(this))
            ];

        this.addColumn(new GridColumnStatus(OrderDataHelper.NotificationRequestStatuses));

        if (OrderDataHelper.userRole == this.profileTypes.admin)
        {
            this.addColumn(new GridColumnMerchant("customer.displayName", "customer.uid"));
        }

        this.storageKey = "NotificationRequest";
        this.addColumn(new GridColumnPurchaser("purchaser.displayName", "purchaser.uid"));
        this.addColumn(new GridColumn("purchaser.emailAddress", "Email", GridColumnType.Email));
        this.addColumn(new GridColumn("purchaser.language.name", "Language"));
        this.addColumn(new GridColumn("country.name", "Country"));
        this.addColumn(new GridColumnCustom("purchaser.cellPhone1", "Phone", { width: 100 }));
        this.addColumn(new GridColumn("createdDate", "Created", GridColumnType.Date));
        this.addColumn(new GridColumn("requester", "Requester"));
        this.addColumn(new GridColumnIcon(gridIcons));
    }

    private canDelete(agData)
    {
        return (agData.statusType != NotificationRequestStatus.Complete);
    }

    private onDelete(agData)
    {
        this.dataForm = this.makeForm(agData);

        let opts: IConfirmationOptions =
        {
            FunctionConfirm: () => this.deleteIt()
        };

        this.confirmService.YesNo(`You sure you want to delete "${agData.purchaser.emailAddress}"?`, opts);
    }

    private deleteIt()
    {
        this.delete().add(() => this.gridRefresh());
    }

    private canViewPurchaser(agData)
    {
        return !Util.isEmpty(agData.purchaser.uid);
    }

    private onPurchaserView(agData)
    {
        let nav;

        switch (OrderDataHelper.userRole)
        {
            case this.profileTypes.admin:
                nav = "admin";
                break;

            //  TODO:  Customers don't have access, should they?
            // case this.profileTypes.customer:
            //     nav = "customer";
            //     break;
        }

        this.router.navigate([`/${nav}/purchasers`, agData.purchaser.uid]);
    }
}