import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ApiService } from "../api/api.service";
import { CustomerAccount } from "./customer-account";

@Injectable({
    providedIn: "root"
})
export class CustomerAccountService extends ApiService<CustomerAccount>
{
    constructor(httpClient: HttpClient)
    {
        super(httpClient, "customer-account");
    }

    get editorForm()
    {
        let form = new FormBuilder();
        let grpFields = form.group(
            {
                uid: [],
                zip: [],
                city: [],
                title: [],
                street1: [],
                street2: [],
                lastName: [, Validators.required],
                stateUID: [],
                cellPhone: [],
                firstName: [, Validators.required],
                workPhone: [],
                middleName: [],
                statusType: [],
                customerUID: [],
                emailAddress: [],
                accountNumber: [],
                accountTypeUID: [, Validators.required],
            });

        return grpFields;
    }
}