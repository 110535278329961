import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiService } from "@app/data/api/api.service";
import { ProductURLCheck } from "./product-url-check.model";

@Injectable({
    providedIn: "root"
})
export class ProductURLCheckService extends ApiService<ProductURLCheck>
{
    constructor(httpClient: HttpClient)
    {
        super(httpClient, "product-url-check");
    }
}