import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild, ViewEncapsulation } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { BaseComponent } from "@app/data";
import { WikiCommon } from "@app/data/wiki/wiki-common";
import { WikiMod } from "@app/data/wiki/wiki.model";
import { WikiService } from "@app/data/wiki/wiki.service";
import { WikiTextComponent } from "@app/shared/misc-components/wiki-renderer/wiki-renderer.component";
import { Util } from "@app/utils/util";
import { NgSelectComponent } from '@ng-select/ng-select';
import { ToastrService } from "ngx-toastr";
import tippy, { hideAll } from "tippy.js";

@Component(
    {
        selector: "wiki-editor",
        styleUrls: ["wiki-editor.component.scss", "../../styles/ee-wiki.scss"],
        templateUrl: "./wiki-editor.component.html",
        encapsulation: ViewEncapsulation.None
    })
export class WikiEditorComponent extends BaseComponent<WikiMod> implements OnInit, AfterViewInit
{
    @ViewChild("selctType")
    selctType: NgSelectComponent;

    @ViewChild("inWiki")
    inWiki: ElementRef;

    @ViewChild("wikiElm")
    wikiElm: WikiTextComponent;

    @ViewChild("iconContainer")
    iconContainer: ElementRef;

    @ViewChild("iconMenuTrigger")
    iconMenuTrigger: ElementRef;

    @Output()
    wikiSaveEvent:EventEmitter<string> = new EventEmitter();

    @Output()
    wikiLoadedEvent: EventEmitter<WikiMod> = new EventEmitter();

    @Output()
    wikiValidateEvent:EventEmitter<void> = new EventEmitter();

    @Output()
    editorReady = new EventEmitter();

    public get isDirty(): boolean
    {
        return this.dataForm.dirty;
    }

    public get wikiText(): string
    {
        return this.getValue("wikiText");
    }

    public readonly tag_break = "<br />";
    public readonly tag_email = "EML";
    public readonly tag_phone = "PHN";
    public readonly tag_linkIn = "LKI";
    public readonly tag_linkEx = "LKE";
    public readonly tag_linkAnchor = "LKA";

    public iconList;
    public wikiErrors;
    public currentWiki: WikiMod;

    private menuOptions =
        {
            arrow: false,
            trigger: "manual",
            appendTo: document.body,
            placement: "top",
            hideOnClick: true,
            interactive: true,
            onShow: (instance) =>
            {
                hideAll({ exclude: instance });
            },
            onClickOutside: (instance, event) =>
            {
                this.isOpen = false;
                instance.hide();
            }
        };

    private isOpen: boolean = false;
    private menuInstance;

    constructor(toastrService: ToastrService,
        private wikiServ: WikiService)
    {
        super(toastrService);

        let form = new FormBuilder();
        let grpFields = form.group({ wikiText: [Validators.required] });

        this.dataForm = grpFields;
        this.apiService = wikiServ;
    }

    ngOnInit(): void
    {
        this.loadIcons();

        setTimeout(() => this.editorReady.emit(), 100);
    }

    public ngAfterViewInit(): void
    {
        this.menuInstance = tippy(this.iconMenuTrigger.nativeElement);
        this.menuInstance.setProps(this.menuOptions);
        this.menuInstance.setContent(this.iconContainer.nativeElement);
    }

    public clearWiki(): void
    {
        this.currentWiki = null;
        this.setValue("wikiText", null);

        this.onPreview();
    }

    public loadWiki(filter: WikiMod): void
    {
        this.blockOn("Loading...");

        this.resetForm();

        let subs = this.wikiServ.post("detail", filter).subscribe(
            retRes => this.wikiLoaded(retRes),
            errRes => this.error(errRes));

        subs.add(() => this.blockOff());
    }

    public setWiki(wikiText: string): void
    {
        this.setValue("wikiText", wikiText);

        this.onPreview();
    }

    public uploadImage(evtArg): void
    {
        this.blockOn("Uploading...");

        this.wikiServ.imageUpload(evtArg.target.files[0]).subscribe(
            retRes => this.imageDone(retRes),
            errRes => this.error(errRes),
            () => this.blockOff());
    }

    public onSave(): void
    {
        this.wikiValidateEvent.emit();
    }

    public setValid(): void
    {
        let data = this.getValue("wikiText");

        this.currentWiki.wikiText = data;

        this.markClean();
        this.wikiSaveEvent.emit(data);
    }

    public markClean(): void
    {
        this.dataForm.markAsPristine();
        this.dataForm.markAsUntouched();
    }

    public onPreview(): void
    {
        let wiki = this.getValue("wikiText");

        this.wikiErrors = this.wikiElm.parseWiki(wiki);
    }

    public onIconToggle(): void
    {
        if (this.isOpen)
        {
            this.menuInstance.hide();
        }
        else
        {
            this.menuInstance.show();
        }

        this.isOpen = !this.isOpen;
    }

    public onIconInsert(derIcon): void
    {
        this.onIconToggle();

        this.insertText(`[[${derIcon.name}]]`);
    }

    public onInsertWiki(tag): void
    {
        switch (tag)
        {
            case this.tag_email:
                tag = this.makeEmail();
                break;

            case this.tag_phone:
                tag = this.makePhone();
                break;

            case this.tag_linkIn:
                tag = this.makeLinkInternal();
                break;

            case this.tag_linkEx:
                tag = this.makeLinkExternal();
                break;

            case this.tag_linkAnchor:
                tag = this.makeLinkAnchor();
                break;
        }

        if (tag == null)
        {
            return;
        }

        this.insertText(tag);
    }

    public onInsertFix(idx): void
    {
        this.onInsertWiki(this.wikiErrors[idx].fix);

        this.wikiErrors.splice(idx, 1);

        Util.reindexArray(this.wikiErrors);
    }

    private loadIcons(): void
    {
        let keys = Object.keys(this.Icons);
        let vals = Object.values(this.Icons);

        this.iconList = [];

        for (let lp = 0; lp < keys.length; lp++)
        {
            if (keys[lp].startsWith("Size_"))
            {
                continue;
            }

            this.iconList.push({ name: keys[lp], icon: vals[lp] });
        }

        this.iconList.sort((uno, two) => (uno.name > two.name) ? 1 : -1);
    }

    private wikiLoaded(data: WikiMod): void
    {
        this.currentWiki = data;

        this.setWiki(data.wikiText);

        this.wikiLoadedEvent.emit(this.currentWiki);
    }

    private insertText(text): void
    {
        let inElm = this.inWiki.nativeElement;

        inElm.setRangeText(text, inElm.selectionStart, inElm.selectionEnd);
        inElm.dispatchEvent(new Event("input"));
    }

    private imageDone(retRes): void
    {
        this.onInsertWiki(WikiCommon.makeTagImage(retRes));
    }

    private makeEmail(): string
    {
        let mail = prompt("E-mail address");

        if (mail == null)
        {
            return null;
        }

        return `<mailto:${mail}>`;
    }

    private makePhone(): string
    {
        let phone = prompt("Phone number");

        if (phone == null)
        {
            return null;
        }

        return WikiCommon.makePhoneLink(phone);
    }

    private makeLinkInternal(): string
    {
        let link = prompt("Internal Link");

        if (link == null)
        {
            return null;
        }

        return WikiCommon.makeInternalLink("Link text", link);
    }

    private makeLinkAnchor(): string
    {
        let link = prompt("Internal Link Anchor");

        if (link == null)
        {
            return null;
        }

        return WikiCommon.makeInternalAnchor(link);
    }

    private makeLinkExternal(): string
    {
        let link = prompt("External Link");

        if (link == null)
        {
            return null;
        }

        return WikiCommon.makeExternalLink("Link text", link);
    }
}