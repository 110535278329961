import { OnDestroy } from "@angular/core";
import { Observable, Subject } from "rxjs";
import * as XLSX from "xlsx";

export class ExcelHelper implements OnDestroy
{
    public static readonly ExcelExtension = ".xlsx";

    private fileOb: Observable<any>;
    private fileSub: Subject<any>;

    constructor()
    {
        this.fileSub = new Subject();
    }

    public static makeTemplate(fileName: string, cols: string[]): void
    {
        let book = XLSX.utils.book_new();
        let sheet = XLSX.utils.aoa_to_sheet([cols]);

        XLSX.utils.book_append_sheet(book, sheet, fileName);
        XLSX.writeFile(book, fileName + ExcelHelper.ExcelExtension);
    }

    public readFile(fileSelectEvt) : Observable<any>
    {
        if (fileSelectEvt.target.files.length == 0)
        {
            let obby = new Observable(obby => { obby.next(null); obby.complete(); });

            return obby;
        }

        this.fileOb = this.fileSub.asObservable();

        let file = fileSelectEvt.target.files[0];
        let reader: FileReader = new FileReader();

        reader.onload = (retRes) => this.makeData(retRes);
        reader.readAsBinaryString(file);

        return this.fileOb;
    }

    ngOnDestroy(): void
    {
        this.fileSub.unsubscribe();
    }

    private makeData(evtArg)
    {
        let bin = evtArg.target.result;
        let book = XLSX.read(bin, { type: "binary" });
        let name = book.SheetNames[0];
        let sheet = book.Sheets[name];

        let data = XLSX.utils.sheet_to_json(sheet);

        this.fileSub.next(data);
    }
}