import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { Observable } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { ApiService } from "../api/api.service";
import { FixedImage } from "../misc/fixed-image";
import { PurchaserAddressImage } from "./purchaser-address-image.model";

@Injectable({
    providedIn: "root"
})
export class PurchaserAddressImageService extends ApiService<PurchaserAddressImage>
{
    public get editorForm(): FormGroup
    {
        let form = new FormBuilder();
        let grpFields = form.group(
            {
                uid: [],
                name: [, Validators.required],
                fileName: [],
                imageType: [, Validators.required],
                fileNameUID: [],
                purchaserAddressUID: [Validators.required]
            });

        return grpFields;
    }

    public serviceImage: SafeUrl;

    constructor(httpClient: HttpClient, private sanitizer: DomSanitizer)
    {
        super(httpClient, "purchaser-address-image");
    }

    public getImage(purchaserAddyUID: string): Observable<FixedImage>
    {
        let obby = this.getFile("get-image", purchaserAddyUID).pipe(map(retRes =>
        {
            let fixImg = new FixedImage();

            fixImg.url = URL.createObjectURL(retRes);
            fixImg.safeURL = this.sanitizer.bypassSecurityTrustUrl(fixImg.url);

            return fixImg;
        }));


        return obby;
    }

    public upload(imgFile: Blob, addyData: PurchaserAddressImage): Observable<PurchaserAddressImage>
    {
        let name = (addyData.name || "ThisDoesntMatter") + ".png";
        let uploadData = new FormData();

        uploadData.append(name, imgFile);

        let obby = this.postFile("upload", uploadData).pipe(
            map(retRes =>
            {
                addyData.fileName = retRes.fileNameUID + ".png";
                addyData.fileNameUID = retRes.fileNameUID;

                return retRes;
            }),
            mergeMap(d2 => this.save(addyData)));

        return obby;
    }
}