import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { ProductService, ProfileType, Transaction, TransactionProduct, TransactionService } from "@app/data";
import { OrderProductStatusType } from "@app/data/enums/order-product-status-type.enum";
import { OrderStatusType } from "@app/data/enums/order-status-type.enum";
import { OrderCommon } from "@app/data/orders/order-common";
import { ShipmentService } from "@app/data/shipment/shipment.service";
import { TransactionProductService } from "@app/data/transaction-product/transaction-product.service";
import { GridBase } from "@app/shared/ag-grid-helpers/ag-grid-base";
import { GridCellImage } from "@app/shared/ag-grid-helpers/models/grid-cell-image.model";
import { GridColumnBoolean } from "@app/shared/ag-grid-helpers/models/grid-column-boolean";
import { GridColumnCustom } from "@app/shared/ag-grid-helpers/models/grid-column-custom.model";
import { GridColumnIcon } from "@app/shared/ag-grid-helpers/models/grid-column-icon.model";
import { GridColumnImage } from "@app/shared/ag-grid-helpers/models/grid-column-image.model";
import { GridColumnPartNumber } from "@app/shared/ag-grid-helpers/models/grid-column-part-number";
import { GridColumnStatus } from "@app/shared/ag-grid-helpers/models/grid-column-status";
import { GridColumn, GridColumnType } from "@app/shared/ag-grid-helpers/models/grid-column.model";
import { ConfirmationService, IConfirmationOptions } from "@app/shared/confirmation/confirmation.service";
import { OrderDataHelper } from "@app/shared/orders/order-data.helper";
import { GridColumnOrderHolds } from "@app/shared/orders/order-grid-components/hold-renderer/grid-cell-holds.model";
import { OrderProductBulkComponent } from "@app/shared/orders/order-manual-entry/order-product-bulk/order-product-bulk.component";
import { OrderProductComponent } from "@app/shared/orders/order-manual-entry/order-product/order-product.component";
import { Util } from "@app/utils/util";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { ShipmentDetailComponent } from "../shipment-detail/shipment-detail.component";

@Component({
    selector: "order-product-list",
    styleUrls: ["./product-list.component.scss"],
    templateUrl: "./product-list.component.html",
    encapsulation: ViewEncapsulation.None
})
export class OrderProductListComponent extends GridBase<TransactionProduct> implements OnInit
{
    @ViewChild("dialogBulk")
    dialogBulk: OrderProductBulkComponent;

    @ViewChild("dialogShipment")
    dialogShipment: ShipmentDetailComponent;

    @ViewChild("dialogProductEntry")
    dialogProductEntry: OrderProductComponent;

    public get canShip()
    {
        return OrderDataHelper.CanShip(this.orderDetails);
    }

    public get canProduct()
    {
        return (!this.orderDetails.isThereAShipment && this.orderDetails.statusType != OrderStatusType.Complete);
    }

    public userRole: number;
    public orderDetails: Transaction;

    constructor(
        toastrService: ToastrService,
        public modalService: NgbModal,
        private prodServ: ProductService,
        public prodDetailsServ: TransactionProductService,
        private shipService: ShipmentService,
        private confirmService: ConfirmationService,
        private transactionServ: TransactionService)
    {
        super(toastrService, prodDetailsServ);

        this.modalService = modalService;
    }

    ngOnInit()
    {
        this.userRole = OrderDataHelper.userRole;
        this.orderDetails = this.data;

        this.setupGrid();
    }

    public onMyGridReady(params)
    {
        this.onGridReady(params, false);

        this.filterAdd("transactionUID", this.orderDetails.uid);

        this.gridLoad();
    }

    public onShipmentAdd()
    {
        this.dialogShipment.openAdd();
    }

    public onProductAdd()
    {
        if (Util.isEmpty(this.dialogProductEntry.availableProducts))
        {
            this.getProducts();
        }

        this.dialogProductEntry.merchantUID = this.orderDetails.customer.uid;
        this.dialogProductEntry.existingOrderUID = this.orderDetails.uid;
        this.dialogProductEntry.openDialog();
    }

    public onBulkTemplate()
    {
        OrderDataHelper.getBulkTemplate();
    }

    public onBulkOpen()
    {
        if (Util.isEmpty(this.dialogProductEntry.availableProducts))
        {
            this.getProducts();
        }

        this.dialogBulk.existingOrderUID = this.orderDetails.uid;
        this.dialogBulk.openDialog(this.orderDetails.customerUID);
    }

    public onDataRefresh()
    {
        this.close();

        this.transactionServ.detail({ uid: this.orderDetails.uid }).subscribe(
            retRes =>
            {
                this.orderDetails = retRes;

                this.gridRefresh();

                if (this.update)
                {
                    this.update.emit(retRes);
                }
            },
            errRes => this.error(errRes));
    }

    private getProducts(): void
    {
        let filt = null;

        if (OrderDataHelper.userRole == this.profileTypes.admin)
        {
            filt = { customerUID: this.orderDetails.customer.uid };
        }

        this.prodServ.getLookup(filt).add(() =>
        {
            this.dialogBulk.availableProducts = this.prodServ.lookupList;
            this.dialogProductEntry.availableProducts = this.prodServ.lookupList;
        });
    }

    private canClearHold(data)
    {
        let canDo =
            data.isImportPermitHold &&
            data.statusType != OrderDataHelper.Status_Cleared.id &&
            data.statusType != OrderDataHelper.Status_Complete.id &&
            data.statusType != OrderDataHelper.Status_Cancelled.id &&
            data.statusType != OrderDataHelper.Status_Suspended.id &&
            OrderDataHelper.userRole == ProfileType.admin;

        return canDo;
    }

    private canCancel(data)
    {
        let canDo =
            data.statusType != OrderStatusType.Complete &&
            data.statusType != OrderStatusType.Cancelled &&
            data.statusType != OrderStatusType.Suspended &&
            this.orderDetails.statusType != OrderStatusType.Complete;

        return canDo;
    }

    private onClearHold(data)
    {
        let opts: IConfirmationOptions =
        {
            FunctionConfirm: () => this.doClearHold(data)
        };

        this.confirmService.YesNo(`Are you sure you want to clear the hold on the Import Permit for "${data.customerDescription}"?`, opts);
    }

    private onCancelConfirm(data)
    {
        let mess;

        if (data.isOnActiveShipment && data.shipmentUID)
        {
            mess = "This product is part of an active Shipment.  Are you sure you want to cancel?";
        }
        else
        {
            mess = `Are you sure you want to cancel "${data.customerDescription}"?`;
        }

        let opts: IConfirmationOptions =
        {
            FunctionConfirm: () => this.doCancelItem(data)
        };

        this.confirmService.YesNo(mess, opts);
    }

    private setupGrid()
    {
        let images =
            [
                new GridCellImage("product.imageLink1")
            ];

        this.storageKey = "OrdersProductList";
        this.addColumn(new GridColumnStatus(OrderCommon.ProductStatuses));
        this.addColumn(new GridColumnPartNumber("product"));
        this.addColumn(new GridColumnCustom("customerDescription", "Description", { width: 150, minWidth: 100 }));
        this.addColumn(new GridColumnImage(images, "Images", 100));
        this.addColumn(new GridColumn("amount", "Price", GridColumnType.Money));
        this.addColumn(new GridColumn("extendedAmount", "Total", GridColumnType.Money));

        if (OrderDataHelper.userRole == ProfileType.admin)
        {
            this.addColumn(new GridColumn("classification", "Classification"));
            this.addColumn(new GridColumnCustom("product.exportScheduleB", "Schedule B #", { width: 125, suppressSizeToFit: true }));
        }

        this.addColumn(new GridColumn("licenseNumber", "License #"));
        this.addColumn(new GridColumnCustom("importPermit1", "Permit", { cellRenderer: this.renderPermit.bind(this) }));
        this.addColumn(new GridColumnCustom("quantityShipped", "Shipped", { cellRenderer: this.renderShippedQuantity, width: 75, suppressSizeToFit: true }));
        this.addColumn(new GridColumnBoolean("isChanged", "Changed?"));
        this.addColumn(new GridColumnBoolean("isOnOriginalOrder", "On Original Order?"));
        this.addColumn(new GridColumn("originalPartNumber", "Original Part #"));
        this.addColumn(new GridColumn("originalQuantity", "Original Qty", GridColumnType.Number));
        this.addColumn(new GridColumn("originalAmount", "Original Amt", GridColumnType.Money));
        this.addColumn(new GridColumnOrderHolds());

        if (this.userRole == ProfileType.freightForwarder)
        {
            return;
        }

        let gridIcons =
            [
                { icon: this.Icons.Edit, toolTip: "Edit Order item", onClick: this.onEdit.bind(this), isVisible: this.canEdit.bind(this) },
                { icon: this.Icons.Cancel, toolTip: "Cancel item", onClick: this.onCancelConfirm.bind(this), isVisible: this.canCancel.bind(this) },
                { icon: this.Icons.Hold, toolTip: "Clear Import Permit Hold", onClick: this.onClearHold.bind(this), isVisible: this.canClearHold }
            ];

        this.addColumn(new GridColumnIcon(gridIcons));

        this.rowDoubleClick(this.onEdit.bind(this));
    }

    private canEdit(agData)
    {
        return (this.canProduct && agData.statusType != OrderProductStatusType.complete);
    }

    private onEdit(agData)
    {
        if (!this.canEdit(agData))
        {
            return;
        }

        this.dialogProductEntry.existingOrderUID = this.orderDetails.uid;
        this.dialogProductEntry.openDialog(agData);
    }

    private doClearHold(data)
    {
        let sendMe =
        {
            uid: data.uid,
            statusType: OrderDataHelper.Status_Cleared.id,
            isImportPermitHold: false
        };

        this.prodDetailsServ.save(sendMe).subscribe(
            retRes => this.onDataRefresh(),
            errRes => this.error(errRes));
    }

    private doCancelItem(data)
    {
        this.prodDetailsServ.saveStatus(data.uid, OrderDataHelper.Status_Cancelled.id).subscribe(
            retRes => this.onDataRefresh(),
            errRes => this.error(errRes));
    }

    private renderShippedQuantity(params: any)
    {
        let data = params.data;
        let shipped = data?.quantityShipped ?? 0;

        return `${shipped} of ${data.quantity}`;
    }

    private renderPermit(params: any)
    {
        let permits = this.prodDetailsServ.makePermitArray(params.data);

        if (Util.isEmpty(permits))
        {
            return Util.DOM_Space;
        }

        let outHTML = "";

        for (let lp = 0; lp < permits.length; lp++)
        {
            outHTML += `<div class="permit-item" title="${permits[lp]}">${permits[lp]}</div>`;
        }

        return outHTML;
    }
}