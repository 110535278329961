import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { FreightForwarderRequestDTO } from "@app/data/freight/freight-forwarder-request.dto";
import { Observable, Subject } from "rxjs";
import { ApiService } from "../api/api.service";
import { Freight } from "./freight";

@Injectable({
    providedIn: "root"
})
export class FreightService extends ApiService<Freight>
{
    public readonly Tab_Tasks = 1;
    public readonly Tab_Detail = 2;
    public readonly Tab_Address = 3;
    public readonly Tab_Members = 4;
    public readonly Tab_Contacts = 5;

    public freightAnswer: Observable<any>;
    public freightQuestion: Observable<any>;

    private messageAnswer = new Subject();
    private messageQuestion = new Subject();

    public get editorForm()
    {
        let form = new FormBuilder();
        let grpFields = form.group(
            {
                uid: [],
                countryUID: [, Validators.required],
                statusType: [],
                companyName: [, Validators.required],
                description: [],
                websiteLink: []
            });

        return grpFields;
    }

    constructor(httpClient: HttpClient)
    {
        super(httpClient, "freight-forwarder");

        this.freightAnswer = this.messageAnswer.asObservable();
        this.freightQuestion = this.messageQuestion.asObservable();
    }

    public sendAnswer(request: FreightForwarderRequestDTO)
    {
        this.messageAnswer.next(request);
    }

    public sendQuestion(request: FreightForwarderRequestDTO)
    {
        this.messageQuestion.next(request);
    }
}