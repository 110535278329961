// TODO:  This is currently set up for end of the month, not sure what else it could be used for...  more work to come?

import { Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { DateFormatter } from "@app/shared/pipes/date-formatter.pipe";
import { DateHelper } from "@app/utils/date-helper";
import { Util } from '@app/utils/util';
import { NgSelectComponent } from "@ng-select/ng-select";

@Component({
    selector: "year-month-selection",
    styleUrls: ["./year-month-selection.component.scss"],
    templateUrl: "./year-month-selection.component.html",
    providers:
        [
            {
                multi: true,
                provide: NG_VALUE_ACCESSOR,
                useExisting: forwardRef(() => YearMonthSelectionComponent)
            }
        ]
})
export class YearMonthSelectionComponent implements ControlValueAccessor, OnInit
{
    @ViewChild("selectYearMonth")
    selectYearMonth: NgSelectComponent;

    @Input()
    clearable: boolean = false;

    @Input()
    autoSetFirst: boolean = true;

    @Input()
    valueFormat: string = DateFormatter.FormatAmericanDate;

    @Input()
    placeHolder: string = "Select";

    @Input()
    displayFormat: string = "YYYY-MMMM";

    @Input()
    countBackMonths: number = 13;

    @Input("value")
    cycleValue: string;

    @Output()
    yearMonthChanged = new EventEmitter();

    get value()
    {
        return this.cycleValue;
    }

    set value(val)
    {
        this.cycleValue = val;

        this.onChange(val);
        this.onTouched();
    }

    public cycleMonths = [];

    onChange: any = () => { };
    onTouched: any = () => { };

    ngOnInit(): void
    {
        this.popMonths();
    }

    registerOnChange(execFun)
    {
        this.onChange = execFun;
    }

    registerOnTouched(execFun)
    {
        this.onTouched = execFun;
    }

    writeValue(inValue)
    {
        this.value = inValue;
    }

    public onValueChange(evtArg)
    {
        this.yearMonthChanged.emit(evtArg);
    }

    public popMonths(dateList = null)
    {
        let useDates = [];

        if (Util.isEmpty(dateList))
        {
            let now = new Date();

            for (let lp = 0; lp < this.countBackMonths; lp++)
            {
                let wDate = new Date(now.getFullYear(), -(lp), 1);

                useDates.push(wDate);
            }
        }
        else
        {
            for (let dude of dateList)
            {
                let hold = DateHelper.makeDate(dude);

                if (hold != null)
                {
                    useDates.push(hold);
                }
            }
        }

        this.cycleMonths = [];

        for (let dude of useDates)
        {
            let dateVal = DateHelper.endOfMonthDate(dude);
            let amerEOM = DateHelper.formatDate(dateVal, this.valueFormat);

            this.cycleMonths.push(
                {
                    date: amerEOM,
                    label: DateHelper.formatDate(dateVal, this.displayFormat)
                });
        }

        if (this.autoSetFirst)
        {
            setTimeout(this.setFirst.bind(this), 500);
        }
    }

    private setFirst()
    {
        this.selectYearMonth.select(this.selectYearMonth.itemsList.items[0]);
    }
}