import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AppService } from "@app/app.service";
import { ProfileType, Transaction, TransactionService } from "@app/data";
import { GridBase } from "@app/shared/ag-grid-helpers/ag-grid-base";
import { GridHelpers } from "@app/shared/ag-grid-helpers/grid-helper";
import { GridCellBoolean } from "@app/shared/ag-grid-helpers/models/grid-cell-boolean.model";
import { GridColumnBoolean } from "@app/shared/ag-grid-helpers/models/grid-column-boolean";
import { GridColumnCustom } from "@app/shared/ag-grid-helpers/models/grid-column-custom.model";
import { GridColumnIcon } from "@app/shared/ag-grid-helpers/models/grid-column-icon.model";
import { GridColumnMerchant } from "@app/shared/ag-grid-helpers/models/grid-column-merchant";
import { GridColumnPurchaser } from "@app/shared/ag-grid-helpers/models/grid-column-purchaser";
import { GridColumnPurchaserType } from "@app/shared/ag-grid-helpers/models/grid-column-purchaser-type";
import { GridColumnStatus } from "@app/shared/ag-grid-helpers/models/grid-column-status";
import { GridColumn, GridColumnType } from "@app/shared/ag-grid-helpers/models/grid-column.model";
import { ConfirmationService, IConfirmationOptions } from "@app/shared/confirmation/confirmation.service";
import { OrderDataHelper } from "@app/shared/orders/order-data.helper";
import { GridColumnOrderHolds } from "@app/shared/orders/order-grid-components/hold-renderer/grid-cell-holds.model";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: "order-list",
    templateUrl: "./order-list.component.html"
})
export class OrderListComponent extends GridBase<Transaction>
{
    public get canAdd()
    {
        return (OrderDataHelper.userRole == this.profileTypes.admin || OrderDataHelper.userRole == this.profileTypes.customer);
    }

    constructor(toastrService: ToastrService,
        private router: Router,
        private appService: AppService,
        private activeRoute: ActivatedRoute,
        private confirmService: ConfirmationService,
        private transactionService: TransactionService)
    {
        super(toastrService, transactionService);

        this.title = "Orders";
        OrderDataHelper.userRole = this.activeRoute.snapshot.data.userType;
        this.appService.pageTitle = this.title;

        this.setupGrid();
    }

    public onAdd()
    {
        let nav = this.profileTypes[OrderDataHelper.userRole];

        this.router.navigateByUrl(`/${nav}/order-add`);
    }

    public canCancel(data)
    {
        let canDo =
            data.statusType != OrderDataHelper.Status_Complete.id &&
            data.statusType != OrderDataHelper.Status_Cancelled.id &&
            data.statusType != OrderDataHelper.Status_Suspended.id &&
            OrderDataHelper.userRole == this.profileTypes.customer;

        return canDo;
    }

    public onCancelConfirm(data)
    {
        let opts: IConfirmationOptions =
        {
            FunctionConfirm: () => this.onCancelIt(data)
        };

        this.confirmService.YesNo(`Are you sure you want to cancel Order #${data.poNumber}?`, opts);
    }

    public onCreateShipment(data)
    {
        let mod;

        switch (OrderDataHelper.userRole)
        {
            case this.profileTypes.admin:
                mod = "admin";
                break;

            case this.profileTypes.customer:
                mod = "customer";
                break;
        }

        this.router.navigate([`/${mod}/order-details`, OrderDataHelper.Tab_Ship, data.uid]);
    }

    private setupGrid()
    {
        let bools = new GridCellBoolean(GridCellBoolean.DefaultYesText, GridCellBoolean.DefaultNoText);
        let gridIcons =
            [
                GridHelpers.MakeViewIcon(this.onDetail.bind(this)),
                { icon: this.Icons.Cancel, toolTip: "Cancel order", onClick: this.onCancelConfirm.bind(this), isVisible: this.canCancel.bind(this) },
                { icon: this.Icons.Shipment, toolTip: "Create shipment", onClick: this.onCreateShipment.bind(this), isVisible: OrderDataHelper.CanShip }
            ];

        this.storageKey = "OrderList";
        this.setServerPath("list");
        this.addColumn(new GridColumnStatus(OrderDataHelper.OrderStatuses));

        if (OrderDataHelper.userRole != ProfileType.customer)
        {
            this.addColumn(new GridColumnMerchant("customer.displayName", "customer.uid"));
        }

        this.addColumn(new GridColumnPurchaser("purchaser.displayName", "purchaser.uid", { showType: false }));
        this.addColumn(new GridColumnPurchaserType("purchaser.purchaserTypeName"));
        this.addColumn(new GridColumnCustom("purchaser.language.name", "Language", { minWidth: 100, width: 150 }));

        this.addColumn(new GridColumn("purchaseDate", "Date", GridColumnType.DateTime));
        this.addColumn(new GridColumnCustom("poNumber", "Order #", { minWidth: 100 }));
        this.addColumn(new GridColumn("orderValue", "Value", GridColumnType.Money));
        this.addColumn(new GridColumn("shippingAddress.country.name", "Destination"));

        if (OrderDataHelper.userRole == ProfileType.admin)
        {
            this.addColumn(new GridColumnBoolean("purchaser.isOnWatchList", "Watch List"));
            this.addColumn(new GridColumnBoolean("isNewDocument", "New Doc", bools));
        }

        this.addColumn(new GridColumnBoolean("isNote", "Note?", bools));
        this.addColumn(new GridColumnBoolean("shipment.isAESRequired", "AES", bools));
        this.addColumn(new GridColumnOrderHolds());
        this.addColumn(new GridColumnIcon(gridIcons));

        this.rowDoubleClick(this.onDetail.bind(this));
    }

    private onCancelIt(data)
    {
        this.transactionService.saveStatus(data.uid, OrderDataHelper.Status_Cancelled.id).subscribe(
            retRes => this.gridRefresh(),
            errRes => this.error(errRes));
    }

    private onDetail(agData: any)
    {
        let mod: string;

        switch (OrderDataHelper.userRole)
        {
            case ProfileType.admin:
                mod = "admin";
                break;

            case ProfileType.freightForwarder:
                mod = "ff";
                break;

            case ProfileType.customer:
                mod = "customer";
                break;
        }

        this.router.navigate([`/${mod}/order-details`, agData.uid]);
    }
}