import { GridOptions } from "@ag-grid-enterprise/all-modules";
import { GlobalIcons } from "@app/shared/GlobalIcons";
import { Util } from "@app/utils/util";
import { GridCellIcon } from "./models/grid-cell-icon.model";

export class GridHelpers
{
    public static readonly AlignRight = "rightAligned";
    public static readonly CacheBlockSize = 25;
    public static readonly ServerSideStore = "partial";

    public static readonly GridStateVersion = "2.1";

    public static readonly DOMLayout_Auto = "autoHeight";
    public static readonly DOMLayout_Normal = "normal";

    public static readonly StateKey_Filter = "Filter";
    public static readonly StateKey_Columns = "Columns";
    public static readonly StateKey_Version = "Version";

    public static readonly Theme = "ag-theme-alpine";
    public static readonly PageSize = 25;
    public static readonly DefaultRowHeight = 40;

    public static readonly Operator_Or = "OR";
    public static readonly Operator_And = "AND";

    public static readonly RowModel_Type = "serverSide";
    public static readonly RowModel_Client = "ClientSideRowModel";
    public static readonly RowModel_Server = "ServerSideRowModel";

    public static readonly ColumnPinned_Left = "left";
    public static readonly ColumnPinned_Right = "right";

    public static readonly SortDir_Ascending = "asc";
    public static readonly SortDir_Decending = "desc";

    public static readonly FilterType_Set = "agSetColumnFilter";
    public static readonly FilterType_Date = "agDateColumnFilter";
    public static readonly FilterType_Text = "agTextColumnFilter";
    public static readonly FilterType_Number = "agNumberColumnFilter";
    public static readonly FilterDefaultAction = { buttons: ["reset", "apply"], closeOnApply: true };

    public static GetGridDefaults(): GridOptions
    {
        let opts = Object.create(
            {
                domLayout: GridHelpers.DOMLayout_Auto,
                rowHeight: GridHelpers.DefaultRowHeight,
                columnDefs: [],
                pagination: true,
                animateRows: true,
                paginationPageSize: GridHelpers.PageSize,
                suppressCellSelection: true,
                suppressLoadingOverlay: true,
                enableCellTextSelection: true,
                defaultColDef:
                {
                    filter: true,
                    sortable: true,
                    resizable: true
                }
            });

        return opts;
    }

    public static MakeEditIcon(clickFunction: any = null, visiblefunction: any = null): GridCellIcon
    {
        let edit = new GridCellIcon();

        edit.icon = GlobalIcons.Edit;
        edit.onClick = clickFunction;
        edit.toolTip = "Edit";
        edit.isVisible = visiblefunction ?? true;

        return edit;
    }

    public static MakeViewIcon(clickFunction: any = null, visiblefunction: any = null): GridCellIcon
    {
        let view = new GridCellIcon();

        view.icon = GlobalIcons.View;
        view.onClick = clickFunction;
        view.toolTip = "View details";
        view.isVisible = visiblefunction ?? true;

        return view;
    }

    public static MakeDeleteIcon(clickFunction: any = null, visiblefunction: any = null): GridCellIcon
    {
        let del = new GridCellIcon();

        del.icon = GlobalIcons.Trash;
        del.onClick = clickFunction;
        del.toolTip = "Delete";
        del.isVisible = visiblefunction ?? true;

        return del;
    }

    public static MakeDownloadIcon(clickFunction: any = null, visiblefunction: any = null): GridCellIcon
    {
        let del = new GridCellIcon();

        del.icon = GlobalIcons.Download;
        del.onClick = clickFunction;
        del.toolTip = "Download";
        del.isVisible = visiblefunction ?? true;

        return del;
    }

    public static FixSizes(grid)
    {
        //  Ugly fix to make sure the grid is rendered before updating the column sizes
        setTimeout(() =>
        {
            grid.sizeColumnsToFit();
            grid.resetRowHeights();
            grid.redrawRows();
        }, 100);
    }

    public static StateSave(gridBase, filters = null)
    {
        let store = Util.DataStorage.get(gridBase.storageKey);

        if (store == null)
        {
            store = {};
        }

        if (filters == null)
        {
            filters = gridBase.mainGrid?.getFilterModel();
        }

        store[GridHelpers.StateKey_Filter] = filters;
        store[GridHelpers.StateKey_Columns] = gridBase.columnManager?.getColumnState();
        store[GridHelpers.StateKey_Version] = GridHelpers.GridStateVersion;

        Util.DataStorage.set(gridBase.storageKey, store);
    }

    public static StateLoad(gridBase)
    {
        let store = Util.DataStorage.get(gridBase.storageKey);

        if (store == null)
        {
            return;
        }

        let ver = store[GridHelpers.StateKey_Version];
        let colData = store[GridHelpers.StateKey_Columns];
        let filtData = store[GridHelpers.StateKey_Filter];

        if (ver == GridHelpers.GridStateVersion)
        {
            gridBase.mainGrid.setFilterModel(filtData);
            gridBase.columnManager.applyColumnState({ state: colData, applyOrder: true });
        }
        else
        {
            console.warn(`Grid State Version mismatch, no data loaded: Grid: ${gridBase.storageKey}\tVersion: ${ver}\tCurrent Version: ${GridHelpers.GridStateVersion}`);

            Util.DataStorage.delete(gridBase.storageKey);
        }
    }

    public static GridStateClear(gridBase)
    {
        gridBase.gridStateReset();
    }
}