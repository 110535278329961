import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ApiService } from "../api/api.service";
import { ShopOrderProduct } from "./shop-order-product";

@Injectable({
    providedIn: "root"
})
export class ShopOrderProductService extends ApiService<ShopOrderProduct>
{
    public get dataForm()
    {
        let fb = new FormBuilder();
        let grp = fb.group(
            {
                uid: [],
                amount: [, [Validators.required, Validators.min(.01)]],
                category: [],
                quantity: [, [Validators.required, Validators.min(1)]],
                lineNumber: [],
                partNumber: [,Validators.required],
                subCategory: [],
                altDescription: [],
                customerDescription: []
            });

        return grp;
    }

    constructor(httpClient: HttpClient)
    {
        super(httpClient, "order-manual-entry");
    }
}