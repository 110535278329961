export enum PurchaserStatusType
{
    Delete = -2,
    NotFound = -1,
    Approved = 1,
    Pending = 2,
    New = 7,
    Inactive = 9,
    PendingReview = 10,
    OnHold = 11,
    Denied = 12
}