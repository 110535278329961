export class FreightForwarderRequestDTO
{
    public static readonly FreightRequest_Save = 1;
    public static readonly FreightRequest_Update = 2;
    public static readonly FreightRequest_DirtyTab = 3;
    public static readonly FreightRequest_DirtyClose = 4;
    public static readonly FreightRequest_TabChanged = 5;

    data: any;
    taskID: number;

    constructor(taskID: number = 0, data: any = null)
    {
        this.data = data;
        this.taskID = taskID;
    }
}