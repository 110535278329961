import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiService } from '@app/data';
import { CustomerSearch } from "./customer-search";

@Injectable(
{
    providedIn: "root"
})
export class CustomerSearchService extends ApiService<CustomerSearch>
{
    constructor(httpClient: HttpClient)
    {
        super(httpClient, "customer-search");
    }
}