import { EventEmitter, Input, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ApiService, Model } from "@app/data";
import { ProfileType } from "@app/data/enums/profile-type.enum";
import { CommonSettings } from "@app/shared/common-settings";
import { GlobalIcons } from "@app/shared/GlobalIcons";
import { DateFormatter } from "@app/shared/pipes/date-formatter.pipe";
import { DateHelper } from "@app/utils/date-helper";
import { Util } from "@app/utils/util";
import { NgbDate, NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { ToastrService } from "ngx-toastr";
import { Subscription } from 'rxjs';

export class BaseComponent<T>
{
    public readonly Icons = GlobalIcons;
    public readonly profileTypes = ProfileType;

    @Input()
    uid: string;

    @Input()
    parentUID: string;

    @Input()
    data: T;

    @Input()
    dataList: unknown[];

    @Input()
    search: T;

    @Input()
    readOnly: boolean;

    @BlockUI()
    blockUI: NgBlockUI;

    @Output()
    update = new EventEmitter();

    @Output()
    closeEmitter = new EventEmitter();

    public get formControls()
    {
        return this.dataForm.controls;
    }

    public get dataLength(): number
    {
        return this.dataList?.length || 0;
    }

    public get dataValue()
    {
        return Object.assign({}, this.dataForm?.value);
    }

    public get searchValue()
    {
        return Object.assign({}, this.searchForm?.value);
    }

    public get isSmallScreen(): boolean
    {
        return document.body.offsetWidth < 1024;
    }

    public loading = false;
    public pageIndex = 1;
    public pageLimit = 25;
    public pageTotal = 1;
    public submitted = false;
    public submitError = false;
    public autoHandleLoading: boolean = true;

    public title: string;
    public dataForm: FormGroup;
    public modalRef: NgbModalRef;
    public apiService: ApiService<T>;
    public searchForm: FormGroup;
    public modalService: NgbModal;

    private dateList;

    constructor(private toastrService: ToastrService)
    {
    }

    public patchValue(): void
    {
        if (!this.data)
        {
            return;
        }

        this.uid = (this.data as Model).uid;

        if (!this.dataForm)
        {
            return;
        }

        this.dataForm.patchValue(this.data);
        this.dataForm.markAsPristine();

        let keys = Object.keys(this.dataForm.controls);

        for (let dude of keys)
        {
            if (!this.data.hasOwnProperty(dude))
            {
                continue;
            }

            let val = this.data[dude];

            if ( !DateHelper.paternMatch(val) || !DateHelper.isDate(val))
            {
                continue;
            }

            let field = this.dataForm.get(dude);

            if (!field)
            {
                continue;
            }

            field.setValue(DateHelper.makeNGBDate(val));
        }
    }

    public enable(controls: string[]): void
    {
        controls.map(cont => this.dataForm.get(cont).enable());
    }

    public disable(controls: string[]): void
    {
        controls.map(cont => this.dataForm.get(cont).disable());
    }

    public makeRequired(controls: string[], isRequired: boolean = true): void
    {
        for (let cont of controls)
        {
            let field = this.dataForm.get(cont);

            if (!field)
            {
                continue;
            }

            if (isRequired)
            {
                field.setValidators([Validators.required]);
            }
            else
            {
                field.clearValidators();
            }

            field.updateValueAndValidity();
        }
    }

    public getValue(control: string)
    {
        let field = this.dataForm.get(control);

        if (field)
        {
            return field.value;
        }

        return null;
    }

    public setValue(control: string, value): void
    {
        if (this.data)
        {
            this.data[control] = value;
        }

        this.dataForm.get(control).setValue(value);
    }

    public resetForm(): void
    {
        if (!this.dataForm)
        {
            return;
        }

        this.submitted = false;
        this.submitError = false;

        this.dataForm.reset();
        this.dataForm.markAsPristine();
        this.dataForm.markAsUntouched();
    }

    public sort(evtArg): Subscription
    {
        if (Util.isEmpty(this.searchForm))
        {
            this.searchForm = new FormGroup({ sortColumn: new FormControl(), sortDirection: new FormControl() });
        }
        else
        {
            if (!this.searchForm.contains("sortColumn"))
            {
                this.searchForm.addControl("sortColumn", new FormControl());
            }

            if (!this.searchForm.contains("sortDirection"))
            {
                this.searchForm.addControl("sortDirection", new FormControl());
            }
        }

        this.searchForm.controls.sortColumn.setValue(evtArg.column);
        this.searchForm.controls.sortDirection.setValue(evtArg.direction);

        return this.list();
    }

    public list(path: string = "list"): Subscription
    {
        if (this.autoHandleLoading)
        {
            this.loadingOn();
        }

        let query = this.searchValue;

        query.pageIndex = this.pageIndex;
        query.pageLimit = this.pageLimit;

        let obby = this.apiService.getList(path, query).subscribe(
            retRes =>
            {
                if (retRes.query)
                {
                    this.pageTotal = (retRes.query as Model).pageTotal;
                }

                this.dataList = retRes.results;
            },
            errRes =>
            {
                this.error(errRes);
            });

        obby.add(() =>
        {
            if (this.autoHandleLoading)
            {
                this.loadingOff();
            }
        });

        return obby;
    }

    public postGetlist(path: string = "list"): Subscription
    {
        if (this.autoHandleLoading)
        {
            this.loadingOn();
        }

        let query = this.searchValue;

        query.pageIndex = this.pageIndex;
        query.pageLimit = this.pageLimit;

        let obby = this.apiService.postGetList(path, query).subscribe(
            retRes =>
            {
                if (retRes.query)
                {
                    this.pageTotal = (retRes.query as Model).pageTotal;
                }

                this.dataList = retRes.results;
            },
            errRes =>
            {
                this.error(errRes);
            });

        obby.add(() =>
        {
            if (this.autoHandleLoading)
            {
                this.loadingOff();
            }
        });

        return obby;
    }

    //  This is a mess, but it works.
    //  The ngbDatepicker uses an object {year, month, day}, but that can't be used by .Net.  Here we"ll change it to the US format for transmission and change it back upon return.
    public submitDatesStore(data: any = null): any
    {
        let sendData;

        this.dateList = new Map();

        if (data == null)
        {
            sendData = this.dataForm?.value;
        }
        else
        {
            sendData = data;
        }

        let forDate = new DateFormatter();

        for (let field in sendData)
        {
            let testField = sendData[field];

            if (testField instanceof NgbDate || (testField instanceof Object && "year" in testField && "month" in testField && "day" in testField))
            {
                this.dateList.set(field, sendData[field]);

                sendData[field] = forDate.transform(sendData[field], DateFormatter.FormatAmerican);
            }
        }

        return sendData;
    }

    public submiteDatesReset(data: any): void
    {
        if (this.dateList == null || this.dateList.size == 0)
        {
            return;
        }

        for (let item of this.dateList)
        {
            data[item[0]] = item[1];
        }

        this.dateList = null;
    }

    public submit(path: string = "save"): Subscription
    {
        if (this.invalid())
        {
            if (this.autoHandleLoading)
            {
                this.loadingOff();
            }

            this.submitError = true;
            return null;
        }

        if (this.autoHandleLoading)
        {
            this.loadingOn();
        }

        let sendData = this.submitDatesStore();

        let obby = this.apiService.post(path, sendData).subscribe(
            retData =>
            {
                this.submiteDatesReset(retData);

                this.data = retData;
                this.submitError = false;

                this.patchValue();

                if (this.update)
                {
                    this.update.emit(retData);
                }
            },
            errRes =>
            {
                this.submitError = true;

                this.error(errRes);
            });

        obby.add(() =>
        {
            if (this.autoHandleLoading)
            {
                this.loadingOff();
            }
        });

        return obby;
    }

    public invalid(message?: string): boolean
    {
        this.submitted = true;

        if (this.dataForm.invalid)
        {
            Util.WhatsWrongWithForm(this.dataForm);

            message = message ? message : "Fields are required";
            this.warning(message);

            return true;
        }

        return false;
    }

    public detail(data: any = null, path: string = "detail"): Subscription
    {
        if (this.autoHandleLoading)
        {
            this.loadingOn();
        }

        let filt = this.searchValue;

        filt = { ...filt, ...data };

        let obby = this.apiService.get(path, filt).subscribe(
            retRes =>
            {
                this.uid = (retRes as Model).uid;
                this.data = retRes;

                if (this.data && this.dataForm)
                {
                    this.dataForm.patchValue(retRes);
                }
            },
            errRes =>
            {
                this.error(errRes);
            });

        obby.add(() =>
        {
            if (this.autoHandleLoading)
            {
                this.loadingOff();
            }
        });

        return obby;
    }

    public delete(path: string = "delete"): Subscription
    {
        if (this.autoHandleLoading)
        {
            this.loadingOn();
        }

        let obby = this.apiService.delete(path, this.dataValue).subscribe(
            retRes =>
            {
                this.data = retRes;

                if (this.data && this.dataForm)
                {
                    this.dataForm.patchValue(retRes);
                }
            },
            errRes =>
            {
                this.error(errRes);
            });

        obby.add(() =>
        {
            if (this.autoHandleLoading)
            {
                this.loadingOff();
            }
        });

        return obby;
    }

    public makeForm(dataObj): FormGroup
    {
        let fb = new FormGroup({});
        let dateForm = new DateFormatter();

        for (let who in dataObj)
        {
            let val = dataObj[who];

            //  Test to see if the val is a date
            if (typeof val != "number" && !isNaN(Date.parse(val)))
            {
                // Apparently we don't like to ship the ISO format, so americanize it
                val = dateForm.transform(val, DateFormatter.FormatAmerican);
            }

            fb.addControl(who, new FormControl(val));
        }

        return fb;
    }

    public open(template: any, data?: T, size: string | null = null): void
    {
        this.resetForm();

        if (data)
        {
            this.uid = (data as Model).uid;
            this.data = data;
            this.patchValue();
        }
        else
        {
            this.uid = undefined;
            this.data = undefined;
        }

        let opts = CommonSettings.DialogOptions(size);

        this.modalRef = this.modalService.open(template, opts);
    }

    public close(): void
    {
        if (this.modalRef)
        {
            this.modalRef.close();
            return;
        }

        if (this.closeEmitter )
        {
            this.closeEmitter.emit();
        }
    }

    public success(message, title?: string): void
    {
        this.toastrService.success(message, title, CommonSettings.ToastOptions);
    }

    public info(message, title?: string): void
    {
        this.toastrService.info(message, title, CommonSettings.ToastOptions);
    }

    public warning(message, title?: string): void
    {
        this.toastrService.warning(message, title, CommonSettings.ToastOptions);
    }

    public error(message: any, title?: string): void
    {
        message = Util.parseError(message);

        this.toastrService.error(message, title, CommonSettings.ToastOptions);
    }

    public loadingOn(message: string = "Loading..."): void
    {
        this.loading = true;
        this.blockOn(message);
    }

    public loadingOff(): void
    {
        this.loading = false;

        if (this.blockUI.isActive)
        {
            this.blockUI.stop();
        }
    }

    public blockOn(message: string = "Loading..."): void
    {
        if (!this.blockUI.isActive)
        {
            this.blockUI.start(message);
        }
    }

    public blockOff(): void
    {
        this.blockUI.stop();
    }

    public blockToggle(blockIt: boolean): void
    {
        if (blockIt)
        {
            this.blockOn();
        }
        else
        {
            this.blockOff();
        }
    }
}