import { Component, Input } from "@angular/core";
import { Util } from "@app/utils/util";

@Component({
    selector: "percent-meter",
    styleUrls: ["./percent-meter.component.scss"],
    templateUrl: "./percent-meter.component.html",
})
export class PercentMeterComponent
{
    public percentValue: number;

    // TODO: These should be in a database or config file
    @Input()
    lowPoint: number = 5;

    @Input()
    highPoint: number = 75;

    @Input()
    optimalPoint: number = 100;

    @Input()
    get value(): number { return this.percentValue; };
    set value(val: number)
    {
        if (Util.isEmpty(val))
        {
            val = 0;
        }

        if (val < 1)
        {
            val *= 100;
        }

        this.percentValue = Math.round(val);
    }
}