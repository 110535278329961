export class GoogleMapsStreetOption
{
    FOV: number = 90;
    Size: number = 1024;
    Pitch: number = 0;
    Heading: number;

    constructor()
    {
    }
}