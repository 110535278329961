import { Component, ElementRef, Input, ViewChild } from "@angular/core";
import { BaseComponent, CustomerService, Transaction } from "@app/data";
import { ImageRequest } from "@app/data/misc/image-request.model";
import { ShipmentProductService } from '@app/data/shipment-product/shipment-product.service';
import { Shipment } from "@app/data/shipment/shipment";
import { ShipmentCommercialInvoice } from "@app/data/shipment/shipment-commercial-invoice.model";
import { ShipmentCommercialInvoiceService } from "@app/data/shipment/shipment-commercial-invoice.service";
import { CommonSettings } from '@app/shared/common-settings';
import { ConfirmationService, IConfirmationOptions } from "@app/shared/confirmation/confirmation.service";
import { DocumentPreviewService } from "@app/shared/document-preview/document-preview.service";
import { DateFormatter } from "@app/shared/pipes/date-formatter.pipe";
import { DateHelper } from "@app/utils/date-helper";
import { Util } from '@app/utils/util';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { CommercialInvoiceGenerator } from "./commercial-invoice.generator";

@Component({
    selector: "commercial-invoice",
    templateUrl: "./commercial-invoice.html"
})
export class CommericalInvoiceComponent extends BaseComponent<ShipmentCommercialInvoice>
{
    @Input()
    orderDetails: Transaction;

    @ViewChild("dialogInvoice")
    dialogInvoice: ElementRef;

    public shipmentRec: Shipment;

    private genny: CommercialInvoiceGenerator;

    constructor(toastrService: ToastrService,
        private custServ: CustomerService,
        private modalServ: NgbModal,
        private invoiceServ: ShipmentCommercialInvoiceService,
        private confirmService: ConfirmationService,
        private docViewService: DocumentPreviewService,
        private shipProdService: ShipmentProductService)
    {
        super(toastrService);

        this.title = "Commercial Invoice";
        this.dataForm = this.invoiceServ.editorForm;
        this.apiService = this.shipProdService;
        this.modalService = modalServ;
    }

    public openInvoice(shipmentRec: Shipment): void
    {
        this.genny = new CommercialInvoiceGenerator();
        this.shipmentRec = shipmentRec;

        this.open(this.dialogInvoice);

        let filt;

        if (Util.isEmpty(shipmentRec.shipmentCommercialInvoice?.uid))
        {
            filt = { shipmentUID: this.shipmentRec.uid };
        }
        else
        {
            filt = { uid: shipmentRec.shipmentCommercialInvoice.uid };
        }

        this.invoiceServ.detail(filt).subscribe(
            retRes => this.loadDetails(retRes),
            errRes => this.error(errRes));
    }

    public onClose()
    {
        if (!this.dataForm.dirty)
        {
            this.close();
            return;
        }

        let opts: IConfirmationOptions =
        {
            FunctionConfirm: () => this.close()
        };

        this.confirmService.YesNo(CommonSettings.DirtyFormConfirmationMessage, opts);
    }

    public onGenerate()
    {
        this.blockOn("Generating...");

        this.shipProdService.postGetList("list", { shipmentUID: this.shipmentRec.uid }).subscribe(
            retRes => this.loadProducts(retRes),
            errRes => this.gotsError(errRes));
    }

    private loadDetails(retRes): void
    {
        this.data = retRes;
        this.patchValue();

        if (Util.isEmpty(retRes.invoiceDate))
        {
            this.setValue("invoiceDate", DateHelper.makeNGBDate(DateFormatter.Now));
        }
    }

    private loadProducts(retRes): void
    {
        this.data = { ...this.data, ...this.dataForm.value };

        this.genny.products = retRes.results;
        this.genny.orderDetails = this.orderDetails;
        this.genny.commercialInvoice = this.data;

        this.saveInvoice();

        if (Util.isEmpty(this.data.customer?.logoImageURL))
        {
            this.makePDF(null);
            return;
        }

        let req = new ImageRequest(this.data.customer.logoImageURL, 200);
        let sub = this.custServ.getImage(req).subscribe(retRes =>
        {
            this.genny.logo = new Image();
            this.genny.logo.src = retRes.url;
        });

        sub.add(() => this.makePDF(retRes));
    }

    private makePDF(retRes: any): void
    {
        let pdfData = this.genny.generate();

        this.docViewService.openPDF(pdfData, this.title);
        this.blockOff();
    }

    private saveInvoice(): void
    {
        let saveMe = this.submitDatesStore(this.data);

        this.invoiceServ.save(saveMe).subscribe(
            retRes => this.saveComplete(retRes),
            errRes => this.error(errRes));
    }

    private saveComplete(retRes)
    {
        this.success("Commercial Invoice data saved");

        this.submiteDatesReset(retRes);

        this.data = retRes;
        this.patchValue();

        if (this.update)
        {
            this.update.emit();
        }
    }

    private gotsError(message: any): void
    {
        this.blockOff();
        this.error(message);
    }
}