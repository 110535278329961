import { HttpClient } from "@angular/common/http";
import { Component, ElementRef, ViewChild } from "@angular/core";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { CommonSettings } from "../common-settings";
import { GlobalIcons } from "../GlobalIcons";

@Component({
    selector: "app-privacy-policy",
    styleUrls: ["./privacy-policy.component.scss"],
    templateUrl: "./privacy-policy.component.html"
})
export class PrivacyPolicyComponent
{
    @ViewChild("dialogPolicy")
    dialogPolicy: ElementRef<any>;

    public readonly Icons = GlobalIcons;

    public policies;
    public policyOutline;

    private modalRef: NgbModalRef;

    constructor(public client: HttpClient, private modalService: NgbModal)
    {
    }

    public open()
    {
        let opts = CommonSettings.DialogOptions(CommonSettings.DialogSize_XLarge);

        this.modalRef = this.modalService.open(this.dialogPolicy, opts);

        this.client.get("assets/documents/privacy-policies/index.json").subscribe(retRes => this.loadList(retRes));
    }

    public onClose()
    {
        this.modalRef.close();
    }

    public onPolicyChange(evtArg)
    {
        this.loadFile(evtArg.target.value);
    }

    private loadFile(filename: string)
    {
        this.client.get("assets/documents/privacy-policies/" + filename).subscribe(retRes => this.loadPolicies(retRes));
    }

    private loadList(listPolicies)
    {
        this.policies = listPolicies;

        let curr = this.policies.find(elm => elm.current);

        this.loadFile(curr.file);
    }

    private loadPolicies(data)
    {
        this.policyOutline = this.processNode(data, 0);
    }

    private processNode(data, level: number)
    {
        let outH = `<ol class="level-${level}">`;

        for (let lp = 0; lp < data.length; lp++)
        {
            let node = data[lp];

            outH += "<li>";

            if (node.children)
            {
                outH += node.text;
                outH += this.processNode(node.children, level + 1);
            }
            else
            {
                outH += node.text;
            }

            if (node.subtext)
            {
                outH += node.subtext;
            }

            outH += "</li>";
        }

        return outH + "</ol>";
    }
}