import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { AuthService, ProfileType, TransactionDocument } from "@app/data";
import { EntityType } from "@app/data/enums/entity-type.enum";
import { TransactionDocumentService } from "@app/data/transaction-document/transaction-document.service";
import { GridBase } from "@app/shared/ag-grid-helpers/ag-grid-base";
import { GridHelpers } from "@app/shared/ag-grid-helpers/grid-helper";
import { GridColumnCustom } from "@app/shared/ag-grid-helpers/models/grid-column-custom.model";
import { GridColumnDocumentType } from "@app/shared/ag-grid-helpers/models/grid-column-document-type";
import { GridColumnIcon } from "@app/shared/ag-grid-helpers/models/grid-column-icon.model";
import { GridColumnStatus } from '@app/shared/ag-grid-helpers/models/grid-column-status';
import { GridColumn, GridColumnType } from "@app/shared/ag-grid-helpers/models/grid-column.model";
import { ConfirmationService, IConfirmationOptions } from "@app/shared/confirmation/confirmation.service";
import { DocumentPreviewService } from "@app/shared/document-preview/document-preview.service";
import { DocumentCommon } from "@app/shared/documents/document-common";
import { DocumentEditComponent } from "@app/shared/documents/document-edit/document-edit.component";
import { OrderDataHelper } from '@app/shared/orders/order-data.helper';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FileSaverService } from "ngx-filesaver";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: "order-document-list",
    templateUrl: "./order-document-list.component.html"
})
export class OrderDocumentListComponent extends GridBase<TransactionDocument> implements OnInit
{
    @Input()
    documentEditType: number = DocumentEditComponent.EditType_Order;

    @ViewChild("dialogDoc")
    dialogDoc: DocumentEditComponent;

    public readonly noteEntityType = EntityType.OrderDocument;

    public orderDetails;
    public canAddDocument: boolean = true;

    constructor(
        toastrService: ToastrService,
        public modalService: NgbModal,
        public confirmService: ConfirmationService,
        public transactionDocServ: TransactionDocumentService,
        private authService: AuthService,
        private docViewService: DocumentPreviewService,
        private fileSaverService: FileSaverService)
    {
        super(toastrService, transactionDocServ);

        this.title = "Order Documents";
        this.setupGrid();
    }

    async ngOnInit()
    {
        this.orderDetails = this.data;

        this.canAddDocument = this.documentEditType == DocumentEditComponent.EditType_Manual || this.transactionDocServ.canUploadDocument(this.orderDetails);
    }

    public onMyGridReady(evtArg)
    {
        this.filterAdd("transactionUID", this.orderDetails.uid);

        this.onGridReady(evtArg);
    }

    public onAdd()
    {
        this.dialogDoc.openAdd();
    }

    public onRefresh()
    {
        this.gridRefresh();

        if (this.authService.profile.profileType != ProfileType.admin && this.authService.profile.profileType != ProfileType.customer)
        {
            this.success("Document has been submitted for approval");
        }
    }

    private setupGrid()
    {
        let gridIcons =
            [
                GridHelpers.MakeEditIcon(this.onEdit.bind(this), this.canEdit),
                GridHelpers.MakeDeleteIcon(this.onDelete.bind(this), this.canDelete),
                GridHelpers.MakeDownloadIcon(this.onDownload.bind(this)),
                GridHelpers.MakeViewIcon(this.onViewDoc.bind(this))
            ];

        this.storageKey = "OrderDocumentList";
        this.addColumn(new GridColumnStatus(DocumentCommon.DocumentStatuses));
        this.addColumn(new GridColumnCustom("name", "Name", { maxWidth: 300 }));
        this.addColumn(new GridColumnDocumentType("documentType"));
        this.addColumn(new GridColumn("importPermit", "Import Permit"));
        this.addColumn(new GridColumnCustom("issuedBy", "Issued By", { maxWidth: 200 }));
        this.addColumn(new GridColumn("issuedDate", "Issued", GridColumnType.Date));
        this.addColumn(new GridColumn("expirationDate", "Expiration", GridColumnType.Date));
        this.addColumn(new GridColumnIcon(gridIcons));

        this.rowDoubleClick(this.onEdit.bind(this));
    }

    private onViewDoc(agData: any): void
    {
        let path = this.transactionDocServ.buildUrl("download-pdf");
        let fileName = `${path}?fileNameUID=${agData.fileNameUID}`;

        this.docViewService.openConvert(fileName, this.title);
    }

    public canEdit(doc: TransactionDocument): boolean
    {
        return (!doc.isDocumentReadOnly && OrderDataHelper.userRole == ProfileType.admin);
    }

    private onEdit(agData: any)
    {
        this.dialogDoc.openEdit(agData);
    }

    public canDelete(doc: TransactionDocument): boolean
    {
        return (!doc.isDocumentReadOnly && OrderDataHelper.userRole == ProfileType.admin);
    }

    private onDelete(selDoc: any)
    {
        this.dataForm = this.makeForm(selDoc);

        let opts: IConfirmationOptions =
        {
            FunctionConfirm: () => this.deleteIt()
        };

        this.confirmService.Delete(`Are you sure you want to delete ${selDoc.name}?`, opts);
    }

    private deleteIt()
    {
        this.delete().add(() => this.gridRefresh());
    }

    private onDownload(agData: any)
    {
        this.transactionDocServ.getFile("download", agData.fileNameUID).subscribe(
            retRes =>
            {
                this.fileSaverService.save(retRes, agData.fileName);
            },
            errRes => { this.error("Failed to download document"); }
        );
    }
}