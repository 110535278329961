import { Component, ElementRef, EventEmitter, Output, ViewChild } from "@angular/core";
import { BaseComponent, Purchaser } from "@app/data";
import { FixedImage } from "@app/data/misc/fixed-image";
import { CommonSettings } from "@app/shared/common-settings";
import { GoogleMapsHelperService } from "@app/shared/google-maps/google-maps-helper.service";
import { GoogleMapsCoordinates } from "@app/shared/google-maps/models/google-maps-coordinates.model";
import { GoogleMapsStreetOption } from "@app/shared/google-maps/models/google-maps-street-option.model";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: "purchaser-address-verification-street",
    styleUrls: ["./purchaser-address-verification-street.scss", "../../../../shared/styles/slider.scss"],
    templateUrl: "./purchaser-address-verification-street.html"
})
export class PurchaserAddressVerificationStreet extends BaseComponent<Purchaser>
{
    @ViewChild("dialogStreet")
    dialogStreet: ElementRef;

    @Output()
    streetSaveEvent = new EventEmitter<FixedImage>();

    public optsPitch =
        {
            ceil: 80,
            step: 1,
            floor: -80,
            maxLabel: "80",
            minLabel: "-80"
        };

    public optsHeading =
        {
            ceil: 360,
            step: 1,
            floor: 0,
            maxLabel: "360",
            minLabel: "0"
        };

    public numPitch: number;
    public imgStreet: FixedImage;
    public numHeading: number;

    private cords: GoogleMapsCoordinates;

    constructor(
        toastrService: ToastrService,
        public modalService: NgbModal,
        private mapsHelperServ: GoogleMapsHelperService)
    {
        super(toastrService);

        this.title = "Purchaser Verification - Street tweaker";
        this.modalService = modalService;
    }

    public openStreet(lat: number, lng: number, img: FixedImage): void
    {
        this.cords = new GoogleMapsCoordinates(lat, lng);
        this.numPitch = 0;
        this.imgStreet = img;
        this.numHeading = 0;

        this.open(this.dialogStreet, null, CommonSettings.DialogSize_XLarge);
    }

    public onImageChange(): void
    {
        let optStreet = new GoogleMapsStreetOption();

        optStreet.Pitch = this.numPitch;
        optStreet.Heading = this.numHeading;

        this.mapsHelperServ.getStreetImage(this.cords, optStreet).subscribe(
            retRes => this.imgStreet = retRes,
            errRes => console.error(errRes));
    }

    public onClose(): void
    {
        this.close();
    }

    public onSave(): void
    {
        this.close();
        this.streetSaveEvent.emit(this.imgStreet);
    }
}