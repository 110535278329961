export class ShipProductDTO
{
    uid: string;
    total: number;
    weight: number;
    aesCode: string;
    license: string;
    ordered: number;
    shipped: number;
    importHTS: string;
    remaining: number;
    weightRaw: number;
    partNumber: string;
    productUID: string;
    description: string;
    classification: string;
    exportScheduleB: string;

    constructor(product: any)
    {
        let derWeight = product.kilogramsPerItem * product.quantityShipped;

        this.uid = product.uid;
        this.total = product.amountShipped;
        this.aesCode = product.aesCode;
        this.license = product.transactionProduct.licenseNumber;
        this.ordered = product.transactionProduct.quantity;
        this.shipped = product.quantityShipped;
        this.importHTS = product.productCountry?.importHTS;
        this.remaining = product.transactionProduct.quantityRemaining;
        this.partNumber = product.transactionProduct.partNumber;
        this.productUID = product.transactionProductUID;
        this.description = product.transactionProduct.customerDescription;
        this.classification = product.transactionProduct.classification;
        this.exportScheduleB = product.product.exportScheduleB;

        this.weight = derWeight;
        this.weightRaw = derWeight;

        if (this.weight < 1)
        {
            this.weight = 1;
        }
    }
}