import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AppService } from "@app/app.service";
import { BaseComponent, ProfileType, Transaction, TransactionService } from "@app/data";
import { TaskEntity } from "@app/data/enums/task-entity.enum";
import { OrderDataHelper } from '@app/shared/orders/order-data.helper';
import { DateFormatter } from "@app/shared/pipes/date-formatter.pipe";
import { ToastrService } from "ngx-toastr";
import { ShipmentListComponent } from "./shipment-list/shipment-list.component";

@Component({
    selector: "order-details",
    styleUrls: ["./order-details.component.scss"],
    templateUrl: "./order-details.component.html"
})
export class OrderDetailsComponent extends BaseComponent<Transaction> implements OnInit
{
    @ViewChild("shipmentList")
    shipmentList: ShipmentListComponent;

    public readonly formatDT: string = DateFormatter.FormatDateTime;
    public readonly myEntityType = TaskEntity.Order;

    public setTab: number;
    public userRole: number;
    public orderDetails: Transaction;

    private presetTab: boolean;

    constructor(
        toastrService: ToastrService,
        private router: Router,
        private appService: AppService,
        private activeRoute: ActivatedRoute,
        private transactionServ: TransactionService)
    {
        super(toastrService);

        this.title = "Order Details";
        this.loading = true;
        this.appService.pageTitle = this.title;
    }

    ngOnInit()
    {
        this.userRole = this.activeRoute.snapshot.data.userType;
        OrderDataHelper.userRole = this.userRole;

        if (!this.activeRoute.snapshot.paramMap.has("orderId"))
        {
            this.loading = false;
            return;
        }

        let orderID = this.activeRoute.snapshot.params.orderId;

        this.transactionServ.detail({ uid: orderID }).subscribe(
            retRes => this.loadDetails(retRes),
            errRes => this.error(errRes));
    }

    public onGotoOrders()
    {
        let nav: string;

        switch (this.userRole)
        {
            case ProfileType.admin:
                nav = "/admin";
                break;

            case ProfileType.freightForwarder:
                nav = "/ff";
                break;

            case ProfileType.customer:
                nav = "/customer";
                break;
        }

        this.router.navigate([nav + "/orders"]);
    }

    public onShipmentListReady()
    {
        if (this.presetTab)
        {
            this.presetTab = false;
            setTimeout(() => this.shipmentList.onAdd(), 1000);
        }
    }

    public onOrderUpdate(order)
    {
        this.orderDetails = order;
    }

    public onOrderRefresh()
    {
        this.transactionServ.detail({ uid: this.orderDetails.uid }).subscribe(
            retRes => this.loadDetails(retRes),
            errRes => this.error(errRes));
    }

    public onAddressSync(): void
    {
        this.orderDetails.isSyncShipAddress = true;

        this.transactionServ.save(this.orderDetails).subscribe(
            {
                next: (retRes) =>
                {
                    this.orderDetails = retRes;
                    this.success("Address updated");
                },
                error: (errRes) => this.error(errRes)
            });
    }

    private loadDetails(data)
    {
        this.loading = false;
        this.orderDetails = data;

        if (this.activeRoute.snapshot.paramMap.has("tab"))
        {
            this.setTab = parseInt(this.activeRoute.snapshot.paramMap.get("tab"));
            this.presetTab = true;
        }
    }
}