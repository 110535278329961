export class GridCellImage
{
    public toolTip: string;
    public imageURL: string;
    public onClick?: Function;
    public imageField: string;

    constructor(field?: string)
    {
        this.imageField = field;
    }
}