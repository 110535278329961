import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ApiService } from "../api/api.service";
import { Shipment } from "./shipment";

@Injectable({
    providedIn: "root"
})
export class ShipmentService extends ApiService<Shipment>
{
    public get editorForm()
    {
        let form = new FormBuilder();
        let grpFields = form.group(
            {
                uid: [],
                comment: [],
                freightUID: [],
                statusType: [],
                shipmentDate: [Validators.required],
                shipmentNumber: [],
                trackingNumber: [],
                transactionUID: [],
                shipmentMethodUID: [],
                shippingInstructions: [],
                aesInternalTransactionNumber: []
            });

        return grpFields;
    }

    constructor(httpClient: HttpClient)
    {
        super(httpClient, "shipment");
    }

    public saveStatus(shipmentUID, status: number)
    {
        return this.save({ uid: shipmentUID, statusType: status });
    }
}