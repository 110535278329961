import { IAfterGuiAttachedParams, ICellRendererParams } from '@ag-grid-enterprise/all-modules';
import { Component } from "@angular/core";
import { AgRendererComponent } from "ag-grid-angular";
import { threadId } from "worker_threads";

@Component(
    {
        styleUrls: ["./grid-cell-star-rating-renderer.scss"],
        templateUrl: "./grid-cell-star-rating-renderer.html"
    })
export class GridCellStarRatingRenderer implements AgRendererComponent
{
    public rating: number;

    private cellParams: ICellRendererParams;

    agInit(params): void
    {
        this.rating = params.value;
        this.cellParams = params;
    }

    refresh(params): boolean
    {
        this.cellParams = params;
        return true;
    }

    afterGuiAttached?(params?: IAfterGuiAttachedParams): void
    {
    }
}