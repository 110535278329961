import { FreightForwarderType } from "@app/data/enums/freight-forwarder-type.enum";
import { CommonSettings } from "@app/shared/common-settings";
import { Util } from "@app/utils/util";

export class FreightForwarderCommon
{
    public static readonly EODNoMatch = "No Match";
    public static readonly ShipMethodCode = "FF";

    public static readonly RecordTypes =
        [
            { id: 1, name: "Freight Forwarder" },
            { id: 2, name: "Customs Broker" }
        ];

    public static readonly FreightForwarderStatuses =
        [
            { id: 1, name: "Approved" },
            { id: 2, name: "Pending" },
            { id: 7, name: "New" },
            { id: 9, name: "Inactive" },
            { id: 10, name: "Pending Review" },
            { id: 11, name: "Hold" },
            { id: 12, name: "Deny" }
        ];

    public static recordType(countryUID: string): string
    {
        if (Util.isEmpty(countryUID))
        {
            return "";
        }

        if (countryUID == CommonSettings.USAUID)
        {
            return FreightForwarderCommon.getDescription(FreightForwarderType.FreightFordwarder);
        }

        return FreightForwarderCommon.getDescription(FreightForwarderType.CustomersBroker);
    }

    public static getDescription(recordType: number): string
    {
        let ffType = FreightForwarderCommon.RecordTypes.find(elm => elm.id == recordType);

        return ffType.name;
    }
}