import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { BaseComponent } from "@app/data";
import { FixedImage } from "@app/data/misc/fixed-image";
import { PurchaserAddressImage } from "@app/data/purchaser-address/purchaser-address-image.model";
import { PurchaserAddressImageService } from "@app/data/purchaser-address/purchaser-address-image.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: "purchaser-address-verification-image",
    styleUrls: ["../purchaser-address-verification-capture/purchaser-address-verification-capture.component.scss"],
    templateUrl: "./purchaser-address-verification-image.component.html"
})
export class PurchaserAddressVerificationImage extends BaseComponent<PurchaserAddressImage> implements OnInit
{
    @Output()
    imageEdit = new EventEmitter<PurchaserAddressImage>();

    @Output()
    imageDelete = new EventEmitter<string>();

    public addyImage: FixedImage;

    constructor(
        toastrService: ToastrService,
        public modalService: NgbModal,
        private purchaserImgServ: PurchaserAddressImageService)
    {
        super(toastrService);

        this.apiService = purchaserImgServ;
        this.modalService = this.modalService;
    }

    ngOnInit(): void
    {
        let sub = this.purchaserImgServ.getImage(this.data.fileNameUID);

        sub.subscribe(retRes => this.addyImage = retRes);
    }

    public onImageOpen(): void
    {
        let win = window.open("");

        win.document.write(`<img src="${this.addyImage.url}" />`);
    }

    public onImageSource(): void
    {
        window.open(this.data.imageURL);
    }

    public onImageEdit(): void
    {
        this.imageEdit.emit(this.data);
    }

    public onImageDelete(): void
    {
        this.imageDelete.emit(this.data.uid);
    }
}