import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ComponentQandA } from "../misc/component-q-and-a";
import { License } from "./license";

@Injectable({
    providedIn: "root"
})
export class LicenseService extends ComponentQandA<License>
{
    public get editorForm()
    {
        let form = new FormBuilder();
        let grpFields = form.group(
            {
                uid: [],
                comment: [],
                issueDate: [],
                submitDate: [, Validators.required],
                freightUID: [],
                licenseType: [, Validators.required],
                customerUID: [, Validators.required],
                purchaserUID: [, Validators.required],
                licenseNumber: [],
                expirationDate: [],
                aepReferenceNumber: [, Validators.required],
                destinationCountryUID: [, Validators.required]
            });

        return grpFields;
    }

    constructor(httpClient: HttpClient)
    {
        super(httpClient, "license");
    }
}