import { ApiService } from "@app/data/api/api.service";
import { Observable } from "rxjs";
import { Subject } from "rxjs/internal/Subject";
import { QandAData } from "./q-and-a-data.model";

export class ComponentQandA<T> extends ApiService<T>
{
    public messageAnswer = new Subject();
    public messageQuestion = new Subject();

    public answerHandeler: Observable<any> = this.messageAnswer.asObservable();
    public questionHandler: Observable<any> = this.messageQuestion.asObservable();

    public sendAnswer(request: QandAData)
    {
        this.messageAnswer.next(request);
    }

    public sendQuestion(request: QandAData)
    {
        this.messageQuestion.next(request);
    }
}