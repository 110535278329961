import { DateHelper } from '@app/utils/date-helper';
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { AuthService, CustomerService, ProfileType } from "@app/data";
import { MerchantCommon } from "@app/data/customer/merchant-common";
import { MerchantRequestDTO } from "@app/data/customer/merchant-request.dto";
import { CommonSettings } from "@app/shared/common-settings";
import { MerchantBase } from "@app/shared/customer/merchant-base";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ToastrService } from "ngx-toastr";

@Component(
    {
        selector: "customer-details-edit",
        templateUrl: "./customer-details-edit.component.html"
    })
export class CustomerDetailsEditComponent extends MerchantBase implements OnInit, OnDestroy
{
    @Input()
    customerUID: string;

    public readonly statuses = MerchantCommon.merchantStatuses;
    public readonly editorConfig = CommonSettings.CKEditorToolbar;
    public readonly profileTypes = ProfileType;
    public readonly messageEditor = ClassicEditor;

    public userRole: number;

    constructor(toastrService: ToastrService,
        private authService: AuthService,
        private customerService: CustomerService)
    {
        super(toastrService);

        this.dataForm = this.customerService.editorForm;
        this.apiService = this.customerService;
        this.subQuestion = this.customerService.merchantQuestion.subscribe((request) => { this.handleQuestion(request) });
    }

    async ngOnInit()
    {
        let prof = await this.authService.getProfile$().toPromise();

        this.userRole = prof.profileType;
    }

    //  This is the fix for a race condiction on the Customer screen.  The HTML is already built before the data is downloaded, so we listen for the data to be changed with this:
    ngOnChanges()
    {
        this.loadCustomer(this.customerUID);
    }

    ngOnDestroy(): void
    {
        this.subQuestion.unsubscribe();
    }

    private handleQuestion(question: MerchantRequestDTO)
    {
        switch (question.requestID )
        {
            case MerchantRequestDTO.Request_Save:
                this.saveMe();
                break;
        }
    }

    private saveMe()
    {
        let sub = this.submit();

        if (!sub)
        {
            return;
        }

        sub.add(() => this.submitDone());
    }

    private submitDone()
    {
        if (this.submitError)
        {
            return;
        }

        let ans = new MerchantRequestDTO(MerchantRequestDTO.Request_Save, this.data);

        this.customerService.sendAnswer(ans);
    }
}