import { ProfileType } from '@app/data';
//  TODO:  This is getting out-of-hand!  There are too many variations for different users.  Need to come up with a better solution

export enum DocumentTypesEnum
{
    Other = "OTHER",
    AEPOther = "AEP",
    Invoice = "INV",
    SalesOrder = "SO",
    ImportPermit = "IMP",
    EndUserStatement = "EUS"
}

export class OrderCommon
{
    public static readonly DocumentType_Other = "OTHER";
    public static readonly DocumentType_AEPOther = "AEP";
    public static readonly DocumentType_ImportPermit = "IMP";

    public static readonly OrderStatuses =
        [
            { id: -2, name: "Order Check" },
            { id: 1, name: "Active" },
            { id: 2, name: "Partially Cleared" },
            { id: 3, name: "Cleared" },
            { id: 4, name: "Complete" },
            { id: 5, name: "Failed" },
            { id: 10, name: "Hold" },
            { id: 11, name: "Suspended" },
            { id: 13, name: "Cancelled" }
        ];

    public static readonly ChargeStatuses =
        [
            { id: 1, name: "Active" },
            { id: 2, name: "Pending" },
            { id: 4, name: "Complete" },
            { id: 7, name: "New" },
            { id: 9, name: "Cancelled" },
            { id: 10, name: "Pending Review" },
        ];

    public static readonly DocumentDesignationTypes =
        [
            { id: 1, name: "Active" },
            { id: 2, name: "Approved" },
            { id: 3, name: "Other" },
            { id: 4, name: "Under Review" }
        ];

    public static readonly PurchaserDocumentDesignationTypes =
        [
            { id: 1, name: "Active" },
            { id: 2, name: "Approved" },
            { id: 3, name: "Other" },
            { id: 4, name: "Under Review" },
            { id: 5, name: "Expired" }
        ];

    public static readonly DocumentTypes =
        [
            { id: "AIR", name: "Air Waybill", uid: null },
            { id: "ATF", name: "ATF Form 9", uid: null },
            { id: "DEL_CONF", name: "Delivery Confirmation", uid: null },
            { id: "EEI", name: "EEI", uid: null },
            { id: "EUS", name: "End User Statement", uid: null },
            { id: "IMP", name: "Import Permit", uid: null },
            { id: "INV", name: "Invoice", uid: null },
            { id: "Quote", name: "Quote", uid: null },
            { id: "SO", name: "Sales Order", uid: null },
            { id: "OTHER", name: "Other", uid: null },
            { id: "AEP", name: "AEP Other", uid: null }
        ];

    public static readonly MerchantDocumentTypes =
        [
            { id: "AIR", name: "Air Waybill", uid: null },
            { id: "ATF", name: "ATF Form 9", uid: null },
            { id: "DEL_CONF", name: "Delivery Confirmation", uid: null },
            { id: "EEI", name: "EEI", uid: null },
            { id: "EUS", name: "End User Statement", uid: null },
            { id: "INV", name: "Invoice", uid: null },
            { id: "Quote", name: "Quote", uid: null },
            { id: "SO", name: "Sales Order", uid: null }
        ];

    public static readonly FreightDocumentTypes =
    [
        { id: "AIR", name: "Air Waybill", uid: null },
        { id: "ATF", name: "ATF Form 9", uid: null },
        { id: "DEL_CONF", name: "Delivery Confirmation", uid: null },
        { id: "EEI", name: "EEI", uid: null },
        { id: "Quote", name: "Quote", uid: null },
        { id: "OTHER", name: "Other", uid: null }
    ];

    public static readonly ProductStatuses =
        [
            { id: 1, name: "Active" },
            { id: 3, name: "ClearedForShipment" },
            { id: 4, name: "Complete" },
            { id: 5, name: "Failed" },
            { id: 10, name: "Hold" },
            { id: 11, name: "Suspended" },
            { id: 13, name: "Cancelled" },
            { id: 20, name: "PartNumberNotFound" },
            { id: 21, name: "ProductNotActive" },
            { id: 22, name: "ProductIsNotExportable" },
            { id: 23, name: "InvalidShipCountry" },
            { id: 24, name: "ProductNotImportable" },
            { id: 25, name: "ExtendedCountryNotExportable" },
            { id: 26, name: "ProductPurchaserNotImportable" },
            { id: 27, name: "ProductIsIneligible" },
            { id: 28, name: "ProductNeedFirearmLicense" }
        ];

    public static readonly PurchaserDocumentTypes =
        [
            { id: "IMP", name: "Import Permit", uid: null },
            { id: "OTHER", name: "Other", uid: null }
        ];

    public static PlugDocumentTypes(data, userRole)
    {
        let list;
        let newList = [];

        switch (userRole)
        {
            case ProfileType.admin:
                list = OrderCommon.DocumentTypes;
                break;

            case ProfileType.customer:
                list = OrderCommon.MerchantDocumentTypes;
                break;

            case ProfileType.purchaser:
                list = OrderCommon.PurchaserDocumentTypes;
                break;

            case ProfileType.freightForwarder:
                list = OrderCommon.FreightDocumentTypes;
                break;

            default:
                return newList;
        }

        for (var rec of data)
        {
            let type = list.find(elm => elm.id == rec.code);

            if (type)
            {
                type.uid = rec.uid;

                newList.push(type);
            }
        }

        return newList;
    }
}