import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, mergeMap } from "rxjs/operators";
import { ApiService } from "../api/api.service";
import { DispatchService } from "../dispatch/dispatch.service";
import { CountryExportControl } from "./country-export-control";

@Injectable({
    providedIn: "root"
})
export class CountryExportControlService extends ApiService<CountryExportControl>
{
    constructor(httpClient: HttpClient, private dispatchService: DispatchService)
    {
        super(httpClient, "country-export-control");
    }

    import(file: File, data: CountryExportControl)
    {
        return this.dispatchService.upload(file).pipe(
            map(d1 =>
            {
                data.dispatch = {
                    fileName: d1.fileName,
                    fileNameUID: d1.fileNameUID
                };

                return d1;
            }),
            mergeMap(d2 =>
            {
                return this.post("import", data);
            })
        );
    }
}