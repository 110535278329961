import { GridColumn } from "./grid-column.model";

export class GridColumnCustom extends GridColumn
{
    constructor(field: string, header: string, props: any)
    {
        super(field, header);

        Object.assign(this, props);
    }
}