import { Component, EventEmitter, Input, Output } from "@angular/core";
import { InputEditViewType } from "@app/data/enums/input-edit-view-type.enum";
import { GlobalIcons } from "@app/shared/GlobalIcons";

@Component({
    selector: "input-edit-save",
    templateUrl: "./input-edit-save.html"
})
export class InputEditSave
{
    @Input()
    saving: boolean = false;

    @Input()
    inputValue: string;

    @Input()
    viewType: number = InputEditViewType.Text;

    @Output()
    saveEvent = new EventEmitter<string>();

    public readonly Icons = GlobalIcons;
    public readonly viewTypes = InputEditViewType;

    public editMode: boolean = false;

    constructor()
    {
    }

    public onEdit(): void
    {
        this.editMode = true;
    }

    public onCancel(): void
    {
        this.editMode = false;
    }

    public onSave(): void
    {
        this.editMode = false;

        this.saveEvent.emit(this.inputValue);
    }
}