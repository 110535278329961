import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ApiService } from '../api/api.service';
import { PurchaserContactHistory } from './purchaser-contact-history';

@Injectable({
  providedIn: 'root'
})
export class PurchaserContactHistoryService extends ApiService<PurchaserContactHistory> {

  constructor(httpClient: HttpClient) {
    super(httpClient, 'purchaser-contact-history');
  }

}
