import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { ProfileType } from "../enums/profile-type.enum";
import { StatusType } from "../enums/status-type.enum";
import { AuthService } from "./auth.service";

@Injectable({
    providedIn: "root"
})
export class AuthGuard implements CanActivate
{
    constructor(private router: Router, private authService: AuthService)
    {
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
    {
        let profile = this.authService.profile;
        /*
        we should read current URL and match against a whitelist of allowable route paths (transaction orders etc)
          */
        return this.authService.isAuthenticated$.pipe(
            tap(async loggedIn =>
            {
                if (!loggedIn)
                {
                    this.authService.login(state.url);
                    return;
                }

                if (!profile)
                {
                    await this.authService.getProfile$().toPromise();
                    profile = this.authService.profile;
                }

                if (profile && state.url.startsWith(profile.redirectUrl))
                {
                    if (profile.profileType === ProfileType.purchaser)
                    {
                        if (profile.purchaser?.statusType === StatusType.new && state.url !== profile.redirectUrl)
                        {
                            this.router.navigate([profile.redirectUrl]);
                            return false;
                        }
                    }
                }

                return true;
            })
        );
    }
}