import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiService } from "../api/api.service";
import { LicenseItem } from "./license-item";

@Injectable({
    providedIn: "root"
})
export class LicenseItemService extends ApiService<LicenseItem>
{
    constructor(httpClient: HttpClient)
    {
        super(httpClient, "license-item");
    }
}