import { Status } from "./status";

export class Model
{
    id?: number;
    uid?: string;
    status?: Status;
    pageIndex?: number;
    pageLimit?: number;
    pageTotal?: number;
    statusType?: number;
    sortColumn?: string;
    createdDate?: Date;
    sortDirection?: string;
}