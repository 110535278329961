import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { ApiService } from "../api/api.service";
import { ShipmentCommercialInvoice } from "./shipment-commercial-invoice.model";

@Injectable({
    providedIn: "root"
})
export class ShipmentCommercialInvoiceService extends ApiService<ShipmentCommercialInvoice>
{
    public get editorForm()
    {
        let form = new FormBuilder();
        let grpFields = form.group(
            {
                uid: [],
                comments: [],
                invoiceDate: [],
                paymentTerm: [],
                otherCharge1: [],
                otherCharge2: [],
                invoiceNumber: [],
                shippingCharge: [],
                handlingCharge: [],
                shipmentNumber: [],
                otherCharge1Description: [],
                otherCharge2Description: []
            });

        return grpFields;
    }

    constructor(httpClient: HttpClient)
    {
        super(httpClient, "shipment-commercial-invoice");
    }
}