import { Component, forwardRef, Input, OnInit } from "@angular/core";
import { AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { Util } from "@app/utils/util";

@Component({
    selector: "enum-selection",
    styleUrls: ["./enum-selection.component.scss"],
    templateUrl: "./enum-selection.component.html",
    providers:
        [
            {
                multi: true,
                provide: NG_VALUE_ACCESSOR,
                useExisting: forwardRef(() => EnumSelectionComponent)
            }
        ]
})
export class EnumSelectionComponent implements ControlValueAccessor, OnInit
{
    onChange: any = () => { };
    onTouched: any = () => { };

    @Input()
    placeholder: string;

    @Input()
    enumData;

    @Input()
    disabled: boolean = false;

    @Input("value")
    statusValue: string;

    get value()
    {
        return this.statusValue;
    }

    set value(val)
    {
        this.statusValue = val;

        this.onChange(val);
        this.onTouched();
    }

    public selectedName: string;

    constructor()
    {
    }

    ngOnInit(): void
    {
        if (Util.isEmpty(this.placeholder))
        {
            this.selectedName = "Select";
        }
        else
        {
            this.selectedName = this.placeholder;
        }
    }

    registerOnChange(execFun)
    {
        this.onChange = execFun;
    }

    registerOnTouched(execFun)
    {
        this.onTouched = execFun;
    }

    writeValue(value)
    {
        if (value == this.value)
        {
            return;
        }

        let findMe = Object.keys(this.enumData)[Object.values(this.enumData).indexOf(value)];

        this.statusValue = value;
        this.selectedName = findMe;
    }

    public onSelectChange(evtArg)
    {
        this.statusValue = evtArg.index;
        this.selectedName = evtArg.name;

        this.onChange(this.statusValue);
        this.onTouched();
    }
}