import { Component } from "@angular/core";
import { LookupService } from '@app/data';
import { GridFilter, GridFilterCondiction, GridFilterDataType, GridFilterStorage } from "@app/shared/ag-grid-helpers/models/grid-filter.model";
import { Util } from "@app/utils/util";
import { AgFilterComponent } from "ag-grid-angular";
import { IDoesFilterPassParams } from "ag-grid-community";
import { GridFilterCustom } from './../grid-filter-custom';

@Component({
    styleUrls: ["../../../styles/gridfilter.scss"],
    templateUrl: "./grid-address-filter.component.html"
})
export class GridAddressFilter extends GridFilterCustom implements AgFilterComponent
{
    public readonly fieldCity = "city";
    public readonly fieldState = "stateUID";
    public readonly fieldStreet = "street1";
    public readonly fieldCountry = "countryUID";

    public inCity: string;
    public inStreet: string;
    public selectState: string;
    public selectCountry: string;

    public listStates
    public listCountries;

    private field: string;
    private prefixField: boolean = false;

    constructor(private lookupServ: LookupService)
    {
        super();
    }

    agInit(params: any): void
    {
        this.initFilter(params);

        this.field = this.getParam("field");
        this.prefixField = this.getParam("prefixField", false);

        this.lookupServ.getCountryList().add(() => this.loadCountries());
    }

    isFilterActive(): boolean
    {
        return (!Util.isEmpty(this.inCity) || !Util.isEmpty(this.inStreet) || this.selectState != null || this.selectCountry != null);
    }

    doesFilterPass(params: IDoesFilterPassParams): boolean
    {
        if (this.isServerSide)
        {
            return true;
        }

        let addy = (this.field == null ? params.data : params.data[this.field]);

        if (Util.isEmpty(addy))
        {
            return false;
        }

        let passCity: boolean;
        let passCoun: boolean;
        let passStat: boolean;
        let passStrt: boolean;

        let dataCity = addy[this.fieldCity];
        let dataCoun = addy[this.fieldCountry];
        let dataStat = addy[this.fieldState];
        let dataStrt = addy[this.fieldStreet];

        if (Util.isEmpty(this.inCity))
        {
            passCity = true;
        }
        else
        {
            if (Util.isEmpty(dataCity))
            {
                passCity = false;
            }
            else
            {
                passCity = dataCity.toLowerCase().indexOf(this.inCity.toLowerCase()) != -1;
            }
        }

        if (Util.isEmpty(this.inStreet))
        {
            passStrt = true;
        }
        else
        {
            if (Util.isEmpty(dataStrt))
            {
                passStrt = false;
            }
            else
            {
                passStrt = dataStrt.toLowerCase().indexOf(this.inStreet.toLowerCase()) != -1;
            }
        }

        if (this.selectCountry == null)
        {
            passCoun = true;
        }
        else
        {
            passCoun = (Util.isEmpty(dataCoun) || dataCoun == this.selectCountry);
        }

        if (this.selectState == null)
        {
            passStat = true;
        }
        else
        {
            passStat = (Util.isEmpty(dataStat) || dataStat == this.selectState);
        }

        return (passCity && passCoun && passStat && passStrt);
    }

    getModel()
    {
        let filt = new GridFilterStorage();

        if (!Util.isEmpty(this.inCity))
        {
            let name = this.getFieldName(this.fieldCity);

            filt.add(name, new GridFilter(this.inCity, GridFilterDataType.text, GridFilterCondiction.contains));
        }

        if (!Util.isEmpty(this.inStreet))
        {
            let name = this.getFieldName(this.fieldStreet);

            filt.add(name, new GridFilter(this.inStreet, GridFilterDataType.text, GridFilterCondiction.contains));
        }

        if (this.selectState)
        {
            let name = this.getFieldName(this.fieldState);

            filt.add(name, new GridFilter(this.selectState));
        }

        if (this.selectCountry)
        {
            let name = this.getFieldName(this.fieldCountry);

            filt.add(name, new GridFilter(this.selectCountry));
        }

        return filt;
    }

    private getFieldName(field: string): string
    {
        if (this.prefixField)
        {
            return `${this.field}.${field}`;
        }

        return field;
    }

    setModel(model: any)
    {
        let filter = model?.multiple;

        if (!filter)
        {
            this.inCity = null;
            this.inStreet = null;
            this.selectState = null;
            this.selectCountry = null;
            return;
        }

        for (let name in filter)
        {
            let val = filter[name].filter

            switch (name)
            {
                case this.fieldCity:
                case `${this.field}.${this.fieldCity}`:
                    this.inCity = val;
                    break;

                case this.fieldStreet:
                case `${this.field}.${this.fieldStreet}`:
                    this.inStreet = val;
                    break;

                case this.fieldState:
                case `${this.field}.${this.fieldState}`:
                    this.selectState = val;
                    break;

                case this.fieldCountry:
                case `${this.field}.${this.fieldCountry}`:
                    this.selectCountry = val;
                    this.onCountryChange();
                    break;
            }
        }
    }

    public onCountryChange(): void
    {
        if (this.selectCountry == null)
        {
            this.selectState = null;
            return;
        }

        this.lookupServ.getCountryStateList(this.selectCountry).add(() =>
        {
            this.listStates = this.lookupServ.countryStateList;
            this.listStates.unshift({ uid: null, name: "All" });
        });
    }

    public onFilterClear()
    {
        this.inCity = null;
        this.inStreet = null;
        this.selectState = null;
        this.selectCountry = null;

        this.applyAndClose();
    }

    public onFilterApply()
    {
        this.applyAndClose();
    }

    private loadCountries()
    {
        this.listCountries = this.lookupServ.countryList;
        this.listCountries.unshift({ uid: null, name: "All" });
    }
}