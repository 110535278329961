export enum GoogleMapsType
{
    Road = "roadmap",
    Hybrid = "hybrid",
    Terrain = "terrain",
    Satellite = "satellite"
}

export enum GoogleMapsZoom
{
    World = 1,
    Continent = 5,
    City = 10,
    Street = 17,
    Default = 18,
    Buildings = 21
}

export class GoogleConsts
{
    public static readonly MapURL = "https://maps.googleapis.com/maps/api/staticmap?center=";
    public static readonly StreetURL = "https://maps.googleapis.com/maps/api/streetview?location=";
    public static readonly GeoCodeURL = "https://maps.googleapis.com/maps/api/geocode/json?address=";
}