//  I'm leaving the dialog logic in here.  We might need it later?
//  For now, we'll just open in a new Window

import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Util } from "@app/utils/util";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { CommonSettings } from "../common-settings";

@Injectable()
export class DocumentPreviewService
{
    constructor(private httpClient: HttpClient, private toastrService: ToastrService, private modalService: NgbModal, private sanitizer: DomSanitizer)
    {
    }

    public openConvert(fileLink: string, title: string): void
    {
        //  Might find a user later??
        // let opts = CommonSettings.DialogOptions(CommonSettings.DialogSize_XLarge);
        // let modRef = this.modalService.open(DocumentPreviewComponent, opts);
        // modRef.componentInstance.title = title;
        // modRef.componentInstance.fileLink = fileLink;

        // obby.subscribe(
        //     retRes => modRef.componentInstance.setPDF = retRes,
        //     errRes => modRef.componentInstance.setError = errRes);

        var obby = this.httpClient.get(fileLink,
            {
                observe: "response",
                responseType: "blob",
            });

        obby.subscribe(
            retRes => this.popPDF(retRes),
            errRes => this.toastrService.error(Util.parseError(errRes), title, CommonSettings.ToastOptions));
    }

    public openPDF(pdfSource: Blob, title: string = "Preview"): void
    {
        // let opts = CommonSettings.DialogOptions(CommonSettings.DialogSize_XLarge);
        // let modalRef = this.modalService.open(DocumentPreviewComponent, opts);

        // modalRef.componentInstance.title = title;
        // modalRef.componentInstance.setPDF = pdfSource;

        this.popPDF(pdfSource);
    }

    private async popPDF(val)
    {
        let testVal: any;

        if (val instanceof HttpResponse)
        {
            testVal = val.body;
        }
        else
        {
            testVal = val;
        }

        let blob = new Blob([testVal], { type: 'application/pdf' })
        let objURL = URL.createObjectURL(blob);

        window.open(objURL, "_blank", "popup=true");
    }
}