import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ApiService } from "../api/api.service";
import { CustomerAddress } from "./customer-address";

@Injectable({
    providedIn: "root"
})
export class CustomerAddressService extends ApiService<CustomerAddress>
{
    get editorForm()
    {
        let form = new FormBuilder();
        let grpFields = form.group(
            {
                uid: [],
                zip: [],
                city: [],
                street1: [, Validators.required],
                street2: [],
                stateUID: [],
                isDefault: [],
                statusType: [],
                workPhone1: [],
                workPhone2: [],
                customerUID: [],
                addressTypeUID: [, Validators.required]
            });

        return grpFields;
    }

    constructor(httpClient: HttpClient)
    {
        super(httpClient, "customer-address");
    }
}