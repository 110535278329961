import { Component, ViewChild } from "@angular/core";
import { CustomerPromo } from "@app/data/customer-promo/customer-promo";
import { CustomerPromoService } from "@app/data/customer-promo/customer-promo.service";
import { GridBase } from "@app/shared/ag-grid-helpers/ag-grid-base";
import { GridHelpers } from "@app/shared/ag-grid-helpers/grid-helper";
import { GridCellImage } from "@app/shared/ag-grid-helpers/models/grid-cell-image.model";
import { GridColumnIcon } from "@app/shared/ag-grid-helpers/models/grid-column-icon.model";
import { GridColumnImage } from "@app/shared/ag-grid-helpers/models/grid-column-image.model";
import { GridColumn, GridColumnType } from '@app/shared/ag-grid-helpers/models/grid-column.model';
import { ConfirmationService, IConfirmationOptions } from "@app/shared/confirmation/confirmation.service";
import { ToastrService } from "ngx-toastr";
import { CustomerMarketingPromosEdit } from "../customer-marketing-promos-edit/customer-marketing-promos-edit";

@Component({
    selector: "customer-marketing-promos-list",
    styleUrls: ["customer-marketing-promos-list.scss"],
    templateUrl: "customer-marketing-promos-list.html"
})
export class CustomerMarketingPromosList extends GridBase<CustomerPromo>
{
    @ViewChild("dialogEdit")
    dialogEdit: CustomerMarketingPromosEdit;

    constructor(
        toastrService: ToastrService,
        private promoServ: CustomerPromoService,
        private confirmService: ConfirmationService)
    {
        super(toastrService, promoServ);

        this.setupGrid();
    }

    public onMyGridReady(evtArg: any): void
    {
        this.filterAdd("customerUID", this.parentUID, false);

        this.onGridReady(evtArg);
    }

    public onAdd(): void
    {
        this.dialogEdit.openAdd(this.parentUID);
    }

    private setupGrid(): void
    {
        let img = new GridCellImage("promotionImage");
        let gridIcons =
            [
                GridHelpers.MakeEditIcon(this.onEdit.bind(this)),
                GridHelpers.MakeDeleteIcon(this.onDelete.bind(this)),
            ];

        img.onClick = this.gotoPromo.bind(this);
        img.toolTip = "Go to Landing Page";

        this.storageKey = "Customer-Promos";
        this.gridOpts.domLayout = GridHelpers.DOMLayout_Normal;
        this.gridOpts.paginationPageSize = 15;

        this.addColumn(new GridColumn("promotionDescription", "Description"));
        this.addColumn(new GridColumn("clickCount", "Clicks", GridColumnType.Number));
        this.addColumn(new GridColumn("startDate", "Start", GridColumnType.Date));
        this.addColumn(new GridColumn("endDate", "End", GridColumnType.Date));
        this.addColumn(new GridColumnImage([img], "Image"));
        this.addColumn(new GridColumnIcon(gridIcons));
        this.rowDoubleClick(this.onEdit.bind(this));
    }

    private gotoPromo(img: GridCellImage): void
    {
        let promo = this.gridData.find(elm => elm.promotionImage == img.imageURL);

        if (!promo)
        {
            return;
        }

        window.open(promo.promotionURL, "_blank");
    }

    private onEdit(agData: CustomerPromo): void
    {
        this.dialogEdit.openEdit(agData);
    }

    private onDelete(agData: CustomerPromo): void
    {
        this.dataForm = this.makeForm(agData);

        let opts: IConfirmationOptions =
        {
            FunctionConfirm: () => this.deleteIt()
        };

        this.confirmService.Delete(`Are you sure you want to delete this Promotion?`, opts);
    }

    private deleteIt(): void
    {
        this.delete().add(() => this.gridRefresh());
    }
}