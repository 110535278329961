import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { LookupService } from "@app/data";
import { CommonSettings } from "@app/shared/common-settings";
import { ConfirmationService, IConfirmationOptions } from "@app/shared/confirmation/confirmation.service";
import { GlobalIcons } from '@app/shared/GlobalIcons';
import { Util } from "@app/utils/util";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: "address-dialog",
    templateUrl: "./address-dialog.component.html"
})
export class AddressDialogComponent implements OnInit
{
    @Input()
    statuses;

    @Input()
    showPhone: boolean = true;

    @Input()
    addressTypes;

    @Output()
    saveAddress = new EventEmitter();

    @ViewChild("dialogAddress")
    dialogAddress: TemplateRef<any>;

    public readonly Icons = GlobalIcons;

    public dataForm: FormGroup;
    public stateList;
    public submitted: boolean;
    public showCountry: boolean;

    private dialogRef: NgbModalRef;

    constructor(
        public lookupService: LookupService,
        private toastrService: ToastrService,
        private modalService: NgbModal,
        private confirmService: ConfirmationService)
    {
    }

    ngOnInit(): void
    {
        let form = new FormBuilder();
        let grpFields = form.group(
            {
                uid: [],
                zip: [, Validators.required],
                city: [, Validators.required],
                street1: [, Validators.required],
                street2: [],
                stateUID: [, Validators.required],
                isDefault: [],
                countryUID: [],
                statusType: [],
                workPhone1: [],
                workPhone2: [],
                customerUID: [],
                addressTypeUID: []
            });

        this.dataForm = grpFields;
    }

    public openAdd(restrictCountry = null)
    {
        this.dataForm.reset();

        this.setupUI(restrictCountry);
    }

    public openEdit(addressData)
    {
        this.dataForm.reset();
        this.dataForm.patchValue(addressData);
        this.dataForm.markAsPristine();

        this.setupUI(addressData.countryUID);
    }

    public close()
    {
        this.dialogRef.close();
    }

    public onCountryChange(evtArg)
    {
        this.lookupService.getCountryStateList(evtArg.uid).add(() =>
        {
            this.stateList = this.lookupService.countryStateList;

            if (!Util.isEmpty(this.stateList))
            {
                this.dataForm.get("stateUID").setValue(this.stateList[0].uid);
            }
        });
    }

    public onClose()
    {
        if (!this.dataForm.dirty)
        {
            this.dialogRef.close();
            return;
        }

        let opts: IConfirmationOptions =
        {
            FunctionConfirm: () => this.dialogRef.close()
        };

        this.confirmService.YesNo(CommonSettings.DirtyFormConfirmationMessage, opts);
    }

    public onSave()
    {
        if (this.dataForm.invalid)
        {
            this.submitted = true;

            this.toastrService.warning("Invalid or missing data", "Address", CommonSettings.ToastOptions);
            return;
        }

        this.saveAddress.emit(this.dataForm.value);
    }

    private setupUI(country)
    {
        this.dataForm.get("countryUID").setValue(country);

        if (country == null)
        {
            this.showCountry = true;
            this.lookupService.getCountryList();
        }
        else
        {
            this.showCountry = false;

            if (country == CommonSettings.USAUID)
            {
                this.lookupService.getUSACountryStateList().add(() => this.stateList = this.lookupService.usaCountryStateList);
            }
            else
            {
                this.lookupService.getCountryStateList(country).add(() => this.stateList = this.lookupService.countryStateList);
            }
        }

        if (this.statuses)
        {
            this.dataForm.get("statusType").setValidators([Validators.required]);
        }
        else
        {
            this.dataForm.get("statusType").clearValidators();
        }

        this.dataForm.get("statusType").updateValueAndValidity();

        this.dialogRef = this.modalService.open(this.dialogAddress, CommonSettings.DialogOptions());
        this.submitted = false;
    }
}