import { ICellRendererParams } from "@ag-grid-enterprise/all-modules";
import { Component } from "@angular/core";
import { AgRendererComponent } from "ag-grid-angular";

@Component(
    {
        templateUrl: "./grid-cell-meter.html"
    })
export class GridCellMeter implements AgRendererComponent
{
    public meterValue: number;

    private field: string;
    private cellParams: ICellRendererParams;

    agInit(params): void
    {
        this.field = params.field;
        this.cellParams = params;
        this.meterValue = params.data[this.field];
    }

    refresh(params): boolean
    {
        this.cellParams = params;
        return true;
    }
}