import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ApiService } from "@app/data/api/api.service";
import { Feedback } from "./feedback.model";

@Injectable({
    providedIn: "root"
})
export class FeedbackService extends ApiService<Feedback>
{
    public get editorForm()
    {
        let fb = new FormBuilder();
        let grp = fb.group(
            {
                comment: [, Validators.required],
                ratingType: [],
                feedbackType: []
            });

        return grp;
    }

    constructor(httpClient: HttpClient)
    {
        super(httpClient, "feedback");
    }
}