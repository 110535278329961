import { GridHelpers } from '@app/shared/ag-grid-helpers/grid-helper';
import { Util } from "@app/utils/util";
import { GridColumn } from "./grid-column.model";
import { GridFilterCondiction } from './grid-filter.model';

export class GridColumnDocumentType extends GridColumn
{
    constructor(renderField: string)
    {
        super(renderField, "Type");

        let opts =
        {
            trimInput: true,
            valueGetter: this.getValue,
            textCustomComparator: this.testType,
            suppressAndOrCondition: true,
        };

        this.maxWidth = 300;
        this.cellRenderer = this.docCellRenderer.bind(this);
        this.filterParams = Object.assign(GridHelpers.FilterDefaultAction, opts);
    }

    private docCellRenderer(params: any)
    {
        let data = params.data;

        if (!Util.isEmpty(data.documentOther))
        {
            return `<div title="${data.documentOther}">${data.documentOther}</div>`;
        }

        if (Util.isEmpty(data.documentType?.name))
        {
            return Util.DOM_Space;
        }

        return data.documentType.name;
    }

    private getValue(params)
    {
        return params.data.documentOther || params.data.documentType.name;
    }

    private testType(condition: string, data: string, findMe: string)
    {
        if (Util.isEmpty(findMe))
        {
            return true;
        }

        switch (condition)
        {
            case GridFilterCondiction.equals:
                return findMe == data;

            case GridFilterCondiction.notEqual:
                return findMe != data;

            case GridFilterCondiction.contains:
                return data.indexOf(findMe) > -1;

            case GridFilterCondiction.notContains:
                return data.indexOf(findMe) == -1;

            case GridFilterCondiction.startsWith:
                return findMe.startsWith(data);

            case GridFilterCondiction.endsWith:
                return findMe.endsWith(data);

            default:
                return false;
        }
    }
}