import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { ApiResult, BaseComponent } from "@app/data";
import { NoteMod } from "@app/data/notes/note.model";
import { NotesService } from "@app/data/notes/notes.service";
import { NotesEdit } from "@app/shared/notes/notes-edit/notes-edit";
import { DateFormatter } from "@app/shared/pipes/date-formatter.pipe";
import { Util } from "@app/utils/util";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: "notes-list",
    styleUrls: ["./notes-list.scss"],
    templateUrl: "./notes-list.html"
})
export class NotesList extends BaseComponent<NoteMod> implements OnInit
{
    @Input()
    entityUID: string;

    @Input()
    entityType: number;

    @ViewChild("dialogNoteEdit")
    dialogNoteEdit: NotesEdit;

    public readonly formatDT: string = DateFormatter.FormatDateTime;

    public notes: Array<NoteMod>;

    constructor(
        toastrService: ToastrService,
        private noteServ: NotesService)
    {
        super(toastrService);

        this.apiService = this.noteServ;
    }

    ngOnInit(): void
    {
        this.refreshNotes();
    }

    public onAdd()
    {
        this.dialogNoteEdit.openAdd();
    }

    public onNoteClick(note: NoteMod): void
    {
        this.dialogNoteEdit.openEdit(note);
    }

    public refreshNotes()
    {
        let filt =
        {
            entityUID: this.entityUID,
            entityType: this.entityType
        };

        this.noteServ.postGetList("list", filt).subscribe(
            {
                next: (retRes) => this.loadNotes(retRes)
            });
    }

    private loadNotes(notes: ApiResult<NoteMod>): void
    {
        this.notes = notes.results;

        for (let item of this.notes)
        {
            item.note = Util.replaceAll(item.note, "\n", "<br />");
        }
    }
}