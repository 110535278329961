import { Pipe, PipeTransform } from "@angular/core";
import { DateHelper } from "@app/utils/date-helper";
import { Util } from "@app/utils/util";
import * as moment from "moment";

@Pipe({
    name: "DateFormatter",
    pure: true
})
export class DateFormatter implements PipeTransform
{
    public static readonly Now = "NOW";
    public static readonly FormatTime = "hh:mm A";
    public static readonly FormatDefault = "YYYY-MM-DD";
    public static readonly FormatAmerican = "MM/DD/YYYY hh:mm A";
    public static readonly FormatDateTime = "YYYY-MM-DD hh:mm A";
    public static readonly FormatDateTimeMil = "YYYY-MM-DD HH:mm";
    public static readonly FormatAmericanDate = "MM/DD/YYYY";

    transform(inDate: any, displayFormat = DateFormatter.FormatDefault): string
    {
        if (Util.isEmpty(inDate))
        {
            return "";
        }

        let outDate;

        if (inDate == DateFormatter.Now)
        {
            outDate = new Date();
        }
        else
        {
            outDate = DateHelper.makeDate(inDate);

            if (outDate == null)
            {
                return "";
            }
        }

        return moment(outDate).format(displayFormat);
    }
}