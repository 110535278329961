import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiService } from "@app/data/api/api.service";
import { ProductHistory } from "@app/data/product/product-history.model";

@Injectable({
    providedIn: "root"
})
export class ProductHistoryService extends ApiService<ProductHistory>
{
    constructor(httpClient: HttpClient)
    {
        super(httpClient, "product-history");
    }
}