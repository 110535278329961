import { GlobalIcons } from "@app/shared/GlobalIcons";

export class GridCellBooleanOption
{
    public icon?: string;
    public color?: string;
    public label?: string;
    public toolTip?: string;
}

export class GridCellBoolean
{
    public static readonly DefaultNoIcon: GridCellBooleanOption =
        {
            icon: GlobalIcons.No,
            color: "#c33",
            label: "No",
            toolTip: "No"
        };

    public static readonly DefaultYesIcon: GridCellBooleanOption =
        {
            icon: GlobalIcons.Check,
            color: "#3c3",
            label: "Yes",
            toolTip: "Yes"
        };

    public static readonly DefaultNoText: GridCellBooleanOption =
        {
            label: "NO",
            toolTip: "No"
        };

    public static readonly DefaultYesText: GridCellBooleanOption =
        {
            label: "YES",
            toolTip: "Yes"
        };

    public static readonly DefaultFalseText: GridCellBooleanOption =
        {
            label: "False",
            toolTip: "False"
        };

    public static readonly DefaultTrueText: GridCellBooleanOption =
        {
            label: "True",
            toolTip: "True"
        };

    public trueOption: GridCellBooleanOption;
    public falseOption: GridCellBooleanOption;

    constructor(trueOpt: GridCellBooleanOption = GridCellBoolean.DefaultYesText, falseOpt: GridCellBooleanOption = GridCellBoolean.DefaultNoText)
    {
        this.trueOption = trueOpt;
        this.falseOption = falseOpt;
    }
}