import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TransactionProductLicense } from "@app/data/transaction-product-license/transaction-product-license";
import { TransactionProductLicenseService } from "@app/data/transaction-product-license/transaction-product-license.service";
import { GridBase } from "@app/shared/ag-grid-helpers/ag-grid-base";
import { GridHelpers } from "@app/shared/ag-grid-helpers/grid-helper";
import { GridColumnIcon } from "@app/shared/ag-grid-helpers/models/grid-column-icon.model";
import { GridColumn, GridColumnType } from "@app/shared/ag-grid-helpers/models/grid-column.model";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: "app-license-transaction-product-list",
    styleUrls: ["./license-transaction-product-list.component.scss"],
    templateUrl: "./license-transaction-product-list.component.html",
})

export class TransactionProductLicenseListComponent extends GridBase<TransactionProductLicense> implements OnInit
{
    constructor(
        toastrService: ToastrService,
        public modalService: NgbModal,
        private router: Router,
        private transProductLicenseService: TransactionProductLicenseService)
    {
        super(toastrService, transProductLicenseService);

        this.setupGrid();
    }

    ngOnInit(): void
    {
        this.filterAdd("licenseUID", this.parentUID);
    }

    private setupGrid()
    {
        let gridIcons =
            [
                GridHelpers.MakeViewIcon(this.onDetail.bind(this))
            ];

        this.storageKey = "LicenseDocumentList";
        this.gridOpts.domLayout = GridHelpers.DOMLayout_Normal;
        this.gridOpts.paginationPageSize = 10;

        this.addColumn(new GridColumn("licenseItem.exportLineItem", "#"));
        this.addColumn(new GridColumn("licenseItem.description", "Description"));
        this.addColumn(new GridColumn("transactionProduct.partNumber", "Part #"));
        this.addColumn(new GridColumn("transaction.poNumber", "PO #"));
        this.addColumn(new GridColumn("quantity", "Qty", GridColumnType.Number));
        this.addColumn(new GridColumn("amount", "Amt", GridColumnType.Money));
        this.addColumn(new GridColumn("dateApplied", "Date", GridColumnType.Date));
        this.addColumn(new GridColumnIcon(gridIcons));

        this.rowDoubleClick(this.onDetail.bind(this));
    }

    public onDetail(transData: any): void
    {
        this.modalService.dismissAll();
        this.router.navigate(["/admin/order-details", transData.transaction.uid]);
    }
}