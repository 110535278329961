import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { AuthService, BaseComponent, ProfileType, PurchaserDocumentService, Transaction, TransactionDocument } from "@app/data";
import { DocumentDesignationType } from "@app/data/enums/document-designation-type.enum";
import { EntityType } from "@app/data/enums/entity-type.enum";
import { OrderDocumentStatusType } from "@app/data/enums/order-document-status-type";
import { TransactionDocumentService } from "@app/data/transaction-document/transaction-document.service";
import { DocumentEditComponent } from "@app/shared/documents/document-edit/document-edit.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FileSaverService } from "ngx-filesaver";
import { ToastrService } from "ngx-toastr";
import { DocumentPreviewService } from "../document-preview/document-preview.service";

@Component({
    selector: "purchaser-document-list",
    styleUrls: ["../../purchaser/purchaser-common/purchaser-common.scss"],
    templateUrl: "./purchaser-document-list.component.html",
})
export class PurchaserDocumentListComponent extends BaseComponent<TransactionDocument> implements OnInit
{
    @ViewChild("dialogDoc")
    dialogDoc: DocumentEditComponent;

    @Input()
    listType: number;

    //  This is temp until Admin has tested
    public get canView(): boolean
    {
        return (this.authService?.profile?.profileType == ProfileType.admin);
    }

    public static readonly ListType_Admin = 0;
    public static readonly ListType_Order = 1;
    public static readonly ListType_Generic = 2;

    public readonly noteEntityType = EntityType.Purchaser;

    public docs;
    public adminType = PurchaserDocumentListComponent.ListType_Admin;
    public docEditType;
    public orderDetails: Transaction;

    constructor(toastrService: ToastrService,
        public modalService: NgbModal,
        private authService: AuthService,
        private docViewService: DocumentPreviewService,
        private tranDocService: TransactionDocumentService,
        private purchaseDocServ: PurchaserDocumentService,
        private fileSaverService: FileSaverService)
    {
        super(toastrService);

        this.title = "Purchaser Documents";
        this.apiService = this.tranDocService;
        this.modalService = this.modalService;
    }

    ngOnInit(): void
    {
        switch (this.listType)
        {
            case PurchaserDocumentListComponent.ListType_Admin:
            case PurchaserDocumentListComponent.ListType_Generic:
                this.docEditType = DocumentEditComponent.EditType_Purchaser;
                break;

            case PurchaserDocumentListComponent.ListType_Order:
                this.parentUID = this.data.uid;
                this.docEditType = DocumentEditComponent.EditType_Order;
                this.orderDetails = this.data;
                break;
        }

        this.getDocs();
    }

    public canAdd()
    {
        if (this.readOnly)
        {
            return false;
        }

        if (this.listType == PurchaserDocumentListComponent.ListType_Order)
        {
            return this.tranDocService.canUploadDocument(this.orderDetails);
        }

        return true;
    }

    public canEdit(document)
    {
        if (this.listType == PurchaserDocumentListComponent.ListType_Admin || this.listType == PurchaserDocumentListComponent.ListType_Generic)
        {
            return true;
        }

        return document.statusType == OrderDocumentStatusType.New;
    }

    public onAdd()
    {
        this.dialogDoc.openAdd();
    }

    public onEdit(data)
    {
        this.dialogDoc.openEdit(data);
    }

    public onView(doc: TransactionDocument): void
    {
        let path = this.tranDocService.buildUrl("download-pdf");
        let fileName = `${path}?fileNameUID=${doc.fileNameUID}`;

        this.docViewService.openConvert(fileName, this.title);
    }

    public onDownload(doc: TransactionDocument): void
    {
        this.tranDocService.getFile("download", doc.fileNameUID).subscribe(
            retRes =>
            {
                this.fileSaverService.save(retRes, doc.fileName);
            },
            errRes => this.error("Failed to download document"));
    }

    public getDesignationClass(doc)
    {
        if (doc.documentDesignationType == DocumentDesignationType.Active || doc.documentDesignationType === DocumentDesignationType.Approved)
        {
            return "badge-success";
        }

        return doc.documentDesignationType == DocumentDesignationType.UnderReview ? "badge-warning" : "badge-primary";
    }

    public onDocumentUpdate()
    {
        this.getDocs();
    }

    private getDocs()
    {
        let filter = null;

        if (this.listType == PurchaserDocumentListComponent.ListType_Admin)
        {
            filter = { purchaserUID: this.parentUID };
        }

        switch (this.listType)
        {
            case PurchaserDocumentListComponent.ListType_Order:
                this.tranDocService.postGetList("list", { transactionUID: this.orderDetails.uid }).subscribe(
                    retRes => this.plugDocs(retRes),
                    errRes => this.error(errRes));
                break;

            case PurchaserDocumentListComponent.ListType_Admin:
            case PurchaserDocumentListComponent.ListType_Generic:
                this.purchaseDocServ.list(filter).subscribe(
                    retRes => this.plugDocs(retRes),
                    errRes => this.error(errRes));
                break;
        }
    }

    private plugDocs(docs): void
    {
        this.docs = docs.results

        if (this.update)
        {
            this.update.emit(this.docs);
        }
    }
}