export class TaskCommon
{
    public static readonly TaskTab_Docs = 1;
    public static readonly TaskTab_Note = 2;
    public static readonly TaskTab_Detail = 3;
    public static readonly DocumentTypeOther = "othr";

    public static readonly TaskEntities =
        [
            { id: 1, name: "Merchant" },
            { id: 2, name: "License" },
            { id: 3, name: "Order" },
            { id: 4, name: "Product" },
            { id: 5, name: "Purchaser" },
            { id: 6, name: "Freight Forwarder" }
        ];

    public static readonly TaskStatuses =
        [
            { id: 1, name: "In Progress" },
            { id: 2, name: "Pending" },
            { id: 4, name: "Complete" },
            { id: 7, name: "New" },
            { id: 11, name: "On Hold" },
            { id: 13, name: "Cancelled" }
        ];

    public static readonly DocumentStatuses =
        [
            { id: 1, name: "Active" },
            { id: 4, name: "Complete" }
        ];
}