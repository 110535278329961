import { AgmCoreModule } from "@agm/core";
import { NgxSliderModule } from "@angular-slider/ngx-slider";
import { CommonModule, CurrencyPipe, DatePipe } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MerchantDialogComponent } from "@app/admin/customer/merchant-dialog/merchant-dialog.component";
import { LicenseDialogComponent } from "@app/admin/license/license-dialog/license-dialog.component";
import { LicenseDocumentEditComponent } from "@app/admin/license/license-document-edit/license-document-edit.component";
import { LicenseDocumentListComponent } from "@app/admin/license/license-document-list/license-document-list.component";
import { LicenseEditComponent } from "@app/admin/license/license-edit/license-edit.component";
import { TransactionProductLicenseListComponent } from "@app/admin/license/license-transaction-product-list/license-transaction-product-list.component";
import { ProductHistoryList } from "@app/admin/product/product-history/product-history-list";
import { ProductOverrideImportHTSEdit } from "@app/admin/product/product-override-import-hts/product-override-import-hts-edit/product-override-import-hts-edit";
import { ProductOverrideImportHTSList } from "@app/admin/product/product-override-import-hts/product-override-import-hts-list/product-override-import-hts-list";
import { PurchaserAddressHistoryListComponent } from "@app/admin/purchaser/purchaser-address-history-list/purchaser-address-history-list.component";
import { PurchaserAddressListComponent } from "@app/admin/purchaser/purchaser-address-list/purchaser-address-list.component";
import { PurchaserAddressVerificationCapture } from "@app/admin/purchaser/purchaser-address-verification/purchaser-address-verification-capture/purchaser-address-verification-capture.component";
import { PurchaserAddressVerificationEdit } from "@app/admin/purchaser/purchaser-address-verification/purchaser-address-verification-edit/purchaser-address-verification-edit.component";
import { PurchaserAddressVerificationImage } from "@app/admin/purchaser/purchaser-address-verification/purchaser-address-verification-image/purchaser-address-verification-image.component";
import { PurchaserAddressVerificationList } from "@app/admin/purchaser/purchaser-address-verification/purchaser-address-verification-list/purchaser-address-verification-list.component";
import { PurchaserAddressVerificationPaste } from "@app/admin/purchaser/purchaser-address-verification/purchaser-address-verification-paste/purchaser-address-verification-paste";
import { PurchaserAddressVerificationStreet } from "@app/admin/purchaser/purchaser-address-verification/purchaser-address-verification-street/purchaser-address-verification-street";
import { PurchaserContactHistoryListComponent } from "@app/admin/purchaser/purchaser-contact-history-list/purchaser-contact-history-list.component";
import { PurchaserContactListComponent } from "@app/admin/purchaser/purchaser-contact-list/purchaser-contact-list.component";
import { PurchaserDialogComponent } from "@app/admin/purchaser/purchaser-dialog/purchaser-dialog.component";
import { PurchaserHistoryListComponent } from "@app/admin/purchaser/purchaser-history-list/purchaser-history-list.component";
import { PurchaserOfficerHistoryListComponent } from "@app/admin/purchaser/purchaser-officer-history-list/purchaser-officer-history-list.component";
import { PurchaserOfficerListComponent } from "@app/admin/purchaser/purchaser-officer-list/purchaser-officer-list.component";
import { PurchaserOwnerHistoryListComponent } from "@app/admin/purchaser/purchaser-owner-history-list/purchaser-owner-history-list.component";
import { PurchaserOwnerListComponent } from "@app/admin/purchaser/purchaser-owner-list/purchaser-owner-list.component";
import { PurchaserQuestionViewComponent } from "@app/admin/purchaser/purchaser-question-view/purchaser-question-view.component";
import { PurchaserReviewEditComponent } from "@app/admin/purchaser/purchaser-review-edit/purchaser-review-edit.component";
import { PurchaserSubCategoryComponent } from "@app/admin/purchaser/purchaser-sub-category-edit/purchaser-sub-category-edit.component";
import { PurchaserViewComponent } from "@app/admin/purchaser/purchaser-view/purchaser-view.component";
import { AddressComponent } from "@app/shared/address/address.component";
import { CustomerBillingComponent } from "@app/shared/customer/customer-billing/customer-billing.component";
import { CustomerContactEditComponent } from "@app/shared/customer/customer-contact-edit/customer-contact-edit.component";
import { CustomerContactListComponent } from "@app/shared/customer/customer-contact-list/customer-contact-list.component";
import { CustomerDetailsEditComponent } from "@app/shared/customer/customer-details-edit/customer-details-edit.component";
import { CustomerSearchItemComponent } from "@app/shared/customer/customer-search/customer-search-item.component";
import { CustomerSearchComponent } from "@app/shared/customer/customer-search/customer-search.component";
import { InfoLabel } from "@app/shared/info-label/info-label.component";
import { DateFormatter } from "@app/shared/pipes/date-formatter.pipe";
import { EnumToArrayPipe } from "@app/shared/pipes/enum-to-array.pipe";
import { PrivacyPolicyComponent } from "@app/shared/privacy-policy/privacy-policy.component";
import { PurchaserDealerConnectDetailsComponent } from "@app/shared/purchaser-dealer-connect/purchaser-dealer-connect-details/purchaser-dealer-connect-details.component";
import { PurchaserDealerConnectListComponent } from "@app/shared/purchaser-dealer-connect/purchaser-dealer-connect-list/purchaser-dealer-connect-list.component";
import { SortHelperComponent } from "@app/shared/sort-helper/sort-helper.component";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { environment } from "@env/environment";
import { NgbModalModule, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { AgGridModule } from "ag-grid-angular";
import { BlockUIModule } from "ng-block-ui";
import { RatingModule } from "ngx-bootstrap/rating";
import { NgxMaskModule } from "ngx-mask";
import { ShowdownModule } from "ngx-showdown";
import { GridCellNumberEditorComponent } from "./ag-grid-helpers/components/grid-cell-number-editor/grid-cell-number-editor.component";
import { GridAddressFilter } from "./ag-grid-helpers/filters/grid-address-filter/grid-address-filter.component";
import { GridBooleanFilter } from "./ag-grid-helpers/filters/grid-boolean-filter/grid-boolean-filter.component";
import { GridCheckListFilter } from "./ag-grid-helpers/filters/grid-checklist-filter/checklist-filter.component";
import { GridContactFilter } from "./ag-grid-helpers/filters/grid-contact-filter/grid-contact-filter.component";
import { GridCycleFilter } from "./ag-grid-helpers/filters/grid-cycle-filter/grid-cycle-filter.component";
import { GridDateFilter } from "./ag-grid-helpers/filters/grid-date-filter/grid-date-filter.component";
import { GridDispatchTypeFilter } from "./ag-grid-helpers/filters/grid-dispatch-type-filter/grid-dispatch-type-filter.component";
import { GridDropDownFilter } from "./ag-grid-helpers/filters/grid-dropdown-filter/grid-dropdown-filter.component";
import { GridFilterMeter } from "./ag-grid-helpers/filters/grid-filter-meter/grid-filter-meter.component";
import { GridMerchantFilter } from "./ag-grid-helpers/filters/grid-merchant-filter/grid-merchant-filter.component";
import { GridPurchaserFilter } from "./ag-grid-helpers/filters/grid-purchaser-filter/grid-purchaser-filter.component";
import { GridPurchaserTypeFilter } from "./ag-grid-helpers/filters/grid-purchaser-type-filter/grid-purchaser-type-filter.component";
import { GridUserFilter } from "./ag-grid-helpers/filters/grid-user-filter/grid-user-filter.component";
import { GridCellAddressRenderer } from "./ag-grid-helpers/renderers/grid-cell-address/grid-cell-address";
import { GridCellBooleanRenderer } from "./ag-grid-helpers/renderers/grid-cell-boolean/grid-cell-boolean-renderer";
import { GridCellContactRenderer } from "./ag-grid-helpers/renderers/grid-cell-contact/grid-cell-contact";
import { GridCellIconGroup } from "./ag-grid-helpers/renderers/grid-cell-icon-group/grid-cell-icon-group";
import { GridCellImageGroup } from "./ag-grid-helpers/renderers/grid-cell-image-group/grid-cell-image-group";
import { GridCellMerchantRenderer } from "./ag-grid-helpers/renderers/grid-cell-merchant/grid-cell-merchant";
import { GridCellMeter } from "./ag-grid-helpers/renderers/grid-cell-meter/grid-cell-meter";
import { GridCellPartNumberRenderer } from "./ag-grid-helpers/renderers/grid-cell-part-number/grid-cell-part-number";
import { GridCellPurchaserRenderer } from "./ag-grid-helpers/renderers/grid-cell-purchaser/grid-cell-purchaser";
import { GridCellStarRatingRenderer } from "./ag-grid-helpers/renderers/grid-cell-star-rating/grid-cell-star-rating-renderer";
import { GridSidebarExtras } from "./ag-grid-helpers/tools/grid-sidebar-extras";
import { CaptchaComponent } from "./captcha/captcha.component";
import { CustomerAccountEditComponent } from "./customer/customer-account-edit/customer-account-edit.component";
import { CustomerAccountListComponent } from "./customer/customer-account-list/customer-account-list.component";
import { CustomerAddressListComponent } from "./customer/customer-address-list/customer-address-list.component";
import { CustomerMarketingHome } from "./customer/customer-marketing/customer-marketing-home/customer-marketing-home";
import { CustomerMarketingImages } from "./customer/customer-marketing/customer-marketing-images/customer-marketing-images";
import { CustomerMarketingPromosEdit } from "./customer/customer-marketing/customer-marketing-promos/customer-marketing-promos-edit/customer-marketing-promos-edit";
import { CustomerMarketingPromosList } from "./customer/customer-marketing/customer-marketing-promos/customer-marketing-promos-list/customer-marketing-promos-list";
import { CustomerMarketingSearch } from "./customer/customer-marketing/customer-marketing-search/customer-marketing-search";
import { CusteromSaverComponent } from "./customer/customer-saver/customer-saver.component";
import { CustomerSearchUserStatementComponent } from "./customer/customer-search/customer-search-user-statement/customer-search-user-statement.component";
import { MerchantSearchDialog } from "./customer/customer-search/merchant-search-dialog/merchant-search-dialog.component";
import { CustomerTabsComponent } from "./customer/customer-tabs/customer-tabs.component";
import { MerchantPurchasersComponent } from "./customer/merchant-purchasers/merchant-purchasers.component";
import { DealerList } from "./dealer/dealer-list/dealer-list";
import { DocumentEditComponent } from "./documents/document-edit/document-edit.component";
import { EnumSelectionComponent } from "./enum-selection/enum-selection.component";
import { FaqListComponent } from "./faq/faq-list/faq-list.component";
import { FeedbackDialog } from "./feedback/feedback-dialog/feedback-dialog";
import { FreightForwarderAddressListComponent } from "./freight-forwarder/freight-forwarder-edit/freight-forwarder-address-list/freight-forwarder-address-list.component";
import { FreightForwarderContactEditComponent } from "./freight-forwarder/freight-forwarder-edit/freight-forwarder-contact-edit/freight-forwarder-contact-edit.component";
import { FreightForwarderContactListComponent } from "./freight-forwarder/freight-forwarder-edit/freight-forwarder-contact-list/freight-forwarder-contact-list.component";
import { FreightForwarderDetailsComponent } from "./freight-forwarder/freight-forwarder-edit/freight-forwarder-details/freight-forwarder-details.component";
import { FreightForwarderDialogComponent } from "./freight-forwarder/freight-forwarder-edit/freight-forwarder-dialog/freight-forwarder-dialog.component";
import { FreightForwarderTabsComponent } from "./freight-forwarder/freight-forwarder-edit/freight-forwarder-tabs/freight-forwarder-tabs.component";
import { FreightForwarderListComponent } from "./freight-forwarder/freight-forwarder-list/freight-forwarder-list.component";
import { GridFFRecordTypeFilter } from "./freight-forwarder/freight-forwarder-list/grid-components/grid-ff-record-type-filter/grid-ff-record-type-filter.component";
import { AddressDialogComponent } from "./misc-components/address-dialog/address-dialog.component";
import { DateTimeInputComponent } from "./misc-components/date-time-input/date-time-input.component";
import { EmailLinkComponent } from "./misc-components/email-link/email-link.component";
import { InputEditSave } from "./misc-components/input-edit-save/input-edit-save";
import { MembersEditComponent } from "./misc-components/members/members-edit/members-edit.component";
import { MembersListComponent } from "./misc-components/members/members-list/members-list.component";
import { MerchantLink } from "./misc-components/merchant-link/merchant-link";
import { PhoneLink } from "./misc-components/phone-link/phone-link";
import { PopupMenu } from "./misc-components/popup-menu/popup-menu";
import { PopupMenuItem } from "./misc-components/popup-menu/popup-menu-item/popup-menu-item";
import { ProductLink } from "./misc-components/product-link/product-link";
import { PurchaserLink } from "./misc-components/purchaser-link/purchaser-link";
import { StarRating } from "./misc-components/star-rating/star-rating";
import { WebLink } from "./misc-components/web-link/web-link";
import { WikiEditorComponent } from "./misc-components/wiki-editor/wiki-editor.component";
import { WikiTextComponent } from "./misc-components/wiki-renderer/wiki-renderer.component";
import { YearMonthSelectionComponent } from "./misc-components/year-month-selection/year-month-selection.component";
import { NotesEdit } from "./notes/notes-edit/notes-edit";
import { NotesList } from "./notes/notes-list/notes-list";
import { CommericalInvoiceComponent } from "./orders/order-details/commercial-invoice/commercial-invoice";
import { OrderDocumentListComponent } from "./orders/order-details/document-list/order-document-list.component";
import { OrderDetailsComponent } from "./orders/order-details/order-details.component";
import { OrderProductListComponent } from "./orders/order-details/product-list/product-list.component";
import { ShipmentDetailComponent } from "./orders/order-details/shipment-detail/shipment-detail.component";
import { ShipmentListComponent } from "./orders/order-details/shipment-list/shipment-list.component";
import { ShipperLetterInstructionsComponent } from "./orders/order-details/shipper-letter-of-instruction/shipper-letter-of-instruction.component";
import { OrdersHoldFilter } from "./orders/order-grid-components/hold-filter/hold-filter.component";
import { GridCellOrderHoldsRenderer } from "./orders/order-grid-components/hold-renderer/grid-cell-holds.renderer";
import { OrderListComponent } from "./orders/order-list/order-list.component";
import { OrderManualEntryComponent } from "./orders/order-manual-entry/order-manual-entry.component";
import { OrderProductBulkComponent } from "./orders/order-manual-entry/order-product-bulk/order-product-bulk.component";
import { OrderProductComponent } from "./orders/order-manual-entry/order-product/order-product.component";
import { PurchaserAddDialogComponent } from "./orders/order-manual-entry/purchaser-add-dialog/purchaser-add-dialog.component";
import { OrderNoticiationRequestsComponent } from "./orders/order-notification-requests/order-notification-requests.component";
import { OrderReportsComponent } from "./orders/reports/order-reports-home/order-reports-home.component";
import { OrderCartCheckReportComponent } from "./orders/reports/report-cart-check/report-cart-check.component";
import { GridCellProblemRenderer } from "./orders/reports/report-remove-product/grid-cell-problem-renderer/grid-cell-problem-renderer";
import { OrderRemoveProductReportComponent } from "./orders/reports/report-remove-product/report-remove-product.component";
import { PercentMeterComponent } from "./percent-meter/percent-meter.component";
import { SafeHTMLPipe } from "./pipes/safe-html.pipe";
import { ProductCountryListComponent } from "./products/product-country-list/product-country-list.component";
import { ProductDialogComponent } from "./products/product-dialog/product-dialog.component";
import { ProductEditComponent } from "./products/product-edit/product-edit.component";
import { ProductSearches } from "./products/product-searches/product-searches";
import { ProductUploadComponent } from "./products/product-upload/product-upload.component";
import { ProductURLCheckList } from "./products/product-url-check-list/product-url-check-list";
import { PurchaserDocumentListComponent } from "./purchaser-document-list/purchaser-document-list.component";
import { PurchaserTermsComponent } from "./purchaser-terms/purchaser-terms.component";
import { RelaseNotesComponent } from "./resources/release-notes/release-notes.component";
import { UserManualComponent } from "./resources/user-manual/user-manual.component";
import { CanDeactivateGuard } from "./services/can-deactivate-guard";
import { StatusBadgeComponent } from "./status-badge/status-badge.component";
import { MyDashboardComponent } from "./users/my-dashboard/my-dashboard.component";
import { MyProfileComponent } from "./users/my-profile/my-profile.component";
import { MyTaskDetailComponent } from "./users/my-tasks/my-task-edit/my-task-detail/my-task-detail.component";
import { MyTaskDialogComponent } from "./users/my-tasks/my-task-edit/my-task-dialog.component";
import { TaskDocumentEditComponent } from "./users/my-tasks/my-task-edit/my-task-doc-edit/my-task-doc-edit.component";
import { TaskDocumentPaste } from "./users/my-tasks/my-task-edit/my-task-doc-paste/my-task-doc-paste";
import { MyTaskDocumentsComponent } from "./users/my-tasks/my-task-edit/my-task-docs/my-task-docs.component";
import { MyTaskTabsComponent } from "./users/my-tasks/my-task-edit/my-task-tabs.component";
import { MyTasksComponent } from "./users/my-tasks/my-tasks.component";

const gridComponets =
    [
        GridCellMeter,
        GridDateFilter,
        GridUserFilter,
        GridCycleFilter,
        GridFilterMeter,
        OrdersHoldFilter,
        GridAddressFilter,
        GridBooleanFilter,
        GridContactFilter,
        GridCellIconGroup,
        GridSidebarExtras,
        GridCellImageGroup,
        GridDropDownFilter,
        GridMerchantFilter,
        GridCheckListFilter,
        GridPurchaserFilter,
        GridFFRecordTypeFilter,
        GridDispatchTypeFilter,
        GridCellAddressRenderer,
        GridCellContactRenderer,
        GridCellBooleanRenderer,
        GridCellProblemRenderer,
        GridPurchaserTypeFilter,
        GridCellMerchantRenderer,
        GridCellPurchaserRenderer,
        GridCellOrderHoldsRenderer,
        GridCellPartNumberRenderer,
        GridCellStarRatingRenderer,
        GridCellNumberEditorComponent
    ];

const licenseComponents =
    [
        LicenseEditComponent,
        LicenseDialogComponent,
        LicenseDocumentEditComponent,
        LicenseDocumentListComponent
    ];

const miscComponent =
    [
        WebLink,
        NotesEdit,
        NotesList,
        InfoLabel,
        PhoneLink,
        PopupMenu,
        DealerList,
        StarRating,
        ProductLink,
        MerchantLink,
        SafeHTMLPipe,
        PopupMenuItem,
        DateFormatter,
        InputEditSave,
        PurchaserLink,
        FeedbackDialog,
        EnumToArrayPipe,
        AddressComponent,
        CaptchaComponent,
        FaqListComponent,
        WikiTextComponent,
        EmailLinkComponent,
        SortHelperComponent,
        UserManualComponent,
        WikiEditorComponent,
        MembersEditComponent,
        MembersListComponent,
        MerchantSearchDialog,
        RelaseNotesComponent,
        StatusBadgeComponent,
        DocumentEditComponent,
        PercentMeterComponent,
        AddressDialogComponent,
        DateTimeInputComponent,
        EnumSelectionComponent,
        PrivacyPolicyComponent,
        YearMonthSelectionComponent,
        PurchaserDealerConnectListComponent,
        PurchaserDealerConnectDetailsComponent
    ];

const freightForwarderComponents =
    [
        FreightForwarderListComponent,
        FreightForwarderTabsComponent,
        FreightForwarderDialogComponent,
        FreightForwarderDetailsComponent,
        FreightForwarderAddressListComponent,
        FreightForwarderContactEditComponent,
        FreightForwarderContactListComponent
    ];

const taskAndProfileComponents =
    [
        MyTasksComponent,
        TaskDocumentPaste,
        MyProfileComponent,
        MyTaskTabsComponent,
        MyDashboardComponent,
        MyTaskDetailComponent,
        MyTaskDialogComponent,
        MyTaskDocumentsComponent,
        TaskDocumentEditComponent
    ];

const orderComponents =
    [
        OrdersHoldFilter,
        OrderListComponent,
        OrderDetailsComponent,
        OrderProductComponent,
        OrderReportsComponent,
        ShipmentListComponent,
        ShipmentDetailComponent,
        OrderManualEntryComponent,
        OrderProductBulkComponent,
        OrderProductListComponent,
        CommericalInvoiceComponent,
        OrderDocumentListComponent,
        OrderCartCheckReportComponent,
        OrderNoticiationRequestsComponent,
        OrderRemoveProductReportComponent,
        ShipperLetterInstructionsComponent,
        TransactionProductLicenseListComponent
    ];

const productComponents =
    [
        ProductSearches,
        ProductHistoryList,
        ProductURLCheckList,
        ProductEditComponent,
        ProductDialogComponent,
        ProductUploadComponent,
        ProductCountryListComponent,
        ProductOverrideImportHTSEdit,
        ProductOverrideImportHTSList
    ];

const purchaserComponents =
    [
        PurchaserViewComponent,
        PurchaserTermsComponent,
        PurchaserDialogComponent,
        PurchaserAddDialogComponent,
        PurchaserOwnerListComponent,
        PurchaserReviewEditComponent,
        PurchaserAddressListComponent,
        PurchaserContactListComponent,
        PurchaserHistoryListComponent,
        PurchaserOfficerListComponent,
        PurchaserSubCategoryComponent,
        PurchaserDocumentListComponent,
        PurchaserQuestionViewComponent,
        PurchaserAddressVerificationEdit,
        PurchaserAddressVerificationList,
        PurchaserAddressVerificationImage,
        PurchaserAddressVerificationPaste,
        PurchaserAddressVerificationStreet,
        PurchaserOwnerHistoryListComponent,
        PurchaserAddressVerificationCapture,
        PurchaserAddressHistoryListComponent,
        PurchaserContactHistoryListComponent,
        PurchaserOfficerHistoryListComponent
    ];

const customerComponents =
    [
        CustomerMarketingHome,
        CustomerTabsComponent,
        CusteromSaverComponent,
        CustomerMarketingImages,
        CustomerMarketingSearch,
        CustomerSearchComponent,
        MerchantDialogComponent,
        CustomerBillingComponent,
        CustomerMarketingPromosEdit,
        CustomerMarketingPromosList,
        CustomerSearchItemComponent,
        MerchantPurchasersComponent,
        CustomerAccountEditComponent,
        CustomerAccountListComponent,
        CustomerAddressListComponent,
        CustomerContactEditComponent,
        CustomerContactListComponent,
        CustomerDetailsEditComponent,
        CustomerSearchUserStatementComponent
    ];

@NgModule({
    declarations: [
        ...gridComponets,
        ...miscComponent,
        ...orderComponents,
        ...licenseComponents,
        ...productComponents,
        ...customerComponents,
        ...purchaserComponents,
        ...taskAndProfileComponents,
        ...freightForwarderComponents
    ],
    imports: [
        AgGridModule.withComponents([gridComponets]),
        AgmCoreModule.forRoot({ apiKey: environment.goolgeMaps.key }),
        ShowdownModule.forRoot({ emoji: true, flavor: "github" }),
        FormsModule,
        CommonModule,
        NgbModalModule,

        RatingModule.forRoot(),
        NgxMaskModule.forRoot(),
        BlockUIModule.forRoot(),
        CKEditorModule,
        NgxSliderModule,
        ReactiveFormsModule,

        NgbModule,
        NgSelectModule

    ],
    exports: [
        AgGridModule,
        CKEditorModule,

        ...miscComponent,
        ...orderComponents,
        ...licenseComponents,
        ...productComponents,
        ...customerComponents,
        ...purchaserComponents,
        ...taskAndProfileComponents,
        ...freightForwarderComponents
    ],
    providers: [DatePipe, CurrencyPipe, CanDeactivateGuard]
})
export class SharedModule
{
}