import { Component } from "@angular/core";
import { GridFilterCustom } from "@app/shared/ag-grid-helpers/filters/grid-filter-custom";
import { GridFilter, GridFilterStorage } from "@app/shared/ag-grid-helpers/models/grid-filter.model";
import { Util } from "@app/utils/util";
import { AgFilterComponent } from "ag-grid-angular";
import { IDoesFilterPassParams } from "ag-grid-community";

@Component({
    styleUrls: ["../../../styles/gridfilter.scss"],
    templateUrl: "./grid-dropdown-filter.component.html"
})
export class GridDropDownFilter extends GridFilterCustom implements AgFilterComponent
{
    public label: string;
    public itemList: any;
    public idElement: string;
    public selectedID: any;
    public displayElement: string;

    private subObj: string;
    private subUID: string;
    private filterField: string;

    constructor()
    {
        super();
    }

    agInit(params: any): void
    {
        this.initFilter(params);

        this.label = params.label;
        this.itemList = params.items;
        this.idElement = params.idElement;
        this.filterField = params.filterField ?? params.field;
        this.displayElement = params.displayElement;

        if (this.filterField.indexOf(".") == -1)
        {
            this.subObj = null;
            this.subUID = null;
            return;
        }

        let broken = this.filterField.split(".");

        this.subObj = broken[0];
        this.subUID = broken[1];
    }

    isFilterActive(): boolean
    {
        return !Util.isEmpty(this.selectedID);
    }

    doesFilterPass(params: IDoesFilterPassParams): boolean
    {
        if (this.isServerSide)
        {
            return true;
        }

        if (this.subObj == null)
        {
            return this.selectedID == params.data[this.filterField];
        }

        let dude = params.data[this.subObj];

        if (!dude)
        {
            return false;
        }

        return (dude[this.subUID] == this.selectedID);
    }

    getModel()
    {
        if (this.selectedID == null)
        {
            return null;
        }

        return new GridFilterStorage(this.filterField, new GridFilter(this.selectedID));
    }

    setModel(model: any)
    {
        this.selectedID = model?.gridFilter?.filter;
    }

    public onFilterClear()
    {
        this.selectedID = null;
        this.applyAndClose();
    }

    public onFilterApply()
    {
        this.applyAndClose();
    }
}