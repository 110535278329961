import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { AppService } from "@app/app.service";
import { AuthService, BaseComponent } from "@app/data";
import { WikiStatus } from '@app/data/enums/wiki-status.enum';
import { WikiType } from "@app/data/enums/wiki-type.enum";
import { WikiMod } from "@app/data/wiki/wiki.model";
import { WikiService } from "@app/data/wiki/wiki.service";
import { WikiTextComponent } from "@app/shared/misc-components/wiki-renderer/wiki-renderer.component";
import { ToastrService } from "ngx-toastr";

@Component(
    {
        styleUrls: ["../../styles/ee-wiki.scss"],
        templateUrl: "./user-manual.component.html",
        encapsulation: ViewEncapsulation.None
    })
export class UserManualComponent extends BaseComponent<WikiMod> implements OnInit
{
    @ViewChild("wikiElm")
    wikiElm: WikiTextComponent;

    constructor(toastrService: ToastrService,
        private wikiServ: WikiService,
        private appService: AppService,
        private authService: AuthService)
    {
        super(toastrService);

        this.title = "EasyExport User Manual";
        this.apiService = wikiServ;
        this.appService.pageTitle = this.title;
    }

    async ngOnInit()
    {
        let prof = await this.authService.getProfile$().toPromise();

        this.loadManual(prof.profileType);
    }

    private loadManual(profType)
    {
        this.blockOn("Loading...");

        let filt: WikiMod =
        {
            wikiType: WikiType.userManual,
            statusType: WikiStatus.Active,
            profileType: profType
        }

        this.wikiServ.post("detail", filt).subscribe(
            retRes => this.doneDidLoad(retRes),
            errRes => this.error(errRes),
            () => this.blockOff());
    }

    private doneDidLoad(data)
    {
        this.wikiElm.parseWiki(data.wikiText);
    }
}