import { GridCellPartNumberRenderer } from "../renderers/grid-cell-part-number/grid-cell-part-number";
import { GridColumn } from "./grid-column.model";

export class GridColumnPartNumber extends GridColumn
{
    constructor(subField: string = null, header: string = "Part #")
    {
        super("partNumber", header);

        this.width = 175;
        this.minWidth = 100;
        this.cellRendererParams = { field: subField };
        this.cellRendererFramework = GridCellPartNumberRenderer;
    }
}