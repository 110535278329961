import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ApiService } from "../api/api.service";
import { PurchaserAddress } from "./purchaser-address";

@Injectable({
    providedIn: "root"
})
export class PurchaserAddressService extends ApiService<PurchaserAddress>
{
    public get editorForm()
    {
        let form = new FormBuilder();
        let grpFields = form.group(
            {
                uid: [],
                zip: [],
                city: [, Validators.required],
                street1: [, Validators.required],
                street2: [],
                stateUID: [, Validators.required],
                isDefault: [],
                countryUID: [],
                statusType: [],
                addressType: [],
                experianKey: [],
                isExperianAddress: [],
                isOnlyAddressOfThisType: []
            });

        return grpFields;
    }

    constructor(httpClient: HttpClient)
    {
        super(httpClient, "purchaser-address");
    }
}