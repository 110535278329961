import { GridCellImageGroup } from "../renderers/grid-cell-image-group/grid-cell-image-group";
import { GridColumn } from "./grid-column.model";

export class GridColumnImage extends GridColumn
{
    constructor(images: Array<any>, header: string, width: number = 100)
    {
        super(null, header);

        this.width = width;
        this.filter = null;
        this.minWidth = width;
        this.sortable = false;
        this.resizable = false;
        this.suppressSizeToFit = true;
        this.cellRendererParams = { Images: images };
        this.cellRendererFramework = GridCellImageGroup;
    }
}