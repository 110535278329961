import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ApiService } from '../api/api.service';
import { PurchaserOfficerHistory } from './purchaser-officer-history';

@Injectable({
  providedIn: 'root'
})
export class PurchaserOfficerHistoryService extends ApiService<PurchaserOfficerHistory> {

  constructor(httpClient: HttpClient) {
    super(httpClient, 'purchaser-officer-history');
  }

}
