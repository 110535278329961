import { CommonSettings } from '@app/shared/common-settings';
import { Component, OnDestroy, TemplateRef, ViewChild } from "@angular/core";
import { BaseComponent, Customer, CustomerService } from "@app/data";
import { MerchantRequestDTO } from "@app/data/customer/merchant-request.dto";
import { TaskEntity } from '@app/data/enums/task-entity.enum';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { Subscription } from 'rxjs';

@Component(
{
    selector: "merchant-dialog",
    templateUrl: "./merchant-dialog.component.html",
})
export class MerchantDialogComponent extends BaseComponent<Customer> implements OnDestroy
{
    @ViewChild("dialogMerchant")
    dialogMerchant: TemplateRef<any>;

    public licenseName: string;
    public taskEntityType = TaskEntity.License;

    private subQuestion: Subscription;

    constructor(
        toastrService: ToastrService,
        public modalService: NgbModal,
        private custServ: CustomerService)
    {
        super(toastrService);

        this.subQuestion = this.custServ.merchantQuestion.subscribe((request) => { this.handleQuestion(request) });
        this.modalService = modalService;
    }

    ngOnDestroy(): void
    {
        this.subQuestion.unsubscribe();
    }

    public openAdd()
    {
        this.open(this.dialogMerchant, null, CommonSettings.DialogSize_XLarge);
    }

    public openEdit(merchantUID)
    {
        this.open(this.dialogMerchant, { uid: merchantUID }, CommonSettings.DialogSize_XLarge);
    }

    public onCustomerUpdated()
    {
        if (this.update)
        {
            this.update.emit();
        }
    }

    public onClose()
    {
        let quest = new MerchantRequestDTO(MerchantRequestDTO.Request_DirtyClose);

        this.custServ.sendQuestion(quest);
    }

    private handleQuestion(question: MerchantRequestDTO)
    {
        switch (question.requestID)
        {
            case MerchantRequestDTO.Request_Close:
                this.close();
                break;
        }
    }
}