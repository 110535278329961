import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { ShipmentInstruction } from '@app/data/shipment/shipmentinstruction';
import { ApiService } from "../api/api.service";

@Injectable({
    providedIn: "root"
})
export class ShipmentInstructionService extends ApiService<ShipmentInstruction>
{
    public get editorForm()
    {
        let form = new FormBuilder();
        let grpFields = form.group(
            {
                inBondCode: [],
                entryNumber: [],
                paymentType: [],
                packageType: [],
                loadingPier: [],
                billOfLading: [],
                portOfExport: [],
                inlandCarrier: [],
                forwardingAgent: [],
                grossWeightInKG: [],
                isContainerized: [],
                modeOfTransport: [],
                portOfUnloading: [],
                exportingCarrier: [],
                numberOfPackages: [],
                dateOfExportation: [],
                specialInstruction: [],
                shipmentReferenceNumber: [],
                carrierIdentificationCode: [],
                isRoutedExportTransaction: [],
                isShipperInsuranceRequested: [],
                isTransactionPartiesRelated: [],
                transportationReferenceNumber: []
            });

        return grpFields;
    }

    constructor(httpClient: HttpClient)
    {
        super(httpClient, "shipment-instruction");
    }
}