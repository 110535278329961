import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ApiResult, LookupService, ProfileType, PurchaserType } from "@app/data";
import { ApiService } from "@app/data/api/api.service";
import { Lookup } from "@app/data/lookup/lookup";
import { CommonSettings } from '@app/shared/common-settings';
import { Observable } from "rxjs";
import { Faq } from "./faq";

@Injectable({
    providedIn: "root"
})
export class FaqService extends ApiService<Faq>
{
    public get editorForm()
    {
        let form = new FormBuilder();
        let grpFields = form.group(
            {
                uid: [],
                answer: [],
                question: [, Validators.required],
                countryUID: [],
                statusType: [],
                profileType: [],
                purchaserType: []
            });

        return grpFields;
    }

    private readonly Country_Global

    constructor(httpClient: HttpClient, lookupService: LookupService)
    {
        super(httpClient, "faq");
    }

    public getCountries(): Observable<ApiResult<Lookup>>
    {
        return this.httpClient.get<ApiResult<Lookup>>(this.buildUrl("countries"));
    }

    //  This probably isn't the best way, but...
    public getProfiles(countryUID: string): Array<Lookup>
    {
        let profs: Array<Lookup> = [];

        if (countryUID == CommonSettings.USAUID)
        {
            profs.push({ id: ProfileType.customer, name: "Merchant" });
            profs.push({ id: ProfileType.freightForwarder, name: "Freight Forwarder" });

            return profs;
        }

        profs.push({ id: 0, name: "All" });
        profs.push({ id: PurchaserType.Business, name: "Business" });
        profs.push({ id: PurchaserType.Government, name: "Government" });
        profs.push({ id: PurchaserType.Individual, name: "Individual Full" });
        profs.push({ id: 4, name: "Individual Limited" });

        return profs;
    }
}