import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiService } from "../api/api.service";
import { PurchaserAuthListDTO } from "./purchaser-authlist.dto";
import { PurchaserEndUserStatement } from "./purchaser-enduserstatement";

@Injectable({
    providedIn: "root"
})
export class PurchaserEndUserStatementService extends ApiService<PurchaserEndUserStatement>
{
    constructor(httpClient: HttpClient)
    {
        super(httpClient, "purchaser-auth");
    }

    public merchantsSave(data: PurchaserAuthListDTO)
    {
        return this.httpClient.post(this.buildUrl("save-list"), JSON.stringify(data));
    }

    public merchantsDelete(data: PurchaserAuthListDTO)
    {
        return this.httpClient.post(this.buildUrl("delete-list"), JSON.stringify(data));
    }
}