import { AfterViewInit, Component, ElementRef, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AppService } from "@app/app.service";
import { CustomerService, ProductService, ProfileType, PurchaserAddressService, PurchaserAddressType, PurchaserService, Transaction } from "@app/data";
import { OrderStatusType } from "@app/data/enums/order-status-type.enum";
import { ShopOrderProduct } from "@app/data/orders/shop-order-product";
import { ShopOrderStatusService } from "@app/data/orders/shop-order-status.service";
import { ShopOrderService } from "@app/data/orders/shop-order.service";
import { ShopPostMessageService } from "@app/data/orders/shop-post-message.service";
import { GridBase } from "@app/shared/ag-grid-helpers/ag-grid-base";
import { GridHelpers } from "@app/shared/ag-grid-helpers/grid-helper";
import { GridColumnCustom } from "@app/shared/ag-grid-helpers/models/grid-column-custom.model";
import { GridColumnIcon } from "@app/shared/ag-grid-helpers/models/grid-column-icon.model";
import { GridColumn, GridColumnType } from "@app/shared/ag-grid-helpers/models/grid-column.model";
import { CommonSettings } from '@app/shared/common-settings';
import { ConfirmationService, IConfirmationOptions } from "@app/shared/confirmation/confirmation.service";
import { DocumentEditComponent } from "@app/shared/documents/document-edit/document-edit.component";
import { OrderDataHelper } from '@app/shared/orders/order-data.helper';
import { DateFormatter } from '@app/shared/pipes/date-formatter.pipe';
import { ContactFormatter } from "@app/utils/contact-formatter";
import { DateHelper } from '@app/utils/date-helper';
import { Util } from "@app/utils/util";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectComponent } from "@ng-select/ng-select";
import { ToastrService } from "ngx-toastr";
import { OrderProductBulkComponent } from "./order-product-bulk/order-product-bulk.component";
import { OrderProductComponent } from "./order-product/order-product.component";
import { PurchaserAddDialogComponent } from "./purchaser-add-dialog/purchaser-add-dialog.component";

@Component({
    selector: "order-manual-entry",
    styleUrls: ["./order-manual-entry.component.scss"],
    templateUrl: "./order-manual-entry.component.html"
})
export class OrderManualEntryComponent extends GridBase<ShopOrderProduct> implements OnInit, AfterViewInit
{
    @ViewChild("obscure")
    obscure: ElementRef;

    @ViewChild("obEnd")
    obEnd: ElementRef;

    @ViewChild("obStart")
    obStart: ElementRef;

    @ViewChild("dialogDocs")
    dialogDocs: TemplateRef<any>;

    @ViewChild("dialogBulk")
    dialogBulk: OrderProductBulkComponent;

    @ViewChild("dialogPurchaser")
    dialogPurchaser: PurchaserAddDialogComponent;

    @ViewChild("dialogProductEntry")
    dialogProductEntry: OrderProductComponent;

    @ViewChild("selectMerchant")
    selectMerchant: NgSelectComponent;

    @ViewChild("selectPurchaser")
    selectPurchaser: NgSelectComponent;

    @ViewChild("selectAddressBill")
    selectAddressBill: NgSelectComponent;

    @ViewChild("selectAddressShip")
    selectAddressShip: NgSelectComponent;

    public get merchantUID()
    {
        return this.getValue("customerUID");
    }

    public get canAction()
    {
        if (this.blockUI.isActive)
        {
            return true;
        }

        return this.dataForm.valid && this.products?.length > 0;
    }

    public get canAddProduct()
    {
        return this.userRole != this.profileTypes.admin || (this.userRole == this.profileTypes.admin && this.merchantUID);
    }

    public get canChangeDate()
    {
        return (this.userRole == this.profileTypes.admin);
    }

    public get orderTotal()
    {
        let tots = 0;

        this.gridData?.forEach(elm => tots += elm.total);

        return tots;
    }

    public readonly orderState_Create = OrderDataHelper.OrderState_Create;
    public readonly orderState_Validate = OrderDataHelper.OrderState_Validate;

    private readonly OrderLevelPart = "ALL";

    public addDocs: boolean;
    public products = [];
    public userRole: number;
    public purchasers;
    public docEditType = DocumentEditComponent.EditType_Manual;
    public addressBill;
    public addressShip;
    public goodMessage: string;
    public currentState = OrderDataHelper.OrderState_Validate;
    public errorMessage: string;
    public orderDetails: Transaction;
    public missingProduct = false;

    private currentMerch: string = null;

    constructor(toastrService: ToastrService,
        public customerServ: CustomerService,
        public modalService: NgbModal,
        public purchaserServ: PurchaserService,
        private router: Router,
        private prodServ: ProductService,
        private appService: AppService,
        private activeRoute: ActivatedRoute,
        private shopOrderServ: ShopOrderService,
        private confirmService: ConfirmationService,
        private purchaserAddyServ: PurchaserAddressService,
        private shopOrderStatusServ: ShopOrderStatusService,
        private shopPostMessageServ: ShopPostMessageService)
    {
        super(toastrService, shopOrderServ);

        this.title = "New Order";
        this.dataForm = this.shopOrderServ.dataForm;
        this.autoHandleLoading = false;
        this.appService.pageTitle = this.title;

        this.setupGrid();
    }

    ngAfterViewInit(): void
    {
        if (this.userRole != ProfileType.admin)
        {
            return;
        }

        let beg = this.obStart.nativeElement.offsetTop;
        let end = this.obEnd.nativeElement.clientHeight;
        let wid = this.obStart.nativeElement.offsetWidth;
        let hit = (this.obEnd.nativeElement.offsetTop - beg) + end;

        this.obscure.nativeElement.style.top = `${beg}px`;
        this.obscure.nativeElement.style.width = `${wid}px`;
        this.obscure.nativeElement.style.height = `${hit}px`;
    }

    ngOnInit(): void
    {
        this.userRole = this.activeRoute.snapshot.data.userType;
        OrderDataHelper.userRole = this.userRole;

        this.customerServ.getLookup();
        this.purchaserServ.getPurchaserLookup().add(() => this.purchasers = this.purchaserServ.lookupList);

        this.setValue("purchaseDate", DateHelper.makeNGBDate(DateFormatter.Now));

        if (this.userRole != ProfileType.admin)
        {
            this.getProducts();
        }
    }

    public canDeactivate()
    {
        if (this.dataForm.dirty)
        {
            return this.confirmService.YesNo(CommonSettings.DirtyFormConfirmationMessage);
        }

        return true;
    }

    public onGotoOrders(): void
    {
        this.moveToOrders();
    }

    public onPurchaserOpenAdd(): void
    {
        this.dialogPurchaser.openAdd(this.merchantUID);
    }

    public onPurchaserEnter(evtArg): void
    {
        let inText = evtArg.target.value;

        if (!Util.isEmpty(inText) && this.selectPurchaser.itemsList.filteredItems.length == 0)
        {
            this.dialogPurchaser.openWithSearch(this.merchantUID, inText);
            this.selectPurchaser.close();
        }
    }

    public onPurchaserAdd(purchaser): void
    {
        this.purchasers = [...this.purchasers, purchaser];

        let cnt = this.selectPurchaser.itemsList.items.length;

        //  Give the DOM a sec to catch up
        setTimeout(() =>
        {
            this.selectPurchaser.select(this.selectPurchaser.itemsList.items[cnt]);
            this.onPurchaserSelect();
        }, 250);
    }

    public onMerchantChange(evtArg): void
    {
        if (evtArg.uid == this.currentMerch)
        {
            return;
        }

        this.getProducts();

        if (Util.isEmpty(this.products))
        {
            this.currentMerch = evtArg.uid;

            this.resetState();
            return;
        }

        let opts: IConfirmationOptions =
        {
            FunctionClose: () => this.merchantSetCurrent(),
            FunctionConfirm: () =>
            {
                this.currentMerch = evtArg.uid;

                this.resetState(true);
                this.merchantSetCurrent();
            }
        };

        this.confirmService.YesNo("Switching the Merchant will clear all data; continue?", opts);
    }

    public onPurchaserSelect(): void
    {
        let pUID = this.getValue("purchaserUID");

        this.addressBill = [];
        this.addressShip = [];
        this.resetState();

        this.purchaserAddyServ.getList("profile-list", { purchaserUID: pUID }).subscribe((retRes) => this.splitAddress(retRes));
    }

    public onAddressChange(): void
    {
        this.resetState();
    }

    public onProductOpen(): void
    {
        this.dialogProductEntry.openDialog();
    }

    public onBulkOpen(): void
    {
        this.dialogBulk.openDialog(this.merchantUID);
    }

    public onBulkTemplate(): void
    {
        OrderDataHelper.getBulkTemplate();
    }

    public onBulkAddProducts(products): void
    {
        for (let prod of products)
        {
            this.onProductSave(prod);
        }
    }

    public onProductSave(saveProd): void
    {
        this.resetState();

        saveProd.total = saveProd.quantity * saveProd.amount;
        saveProd.issues = [];

        if (saveProd.lineNumber == null)
        {
            let existProd = this.products.find(elm => elm.partNumber == saveProd.partNumber);

            if (existProd == null || existProd.amount != saveProd.amount)
            {
                saveProd.lineNumber = this.products.length + 1;
                this.products.push(saveProd);
            }
            else
            {
                existProd.quantity += saveProd.quantity;

                existProd.total = existProd.amount * existProd.quantity;
                existProd.altDesc = saveProd.altDesc;
                existProd.description = saveProd.description;
            }
        }
        else
        {
            let idx = this.products.findIndex(elm => elm.lineNumber == saveProd.lineNumber);

            this.products[idx] = saveProd;
        }

        this.gridPopulate(this.products);
    }

    public onValidate()
    {
        this.blockOn("Validating...");

        this.missingProduct = Util.isEmpty(this.gridData);

        if (this.invalid() || this.missingProduct)
        {
            this.blockOff();
            return;
        }

        this.resetState(false, false);

        this.setValue("stage", OrderDataHelper.OrderState_Validate);

        this.submit("save-order").add(() => this.saveProducts());
    }

    public onCreate(openDocs: boolean)
    {
        this.blockOn("Creating...");

        this.addDocs = openDocs;
        this.resetState(false, false);

        this.setValue("stage", OrderDataHelper.OrderState_Create);

        this.submit("save-order").add(() => this.saveProducts());
    }

    public onDocumentDone()
    {
        this.close();
        this.moveToOrders();
    }

    private setupGrid()
    {
        let gridIcons =
            [
                GridHelpers.MakeEditIcon(this.onEdit.bind(this)),
                GridHelpers.MakeDeleteIcon(this.onDelete.bind(this))
            ];

        let colQty = new GridColumn("quantity", "Qty", GridColumnType.Number);
        let colDesc = new GridColumn("description", "Part - Description");
        let issueCols = [new GridColumnCustom("message", "Description", { cellRenderer: OrderDataHelper.makeProductMessage })];

        colQty.width = 100;
        colDesc.cellRenderer = "agGroupCellRenderer";

        this.storageKey = "OrderManualEntry";
        this.gridOpts.masterDetail = true;
        this.gridOpts.detailRowHeight = 100;
        this.gridOpts.detailCellRendererParams =
        {
            template: "<div class='sub-grid-wrapper'><div ref='eDetailGrid' class='sub-grid-grid'></div></div>",
            getDetailRowData: function(params)
            {
                params.successCallback(params.data.issues);
            },
            detailGridOptions:
            {
                rowHeight: 36,
                columnDefs: issueCols,
                pagination: false,
                animateRows: true,
                headerHeight: 0,
                paginationPageSize: 50,
                detailRowAutoHeight: true,
                onFirstDataRendered: function (params: any)
                {
                    params.api.resetRowHeights();
                    params.api.sizeColumnsToFit();
                }
            }
        };

        this.addColumn(colDesc);
        this.addColumn(colQty);
        this.addColumn(new GridColumn("amount", "Amount", GridColumnType.Money));
        this.addColumn(new GridColumn("total", "Total", GridColumnType.Money));
        this.addColumn(new GridColumnIcon(gridIcons));

        this.rowDoubleClick(this.onEdit.bind(this));
    }

    private getProducts(): void
    {
        let filt = null;

        if (OrderDataHelper.userRole == this.profileTypes.admin)
        {
            filt = { customerUID: this.merchantUID };
        }

        this.prodServ.getLookup(filt).add(() =>
        {
            this.dialogBulk.availableProducts = this.prodServ.lookupList;
            this.dialogProductEntry.availableProducts = this.prodServ.lookupList;
        });
    }

    private merchantSetCurrent()
    {
        let idx = this.selectMerchant.itemsList.items.findIndex(elm => elm.value["uid"] == this.currentMerch);

        this.selectMerchant.select(this.selectMerchant.itemsList.items[idx]);
    }

    private onEdit(agData)
    {
        this.dialogProductEntry.openDialog(agData);
    }

    private onDelete(agData)
    {
        let opts: IConfirmationOptions =
        {
            FunctionConfirm: () => this.deleteIt(agData.lineNumber)
        };

        this.confirmService.Delete(`Are you sure you want to delete "${agData.description}?"`, opts);
    }

    private deleteIt(lineNum)
    {
        this.resetState();

        let idx = this.products.findIndex(elm => elm.lineNumber == lineNum);

        delete this.products[idx];

        if (this.products.length == 1)
        {
            this.products = [];
            this.gridPopulate(this.products);
            return;
        }

        this.products = Util.reindexArray(this.products);

        for (let lp in this.products)
        {
            this.products[lp].lineNumber = lp + 1;
        }

        this.gridPopulate(this.products);
    }

    private splitAddress(retRes)
    {
        let data = retRes.results;

        if (Util.isEmpty(data))
        {
            this.selectAddressBill.handleClearClick();
            this.selectAddressShip.handleClearClick();
            return;
        }

        let billIdx = 0;
        let shipIdx = 0;
        let contBuild = new ContactFormatter();
        let billDefault = null;
        let shipDefault = null;

        contBuild.delimiter = " ";
        contBuild.includeName = false;

        for (let lp in data)
        {
            contBuild.contactData = data[lp];

            let addy = contBuild.getContact();

            switch (data[lp].addressType)
            {
                case PurchaserAddressType.Billing:
                    if (data[lp].isDefault)
                    {
                        billDefault = data[lp].uid;
                    }

                    this.addressBill = [...this.addressBill, { uid: data[lp].uid, address: addy }];
                    break;

                case PurchaserAddressType.Shipping:
                    if (data[lp].isDefault)
                    {
                        shipDefault = data[lp].uid;
                    }

                    this.addressShip = [...this.addressShip, { uid: data[lp].uid, address: addy }];
                    break;
            }
        }

        if (billDefault != null)
        {
            billIdx = this.addressBill.findIndex(elm => elm.uid == billDefault);
        }

        if (shipDefault != null)
        {
            shipIdx = this.addressShip.findIndex(elm => elm.uid == shipDefault);
        }

        setTimeout(() => this.setAddress(billIdx, shipIdx), 500);
    }

    private setAddress(billIdx, shipIdx)
    {
        if (this.addressBill?.length > 0)
        {
            this.selectAddressBill.select(this.selectAddressBill.itemsList.items[billIdx]);
        }

        if (this.addressShip?.length > 0)
        {
            this.selectAddressShip.select(this.selectAddressShip.itemsList.items[shipIdx]);
        }
    }

    private resetState(fullReset: boolean = false, resetState: boolean = true): void
    {
        if (fullReset)
        {
            this.products = [];
            this.dataForm.reset();

            this.gridPopulate(this.products);
        }
        else
        {
            this.products.forEach(elm => elm.issues = []);
        }

        if (resetState)
        {
            this.currentState = OrderDataHelper.OrderState_Validate;
        }

        this.goodMessage = null;
        this.errorMessage = null;
        this.missingProduct = false;

        this.rowsCollapseAll();
    }

    private saveProducts()
    {
        if (!this.data.uid)
        {
            this.blockOff();
            return;
        }

        let saveProds = [];

        for (let lp in this.products)
        {
            let addMe =
            {
                amount: this.products[lp].amount,
                quantity: this.products[lp].quantity,
                lineNumber: this.products[lp].lineNumber,
                partNumber: this.products[lp].partNumber,
                transactionUID: this.data.uid
            };

            if (this.products[lp].altDescription)
            {
                addMe["customerDescription"] = this.products[lp].altDescription;
            }

            saveProds.push(addMe);
        }

        this.shopOrderStatusServ.postList("save-products", saveProds).subscribe(
            retRes => this.checkOrder(),
            errRes => this.error(errRes));
    }

    private checkOrder()
    {
        let sendIt = { uid: this.data.uid, stage: this.currentState };

        this.shopPostMessageServ.post("status", sendIt).subscribe(
            retRes => this.parseStatus(retRes),
            errRes => this.error(errRes));
    }

    private parseStatus(retRes)
    {
        if (Util.isEmpty(retRes))
        {
            this.blockOff();
            return;
        }

        let mess = retRes.messages;

        for (let dude of mess)
        {
            let prod = this.products.find(elm => elm.partNumber == dude.partNumber);

            if (prod && dude.hasOwnProperty("message"))
            {
                prod.issues.push(dude);
            }
        }

        this.dataForm.markAsDirty();
        this.gridPopulate(this.products);

        for (let lp in this.products)
        {
            let cnt = this.products[lp].issues?.length ?? 0;

            if (cnt > 0)
            {
                this.rowExpand(parseInt(lp));
            }
        }

        if (retRes.statusID == OrderStatusType.Hold ||
            retRes.statusID == OrderStatusType.Active ||
            retRes.statusID == OrderStatusType.Cleared ||
            retRes.statusID == OrderStatusType.PartiallyCleared)
        {
            if (this.currentState == OrderDataHelper.OrderState_Create)
            {
                this.blockOff();

                this.orderDetails = retRes.order;
                this.dataForm.markAsPristine();

                if (this.addDocs)
                {
                    this.open(this.dialogDocs, null, CommonSettings.DialogSize_XLarge);
                }
                else
                {
                    this.moveToOrders();
                }
                return;
            }

            if (retRes.messages[0].partNumber == this.OrderLevelPart)
            {
                this.goodMessage = OrderDataHelper.makeProductMessage(retRes.messages[0]);
            }

            this.currentState = OrderDataHelper.OrderState_Create;
            this.blockOff();
            return;
        }

        if (retRes.messages[0].partNumber == this.OrderLevelPart)
        {
            this.errorMessage = OrderDataHelper.makeProductMessage(retRes.messages[0]);
        }

        this.blockOff();
    }

    private moveToOrders()
    {
        let nav: string;
        let url;

        switch (OrderDataHelper.userRole)
        {
            case ProfileType.admin:
                nav = "/admin";
                break;

            case ProfileType.customer:
                nav = "/customer";
                break;
        }

        if (this.orderDetails)
        {
            url = [`/${nav}/order-details`, this.orderDetails.uid]
        }
        else
        {
            url = [`/${nav}/orders`];
        }

        this.router.navigate(url);
    }
}