import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ApiService } from '../api/api.service';
import { PurchaserOwnerHistory } from './purchaser-owner-history';

@Injectable({
  providedIn: 'root'
})
export class PurchaserOwnerHistoryService extends ApiService<PurchaserOwnerHistory> {

  constructor(httpClient: HttpClient) {
    super(httpClient, 'purchaser-owner-history');
  }

}
