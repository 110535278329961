import { Component, OnDestroy, TemplateRef, ViewChild } from "@angular/core";
import { BaseComponent, Freight, FreightService } from "@app/data";
import { FreightForwarderType } from "@app/data/enums/freight-forwarder-type.enum";
import { FreightForwarderRequestDTO } from "@app/data/freight/freight-forwarder-request.dto";
import { CommonSettings } from "@app/shared/common-settings";
import { FreightForwarderCommon } from "@app/shared/freight-forwarder/freight-forwarder-common";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";

@Component({
    selector: "freight-forwarder-dialog",
    templateUrl: "./freight-forwarder-dialog.component.html"
})
export class FreightForwarderDialogComponent extends BaseComponent<Freight> implements OnDestroy
{
    @ViewChild("dialogFreight")
    dialogFreight: TemplateRef<any>;

    public title: string;
    public showSave: boolean = true;
    public recordType: number;

    private subAns: Subscription;
    private closing: boolean;
    private subQues: Subscription;

    constructor(toastrService: ToastrService,
        public modalService: NgbModal,
        private freightServ: FreightService)
    {
        super(toastrService);

        this.subAns = this.freightServ.freightAnswer.subscribe((req) => { this.handleAnswers(req) });
        this.subQues = this.freightServ.freightQuestion.subscribe((req) => { this.handleQuestion(req) });
        this.apiService = freightServ;
        this.modalService = modalService;
    }

    ngOnDestroy()
    {
        this.subAns.unsubscribe();
        this.subQues.unsubscribe();
    }

    public openAdd(recordType)
    {
        this.data = null;
        this.readOnly = false;
        this.recordType = recordType;

        this.setupUI();
    }

    public openEdit(freight: Freight, readonly = false)
    {
        this.data = freight;
        this.readOnly = readonly;

        if (freight.countryUID == CommonSettings.USAUID)
        {
            this.recordType = FreightForwarderType.FreightFordwarder;
        }
        else
        {
            this.recordType = FreightForwarderType.CustomersBroker;
        }

        this.setupUI();
    }

    public onClose()
    {
        this.freightServ.sendQuestion(new FreightForwarderRequestDTO(FreightForwarderRequestDTO.FreightRequest_DirtyClose));
    }

    public onSave(closeMe: boolean)
    {
        this.loading = true;
        this.closing = closeMe;

        this.freightServ.sendQuestion(new FreightForwarderRequestDTO(FreightForwarderRequestDTO.FreightRequest_Save));
    }

    private setupUI()
    {
        this.title = FreightForwarderCommon.getDescription(this.recordType);
        this.closing = false;
        this.showSave = true;

        if (this.recordType == FreightForwarderType.FreightFordwarder)
        {
            this.title = "Freight Forwarder";

            if (this.data == null)
            {
                this.data = { countryUID: CommonSettings.USAUID };
            }
        }

        this.open(this.dialogFreight, this.data, CommonSettings.DialogSize_XLarge);
    }

    private handleAnswers(answer: FreightForwarderRequestDTO)
    {
        switch (answer.taskID)
        {
            case FreightForwarderRequestDTO.FreightRequest_DirtyClose:
                this.handleDirty(answer.data);
                break;

            case FreightForwarderRequestDTO.FreightRequest_Save:
                this.saveComplete(answer);
                break;

            case FreightForwarderRequestDTO.FreightRequest_TabChanged:
                this.showSave = (answer.data == this.freightServ.Tab_Detail);
                break;
        }
    }

    private handleDirty(moveOn)
    {
        if (moveOn)
        {
            this.close();
        }
    }

    private handleQuestion(request: FreightForwarderRequestDTO)
    {
        switch (request.taskID)
        {
        }
    }

    private saveComplete(answer: FreightForwarderRequestDTO)
    {
        this.loading = false;

        if (answer.data == null)
        {
            return;
        }

        if (this.update)
        {
            this.update.emit();
        }

        if (this.closing)
        {
            this.close();
        }
    }
}