import { Component, ElementRef, ViewChild } from "@angular/core";
import { HistoryListCommon } from "@app/admin/history-list-common/history-list-common";
import { PurchaserAddressHistory, PurchaserAddressHistoryService } from "@app/data";
import { CommonSettings } from "@app/shared/common-settings";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: "purchaser-address-history-list",
    templateUrl: "./purchaser-address-history-list.component.html"
})
export class PurchaserAddressHistoryListComponent extends HistoryListCommon<PurchaserAddressHistory>
{
    @ViewChild("dialogHistory")
    dialogHistory: ElementRef;

    constructor(
        toastrService: ToastrService,
        public modalService: NgbModal,
        private purchaserAddressHistoryService: PurchaserAddressHistoryService)
    {
        super(toastrService, purchaserAddressHistoryService);

        this.modalService = this.modalService;

        this.setupGrid("Purchaser-Address-History");
    }

    public openHistory(addyUID: string): void
    {
        this.open(this.dialogHistory, null, CommonSettings.DialogSize_XLarge);

        this.getHistory({ purchaserAddressUID: addyUID });
    }

    public onClose(): void
    {
        this.close();
    }
}