import { GridPurchaserFilter } from "../filters/grid-purchaser-filter/grid-purchaser-filter.component";
import { GridCellPurchaserRenderer } from "../renderers/grid-cell-purchaser/grid-cell-purchaser";
import { GridColumn } from "./grid-column.model";

export class PurchaserCellOptions
{
    public showType: boolean = true;
}

export class GridColumnPurchaser extends GridColumn
{
    constructor(renderField: string, filterField: string, opts: PurchaserCellOptions = new PurchaserCellOptions())
    {
        super(renderField, "Purchaser");

        this.width = 225;
        this.filter = "gridPurchaserFilter";
        this.filterParams = { field: filterField };
        this.suppressSizeToFit = true;
        this.cellRendererParams = { options: opts };
        this.cellRendererFramework = GridCellPurchaserRenderer;

        this.registerComponents.push({ gridPurchaserFilter: GridPurchaserFilter });
    }
}