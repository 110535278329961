import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild } from "@angular/core";
import { ProfileType, Transaction, TransactionService } from "@app/data";
import { ShipmentStatusType } from "@app/data/enums/shipment-status-type.enum";
import { Shipment } from "@app/data/shipment/shipment";
import { ShipmentCommon } from "@app/data/shipment/shipment-common";
import { ShipmentService } from "@app/data/shipment/shipment.service";
import { GridBase } from "@app/shared/ag-grid-helpers/ag-grid-base";
import { GridHelpers } from "@app/shared/ag-grid-helpers/grid-helper";
import { GridCellBoolean } from "@app/shared/ag-grid-helpers/models/grid-cell-boolean.model";
import { GridColumnBoolean } from "@app/shared/ag-grid-helpers/models/grid-column-boolean";
import { GridColumnCustom } from "@app/shared/ag-grid-helpers/models/grid-column-custom.model";
import { GridColumnIcon } from "@app/shared/ag-grid-helpers/models/grid-column-icon.model";
import { GridColumnStatus } from "@app/shared/ag-grid-helpers/models/grid-column-status";
import { GridColumn, GridColumnType } from "@app/shared/ag-grid-helpers/models/grid-column.model";
import { ConfirmationService, IConfirmationOptions } from "@app/shared/confirmation/confirmation.service";
import { OrderDataHelper } from '@app/shared/orders/order-data.helper';
import { Util } from "@app/utils/util";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { CommericalInvoiceComponent } from "../commercial-invoice/commercial-invoice";
import { ShipmentDetailComponent } from "../shipment-detail/shipment-detail.component";
import { ShipperLetterInstructionsComponent } from "../shipper-letter-of-instruction/shipper-letter-of-instruction.component";

@Component({
    selector: "order-shipment-list",
    templateUrl: "./shipment-list.component.html"
})
export class ShipmentListComponent extends GridBase<Shipment> implements OnInit, AfterViewInit
{
    @ViewChild("dialogSLI")
    dialogSLI: ShipperLetterInstructionsComponent;

    @ViewChild("dialogInvoice")
    dialogInvoice: CommericalInvoiceComponent;

    @ViewChild("dialogShipment")
    dialogShipment: ShipmentDetailComponent;

    @Output()
    shipmentListReady = new EventEmitter();

    public readonly trackingURL = "https://parcelsapp.com/en/tracking/";

    public canShip: boolean;
    public userRole: number = OrderDataHelper.userRole;
    public orderDetails: Transaction;

    constructor(
        toastrService: ToastrService,
        public modalService: NgbModal,
        private confirmService: ConfirmationService,
        private shipmentService: ShipmentService,
        private transactionServ: TransactionService)
    {
        super(toastrService, shipmentService);

        this.modalService = modalService;

        this.setupGrid();
    }

    ngOnInit()
    {
        this.canShip = OrderDataHelper.CanShip(this.data);
        this.orderDetails = this.data;

        this.filterAdd("transactionUID", this.data.uid);
    }

    ngAfterViewInit(): void
    {
        if (this.shipmentListReady)
        {
            this.shipmentListReady.emit("Required!?");
        }
    }

    public onAdd()
    {
        this.dialogShipment.showType = ShipmentDetailComponent.ShowType_New;
        this.dialogShipment.openAdd();
    }

    public onUpdateShipment()
    {
        this.close();
        this.gridRefresh();
        this.updateOrder();
    }

    private setupGrid()
    {
        let bools = new GridCellBoolean(GridCellBoolean.DefaultYesText, GridCellBoolean.DefaultNoText);
        let gridIcons =
            [
                GridHelpers.MakeViewIcon(this.onDetails.bind(this)),
                { icon: this.Icons.PDF, toolTip: "Generate Shipper's Letter of Instruction", onClick: this.onShipperInstructions.bind(this) },
                { icon: this.Icons.Billing, toolTip: "Commerical Invoice", onClick: this.onCommericalInvoice.bind(this) }
            ];

        if (this.userRole != ProfileType.freightForwarder)
        {
            gridIcons.push(GridHelpers.MakeDeleteIcon(this.onDelete.bind(this), this.onCanDelete.bind(this)));
        }

        this.storageKey = "ShipmentList";
        this.addColumn(new GridColumnStatus(ShipmentCommon.ShipmentStatuses));
        this.addColumn(new GridColumn("shipmentNumber", "Ship #"));
        this.addColumn(new GridColumn("shipmentDate", "Ship Date", GridColumnType.Date));
        this.addColumn(new GridColumn("freight.companyName", "Shipped by"));
        this.addColumn(new GridColumnCustom("trackingNumber", "Tracking", { cellRenderer: this.renderTracking.bind(this) }));
        this.addColumn(new GridColumn("aesInternalTransactionNumber", "ITN"));
        this.addColumn(new GridColumn("shippingInstructions", "Shipping Instructions"));
        this.addColumn(new GridColumnBoolean("isAESRequired", "AES", bools));
        this.addColumn(new GridColumnIcon(gridIcons));

        this.rowDoubleClick(this.onDetails.bind(this));
    }

    private renderTracking(gridPrams)
    {
        if (Util.isEmpty(gridPrams.data.trackingNumber))
        {
            return Util.DOM_Space;
        }

        let num = gridPrams.data.trackingNumber;

        return Util.substitute("<a href='{0}{1}' title='Package tracking' target='_blank'>{1}</a>", this.trackingURL, num);
    }

    private onDetails(agData)
    {
        if (agData.statusType == ShipmentStatusType.Complete)
        {
            this.dialogShipment.showType = ShipmentDetailComponent.ShowType_View;
        }
        else
        {
            this.dialogShipment.showType = ShipmentDetailComponent.ShowType_Edit;
        }

        this.dialogShipment.openEdit(agData);
    }

    private onCanDelete(agData)
    {
        return agData.statusType != ShipmentStatusType.Complete;
    }

    private onCommericalInvoice(agData): void
    {
        this.dialogInvoice.openInvoice(agData);
    }

    private onShipperInstructions(agData)
    {
        this.dialogSLI.openSLI(agData);
    }

    private onDelete(agData)
    {
        this.dataForm = this.makeForm(agData);

        let opts: IConfirmationOptions =
        {
            FunctionConfirm: () => this.deleteIt()
        };

        this.confirmService.Delete(`Are you sure you want to delete #${agData.shipmentNumber}?`, opts);
    }

    private deleteIt()
    {
        this.delete().add(() =>
        {
            this.gridRefresh();
            this.updateOrder();
        });
    }

    private updateOrder()
    {
        this.transactionServ.detail({ uid: this.orderDetails.uid }).subscribe(
            retRes =>
            {
                this.data = retRes;
                this.canShip = OrderDataHelper.CanShip(retRes);
                this.orderDetails = retRes;

                if (this.update)
                {
                    this.update.emit(retRes);
                }
            },
            errRes => this.error(errRes));
    }
}