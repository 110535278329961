import { IAfterGuiAttachedParams } from "@ag-grid-enterprise/all-modules";
import { Component } from "@angular/core";
import { GridCellImage } from "@app/shared/ag-grid-helpers/models/grid-cell-image.model";
import { GlobalIcons } from "@app/shared/GlobalIcons";
import { Util } from "@app/utils/util";
import { AgRendererComponent } from "ag-grid-angular";
import cloneDeep from "lodash-es/cloneDeep";

@Component(
    {
        styleUrls: ["./grid-cell-image-group.scss"],
        templateUrl: "./grid-cell-image-group.html"
    })
export class GridCellImageGroup implements AgRendererComponent
{
    public readonly Icons = GlobalIcons;

    public imageList: Array<GridCellImage>;
    public currentImage: GridCellImage;

    private rowData;

    agInit(params): void
    {
        this.rowData = params.data;

        let img = params.Images;

        if (Util.isEmpty(img))
        {
            return;
        }

        this.imageList = [];

        for (let dude of img)
        {
            if (dude.imageField.indexOf("|") == -1)
            {
                this.addImage(dude);
                continue;
            }

            let broken = img.imageField.split("|");

            for (let addMe of broken)
            {
                this.addImage(new GridCellImage(addMe));
            }
        }
    }

    refresh(params): boolean
    {
        this.rowData = params.data;
        return true;
    }

    afterGuiAttached?(params?: IAfterGuiAttachedParams): void
    {
    }

    public onImageShow(img: GridCellImage): void
    {
        this.currentImage = img;
    }

    public onImageClick()
    {
        if (this.currentImage.onClick)
        {
            this.currentImage.onClick(this.currentImage);
        }
        else
        {
            window.open(this.currentImage.imageURL, "_blank");
        }
    }

    private addImage(imgCell: GridCellImage): void
    {
        //  Not sure why this is happening, but if we don't clone it, all cells will have the same data!!!???
        let copyImg = cloneDeep(imgCell);

        if (copyImg.imageField.indexOf(".") == -1)
        {
            copyImg.imageURL = this.rowData[copyImg.imageField];

            if (!Util.isEmpty(copyImg.imageURL))
            {
                this.imageList.push(copyImg);
            }
            return;
        }

        let bro = copyImg.imageField.split(".");
        let pri = bro[0];
        let sec = bro[1];

        copyImg.imageURL = this.rowData[pri][sec];

        if (!Util.isEmpty(copyImg.imageURL))
        {
            this.imageList.push(copyImg);
        }
    }
}