import { Util } from "@app/utils/util";

export class LicenseCommon
{
    public static readonly qaData_Save = 1;
    public static readonly qaData_Dirty = 2;
    public static readonly qaData_Update = 3;
    public static readonly qaData_Release = 4;

    public static LicenseTypes =
        [
            { id: 1, name: "EAR" },
            { id: 2, name: "ITAR" }
        ];

    public static LicenseStatuses =
        [
            { id: 1, name: "Active" },
            { id: 2, name: "Pending" },
            { id: 4, name: "Complete" },
            { id: 7, name: "New" },
            { id: 9, name: "Expired" },
            { id: 10, name: "Pending Review" }
        ];

    public static getLicenseName(licenseType: number): string
    {
        let dude = LicenseCommon.LicenseTypes.find(elm => elm.id == licenseType);

        if (Util.isEmpty(dude))
        {
            return "";
        }

        return dude.name;
    }
}