import { GridColumn } from "@app/shared/ag-grid-helpers/models/grid-column.model";
import { OrdersHoldFilter } from "../hold-filter/hold-filter.component";
import { GridCellOrderHoldsRenderer } from "./grid-cell-holds.renderer";

export class GridColumnOrderHolds extends GridColumn
{
    constructor(width: number = 150)
    {
        super(null, "Holds");

        this.field = "OrderHolds";
        this.width = width;
        this.filter = "ordersHoldFilter";
        this.sortable = false;
        this.resizable = false;
        this.suppressSizeToFit = true;
        this.cellRendererFramework = GridCellOrderHoldsRenderer;

        this.registerComponents.push({ ordersHoldFilter: OrdersHoldFilter });
    }
}