import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { BaseComponent, PurchaserQuestion, PurchaserQuestionService, PurchaserType } from "@app/data";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: "purchaser-question-view",
    styleUrls: ["./purchaser-question-view.component.scss"],
    templateUrl: "./purchaser-question-view.component.html"
})
export class PurchaserQuestionViewComponent extends BaseComponent<PurchaserQuestion> implements OnInit
{
    @Input()
    purchaserType: number;

    public readonly purchaserTypes = PurchaserType;

    public questions: any[];

    constructor(
        toastrService: ToastrService,
        private formBuilder: FormBuilder,
        private purchaserQuestionService: PurchaserQuestionService)
    {
        super(toastrService);

        this.apiService = purchaserQuestionService;
    }

    ngOnInit(): void
    {
        //questions
        if (this.purchaserType === PurchaserType.Individual)
        {
            this.questions = [
                { question: "1. Have you ever been in legal trouble in any country for violating firearm ownership, sale, import or export regulations?" },
                { question: "2. I understand that if I buy firearms, firearm parts, rifle scopes, sound suppressors, or firearm accessories from an EasyExport merchant, I am not permitted to transfer them to anyone else, even to a family member or friend. I will be buying them for my own use.  Please click “Confirmed” to show that you understand and agree with this." },
                { question: "3. I confirm that if I buy firearms, firearm parts, rifle scopes, sound suppressors, or firearm accessories from an EasyExport merchant, I will be buying them for use in my country and not for use in a different country.  Please click “Confirmed” to show that you understand and agree with this." },
            ];
        }
        else if (this.purchaserType === PurchaserType.Business)
        {
            this.questions = [
                { question: "1. Describe your business." },
                { question: "2. Are all your customers located in the same country as your primary business address?" },
                { question: "3. List the types of products this business sells." },
                { question: "4. Has this business or any owner or officer ever been the subject of legal proceedings to deny it the ability to import or export any product?" },
                { question: "5. Has this business ever imported products from the United States? Please note that we do not require that you have previously imported U.S. products. We use this information as part of our evaluation of your request to create a purchaser account." },
                { question: "6. We understand that if we buy firearms, firearm parts, rifle scopes, or firearm accessories from an EasyExport merchant, we are not permitted to sell or transfer them to anyone outside the country to which they are shipped.  Please click “Confirmed” to show that you understand and agree with this." },
                { question: "7. Please click “Confirmed” below to confirm that you will not sell or transfer products to anyone who plans to take or send them to a different country." },
            ];
        }
        else if (this.purchaserType === PurchaserType.Government)
        {
            this.questions = [
                { question: "1. Please describe the mission of this agency." },
                { question: "2. Does your agency expect to purchase firearms, firearm parts, rifle scopes, sound suppressors, or firearm accessories from EasyExport merchants?" },
                { question: "3. We understand that if we buy firearms, firearm parts, rifle scopes, sound suppressors, or firearm accessories from an EasyExport merchant, we are not permitted to sell or transfer them to anyone without the prior approval of the U.S. Government.  Please click “Confirmed” to show that you understand and agree with this." },
            ];
        }

        super.detail({ purchaserUID: this.parentUID });
    }
}