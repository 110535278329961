import { GridUserFilter } from "../filters/grid-user-filter/grid-user-filter.component";
import { GridColumn } from "./grid-column.model";

export class GridColumnUser extends GridColumn
{
    constructor(renderField: string, filterField: string, useUID: boolean = true,  header: string = "Assigned to")
    {
        super(renderField, header);

        this.filter = "gridUserFilter";
        this.maxWidth = 300;
        this.filterParams = { field: filterField, useUID: useUID };

        this.registerComponents.push({ gridUserFilter: GridUserFilter });
    }
}