import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CommonSettings } from "@app/shared/common-settings";
import { Observable, Subscription } from "rxjs";
import { LookupType } from "..";
import { ApiResult } from "../api/api-result";
import { ApiService } from "../api/api.service";
import { Lookup } from "./lookup";

@Injectable({
    providedIn: "root"
})
export class LookupService extends ApiService<Lookup>
{
    countryList: Lookup[];
    exportTypes: Lookup[];
    languageList: Lookup[];
    timezoneList: Lookup[];
    billingUOMList: Lookup[];
    shippingMethod: Lookup[];
    countryStateList: Lookup[];
    currencyCodeList: Lookup[];
    taskCategoryList: Lookup[];
    productActionList: Lookup[];
    merchantSourceList: Lookup[];
    productCaliberList: Lookup[];
    productCategoryList: Lookup[];
    usaCountryStateList: Lookup[];
    billingFrequencyList: Lookup[];
    purchaserCountryList: Lookup[];
    taskDocumentTypeList: Lookup[];
    billingChargeTypeList: Lookup[];
    productAmmunitionList: Lookup[];
    productSellerCategory: Lookup[];
    purchaserDocumentType: Lookup[];
    transactionActionList: Lookup[];
    productSubCategoryList: Lookup[];
    billingRateConstantList: Lookup[];
    profileCountryStateList: Lookup[];
    customerAddressTypeList: Lookup[];
    customerContactTypeList: Lookup[];
    customerAccountTypeList: Lookup[];
    transactionDocumentType: Lookup[];
    productSellerSubCategory: Lookup[];
    purchaserContactTypeList: Lookup[];
    purchaserSubCategoryType: Lookup[];
    transactionDocumentTypeList: Lookup[];
    productExportClassificationList: Lookup[];
    productImportClassificationList: Lookup[];

    constructor(httpClient: HttpClient)
    {
        super(httpClient, "lookup");
    }

    public getByType(lookupType: number)
    {
        return this.getList("list", { lookupType });
    }

    public getExportTypes(): Subscription
    {
        if (this.exportTypes)
        {
            let sub = new Observable(obby => obby.complete());

            return sub.subscribe();
        }

        let sub = this.getList("list", { lookupType: LookupType.ExportType }).subscribe(retRes => this.exportTypes = retRes.results);

        return sub;
    }

    public getCountryList(): Subscription
    {
        let sub = this.getList("country").subscribe(
            retRes =>
            {
                this.countryList = retRes.results;
            });

        return sub;
    }

    public getPurchaserCountry(): Subscription
    {
        if (this.purchaserCountryList)
        {
            let sub = new Observable(obby => obby.complete());

            return sub.subscribe();
        }

        let obby = this.getList("purchaser-country").subscribe(
            retRes =>
            {
                this.purchaserCountryList = retRes.results;
            });

        return obby;
    }

    public getCountryStateList(uid: string): Subscription
    {
        if (this.countryStateList)
        {
            let sub = new Observable(obby => obby.complete());

            return sub.subscribe();
        }

        let sub = this.getList("country-state", { uid }).subscribe(
            retRes =>
            {
                this.countryStateList = retRes.results;
            });

        return sub;
    }

    public getUSACountryStateList(): Subscription
    {
        if (this.usaCountryStateList)
        {
            let sub = new Observable(obby => obby.complete());

            return sub.subscribe();
        }

        let sub = this.getList("country-state", { uid: CommonSettings.USAUID }).subscribe(
            retRes =>
            {
                this.usaCountryStateList = retRes.results;
            });

        return sub;
    }

    public getProfileCountryStateList(): Subscription
    {
        if (this.profileCountryStateList)
        {
            let sub = new Observable(obby => obby.complete());

            return sub.subscribe();
        }

        let sub = this.getList("profile-country-state").subscribe(
            retRes =>
            {
                this.profileCountryStateList = retRes.results;
            });

        return sub;
    }

    public signupPurchaserCountry(): Observable<ApiResult<Lookup>>
    {
        return this.getList("signup-purchaser-country");
    }

    public signupLanguage(): Observable<ApiResult<Lookup>>
    {
        return this.getList("signup-language");
    }

    public signupTimezone(): Observable<ApiResult<Lookup>>
    {
        return this.getList("signup-timezone");
    }

    public getLanguageList(): Subscription
    {
        if (this.languageList)
        {
            let sub = new Observable(obby => obby.complete());

            return sub.subscribe();
        }

        let sub = this.getList("language").subscribe(
            retRes =>
            {
                this.languageList = retRes.results;
            });

        return sub;
    }

    public getTimezoneList(): Subscription
    {
        if (this.timezoneList)
        {
            let sub = new Observable(obby => obby.complete());

            return sub.subscribe();
        }

        let sub = this.getList("timezone").subscribe(
            retRes =>
            {
                this.timezoneList = retRes.results;
            });

        return sub;
    }

    public getShippingMethodList(): Subscription
    {
        if (this.shippingMethod)
        {
            let sub = new Observable(obby => obby.complete());

            return sub.subscribe();
        }

        let sub = this.getList("shipping-method").subscribe(
            retRes =>
            {
                this.shippingMethod = retRes.results;
            });

        return sub;
    }

    public getCustomerAddressTypeList(): Subscription
    {
        if (this.customerAddressTypeList)
        {
            let sub = new Observable(obby => obby.complete());

            return sub.subscribe();
        }

        let sub = this.getList("customer-address-type").subscribe(retRes => this.customerAddressTypeList = retRes.results);

        return sub;
    }

    public getCustomerContactTypeList(): Subscription
    {
        if (this.customerContactTypeList)
        {
            let sub = new Observable(obby => obby.complete());

            return sub.subscribe();
        }

        let sub = this.getList("customer-contact-type").subscribe(retRes => this.customerContactTypeList = retRes.results);

        return sub;
    }

    public getCustomerAccountTypeList(): Subscription
    {
        if (this.customerAccountTypeList)
        {
            let sub = new Observable(obby => obby.complete());

            return sub.subscribe();
        }

        let sub = this.getList("customer-account-type").subscribe(retRes => this.customerAccountTypeList = retRes.results);

        return sub;
    }

    public getPurchaserDocumentTypeList(): Subscription
    {
        if (this.purchaserDocumentType)
        {
            let sub = new Observable(obby => obby.complete());

            return sub.subscribe();
        }

        let sub = this.getList("purchaser-document-type").subscribe(retRes => this.purchaserDocumentType = retRes.results);

        return sub;
    }

    public getPurchaserSubCategoryTypeList(): Subscription
    {
        if (this.purchaserSubCategoryType)
        {
            let sub = new Observable(obby => obby.complete());

            return sub.subscribe();
        }

        let sub = this.getList("purchaser-sub-category-type").subscribe(retRes => this.purchaserSubCategoryType = retRes.results);

        return sub;
    }

    public getProductCategoryList(): Subscription
    {
        if (this.productCategoryList)
        {
            let sub = new Observable(obby => obby.complete());

            return sub.subscribe();
        }

        let sub = this.getList("product-category").subscribe(retRes => this.productCategoryList = retRes.results);

        return sub;
    }

    public getProductSubCategoryList(parentUID: string): Subscription
    {
        if (this.productSubCategoryList)
        {
            let sub = new Observable(obby => obby.complete());

            return sub.subscribe();
        }

        let sub = this.getList("product-subcategory", { parentLookupUID: parentUID }).subscribe(retRes => this.productSubCategoryList = retRes.results);

        return sub;
    }

    public getProductActionList(): Subscription
    {
        if (this.productActionList)
        {
            let sub = new Observable(obby => obby.complete());

            return sub.subscribe();
        }

        let sub = this.getList("product-action").subscribe(retRes => this.productActionList = retRes.results);

        return sub;
    }

    public getProductCaliberList(): Subscription
    {
        if (this.productCaliberList)
        {
            let sub = new Observable(obby => obby.complete());

            return sub.subscribe();
        }

        let sub = this.getList("product-caliber").subscribe(retRes => this.productCaliberList = retRes.results);

        return sub;
    }

    public getProductAmmunitionList(): Subscription
    {
        if (this.productAmmunitionList)
        {
            let sub = new Observable(obby => obby.complete());

            return sub.subscribe();
        }

        let sub = this.getList("product-ammunition").subscribe(retRes => this.productAmmunitionList = retRes.results);

        return sub;
    }

    public getProductExportClassificationList(): Subscription
    {
        if (this.productExportClassificationList)
        {
            let sub = new Observable(obby => obby.complete());

            return sub.subscribe();
        }

        let sub = this.getList("product-export-classification").subscribe(retRes => this.productExportClassificationList = retRes.results);

        return sub;
    }

    public getProductImportClassificationList(): Subscription
    {
        if (this.productImportClassificationList)
        {
            let sub = new Observable(obby => obby.complete());

            return sub.subscribe();
        }

        let sub = this.getList("product-import-classification").subscribe(retRes => this.productImportClassificationList = retRes.results);

        return sub;
    }

    public getTransactionActionList(): Subscription
    {
        if (this.transactionActionList)
        {
            let sub = new Observable(obby => obby.complete());

            return sub.subscribe();
        }

        let sub = this.getList("transaction-action").subscribe(retRes => this.transactionActionList = retRes.results);

        return sub;
    }

    public getPurchaserContactTypeList(): Subscription
    {
        if (this.purchaserContactTypeList)
        {
            let sub = new Observable(obby => obby.complete());

            return sub.subscribe();
        }

        let sub = this.getList("purchaser-contact-type").subscribe(retRes => this.purchaserContactTypeList = retRes.results);

        return sub;
    }

    public getBillingChargeTypeList(): Subscription
    {
        if (this.billingChargeTypeList)
        {
            let sub = new Observable(obby => obby.complete());

            return sub.subscribe();
        }

        let sub = this.getList("billing-charge-type").subscribe(retRes => this.billingChargeTypeList = retRes.results);

        return sub;
    }

    public getBillingFrequencyList(): Subscription
    {
        if (this.billingFrequencyList)
        {
            let sub = new Observable(obby => obby.complete());

            return sub.subscribe();
        }

        let sub = this.getList("billing-frequency").subscribe(retRes => this.billingFrequencyList = retRes.results);

        return sub;
    }

    public getBillingUOMList(): Subscription
    {
        if (this.billingUOMList)
        {
            let sub = new Observable(obby => obby.complete());

            return sub.subscribe();
        }

        let sub = this.getList("billing-uom").subscribe(retRes => this.billingUOMList = retRes.results);

        return sub;
    }

    public getBillingRateConstantList(): Subscription
    {
        if (this.billingRateConstantList)
        {
            let sub = new Observable(obby => obby.complete());

            return sub.subscribe();
        }

        let sub = this.getList("billing-rate-constant").subscribe(retRes => this.billingRateConstantList = retRes.results);

        return sub;
    }

    public getTransactionDocumentTypeList(): Subscription
    {
        if (this.transactionDocumentType)
        {
            let sub = new Observable(obby => obby.complete());

            return sub.subscribe();
        }

        let sub = this.getList("transaction-document-type").subscribe(retRes => this.transactionDocumentType = retRes.results);

        return sub;
    }

    public getTaskCategoryList(): Subscription
    {
        if (this.taskCategoryList)
        {
            let sub = new Observable(obby => obby.complete());

            return sub.subscribe();
        }

        let sub = this.getList("task-category").subscribe(retRes => this.taskCategoryList = retRes.results);

        return sub;
    }

    public getTaskDocumentTypeList(): Subscription
    {
        if (this.taskDocumentTypeList)
        {
            let sub = new Observable(obby => obby.complete());

            return sub.subscribe();
        }

        let sub = this.getList("task-document-types").subscribe(retRes => this.taskDocumentTypeList = retRes.results);

        return sub;
    }

    public getMerchantSourceList(): Subscription
    {
        if (this.merchantSourceList)
        {
            let sub = new Observable(obby => obby.complete());

            return sub.subscribe();
        }

        let sub = this.getList("merchant-source").subscribe(retRes => this.merchantSourceList = retRes.results);

        return sub;
    }

    public getCurrencyCodeList(): Subscription
    {
        if (this.currencyCodeList)
        {
            let sub = new Observable(obby => obby.complete());

            return sub.subscribe();
        }

        let sub = this.getList("currency-code").subscribe(retRes => this.currencyCodeList = retRes.results);

        return sub;
    }

    public getProductSellerCategory(): Subscription
    {
        if (this.productSellerCategory)
        {
            let sub = new Observable(obby => obby.complete());

            return sub.subscribe();
        }

        let sub = this.getList("product-seller-category").subscribe(retRes => this.productSellerCategory = retRes.results);

        return sub;
    }

    public getProductSellerSubCategory(parentUID: string): Subscription
    {
        if (this.productSellerSubCategory)
        {
            let sub = new Observable(obby => obby.complete());

            return sub.subscribe();
        }

        let sub = this.getList("product-seller-subcategory", { parentLookupUID: parentUID }).subscribe(retRes => this.productSellerSubCategory = retRes.results);

        return sub;
    }
}