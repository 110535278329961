export class DispatchCommon
{
    public static readonly DispatchTypes =
        [
            { id: 1, name: "Export on Demand" },
            { id: 2, name: "Email" },
            { id: 3, name: "Data Import" },
            { id: 4, name: "Purchaser Document" }
        ];

    public static readonly DispatchStatuses =
        [
            { id: 1, name: "New" },
            { id: 2, name: "Pending" },
            { id: 3, name: "Processing" },
            { id: 4, name: "Complete" },
            { id: 5, name: "Failed" }
        ];

    public static readonly DispatchProccessTypes =
        [
            [],
            [
                { id: 1, name: "Customer" },
                { id: 2, name: "Purchaser" },
                { id: 3, name: "Purchaser Contact" },
                { id: 4, name: "Purchaser Owner" },
                { id: 5, name: "Purchaser Officer" },
                { id: 6, name: "Freight" },
            ],
            [
                { id: 1, name: "Purchaser Checkout" },
                { id: 2, name: "Confirm Email Address" },
                { id: 3, name: "Signup Purchaser" },
                { id: 4, name: "Purchaser Review Submit" },
                { id: 5, name: "Purchaser Approve" },
                { id: 6, name: "Purchaser Welcome" },
                { id: 7, name: "Information Change Submission" },
                { id: 8, name: "PurchaserChangeApproved" },
                { id: 9, name: "New Orders Suspended" },
                { id: 10, name: "New Pending Orders Suspended" },
                { id: 11, name: "Expired Permit Or License" },
                { id: 12, name: "Overdue Permit Or License" },
                { id: 13, name: "Suspension For Lapsed Permit Or License" },
                { id: 14, name: "Approval Of New License Or Permit" },
                { id: 15, name: "Restatement Of Suspended Registration" },
                { id: 16, name: "Termination Of Registration" },
                { id: 17, name: "Customer Invitation" },
                { id: 18, name: "Acknowledgement Of Customer Registration" },
                { id: 19, name: "Acknowledgement Of Changes" },
                { id: 20, name: "Receipt Of New Product" },
                { id: 21, name: "Decline Freight Forwarder" },
                { id: 22, name: "Purchaser Checkout No Permit" },
                { id: 23, name: "Purchaser Checkout Cleared" },
                { id: 24, name: "Purchaser Checkout No License" },
                { id: 25, name: "Merchant Purchaser Approval Notification" },
                { id: 26, name: "Business Application" },
                { id: 27, name: "Initiate Dealer Export License" },
                { id: 28, name: "Purchaser Checkout No License Australia" },
                { id: 29, name: "Purchaser Checkout No License New Zealand" },
                { id: 30, name: "Purchaser Checkout No License South Africa" },
                { id: 31, name: "Purchaser Checkout Australia" },
                { id: 32, name: "Purchaser Checkout New Zealand" },
                { id: 33, name: "Purchaser Checkout South Africa" },
                { id: 34, name: "Purchaser Missing Firearm Licence Initial" },
                { id: 35, name: "Purchaser Missing Firearm Licence Second" },
                { id: 36, name: "Purchaser Missing Firearm Licence Third" },
                { id: 37, name: "Purchaser Registration Start Initial" },
                { id: 38, name: "Purchaser Registration Start Reminder" },
                { id: 39, name: "Purchaser Registration Incomplete Initial" },
                { id: 40, name: "Purchaser Registration Incomplete Reminder" },
            ],
            [
                { id: 1, name: "Product Rule Export" },
                { id: 2, name: "Product Rule Import" },
                { id: 3, name: "Order License Rule" },
                { id: 4, name: "Product" },
                { id: 5, name: "Country Export Control" },
            ],
            [
                { id: 1, name: "Purchaser Document Check" }
            ]
        ];
}