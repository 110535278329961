import { Component, OnDestroy, OnInit } from "@angular/core";
import { AuthService, CustomerService, ProfileType } from '@app/data';
import { MerchantRequestDTO } from "@app/data/customer/merchant-request.dto";
import { CommonSettings } from "@app/shared/common-settings";
import { GlobalIcons } from '@app/shared/GlobalIcons';
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";

@Component(
{
    selector: "customer-saver",
    templateUrl: './customer-saver.component.html'
})
export class CusteromSaverComponent implements OnInit, OnDestroy
{
    public readonly Icons = GlobalIcons;
    public readonly profileTypes = ProfileType;

    public showSave = true;
    public userRole: number;

    private closeIt: boolean;
    private subAnswer: Subscription;
    private subQuestion: Subscription;

    constructor(public authService: AuthService,
        public toastrService: ToastrService,
        private custServ: CustomerService)
    {
        this.subAnswer = this.custServ.merchantAnswer.subscribe((request) => { this.handleAnswer(request) });
        this.subQuestion = this.custServ.merchantQuestion.subscribe((request) => { this.handleQuestion(request) });
    }

    async ngOnInit()
    {
        let prof = await this.authService.getProfile$().toPromise();

        this.userRole = prof.profileType;
    }

    ngOnDestroy(): void
    {
        this.subAnswer.unsubscribe();
        this.subQuestion.unsubscribe();
    }

    public onClose()
    {
        let quest = new MerchantRequestDTO(MerchantRequestDTO.Request_DirtyClose);

        this.custServ.sendQuestion(quest);
    }

    public onSave(closeIt)
    {
        let quest = new MerchantRequestDTO(MerchantRequestDTO.Request_Save);

        this.closeIt = closeIt;
        this.custServ.sendQuestion(quest);
    }

    private handleAnswer(answer: MerchantRequestDTO)
    {
        switch (answer.requestID)
        {
            case MerchantRequestDTO.Request_Save:
                this.saveComplete();
                break;
        }
    }

    private handleQuestion(question: MerchantRequestDTO)
    {
        switch (question.requestID)
        {
            case MerchantRequestDTO.Request_TabChanged:
                this.showSave = question.data;
                break;
        }
    }

    private saveComplete()
    {
        this.toastrService.success("Save complete", null, CommonSettings.ToastOptions);

        if (!this.closeIt)
        {
            return;
        }

        let quest = new MerchantRequestDTO(MerchantRequestDTO.Request_Close);

        this.custServ.sendQuestion(quest);
    }
}