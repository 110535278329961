import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ApiService } from "../api/api.service";
import { PurchaserLVSLimit } from "./purchaser-lvs-limit";

@Injectable({
    providedIn: "root"
})
export class PurchaserLVSLimitService extends ApiService<PurchaserLVSLimit>
{
    get editorForm()
    {
        let form = new FormBuilder();
        let grpFields = form.group(
            {
                uid: [],
                lvS1: [],
                lvS2: [],
                lvS3: [],
                lvS4: [],
                lvS5: [],
                lvsYear: [],
                customerUID: [, Validators.required],
                purchaserUID: [, Validators.required],
            });

        return grpFields;
    }

    constructor(httpClient: HttpClient)
    {
        super(httpClient, "purchaserLVSLimit");
    }
}