import { Component, ViewChild } from "@angular/core";
import { CustomerAccount, CustomerAccountService } from "@app/data";
import { GridBase } from '@app/shared/ag-grid-helpers/ag-grid-base';
import { GridHelpers } from "@app/shared/ag-grid-helpers/grid-helper";
import { GridColumnAddress } from "@app/shared/ag-grid-helpers/models/grid-column-address";
import { GridColumnContact } from "@app/shared/ag-grid-helpers/models/grid-column-contact";
import { GridColumnCustom } from "@app/shared/ag-grid-helpers/models/grid-column-custom.model";
import { GridColumnIcon } from "@app/shared/ag-grid-helpers/models/grid-column-icon.model";
import { GridColumn } from "@app/shared/ag-grid-helpers/models/grid-column.model";
import { ConfirmationService, IConfirmationOptions } from "@app/shared/confirmation/confirmation.service";
import { ToastrService } from "ngx-toastr";
import { CustomerAccountEditComponent } from "../customer-account-edit/customer-account-edit.component";

@Component({
    selector: "customer-account-list",
    templateUrl: "./customer-account-list.component.html"
})
export class CustomerAccountListComponent extends GridBase<CustomerAccount>
{
    @ViewChild("dialogAccount")
    dialogAccount: CustomerAccountEditComponent;

    constructor(
        toastrService: ToastrService,
        private confirmService: ConfirmationService,
        private customerAccountService: CustomerAccountService)
    {
        super(toastrService, customerAccountService);

        this.setupGrid();
    }

    public onMyGridReady(evtArg)
    {
        this.filterAdd("customerUID", this.parentUID, false);

        this.onGridReady(evtArg);
    }

    public onAdd()
    {
        this.dialogAccount.openAdd(this.parentUID);
    }

    private setupGrid()
    {
        let gridIcons =
            [
                GridHelpers.MakeEditIcon(this.onEdit.bind(this)),
                GridHelpers.MakeDeleteIcon(this.onDelete.bind(this)),
            ];

        this.storageKey = "Merchant-Account";
        this.gridOpts.domLayout = GridHelpers.DOMLayout_Normal;
        this.gridOpts.rowHeight = 75;
        this.gridOpts.paginationPageSize = 10;

        this.addColumn(new GridColumn("accountNumber", "Account"));
        this.addColumn(new GridColumnCustom("accountType.name", "Type", { width: 125 }));
        this.addColumn(new GridColumnContact(null, "Contact"));
        this.addColumn(new GridColumnAddress(null, "Address"));
        this.addColumn(new GridColumnIcon(gridIcons));

        this.rowDoubleClick(this.onEdit.bind(this));
    }

    private onEdit(agData)
    {
        this.dialogAccount.openEdit(agData);
    }

    private onDelete(agData)
    {
        this.dataForm = this.makeForm(agData);

        let opts: IConfirmationOptions =
        {
            FunctionConfirm: () => this.deleteIt()
        };

        this.confirmService.Delete(`Are you sure you want to delete <b>${agData.firstName} ${agData.lastName}</b>?`, opts);
    }

    private deleteIt()
    {
        this.delete().add(() => this.gridRefresh());
    }
}