import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { Util } from "@app/utils/util";
import { ApiService } from "../api/api.service";
import { WikiMod } from "./wiki.model";

@Injectable({
    providedIn: "root"
})
export class WikiService extends ApiService<WikiMod>
{
    public get editorForm()
    {
        let form = new FormBuilder();
        let grpFields = form.group(
            {
                uid: [],
                version: [, Validators.required],
                wikiText: [],
                wikiType: [, Validators.required],
                statusType: [],
                profileType: [],
                releaseDate: []
            });

        return grpFields;
    }

    constructor(httpClient: HttpClient)
    {
        super(httpClient, "wiki");
    }

    public imageUpload(imgFile: File)
    {
        if (Util.isEmpty(imgFile))
        {
            return;
        }

        let uploadData = new FormData();

        uploadData.append(imgFile.name, imgFile);

        return this.postFile("upload-image", uploadData);
    }

    public getWiki(profile: number, wikiType: number)
    {
        let filt = { wikiType: wikiType, profileType: profile };
        let subs = this.post("detail", filt);

        return subs;
    }
}