import { Component } from "@angular/core";
import { GlobalIcons } from "@app/shared/GlobalIcons";
import { AgRendererComponent } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

@Component({
    templateUrl: "./grid-cell-problem-renderer.html",
})
export class GridCellProblemRenderer implements AgRendererComponent
{
    public readonly Icons = GlobalIcons;

    public tooltip: string;
    public probText: string;

    private cellParams: ICellRendererParams;

    constructor()
    {
    }

    agInit(params: any)
    {
        this.tooltip = params.data.product.tooltip;
        this.probText = params.data.product.status.name;
        this.cellParams = params;
    }

    refresh(params): boolean
    {
        this.cellParams = params;
        return true;
    }
}