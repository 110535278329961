import { Component, TemplateRef, ViewChild } from "@angular/core";
import { BaseComponent, LookupService } from "@app/data";
import { FreightForwarderContact } from "@app/data/freight-forwarder-contact/freight-forwarder-contact";
import { FreightForwarderContactService } from "@app/data/freight-forwarder-contact/freight-forwarder-contact.service";
import { CommonSettings } from "@app/shared/common-settings";
import { ConfirmationService, IConfirmationOptions } from "@app/shared/confirmation/confirmation.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: "freight-forwarder-contact-edit",
    templateUrl: "./freight-forwarder-contact-edit.component.html"
})
export class FreightForwarderContactEditComponent extends BaseComponent<FreightForwarderContact>
{
    @ViewChild("dialogContact")
    dialogContact: TemplateRef<any>;

    constructor(
        toastrService: ToastrService,
        public modalService: NgbModal,
        public lookupService: LookupService,
        private contactServ: FreightForwarderContactService,
        private confirmService: ConfirmationService)
    {
        super(toastrService);

        this.dataForm = this.contactServ.editorForm;
        this.apiService = this.contactServ;
        this.modalService = modalService;
    }

    public openAdd()
    {
        this.open(this.dialogContact);
        this.resetForm();
    }

    public openEdit(contactData)
    {
        this.open(this.dialogContact, contactData);
        this.patchValue();
    }

    public onClose()
    {
        if (!this.dataForm.dirty)
        {
            this.close();
            return;
        }

        let opts: IConfirmationOptions =
        {
            FunctionConfirm: () => this.close()
        };

        this.confirmService.YesNo(CommonSettings.DirtyFormConfirmationMessage, opts);
    }

    public onSave()
    {
        this.setValue("freightUID", this.parentUID);

        let sub = this.submit();

        if (sub == null)
        {
            return;
        }

        sub.add(() => this.saveComplete());
    }

    private saveComplete()
    {
        if (!this.submitError)
        {
            this.close();
        }
    }
}