import { GlobalIcons } from '@app/shared/GlobalIcons';
import { Component, ElementRef, ViewChild } from "@angular/core";
import { CommonSettings } from '@app/shared/common-settings';
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { PrivacyPolicyComponent } from "../privacy-policy/privacy-policy.component";

@Component({
    selector: "app-purchaser-terms",
    templateUrl: "./purchaser-terms.component.html"
})
export class PurchaserTermsComponent
{
    @ViewChild("dialogTerms")
    dialogTerms: ElementRef<any>;

    @ViewChild("dialogPolicy")
    dialogPolicy: PrivacyPolicyComponent;

    public readonly Icons = GlobalIcons;

    private modalRef: NgbModalRef;

    constructor(private modalService: NgbModal)
    {
    }

    public open()
    {
        let opts = CommonSettings.DialogOptions(CommonSettings.DialogSize_XLarge);

        this.modalRef = this.modalService.open(this.dialogTerms, opts);
    }

    public onClose()
    {
        this.modalRef.close();
    }

    public onPolicy()
    {
        this.dialogPolicy.open();
    }
}