export enum OrderStatusType
{
    Hold = 10,
    Active = 1,
    Failed = 5,
    Cleared = 3,
    Complete = 4,
    Cancelled = 13,
    Suspended = 11,
    OrderCheck = -2,
    PartiallyCleared = 2
}