import { Component, ElementRef, ViewChild } from "@angular/core";
import { AuthService, BaseComponent, PurchaserAddress } from "@app/data";
import { FixedImage } from "@app/data/misc/fixed-image";
import { PurchaserAddressImage } from "@app/data/purchaser-address/purchaser-address-image.model";
import { PurchaserAddressImageService } from "@app/data/purchaser-address/purchaser-address-image.service";
import { PurchaserCommon } from '@app/data/purchaser/purchaser-common';
import { CommonSettings } from "@app/shared/common-settings";
import { ConfirmationService, IConfirmationOptions } from "@app/shared/confirmation/confirmation.service";
import { ContactFormatter } from "@app/utils/contact-formatter";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: "purchaser-address-verification-edit",
    styleUrls: ["./purchaser-address-verification-edit.component.scss"],
    templateUrl: "./purchaser-address-verification-edit.component.html"
})
export class PurchaserAddressVerificationEdit extends BaseComponent<PurchaserAddressImage>
{
    @ViewChild("dialogDoc")
    dialogDoc: ElementRef<any>;

    public readonly imageTypes = PurchaserCommon.PurchaserAddressImageTypes;

    public docFile: File;
    public addyImage: FixedImage;
    public purchaserAddy: PurchaserAddress;
    public displayAddress: string;

    constructor(
        toastrService: ToastrService,
        public authService: AuthService,
        private modalServ: NgbModal,
        private addyImageServ: PurchaserAddressImageService,
        private confirmService: ConfirmationService)
    {
        super(toastrService);

        this.dataForm = this.addyImageServ.editorForm;
        this.modalService = modalServ;
    }

    public openAdd(purchaserAddy: PurchaserAddress): void
    {
        this.purchaserAddy = purchaserAddy;

        this.open(this.dialogDoc);
        this.setupUI();
    }

    public openEdit(purchaserAddy: PurchaserAddress, imageAddy: PurchaserAddressImage): void
    {
        this.purchaserAddy = purchaserAddy;

        this.open(this.dialogDoc, imageAddy);
        this.setupUI();

        let sub = this.addyImageServ.getImage(this.data.fileNameUID);

        sub.subscribe(retRes => this.addyImage = retRes);
    }

    public onImageOpen(): void
    {
        let win = window.open("");

        win.document.write(`<img src="${this.addyImage.url}" />`);
    }

    private setupUI(): void
    {
        let contForm = new ContactFormatter(this.purchaserAddy);

        this.docFile = null;
        this.displayAddress = contForm.getContact();

        this.setValue("purchaserAddressUID", this.purchaserAddy.uid);
        this.makeRequired(["fileName"], true);
    }

    public onFileSelect(evtArg): void
    {
        this.docFile = evtArg.target.files[0];
        this.formControls.fileName.setValue(this.docFile.name);

        let nameVal = this.docFile.name.indexOf(".") > 0 ? this.docFile.name.split(".")[0] : this.docFile.name;

        this.setValue("name", nameVal);
        this.dataForm.markAsDirty();
    }

    public onClose(): void
    {
        if (!this.dataForm.dirty)
        {
            this.close();
            return;
        }

        let opts: IConfirmationOptions =
        {
            FunctionConfirm: () => this.close()
        };

        this.confirmService.YesNo(CommonSettings.DirtyFormConfirmationMessage, opts);
    }

    public onSave(): void
    {
        if (this.invalid())
        {
            return;
        }

        this.loading = true;

        if (this.data?.uid)
        {
            this.addyImageServ.save(this.dataValue).subscribe(
                retRes => this.saveComplete(),
                errRes => this.error(errRes));
        }
        else
        {
            this.addyImageServ.upload(this.docFile, this.dataValue).subscribe(
                retRes => this.saveComplete(),
                errRes => this.error(errRes));
        }
    }

    private saveComplete(): void
    {
        if (this.update)
        {
            this.update.emit();
        }

       this.loading = false;
       this.close();
    }
}