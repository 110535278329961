import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiService } from "../api/api.service";
import { ShopOrderStatus } from "./shop-order-status";

@Injectable({
    providedIn: "root"
})
export class ShopOrderStatusService extends ApiService<ShopOrderStatus>
{
    constructor(httpClient: HttpClient)
    {
        super(httpClient, "order-manual-entry");
    }
}