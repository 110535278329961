import { AuthService } from '@app/data';
import { FormGroup } from "@angular/forms";

export class Util
{
    public static readonly KeyCode_Enter = 13;

    public static readonly BadDate = "Invalid Date";
    public static readonly DOM_Space = "&nbsp;";
    public static readonly BlankImage = "data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=";

    public static roundTo(value: number, decimals: number)
    {
        let mult = "1".padEnd(decimals + 1, "0");
        let multNum = parseInt(mult);
        let roundIt = Math.round((value + Number.EPSILON) * multNum) / multNum;

        let retNum = roundIt.toString();
        let broken = retNum.split(".");

        if (broken.length == 1)
        {
            broken.push("");
        }

        if (decimals == 0)
        {
            return broken[0];
        }

        return Util.substitute("{0}.{1}", broken[0], broken[1].padEnd(decimals, "0"));
    }

    public static replaceAll(source: string, search: string, replaceWith: string)
    {
        if (Util.isEmpty(source))
        {
            return "";
        }

        source = source.toString();

        var regX = new RegExp(search, "g");

        return source.replace(regX, replaceWith);
    }

    public static substitute(...format)
    {
        let args = Array.prototype.slice.call(arguments, 1);

        return format[0].replace(/{(\d+)}/g, function (match, number)
        {
            return typeof args[number] !== "undefined" ? args[number] : match;
        });
    }

    public static isEmpty(testObj: any): boolean
    {
        if (typeof testObj == "undefined" || testObj == undefined || testObj == null || testObj == "" || testObj === Infinity)
        {
            return true;
        }

        if (typeof testObj == "boolean")
        {
            return (testObj == false);
        }

        if (testObj instanceof Array)
        {
            return (testObj.length == 0);
        }

        if (testObj instanceof Map)
        {
            return (testObj.size == 0);
        }

        let str = testObj.toString().toLowerCase().trim();

        if (str == "" || str == "0" || str == "false" || str == "&nbsp;" || str == "0000-00-00" || str == "NaN")
        {
            return true;
        }

        return false;
    }

    public static enumToKeyValueArray(derEnum)
    {
        let keys = Object.keys(derEnum);
        let vals = Object.values(derEnum);
        let outArray = [];

        for (let lp = 0; lp < (vals.length / 2); lp++)
        {
            outArray.push({ key: vals[lp], value: keys[lp] });
        }

        return outArray;
    }

    public static reindexArray(fixArray)
    {
        if (fixArray == null)
        {
            return null;
        }

        //  This will re-index the array.  Kinda lame
        return fixArray.filter(elm => elm);
    }

    public static fixTags(text: string): string
    {
        if (Util.isEmpty(text))
        {
            return Util.DOM_Space;
        }

        text = Util.replaceAll(text, "<", "&lt;");
        text = Util.replaceAll(text, ">", "&gt;");
        text = Util.replaceAll(text, "\r", "");
        text = Util.replaceAll(text, "\n", "<br />");
        text = Util.replaceAll(text, "\"", "&quot;");

        return text;
    }

    //  Hacky way to get a string from a Blob synchronously.  Taken from https://stackoverflow.com/a/27208593/1324653
    public static blobToString(blobData: Blob): string
    {
        let uri = URL.createObjectURL(blobData);
        let req = new XMLHttpRequest();

        req.open('GET', uri, false);
        req.send();

        URL.revokeObjectURL(uri);

        let ui8 = new Uint8Array(req.response.length);

        for ( let lp = 0; lp < req.response.length; ++lp)
        {
            ui8[lp] = req.response.charCodeAt(lp);
        }

        return new TextDecoder().decode(ui8);
    }

    public static parseError(message: any): string
    {
        if (typeof message != "object")
        {
            return message;
        }

        if (message instanceof Blob)
        {
            return Util.blobToString(message);
        }

        if (message instanceof Array)
        {
            let newMess = [];

            for (let lp = 0; lp < message.length; lp++)
            {
                newMess.push(message[lp]);
            }

            return newMess.join("<br />");
        }

        if ("errors" in message)
        {
            let newMess = [];

            for (let dude in message.errors)
            {
                newMess.push(message.errors[dude]);
            }

            return newMess.join("<br />");
        }

        if ("title" in message)
        {
            return message.title;
        }

        return null;
    }

    public static MakeName(nameData: any, boldDefault: boolean = true): string
    {
        if (Util.isEmpty(nameData))
        {
            return Util.DOM_Space;
        }

        let name = [];

        if (nameData.hasOwnProperty("firstName") && !Util.isEmpty(nameData.firstName))
        {
            name.push(nameData.firstName);
        }

        if (nameData.hasOwnProperty("middleName") && !Util.isEmpty(nameData.middleName))
        {
            name.push(nameData.middleName);
        }

        if (nameData.hasOwnProperty("lastName") && !Util.isEmpty(nameData.lastName))
        {
            name.push(nameData.lastName);
        }

        let outName = name.join(" ");

        if (boldDefault)
        {
            let defCont = (nameData.hasOwnProperty("isDefault") && !Util.isEmpty(nameData.isDefault));

            if (defCont)
            {
                return `<b>${outName}</b>`;
            }
        }

        return outName;
    }

    public static WhatsWrongWithForm(form: FormGroup): void
    {
        let conts = Object.keys(form.controls);

        for (let key of conts)
        {
            let item = form.get(key);

            if (!item.valid)
            {
                console.warn(key);
                console.warn(item.errors);
            }
        }
    }

    public static DataStorage =
        {
            MaxStorageSize: 5000000,
            exists: function(name)
            {
                return (localStorage.getItem(name) !== null);
            },

            get: function(name)
            {
                if (Util.isEmpty(Storage))
                {
                    return null;
                }

                if (localStorage[name])
                {
                    return JSON.parse(localStorage.getItem(name));
                }

                return null;
            },

            set: function(name, data)
            {
                if (Util.isEmpty(Storage))
                {
                    return true;
                }

                let taken = this.spaceTaken();

                data = JSON.stringify(data);

                if (taken + data.length > this.MaxStorageSize)
                {
                    console.warn(`Cannot store data (${name}) in localStorage.  Maximum size exceeded ${this.MaxStorageSize}`);
                    return false;
                }

                localStorage.setItem(name, data);
                return true;
            },

            delete: function(name)
            {
                localStorage.removeItem(name);
            },

            clear: function()
            {
                if (Util.isEmpty(Storage))
                {
                    return true;
                }

                localStorage.clear();

                return true;
            },
            spaceTaken: function()
            {
                var len = 0;

                for (var key in window.localStorage)
                {
                    if (window.localStorage.hasOwnProperty(key))
                    {
                        len += window.localStorage[key].length;
                    }
                }

                return len;
            }
        };
}