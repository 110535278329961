import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiService } from "../api/api.service";
import { Transaction } from "./transaction";

@Injectable({
    providedIn: "root"
})
export class TransactionService extends ApiService<Transaction>
{
    constructor(httpClient: HttpClient)
    {
        super(httpClient, "transaction");
    }

    public saveStatus(orderUID, status: number)
    {
        return this.save({ uid: orderUID, statusType: status });
    }
}