import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ApiService } from "@app/data";
import { Observable } from "rxjs";
import { CustomerPromo } from "./customer-promo";

@Injectable(
{
    providedIn: "root"
})
export class CustomerPromoService extends ApiService<CustomerPromo>
{
    get editorForm()
    {
        let form = new FormBuilder();
        let grpFields = form.group(
            {
                uid: [],
                endDate: [],
                startDate: [],
                countryUID: [],
                customerUID: [],
                promotionURL: [, Validators.required],
                purchaserType: [],
                promotionImage: [, Validators.required],
                promotionDescription: [, Validators.required]
            });

        return grpFields;
    }

    constructor(httpClient: HttpClient)
    {
        super(httpClient, "customer-promo");
    }

    public uploadImage(imgFile: File): Observable<string>
    {
        let uploadData = new FormData();

        uploadData.append(imgFile.name, imgFile);

        return this.httpClient.post<string>(this.buildUrl("upload"), uploadData);
    }
}