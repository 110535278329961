import { Component, HostBinding, Input, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { AppService } from "@app/app.service";
import { AuthService, PurchaserType, StatusType } from "@app/data";
import { ProfileType } from "@app/data/enums/profile-type.enum";
import { LayoutService } from "@app/layout/layout.service";
import { FeedbackDialog } from "@app/shared/feedback/feedback-dialog/feedback-dialog";
import { GlobalIcons } from '@app/shared/GlobalIcons';
import { SidenavMenuComponent } from "src/vendor/libs/sidenav/sidenav-menu.component";

@Component({
    selector: "app-layout-sidenav",
    styleUrls: ["./layout-sidenav.component.scss"],
    templateUrl: "./layout-sidenav.component.html"
})
export class LayoutSidenavComponent
{
    @ViewChild("menuMain")
    menuMain;

    @ViewChild("dailogFeedback")
    dailogFeedback: FeedbackDialog;

    @Input()
    orientation = "vertical";

    @HostBinding("class.flex-grow-0") hostClassFlex = false;
    @HostBinding("class.layout-sidenav") hostClassVertical = false;
    @HostBinding("class.layout-sidenav-horizontal") hostClassHorizontal = false;

    public readonly Icons = GlobalIcons;
    public readonly statusTypes = StatusType;
    public readonly profileTypes = ProfileType;
    public readonly purchaserTypes = PurchaserType;

    public isDealer: boolean;
    public userRole: number;
    public showDealers: boolean;
    public purchaserType: number;
    public purchaserStatusType: number;

    private checkedMenus = [];

    constructor(private router: Router, private appService: AppService, private layoutService: LayoutService, private authService: AuthService)
    {
        // Set host classes
        this.hostClassVertical = this.orientation !== "horizontal";
        this.hostClassHorizontal = !this.hostClassVertical;
        this.hostClassFlex = this.hostClassHorizontal;

        this.isDealer = this.authService.profile?.purchaser?.isDealer;
        this.userRole = this.authService.profile?.profileType;
        this.showDealers = this.authService.profile?.isLocalDealerShown;
        this.purchaserType = this.authService.profile?.purchaser?.purchaserType;
        this.purchaserStatusType = this.authService.profile?.purchaser?.statusType;

        if (!this.userRole)
        {
            this.authService.getProfile$().subscribe((response) =>
            {
                this.userRole = this.authService.profile?.profileType;
                this.purchaserStatusType = this.authService.profile?.purchaser?.statusType;
                this.purchaserType = this.authService.profile?.purchaser?.purchaserType;
            });
        }

        this.authService.profileInfo$.subscribe((value) =>
        {
            this.purchaserStatusType = this.authService.profile?.purchaser?.statusType;
        });
    }

    public getClasses()
    {
        let bg = this.appService.layoutSidenavBg;

        if (this.orientation === "horizontal" && (bg.indexOf(" sidenav-dark") !== -1 || bg.indexOf(" sidenav-light") !== -1))
        {
            bg = bg
                .replace(" sidenav-dark", "")
                .replace(" sidenav-light", "")
                .replace("-darker", "")
                .replace("-dark", "");
        }

        return `${this.orientation === "horizontal" ? "container-p-x " : ""} bg-${bg}`;
    }

    public isActive(url): boolean
    {
        if (url instanceof Array)
        {
            for (let item of url)
            {
                if (this.router.isActive(item, false))
                {
                    return true;
                }
            }

            return false;
        }

        return this.router.isActive(url, false);
    }

    //  Using the checkedMenus array to store what has been checked, this prevents the "NG0100: Expression has changed after it was checked" error
    public isMenuOpen(menuItem: SidenavMenuComponent): boolean
    {
        let item = this.checkedMenus.find(elm => elm.text == menuItem.text);

        if ( item)
        {
            return item.active;
        }

        let act = menuItem.isActive();

        this.checkedMenus.push({ text: menuItem.text, active: act });

        return act;
    }

    public onFeedback(): void
    {
        this.dailogFeedback.openAdd();
    }
}