import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { AuthService, BaseComponent, ProfileType } from "@app/data";
import { PaymentType } from "@app/data/enums/payment-type";
import { Shipment } from "@app/data/shipment/shipment";
import { ShipmentInstruction } from "@app/data/shipment/shipmentinstruction";
import { ShipmentInstructionService } from "@app/data/shipment/shipmentinstruction.service";
import { CommonSettings } from '@app/shared/common-settings';
import { ConfirmationService, IConfirmationOptions } from "@app/shared/confirmation/confirmation.service";
import { DocumentPreviewService } from "@app/shared/document-preview/document-preview.service";
import { DateFormatter } from "@app/shared/pipes/date-formatter.pipe";
import { DateHelper } from "@app/utils/date-helper";
import { Util } from "@app/utils/util";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { forkJoin } from "rxjs";
import { ShipperLetterInstructionsGenerator } from "./shipper-letter-of-instructions.generator";

@Component({
    selector: "shipper-letter-of-instruction",
    styleUrls: ["./shipper-letter-of-instruction.component.scss"],
    templateUrl: "./shipper-letter-of-instruction.component.html"
})
export class ShipperLetterInstructionsComponent extends BaseComponent<ShipmentInstruction> implements OnInit
{
    @ViewChild("dialogSLI")
    dialogSLI: ElementRef<any>;

    public ffField: boolean;
    public shipmentRec: Shipment;

    constructor(toastrService: ToastrService,
        private modalServ: NgbModal,
        private authService: AuthService,
        private confirmService: ConfirmationService,
        private docViewService: DocumentPreviewService,
        private shipInstService: ShipmentInstructionService)
    {
        super(toastrService);

        this.title = "Shipper Letter of Instruction";
        this.dataForm = this.shipInstService.editorForm;
        this.apiService = this.shipInstService;
        this.modalService = modalServ;
    }

    async ngOnInit()
    {
        let prof = await this.authService.getProfile$().toPromise();

        this.ffField = (prof.profileType != ProfileType.freightForwarder);

        if (this.ffField)
        {
            this.disable(["isContainerized"]);
        }
        else
        {
            this.disable(["paymentType", "isShipperInsuranceRequested", "isTransactionPartiesRelated", "isRoutedExportTransaction"]);
        }
    }

    public openSLI(shipmentRec)
    {
        this.shipmentRec = shipmentRec;

        this.open(this.dialogSLI, null, CommonSettings.DialogSize_XLarge);

        if (Util.isEmpty(this.shipmentRec.shipmentInstruction))
        {
            this.setValue("paymentType", PaymentType.Prepaid);
            this.setValue("isRoutedExportTransaction", false);
            this.setValue("isShipperInsuranceRequested", true);
            this.setValue("isTransactionPartiesRelated", false);
        }
        else
        {
            this.shipInstService.detail({ shipmentUID: this.shipmentRec.uid, uid: this.shipmentRec.shipmentInstruction.uid }).subscribe(
                retRes =>
                {
                    this.data = retRes;
                    this.patchValue();
                },
                errRes => this.error(errRes)
            );
        }
    }

    public onClose()
    {
        if (!this.dataForm.dirty)
        {
            this.close();
            return;
        }

        let opts: IConfirmationOptions =
        {
            FunctionConfirm: () => this.close()
        };

        this.confirmService.YesNo(CommonSettings.DirtyFormConfirmationMessage, opts);
    }

    public onGenerate()
    {
        this.blockOn("Generating...");

        let det = this.shipInstService.detail({ shipmentUID: this.shipmentRec.uid });
        let com = this.shipInstService.getList("commodities", { shipmentUID: this.shipmentRec.uid });

        forkJoin([det, com]).subscribe(
            retRes => this.makePDF(retRes),
            errRes => this.error(errRes)
        );
    }

    private makePDF(retRes)
    {
        let sli = new ShipperLetterInstructionsGenerator();
        let detail = retRes[0];
        let fromUser = this.dataForm.value;

        detail.uid = this.data?.uid;
        detail.inBondCode = fromUser.inBondCode;
        detail.entryNumber = fromUser.entryNumber;
        detail.loadingPier = fromUser.loadingPier;
        detail.paymentType = fromUser.paymentType;
        detail.packageType = fromUser.packageType;
        detail.billOfLading = fromUser.billOfLading;
        detail.portOfExport = fromUser.portOfExport;
        detail.inlandCarrier = fromUser.inlandCarrier;
        detail.grossWeightInKG = fromUser.grossWeightInKG;
        detail.isContainerized = (fromUser.isContainerized == "true");
        detail.modeOfTransport = fromUser.modeOfTransport;
        detail.portOfUnloading = fromUser.portOfUnloading;
        detail.exportingCarrier = fromUser.exportingCarrier;
        detail.numberOfPackages = fromUser.numberOfPackages;
        detail.dateOfExportation = DateHelper.formatDate(fromUser.dateOfExportation, DateFormatter.FormatAmerican);
        detail.specialInstruction = fromUser.specialInstruction;
        detail.shipmentReferenceNumber = fromUser.shipmentReferenceNumber;
        detail.carrierIdentificationCode = fromUser.carrierIdentificationCode;
        detail.isRoutedExportTransaction = (fromUser.isRoutedExportTransaction == "true");
        detail.isShipperInsuranceRequested = (fromUser.isShipperInsuranceRequested == "true");
        detail.isTransactionPartiesRelated = (fromUser.isTransactionPartiesRelated == "true");
        detail.transportationReferenceNumber = fromUser.transportationReferenceNumber;

        sli.sliData = detail;
        sli.commodities = retRes[1].results;

        this.shipInstService.save(detail).subscribe(retRes => this.saveComplete(retRes));

        this.resetForm();

        let pdfData = sli.generate();

        this.docViewService.openPDF(pdfData, this.title);

        if (Util.isEmpty(detail.purchaserName)) //  This shouldn't happen, but the test data is missing this, so just-in-case
        {
            detail.purchaserName = "AEP";
        }

        this.blockOff();
    }

    private saveComplete(retRes)
    {
        this.success("SLI data saved");

        this.data = retRes;
        this.patchValue();

        if (this.update)
        {
            this.update.emit();
        }
    }
}