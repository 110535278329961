import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { BaseComponent, LookupService } from "@app/data";
import { ProductOverrideImportHTSService } from "@app/data/product/product-override-import-hts.service";
import { ProductOverrideImportHTS } from "@app/data/product/product-override-import-hts.model";
import { CommonSettings } from "@app/shared/common-settings";
import { ConfirmationService, IConfirmationOptions } from "@app/shared/confirmation/confirmation.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: "product-override-import-hts-edit",
    templateUrl: "./product-override-import-hts-edit.html"
})
export class ProductOverrideImportHTSEdit extends BaseComponent<ProductOverrideImportHTS> implements OnInit
{
    @ViewChild("dialogImport")
    dialogImport: ElementRef;

    constructor(
        toastrService: ToastrService,
        public lookupServ: LookupService,
        private modalServ: NgbModal,
        private confirmService: ConfirmationService,
        private prodImportHTSServ: ProductOverrideImportHTSService)
    {
        super(toastrService);

        this.dataForm = prodImportHTSServ.editForm;
        this.apiService = prodImportHTSServ;
        this.modalService = modalServ;
    }

    ngOnInit(): void
    {
        this.lookupServ.getCountryList();
    }

    public openAdd(): void
    {
        this.open(this.dialogImport, null, CommonSettings.DialogSize_Small);
        this.resetForm();

        this.setValue("productUID", this.parentUID);
    }

    public openEdit(record: ProductOverrideImportHTS): void
    {
        this.open(this.dialogImport, record, CommonSettings.DialogSize_Small);

        this.setValue("productUID", this.parentUID);
    }

    public onClose(): void
    {
        if (!this.dataForm.dirty)
        {
            this.close();
            return;
        }

        let opts: IConfirmationOptions =
        {
            FunctionConfirm: () => this.close()
        };

        this.confirmService.YesNo(CommonSettings.DirtyFormConfirmationMessage, opts);
    }

    public onSave(): void
    {
        let sub = this.submit();

        if (sub)
        {
            sub.add(() => this.doneDidSaved());
        }
    }

    private doneDidSaved(): void
    {
        if (!this.submitError)
        {
            this.close();
        }
    }
}