import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { FreightForwarderContact } from "@app/data/freight-forwarder-contact/freight-forwarder-contact";
import { FreightForwarderContactService } from "@app/data/freight-forwarder-contact/freight-forwarder-contact.service";
import { GridBase } from "@app/shared/ag-grid-helpers/ag-grid-base";
import { GridHelpers } from "@app/shared/ag-grid-helpers/grid-helper";
import { GridColumnCustom } from "@app/shared/ag-grid-helpers/models/grid-column-custom.model";
import { GridColumnIcon } from "@app/shared/ag-grid-helpers/models/grid-column-icon.model";
import { GridColumn } from "@app/shared/ag-grid-helpers/models/grid-column.model";
import { GridCellTextRender } from "@app/shared/ag-grid-helpers/renderers/grid-cell-text.renderer";
import { ConfirmationService, IConfirmationOptions } from "@app/shared/confirmation/confirmation.service";
import { ToastrService } from "ngx-toastr";
import { FreightForwarderContactEditComponent } from "../freight-forwarder-contact-edit/freight-forwarder-contact-edit.component";

@Component({
    selector: "freight-forwarder-contact-list",
    styleUrls: ["./freight-forwarder-contact-list.component.scss"],
    templateUrl: "./freight-forwarder-contact-list.component.html"
})
export class FreightForwarderContactListComponent extends GridBase<FreightForwarderContact> implements OnInit
{
    @ViewChild("dialogContact")
    dialogContact: FreightForwarderContactEditComponent;

    constructor(toastrService: ToastrService,
        private ffContactServ: FreightForwarderContactService,
        private confirmService: ConfirmationService)
    {
        super(toastrService, ffContactServ);
    }

    ngOnInit()
    {
        this.setupGrid();

        this.filterAdd("freightUID", this.parentUID, false);
    }

    public onAdd()
    {
        this.dialogContact.openAdd();
    }

    private setupGrid()
    {
        let gridIcons =
            [
                GridHelpers.MakeEditIcon(this.onEdit.bind(this)),
                GridHelpers.MakeDeleteIcon(this.onDelete.bind(this)),
            ];

        this.storageKey = "FreightForwarder-Contact";
        this.gridOpts.domLayout = GridHelpers.DOMLayout_Normal;
        this.gridOpts.paginationPageSize = 10;

        this.addColumn(new GridColumnCustom("lastName", "Name", { cellRenderer: GridCellTextRender.ContactName }));
        this.addColumn(new GridColumn("title", "Title"));
        this.addColumn(new GridColumnCustom("emailAddress", "Email", { cellRenderer: GridCellTextRender.EmailCellRenderer }));
        this.addColumn(new GridColumnIcon(gridIcons));

        this.rowDoubleClick(this.onEdit.bind(this));
    }

    private onEdit(agData)
    {
        this.dialogContact.openEdit(agData);
    }

    private onDelete(agData)
    {
        this.dataForm = this.makeForm(agData);

        let opts: IConfirmationOptions =
        {
            FunctionConfirm: () => this.deleteIt()
        };

        this.confirmService.Delete(`Are you sure you want to delete this Contact?`, opts);
    }

    private deleteIt()
    {
        this.delete().add(() => this.gridRefresh());
    }
}