import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { AuthService, BaseComponent, CustomerService, Product, ProductService, ProfileType } from "@app/data";
import { ProductCommon } from "@app/data/product/product-common";
import { CommonSettings } from "@app/shared/common-settings";
import { ConfirmationService, IConfirmationOptions } from "@app/shared/confirmation/confirmation.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";

@Component(
{
    selector: "app-product-upload",
    templateUrl: "./product-upload.component.html"
})
export class ProductUploadComponent extends BaseComponent<Product> implements OnInit
{
    @ViewChild("dialogUpload")
    dialogUpload: ElementRef<any>;

    public get isAdmin(): boolean
    {
        return (this.userRole == ProfileType.admin);
    }

    public readonly mode_New: number = 1;
    public readonly mode_Sent: number = 2;

    public userRole: number;
    public fileUpload: File;
    public currentMode: number;

    constructor(
        toastrService: ToastrService,
        public customerService: CustomerService,
        private modalServ: NgbModal,
        private authService: AuthService,
        private formBuilder: FormBuilder,
        private confirmService: ConfirmationService,
        private productService: ProductService)
    {
        super(toastrService);

        this.dataForm = this.formBuilder.group(
            {
                fileName: [, Validators.required],
                customerUID: []
            });
        this.modalService = modalServ;
    }

    async ngOnInit()
    {
        let myProfile = await this.authService.getProfile$().toPromise();

        this.userRole = myProfile.profileType;

        if (this.userRole == ProfileType.admin)
        {
            this.customerService.getCustomerLookup();

            this.makeRequired(["customerUID"]);
        }
    }

    public openUpload(): void
    {
        this.open(this.dialogUpload);
        this.onAnother();
    }

    public onTemplate(): void
    {
        ProductCommon.getTemplate(this.isAdmin);
    }

    public onFileSelect(evtArg): void
    {
        this.fileUpload = evtArg.target.files[0];
        this.formControls["fileName"].setValue(this.fileUpload.name);

        this.dataForm.markAsDirty();
    }

    public onClose(): void
    {
        if (this.currentMode == this.mode_Sent || !this.dataForm.dirty)
        {
            this.close();
            return;
        }

        let opts: IConfirmationOptions =
        {
            FunctionConfirm: () => this.close()
        };

        this.confirmService.YesNo(CommonSettings.DirtyFormConfirmationMessage, opts);
    }

    public onSave(): void
    {
        if (this.invalid())
        {
            return;
        }

        this.loading = true;

        this.productService.import(this.fileUpload, this.dataValue).subscribe(
            retRes =>
            {
                this.loading = false;
                this.currentMode = this.mode_Sent;
            },
            errRes =>
            {
                this.loading = false;
                this.error(errRes);
            });
    }

    public onAnother(): void
    {
        this.fileUpload = null;
        this.currentMode = this.mode_New;
    }
}