import { Component, ElementRef, ViewChild } from "@angular/core";
import { HistoryListCommon } from "@app/admin/history-list-common/history-list-common";
import { Purchaser, PurchaserHistoryService } from "@app/data";
import { PurchaserHistory } from "@app/data/purchaser-history/purchaser-history";
import { CommonSettings } from "@app/shared/common-settings";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: "purchaser-history-list",
    templateUrl: "./purchaser-history-list.component.html"
})
export class PurchaserHistoryListComponent extends HistoryListCommon<PurchaserHistory>
{
    @ViewChild("dialogHistory")
    dialogHistory: ElementRef;

    constructor(
        toastrService: ToastrService,
        public modalServ: NgbModal,
        private purchaserHistoryService: PurchaserHistoryService)
    {
        super(toastrService, purchaserHistoryService);

        this.modalService = modalServ;
        this.setupGrid("Purchaser-History", "Reviewed");
    }

    public openHistory(data: Purchaser): void
    {
        this.open(this.dialogHistory, null, CommonSettings.DialogSize_XLarge);

        this.getHistory({ purchaserUID: data.uid });
    }

    public onClose(): void
    {
        this.close();
    }
}