import { OnDestroy } from '@angular/core';
import { BaseComponent } from "@app/data";
import { ApiService } from "@app/data/api/api.service";
import { GridHelpers } from "@app/shared/ag-grid-helpers/grid-helper";
import { GridFilterDataType } from '@app/shared/ag-grid-helpers/models/grid-filter.model';
import { Util } from "@app/utils/util";
import { ToastrService } from "ngx-toastr";
import { GridColumnGroup } from "./models/grid-column-group.model";
import { GridColumn } from "./models/grid-column.model";
import { GridFilter, GridFilterStorage } from "./models/grid-filter.model";
import { GridSidebarExtras } from "./tools/grid-sidebar-extras";

export class GridBase<T> extends BaseComponent<T> implements OnDestroy
{
    public gridOpts;
    public mainGrid;
    public gridData: Array<T>;
    public dataPath: string;
    public gridTheme = GridHelpers.Theme;
    public storageKey: string = null;
    public columnManager;
    public stateOverrideCallback: () => void;

    public hardFilters = new Map();
    private serverPath: string = null;
    private defaultFilters = new Map();

    constructor(toastrService: ToastrService, apiService: ApiService<T>)
    {
        super(toastrService);

        this.gridOpts = GridHelpers.GetGridDefaults();
        this.apiService = apiService;
        this.gridOpts.frameworkComponents = { extraTools: GridSidebarExtras };

        this.gridOpts.icons =
        {
            left: `<i class='ag-icon ag-icon-left'></i>`
        };

        this.gridOpts.sideBar =
        {
            toolPanels: [
                {
                    id: "extraTools",
                    iconKey: "left",
                    toolPanel: "extraTools",
                    labelDefault: "Tools",
                    toolPanelParams: { grid: this }
                },
                {
                    id: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel",
                    labelDefault: "Columns",
                    toolPanelParams:
                    {
                        suppressPivots: true,
                        suppressValues: true,
                        suppressPivotMode: true,
                        suppressRowGroups: true
                    }
                },
                {
                    id: "filters",
                    iconKey: "filter",
                    toolPanel: "agFiltersToolPanel",
                    labelDefault: "Filters"
                }
            ]
        };
    }

    ngOnDestroy(): void
    {
        this.setEvents(false);
    }

    public setServerPath(path: string)
    {
        this.serverPath = path;

        this.gridOpts.rowModelType = GridHelpers.RowModel_Type;
        this.gridOpts.cacheBlockSize = GridHelpers.CacheBlockSize;
        this.gridOpts.serverSideStoreType = GridHelpers.ServerSideStore;
    }

    public addColumn(column: GridColumn | GridColumnGroup)
    {
        if (column instanceof GridColumn)
        {
            this.parseColumn(column);
        }
        else
        {
            for (let col of column.children)
            {
                this.parseColumn(col);
            }
        }

        this.gridOpts.columnDefs.push(column);
    }

    public setColumns(columns: Array<GridColumn>)
    {
        this.gridOpts.columnDefs = columns;
    }

    public registerCustomComponents(addComps: any)
    {
        this.gridOpts.frameworkComponents = Object.assign(this.gridOpts.frameworkComponents, addComps);
    }

    public onGridReady(params, autoLoad: boolean = true)
    {
        this.mainGrid = params.api;
        this.columnManager = params.columnApi;

        if (this.serverPath == null)
        {
            if (autoLoad)
            {
                this.gridLoad();
            }
        }
        else
        {
            this.initCommunication();
            this.gridStateLoad();
        }
    }

    public gridRefresh()
    {
        if (this.serverPath == null)
        {
            this.gridLoad();
        }
        else
        {
            this.mainGrid.refreshServerSideStore({ purge: false });
        }
    }

    public rowDoubleClick(func: (retRes: any) => any)
    {
        if (func == null)
        {
            this.gridOpts.onRowDoubleClicked = null;
        }
        else
        {
            this.gridOpts.onRowDoubleClicked = (evtArg) => { func(evtArg.data); };
        }
    }

    public filterAdd(field: string, value, autoRefresh: boolean = true)
    {
        let filt;

        if (value instanceof GridFilter)
        {
            filt = value;
        }
        else
        {
            filt = new GridFilter(value);
        }

        if (!this.haveFilterValue(filt))
        {
            this.filterRemove(field, autoRefresh);
            return;
        }

        if (Util.isEmpty(filt.filterField))
        {
            filt.filterField = field;
        }

        this.hardFilters.set(field, filt);

        if (autoRefresh)
        {
            this.mainGrid?.onFilterChanged();
        }
    }

    public filterDefault(field: string, value)
    {
        let filt;

        if (value instanceof GridFilter)
        {
            filt = value;
        }
        else
        {
            filt = new GridFilter(value);
        }

        if (Util.isEmpty(filt.filterField))
        {
            filt.filterField = field;
        }

        this.defaultFilters.set(field, filt);
    }

    public filterRemove(field: string, autoRefresh: boolean = true)
    {
        if (!this.hardFilters.has(field))
        {
            return;
        }

        this.hardFilters.delete(field);

        if (autoRefresh)
        {
            this.mainGrid.onFilterChanged();
        }
    }

    public initCommunication(path: string = null): void
    {
        if (path == null)
        {
            path = this.apiService.buildUrl(this.serverPath);
        }

        let request =
        {
            getRows: (params) =>
            {
                let req = params.request;

                req.filterModel = this.requestFilters(req.filterModel);

                this.apiService.httpClient.post(path, req).subscribe(retRes =>
                {
                    params.success(retRes);

                    this.fixColumnWidths();
                });
            }
        };

        this.mainGrid.setServerSideDatasource(request);
    }

    public gridLoad(data = null, path: string = null)
    {
        if (path == null)
        {
            if (this.dataPath)
            {
                path = this.dataPath;
            }
            else
            {
                path = "list";
            }
        }

        let filter;

        if (data == null)
        {
            filter = this.parseFilters(this.hardFilters, true);
        }
        else
        {
            filter = data;
        }

        filter = Object.assign(filter, { startRow: 0, endRow: 999999 });

        let obby = this.gridGetData(filter, path).subscribe(
            retRes => this.checkData(retRes),
            errRes => this.error(errRes));

        return obby;
    }

    public gridSort(column: string, ascending: boolean = true): void
    {
        let dir = (ascending ? GridHelpers.SortDir_Ascending : GridHelpers.SortDir_Decending);
        let opts =
        {
            state: [{ colId: column, sort: dir }],
            defaultState: { sort: null }
        };

        this.columnManager.applyColumnState(opts);
    }

    public gridGetData(data: any = null, path: string = null)
    {
        if (this.dataPath)
        {
            path = this.dataPath;
        }
        else
        {
            path = "list";
        }

        if (data == null)
        {
            data = {};
        }

        return this.apiService.httpClient.post(this.apiService.buildUrl(path), data);
    }

    public gridPopulate(data: any)
    {
        this.gridData = data;
        this.mainGrid.setRowData(this.gridData);

        this.gridStateLoad();
    }

    public gridSaveState(filters = null)
    {
        if (this.storageKey != null)
        {
            GridHelpers.StateSave(this, filters);
        }
    }

    public gridStateLoad()
    {
        if (this.storageKey == null)
        {
            this.fixColumnWidths();
            return;
        }

        this.setEvents(false);

        GridHelpers.StateLoad(this);

        this.fixColumnWidths(true);
    }

    public gridStateReset()
    {
        let filt = this.parseDefaults();

        this.setEvents(false);
        this.columnManager.resetColumnState();

        Util.DataStorage.delete(this.storageKey);

        this.gridSaveState(filt);
        this.gridStateLoad();
    }

    public fixColumnWidths(setEvents = false)
    {
        setTimeout(() =>
        {
            if (setEvents)
            {
                this.setEvents(true);
            }

            GridHelpers.FixSizes(this.mainGrid);
        }, 1000);
    }

    public rowExpand(rowNumber: number)
    {
        this.mainGrid.getDisplayedRowAtIndex(rowNumber).setExpanded(true);
    }

    public rowCollapse(rowNumber: number)
    {
        this.mainGrid.getDisplayedRowAtIndex(rowNumber).setExpanded(false);
    }

    public rowsExpandAll()
    {
        this.mainGrid.forEachNode((node) => node.setExpanded(true));
    }

    public rowsCollapseAll()
    {
        this.mainGrid.forEachNode((node) => node.setExpanded(false));
    }

    private requestFilters(filtersFromGrid)
    {
        let retFilt = this.parseFilters(filtersFromGrid);

        if (this.hardFilters.size > 0)
        {
            retFilt = Object.assign(retFilt, this.parseFilters(this.hardFilters));
        }

        return retFilt;
    }

    private parseColumn(column: GridColumn)
    {
        if (!Util.isEmpty(column.registerComponents))
        {
            for (let lp = 0; lp < column.registerComponents.length; lp++)
            {
                this.registerCustomComponents(column.registerComponents[lp]);
            }
        }

        delete column.registerComponents;

        //  This will allow any custom filters access to this GridBase and register the filter component
        column.filterParams = Object.assign({ gridBase: this }, column.filterParams);
    }

    private parseDefaults()
    {
        let retFilt = {};

        for (let [key, filt] of this.defaultFilters.entries())
        {
            let addMe = new GridFilterStorage(filt.filterField, filt);

            retFilt = Object.assign(retFilt, { [key]: addMe });
        }

        return retFilt;
    }

    private parseFilters(filters, clientSide = false)
    {
        let retFilt = {};

        if (filters instanceof Map)
        {
            let holdFilt = {};

            for (let filt of filters.values())
            {
                holdFilt = Object.assign(holdFilt, { [filt.filterField]: filt });
            }

            filters = holdFilt;
        }

        for (let dude in filters)
        {
            let dasFilter = filters[dude];

            if (!(dasFilter instanceof GridFilter) && !(dasFilter instanceof GridFilterStorage))
            {
                if (clientSide)
                {
                    retFilt = Object.assign(retFilt, { [dude]: dasFilter.filter });
                }
                else
                {
                    retFilt = Object.assign(retFilt, { [dude]: dasFilter });
                }
                continue;
            }

            if (dasFilter instanceof GridFilter)
            {
                if (clientSide)
                {
                    retFilt = Object.assign(retFilt, { [dasFilter.filterField]: dasFilter.filter });
                }
                else
                {
                    retFilt = Object.assign(retFilt, { [dasFilter.filterField]: dasFilter });
                }
                continue;
            }

            if (dasFilter instanceof GridFilterStorage)
            {
                if (Util.isEmpty(dasFilter["multiple"]))
                {
                    if (clientSide)
                    {
                        retFilt = Object.assign(retFilt, { [dasFilter.filterField]: dasFilter.gridFilter.filter });
                    }
                    else
                    {
                        retFilt = Object.assign(retFilt, { [dasFilter.filterField]: dasFilter.gridFilter });
                    }
                }
                else
                {
                    let mult = dasFilter["multiple"];

                    for (let item in mult)
                    {
                        if (clientSide)
                        {
                            retFilt = Object.assign(retFilt, { [item]: mult[item].filter });
                        }
                        else
                        {
                            retFilt = Object.assign(retFilt, { [item]: mult[item] });
                        }
                    }
                }
            }
        }

        return retFilt;
    }

    private setEvents(turnOn: boolean)
    {
        if (turnOn)
        {
            this.gridOpts.onSortChanged = () => this.gridSaveState();
            this.gridOpts.onFilterChanged = () => { this.gridSaveState() };
            this.gridOpts.onDisplayedColumnsChanged = () => this.gridSaveState();
        }
        else
        {
            this.gridOpts.onSortChanged = null;
            this.gridOpts.onFilterChanged = null
            this.gridOpts.onDisplayedColumnsChanged = null;
        }
    }

    private checkData(retRes)
    {
        if (retRes.hasOwnProperty("results"))
        {
            this.gridPopulate(retRes["results"]);
            return;
        }

        if (retRes.hasOwnProperty("rowData"))
        {
            this.gridPopulate(retRes["rowData"]);
            return;
        }

        this.gridPopulate(retRes);
    }

    private haveFilterValue(checkFilter)
    {
        let value;

        if (checkFilter.filterType == GridFilterDataType.date)
        {
            value = checkFilter.dateTo || checkFilter.dateFrom;
        }
        else
        {
            value = checkFilter.filter;
        }

        //  I'm not sure why, but ("" == false).
        //  If value = false, the result of (value == "") is true. The fix is to make sure value isn't a boolean.  Makes my face hurt!
        if (typeof value != "boolean" && (value == null || value == ""))
        {
            return false;
        }

        return true;
    }
}