import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { MerchantDialogComponent } from "@app/admin/customer/merchant-dialog/merchant-dialog.component";
import { LicenseDialogComponent } from "@app/admin/license/license-dialog/license-dialog.component";
import { PurchaserDialogComponent } from "@app/admin/purchaser/purchaser-dialog/purchaser-dialog.component";
import { AppService } from "@app/app.service";
import { AuthService, Profile } from "@app/data";
import { TaskEntity } from "@app/data/enums/task-entity.enum";
import { TaskStatus } from "@app/data/enums/task-status.enum";
import { TaskCommon } from "@app/data/tasks/task-common";
import { TaskWork } from "@app/data/tasks/taskwork.model";
import { TaskService } from "@app/data/tasks/taskwork.service";
import { GridBase } from "@app/shared/ag-grid-helpers/ag-grid-base";
import { GridHelpers } from "@app/shared/ag-grid-helpers/grid-helper";
import { GridColumnBigText } from "@app/shared/ag-grid-helpers/models/grid-column-big-text";
import { GridColumnCustom } from "@app/shared/ag-grid-helpers/models/grid-column-custom.model";
import { GridColumnIcon } from "@app/shared/ag-grid-helpers/models/grid-column-icon.model";
import { GridColumnStatus } from "@app/shared/ag-grid-helpers/models/grid-column-status";
import { GridColumnTaskEntity } from "@app/shared/ag-grid-helpers/models/grid-column-task-entity";
import { GridColumnUser } from "@app/shared/ag-grid-helpers/models/grid-column-user";
import { GridColumn, GridColumnType } from "@app/shared/ag-grid-helpers/models/grid-column.model";
import { ConfirmationService, IConfirmationOptions } from "@app/shared/confirmation/confirmation.service";
import { FreightForwarderDialogComponent } from "@app/shared/freight-forwarder/freight-forwarder-edit/freight-forwarder-dialog/freight-forwarder-dialog.component";
import { DateFormatter } from "@app/shared/pipes/date-formatter.pipe";
import { ProductDialogComponent } from "@app/shared/products/product-dialog/product-dialog.component";
import { ProductDialogService } from "@app/shared/products/product-dialog/product-dialog.service";
import { DateHelper } from "@app/utils/date-helper";
import { Util } from "@app/utils/util";
import { ToastrService } from "ngx-toastr";
import { MyTaskDialogComponent } from "./my-task-edit/my-task-dialog.component";

@Component({
    selector: "my-tasks",
    styleUrls: ["./my-tasks.component.scss"],
    templateUrl: "./my-tasks.component.html"
})
export class MyTasksComponent extends GridBase<TaskWork> implements OnInit
{
    @Input()
    entityUID: string = null;

    @Input()
    entityType: number = 0;

    @Input()
    restrictHeight: boolean = false;

    @ViewChild("dialogFreight")
    dialogFreight: FreightForwarderDialogComponent;

    @ViewChild("dialogLicense")
    dialogLicense: LicenseDialogComponent;

    @ViewChild("dialogMerchant")
    dialogMerchant: MerchantDialogComponent;

    @ViewChild("dialogPurchaser")
    dialogPurchaser: PurchaserDialogComponent;

    @ViewChild("dialogTaskEdit")
    dialogTaskEdit: MyTaskDialogComponent;

    private myProfile: Profile;

    constructor(
        toastrService: ToastrService,
        private router: Router,
        private taskServ: TaskService,
        private appService: AppService,
        private authService: AuthService,
        private prodDiggyServ: ProductDialogService,
        private confirmService: ConfirmationService)
    {
        super(toastrService, taskServ);

        this.appService.pageTitle = "My Tasks";
    }

    async ngOnInit()
    {
        this.setupGrid();

        if (this.entityUID != null)
        {
            this.filterAdd("entityUID", this.entityUID, false);
            this.filterAdd("entityType", this.entityType, false);
        }

        this.myProfile = await this.authService.getProfile$().toPromise();
    }

    public onTaskAdd()
    {
        this.dialogTaskEdit.openAdd();
    }

    public onUpdate()
    {
        this.gridRefresh();

        if (this.update)
        {
            this.update.emit();
        }
    }

    private canEdit(agData)
    {
        return agData.statusType != TaskStatus.Complete;
    }

    private canView(agData)
    {
        return agData.statusType == TaskStatus.Complete;
    }

    private canComplete(agData)
    {
        return agData.statusType != TaskStatus.Complete;
    }

    private canAssignToMe(agData)
    {
        return agData.statusType == TaskStatus.New;
    }

    private setupGrid()
    {
        let iconWid = (this.entityUID == null ? 280 : 200);
        let colUser = new GridColumnUser("assignedToName", "assignedToUID");
        let gridIcons =
            [
                GridHelpers.MakeEditIcon(this.onEdit.bind(this), this.canEdit.bind(this)),
                GridHelpers.MakeViewIcon(this.onEdit.bind(this), this.canView.bind(this)),
                GridHelpers.MakeDeleteIcon(this.onDelete.bind(this), this.canEdit.bind(this)),
                { icon: this.Icons.Note, onClick: this.onNotes.bind(this), toolTip: "Go to Notes" },
                { icon: this.Icons.GoTo, onClick: this.onGotoEntity.bind(this), toolTip: "Go to related record", isVisible: this.canGoTo.bind(this) },
                { icon: this.Icons.Check, onClick: this.onMarkComplete.bind(this), toolTip: "Mark Task as complete", isVisible: this.canComplete.bind(this) },
                { icon: this.Icons.AssignTo, onClick: this.onAssignToMe.bind(this), toolTip: "Assign this Task to me", isVisible: this.canAssignToMe.bind(this) }
            ];

        colUser.minWidth = 125;
        this.storageKey = "MyTasks";

        if (this.restrictHeight)
        {
            this.gridOpts.domLayout = GridHelpers.DOMLayout_Normal;
            this.gridOpts.paginationPageSize = 10;
        }

        this.setServerPath("list");
        this.addColumn(new GridColumnStatus(TaskCommon.TaskStatuses));

        if (this.entityUID == null)
        {
            this.storageKey += "-Dashboard";
            this.addColumn(new GridColumnTaskEntity("entityType"));
        }

        this.addColumn(new GridColumnCustom("entityName", "Reference", { minWidth: 100 }))
        this.addColumn(new GridColumnCustom("taskCategory.name", "Category", { minWidth: 150 }));
        this.addColumn(new GridColumnCustom("taskDescription", "Description", { minWidth: 150 }));
        this.addColumn(colUser);
        this.addColumn(new GridColumn("taskDate", "Task Date", GridColumnType.DateTime));
        this.addColumn(new GridColumn("dueDate", "Due", GridColumnType.DateTime));
        this.addColumn(new GridColumn("completeDate", "Completed", GridColumnType.DateTime));
        this.addColumn(new GridColumnBigText("note", "Last Note"));
        this.addColumn(new GridColumnIcon(gridIcons, iconWid));

        this.rowDoubleClick(this.onEdit.bind(this));
    }

    private onNotes(agData): void
    {
        this.dialogTaskEdit.openEdit(agData, TaskCommon.TaskTab_Note);
    }

    private onEdit(agData)
    {
        this.dialogTaskEdit.openEdit(agData);
    }

    private onDelete(agData)
    {
        this.dataForm = this.makeForm(agData);

        let opts: IConfirmationOptions =
        {
            FunctionConfirm: () => this.deleteIt()
        };

        this.confirmService.YesNo(`You sure you want to delete "${agData.taskDescription}"?`, opts);
    }

    private deleteIt(): void
    {
        this.delete().add(() => this.onUpdate());
    }

    private onMarkComplete(agData)
    {
        let opts: IConfirmationOptions =
        {
            FunctionConfirm: () => this.doMarkComplete(agData)
        };

        this.confirmService.YesNo(`You sure you want mark this Task as complete?`, opts);
    }

    private doMarkComplete(agData)
    {
        agData.statusType = TaskStatus.Complete;
        agData.completeDate = DateHelper.formatDate(DateFormatter.Now, DateFormatter.FormatDateTimeMil);

        if (Util.isEmpty(agData.assignedToUID))
        {
            agData.assignedToUID = this.myProfile.uid;
        }

        this.taskServ.save(agData).subscribe(
            retRes =>
            {
                this.success("Task marked as Complete");
                this.onUpdate();
            },
            errRes => this.error(errRes));
    }

    private canGoTo()
    {
        return this.entityUID == null;
    }

    private onGotoEntity(agData)
    {
        switch (agData.entityType)
        {
            case TaskEntity.License:
                this.dialogLicense.openEdit(agData);
                break;

            case TaskEntity.Merchant:
                this.dialogMerchant.openEdit(agData.entityUID);
                break;

            case TaskEntity.Order:
                this.router.navigate(["admin/order-details", agData.entityUID]);
                break;

            case TaskEntity.Product:
                this.prodDiggyServ.editByUID(agData.entityUID);
                break;

            case TaskEntity.Purchaser:
                this.dialogPurchaser.openPurchaser(agData.entityUID);
                break;

            case TaskEntity.FreightForwarder:
                this.dialogFreight.openEdit(agData.uid);
                break;
        }
    }

    private onAssignToMe(agData): void
    {
        agData.note = `Task assigned to: ${this.myProfile.lastName}, ${this.myProfile.firstName}`;
        agData.statusType = TaskStatus.InProgress;
        agData.assignedToUID = this.myProfile.uid;

        this.taskServ.post("save", agData).subscribe(
            retRes => this.taskAssigned(),
            errRes => this.error(errRes));
    }

    private taskAssigned()
    {
        this.success("Task has been assigned to you");
        this.onUpdate();
    }
}