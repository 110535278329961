import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ApiService } from "../api/api.service";
import { LicenseTemplateItem } from "./license-template-item";

@Injectable({
    providedIn: "root"
})
export class LicenseTemplateItemService extends ApiService<LicenseTemplateItem>
{
    public get editorForm()
    {
        let form = new FormBuilder();
        let grpFields = form.group(
            {
                uid: [],
                unitAmount: [, Validators.required],
                description: [, Validators.required],
                earCategory: [],
                exportLineItem: [, Validators.required],
                licenseTypeName: [],
                licenseTemplateUID: [],
            });

        return grpFields;
    }

    constructor(httpClient: HttpClient)
    {
        super(httpClient, "license-template-item");
    }
}