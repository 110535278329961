import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { BaseComponent, LookupService } from "@app/data";
import { CustomerPromo } from "@app/data/customer-promo/customer-promo";
import { CustomerPromoService } from "@app/data/customer-promo/customer-promo.service";
import { PurchaserCommon } from "@app/data/purchaser/purchaser-common";
import { CommonSettings } from "@app/shared/common-settings";
import { ConfirmationService, IConfirmationOptions } from "@app/shared/confirmation/confirmation.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: "customer-marketing-promos-edit",
    styleUrls: ["customer-marketing-promos-edit.scss"],
    templateUrl: "customer-marketing-promos-edit.html"
})
export class CustomerMarketingPromosEdit extends BaseComponent<CustomerPromo> implements OnInit
{
    @ViewChild("dialogPromo")
    dialogPromo: ElementRef<any>;

    public readonly opt_None = 0;
    public readonly opt_Direct = 1;
    public readonly opt_Upload = 2;
    public readonly purchTypes = PurchaserCommon.PurchaserTypes;

    public newOption: number;
    public isDragOver: boolean;
    public inPromoImg?: File;
    public currentImgURL?: string;

    constructor(
        toastrService: ToastrService,
        public lookServ: LookupService,
        private modalServ: NgbModal,
        private promoServ: CustomerPromoService,
        private confirmService: ConfirmationService)
    {
        super(toastrService);

        this.dataForm = this.promoServ.editorForm;
        this.apiService = promoServ;
        this.modalService = modalServ;
    }

    ngOnInit(): void
    {
        this.lookServ.getCountryList();
    }

    public openAdd(merchantUID: string): void
    {
        this.setupIU();
        this.open(this.dialogPromo, null, CommonSettings.DialogSize_XLarge);

        this.setValue("customerUID", merchantUID);
    }

    public openEdit(promo: CustomerPromo): void
    {
        this.setupIU();

        this.open(this.dialogPromo, promo, CommonSettings.DialogSize_XLarge);

        this.currentImgURL = promo.promotionImage;
    }

    public onNewChange(opt: number): void
    {
        this.newOption = opt;

        this.setValue("promotionImage", undefined);
    }

    public onDragEnter(): void
    {
        this.isDragOver = true;
    }

    public onDragLeave(): void
    {
        this.isDragOver = false;
    }

    public onImageSelected(evtArg): void
    {
        this.inPromoImg = evtArg.target.files[0];
        this.dataForm.markAsDirty();

        this.setValue("promotionImage", this.inPromoImg.name);
    }

    public onClose(): void
    {
        if (!this.dataForm.dirty)
        {
            this.close();
            return;
        }

        let opts: IConfirmationOptions =
        {
            FunctionConfirm: () => this.close()
        };

        this.confirmService.YesNo(CommonSettings.DirtyFormConfirmationMessage, opts);
    }

    public onSave()
    {
        if (this.newOption == this.opt_Upload)
        {
            this.newImageUpload();
            return;
        }

        let sub = this.submit();

        if (sub)
        {
            sub.add(() => this.doneDidSaved());
        }
    }

    private newImageUpload(): void
    {
        if (this.invalid())
        {
            return;
        }

        this.loadingOn("Uploading...");

        this.promoServ.uploadImage(this.inPromoImg)
            .subscribe(
                {
                    next: (fullURL: string) => this.uploadComplete(fullURL),
                    error: errRes => this.error(errRes)
                })
            .add(() => this.loadingOff());
    }

    private uploadComplete(fullURL: string): void
    {
        this.setValue("promotionImage", fullURL);

        let sub = this.submit();

        if (sub)
        {
            sub.add(() => this.doneDidSaved());
        }
    }

    private doneDidSaved()
    {
        if (!this.submitError)
        {
            this.close();
        }
    }

    private setupIU(): void
    {
        this.newOption = this.opt_None;
        this.inPromoImg = undefined;
        this.isDragOver = false;
        this.currentImgURL = undefined;
    }
}