import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ApiService } from '../api/api.service';
import { Profile } from './profile';

@Injectable({
  providedIn: 'root'
})
export class AntiforgeryService extends ApiService<Profile> {

  constructor(httpClient: HttpClient) {
    super(httpClient, 'antiforgery');
  }

  token() {
    return this.get('token');
  }

  profileToken() {
    return this.get('profile-token');
  }
}
