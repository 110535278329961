import { Component } from "@angular/core";
import { DispatchCommon } from '@app/data/dispatch/dispatch-common';
import { GridFilter, GridFilterStorage } from "@app/shared/ag-grid-helpers/models/grid-filter.model";
import { Util } from "@app/utils/util";
import { AgFilterComponent } from "ag-grid-angular";
import { IDoesFilterPassParams } from "ag-grid-community";
import clone from "lodash-es/clone";
import { GridFilterCustom } from "../grid-filter-custom";

@Component({
    styleUrls: ["../../../styles/gridfilter.scss"],
    templateUrl: "./grid-dispatch-type-filter.component.html"
})
export class GridDispatchTypeFilter extends GridFilterCustom implements AgFilterComponent
{
    public readonly fieldType = "dispatchType";
    public readonly fieldProcess = "dispatchProcessType";
    public readonly dispatchTypes = DispatchCommon.DispatchTypes;

    public selectType: number;
    public selectProc: number;
    public processTypes;

    agInit(params: any): void
    {
        this.initFilter(params);
    }

    isFilterActive(): boolean
    {
        return !Util.isEmpty(this.selectType);
    }

    doesFilterPass(params: IDoesFilterPassParams): boolean
    {
        if (this.isServerSide || Util.isEmpty(this.selectType))
        {
            return true;
        }

        return (this.selectType == params.data[this.fieldType] && (Util.isEmpty(this.selectProc) || this.selectProc == params.data[this.fieldProcess]));
    }

    getModel()
    {
        if (Util.isEmpty(this.selectType))
        {
            return null;
        }

        let filt = new GridFilterStorage();

        filt.add(this.fieldType, new GridFilter(this.selectType));

        if (!Util.isEmpty(this.selectProc))
        {
            filt.add(this.fieldProcess, new GridFilter(this.selectProc));
        }

        return filt;
    }

    setModel(model: any)
    {
        let filter = model?.multiple;

        if (Util.isEmpty(filter))
        {
            this.selectType = null;
            this.selectProc = null;
            return;
        }

        for (let name in filter)
        {
            switch (name)
            {
                case this.fieldType:
                    this.selectType = filter[name].filter;
                    break;

                case this.fieldProcess:
                    this.selectProc = filter[name].filter;
                    break;
            }
        }

        this.onMainFilter();
    }

    public onMainFilter(): void
    {
        let types = clone(DispatchCommon.DispatchProccessTypes[this.selectType]);

        types.sort((val1, val2) => this.sortTypes(val1, val2));
        types.unshift({ id: null, name: "All" });

        this.processTypes = types;
    }

    public onFilterClear()
    {
        this.selectProc = null;
        this.selectType = null;

        this.applyFilter();
        this.closeFilter();
    }

    public onFilterApply()
    {
        this.applyFilter();
        this.closeFilter();
    }

    private sortTypes(val1: any, val2: any): number
    {
        if (val1.name == val2.name)
        {
            return 0;
        }

        return (val1.name > val2.name ? 1 : -1);
    }
}