export class GlobalIcons
{
    public static readonly Size_Large = "fa-lg";
    public static readonly Size_Small = "fa-sm";
    public static readonly Size_XLarge = "fa-2x";
    public static readonly Size_XXLarge = "fa-4x";
    public static readonly Size_ExtraSmall = "fa-xs";

    public static readonly ReleaseNotes_Bug = "fas fa-xs fa-fw fa-bug release-notes-bug";
    public static readonly ReleaseNotes_New = "fas fa-xs fa-fw fa-certificate release-notes-new";
    public static readonly ReleaseNotes_Change = "fas fa-xs fa-fw fa-pen-alt release-notes-change";
    public static readonly ReleaseNotes_Delete = "fas fa-xs fa-fw fa-cut release-notes-delete";

    public static readonly No: string = "fas fa-ban fa-fw";
    public static readonly Add: string = "fas fa-plus fa-fw";
    public static readonly Fix: string = "fas fa-band-aid fa-fw";
    public static readonly PDF: string = "fas fa-file-pdf fa-fw";
    public static readonly Run: string = "fas fa-running fa-fw";
    public static readonly Tag: string = "fas fa-fw fa-tag";
    public static readonly Copy: string = "far fa-copy fa-fw";
    public static readonly Edit: string = "far fa-edit fa-fw";
    public static readonly Star: string = "fas fa-star fa-fw";
    public static readonly Wait: string = "fas fa-hourglass-half fa-fw";
    public static readonly GoTo: string = "fas fa-directions fa-fw";
    public static readonly Help: string = "far fa-question-circle fa-fw";
    public static readonly Hold: string = "fas fa-pause-circle fa-fw";
    public static readonly Info: string = "fas fa-info-circle fa-fw";
    public static readonly Link: string = "fas fa-external-link-alt fa-fw";
    public static readonly List: string = "fas fa-list fa-fw";
    public static readonly Lock: string = "fas fa-lock fa-fw";
    public static readonly Menu: string = "fas fa-bars fa-fw";
    public static readonly Note: string = "far fa-sticky-note fa-fw";
    public static readonly Save: string = "fas fa-save fa-fw";
    public static readonly Test: string = "fas fa-vial fa-fw";
    public static readonly Undo: string = "fas fa-undo-alt fa-fw";
    public static readonly View: string = "fas fa-eye fa-fw";
    public static readonly Check: string = "fas fa-check fa-fw";
    public static readonly Class: string = "far fa-object-group fa-fw";
    public static readonly Clear: string = "fas fa-times-circle fa-fw";
    public static readonly Close: string = "fas fa-times fa-fw";
    public static readonly Excel: string = "far fa-file-excel fa-fw";
    public static readonly Image: string = "far fa-image fa-fw";
    public static readonly Phone: string = "fas fa-fw fa-phone";
    public static readonly Print: string = "fas fa-print fa-fw";
    public static readonly Rules: string = "fas fa-fw fa-pencil-ruler";
    public static readonly Share: string = "fas fa-fw fa-share";
    public static readonly Tasks: string = "fas fa-tasks fa-fw";
    public static readonly Tools: string = "fas fa-fw fa-tools";
    public static readonly Trash: string = "fas fa-trash fa-fw";
    public static readonly Camera: string = "fas fa-fw fa-camera";
    public static readonly Cancel: string = "fas fa-window-close fa-fw";
    public static readonly Dealer: string = "fas fa-fw fa-store";
    public static readonly Expand: string = "fas fa-expand-alt fa-fw";
    public static readonly Export: string = "fas fa-fw fa-file-export";
    public static readonly Filter: string = "fas fa-filter fa-fw";
    public static readonly Import: string = "fas fa-fw fa-file-import";
    public static readonly LogOut: string = "fas fa-sign-out-alt fa-fw";
    public static readonly Manual: string = "fas fa-fw fa-book";
    public static readonly Orders: string = "fas fa-fw fa-receipt";
    public static readonly Resize: string = "fas fa-arrows-alt-h fa-fw";
    public static readonly Search: string = "fas fa-search fa-fw";
    public static readonly Unlock: string = "fas fa-unlock fa-fw";
    public static readonly Upload: string = "fas fa-upload fa-fw";
    public static readonly Account: string = "fas fa-user fa-fw";
    public static readonly Address: string = "fas fa-map fa-fw";
    public static readonly Billing: string = "fas fa-file-invoice-dollar fa-fw";
    public static readonly Contact: string = "fas fa-address-book fa-fw";
    public static readonly Comment: string = "fa-fw fas fa-comment-dots";
    public static readonly Country: string = "fas fa-globe-americas fa-fw";
    public static readonly History: string = "fas fa-history fa-fw";
    public static readonly License: string = "fas fa-fw fa-file-contract";
    public static readonly Members: string = "fas fa-user-friends fa-fw";
    public static readonly Officer: string = "fas fa-user-tie fa-fw";
    public static readonly Product: string = "fas fa-box fa-fw";
    public static readonly Process: string = "fab fa-fw fa-stack-overflow";
    public static readonly Problem: string = "fas fa-exclamation-triangle fa-fw";
    public static readonly Profile: string = "far fa-fw fa-id-badge";
    public static readonly Refresh: string = "fas fa-sync-alt fa-fw";
    public static readonly Reports: string = "fas fa-fw fa-align-left";
    public static readonly Activate: string = "far fa-fw fa-plus-square";
    public static readonly AssignTo: string = "fas fa-fw fa-user-check";
    public static readonly Calendar: string = "far fa-calendar-alt fa-fw";
    public static readonly Collapse: string = "fas fa-compress-alt fa-fw";
    public static readonly Document: string = "fas fa-file-alt fa-fw";
    public static readonly Download: string = "fas fa-download fa-fw";
    public static readonly Envelope: string = "far fa-envelope fa-fw";
    public static readonly MaxValue: string = "fas fa-fw fa-infinity";
    public static readonly Merchant: string = "fas fa-fw fa-people-carry";
    public static readonly Shipment: string = "fas fa-truck fa-fw";
    public static readonly ThumbsUp: string = "fas fa-thumbs-up fa-fw";
    public static readonly ArrowLeft: string = "fas fa-angle-left fa-fw";
    public static readonly ArrowPath: string = "fas fa-long-arrow-alt-right";
    public static readonly Clipboard: string = "fas fa-fw fa-clipboard";
    public static readonly Dashboard: string = "fas fa-desktop fa-fw";
    public static readonly Template: string = "fas fa-fw fa-boxes";
    public static readonly ExpandAll: string = "fas fa-fw fa-expand-arrows-alt";
    public static readonly Marketing: string = "fas fa-fw fa-bullseye";
    public static readonly Purchaser: string = "fas fa-fw fa-users";
    public static readonly ScheduleB: string = "fas fa-fw fa-passport";
    public static readonly ArrowRight: string = "fas fa-angle-right fa-fw";
    public static readonly DatePicker: string = "far fa-fw fa-2x fa-calendar-alt btn icon-btn borderless icon-interactive";
    public static readonly Deactivate: string = "far fa-minus-square";
    public static readonly MoveUpDown: string = "fas fa-fw fa-arrows-alt-v";
    public static readonly MultiImage: string = "far fa-fw fa-images";
    public static readonly Promotions: string = "fas fa-comment-dollar fa-fw";
    public static readonly UserAccess: string = "fas fa-user-lock fa-fw";
    public static readonly CircleCheck: string = "far fa-fw fa-check-circle";
    public static readonly CollapseAll: string = "fas fa-fw fa-compress-arrows-alt";
    public static readonly Transaction: string = "fas fa-cash-register";
    public static readonly Notification: string = "fas fa-bell fa-fw";
    public static readonly Organization: string = "fas fa-fw fa-building";
    public static readonly ReleaseNotes: string = "fas fa-fw fa-file-invoice";
    public static readonly ArrowDoubleLeft: string = "fas fa-angle-double-left fa-fw";
    public static readonly CheckboxChecked: string = "far fa-square fa-fw";
    public static readonly ArrowDoubleRight: string = "fas fa-angle-double-right fa-fw";
    public static readonly CheckboxUnChecked: string = "far fa-check-square fa-fw";
}