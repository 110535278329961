import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AppService } from "@app/app.service";
import { AuthService, BaseComponent, Profile, ProfileType } from "@app/data";
import { CommonSettings } from '@app/shared/common-settings';
import { ToastrService } from "ngx-toastr";

@Component({
    styleUrls: ["my-dashboard.component.scss"],
    templateUrl: "./my-dashboard.component.html"
})
export class MyDashboardComponent extends BaseComponent<Profile> implements OnInit
{
    public readonly tabTask = CommonSettings.DashboardTab_Tasks;
    public readonly tabProfile = CommonSettings.DashboardTab_Profile;

    public setTab: number;
    public myProfile: Profile;

    constructor(
        toastrService: ToastrService,
        private appService: AppService,
        private activeRoute: ActivatedRoute,
        private authService: AuthService)
    {
        super(toastrService);

        this.setTab = CommonSettings.DashboardTab_Tasks;
        this.appService.pageTitle = "Dashboard";
    }

    ngOnInit(): void
    {
        this.authService.getProfile$().subscribe(() =>
        {
            this.myProfile = this.authService.profile

            //  This is temp while testing the Task stuff
            if (this.myProfile.profileType != ProfileType.admin)
            {
                this.setTab = CommonSettings.DashboardTab_Profile;
            }

            if (this.activeRoute.snapshot.paramMap.has("tab"))
            {
                this.setTab = parseInt(this.activeRoute.snapshot.paramMap.get("tab"));
            }
        });
    }
}