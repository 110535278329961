import { GridHelpers } from '@app/shared/ag-grid-helpers/grid-helper';
import { DateHelper } from "@app/utils/date-helper";
import { Util } from "@app/utils/util";
import { GridDateFilter } from "../filters/grid-date-filter/grid-date-filter.component";
import { GridCellTextRender } from "../renderers/grid-cell-text.renderer";

export enum GridColumnType
{
    Date,
    Link,
    Email,
    Money,
    Phone,
    Number,
    String,
    Decimal,
    Percent,
    DateTime
}

export class GridColumn
{
    public hide: boolean = false;
    public sort: string;
    public type: string;
    public field: string;
    public width: number;
    public filter: string = GridHelpers.FilterType_Text;
    public pinned: string;
    public editable: boolean = false;
    public maxWidth: number;
    public minWidth: number = 75;
    public sortable: boolean = true;
    public wrapText: boolean = false;
    public cellClass: string;
    public cellStyle: any;
    public menuTabs = ["filterMenuTab", "generalMenuTab", "columnsMenuTab"];
    public resizable: boolean = true;
    public autoHeight: boolean = false;
    public cellEditor: string;
    public comparator;
    public headerName: string;
    public cellRenderer: any;
    public filterParams;
    public suppressMenu: boolean = false;
    public cellEditorParams: any;
    public suppressSizeToFit: boolean = false;
    public cellRendererParams: any;
    public registerComponents;
    public cellRendererFramework: any;
    public headerComponentParams: any;

    constructor(field: string, header: string, columnType: GridColumnType = GridColumnType.String)
    {
        this.field = field;
        this.filter = GridHelpers.FilterType_Text;
        this.headerName = header;
        this.filterParams = GridHelpers.FilterDefaultAction;
        this.registerComponents = [];

        switch (columnType)
        {
            case GridColumnType.Date:
                this.width = 120;
                this.filter = "dateFilter";
                this.comparator = this.dateSorter;
                this.filterParams = Object.assign(this.filterParams, { suppressAndOrCondition: true, comparator: this.dateFilter, field: this.field });
                this.cellRenderer = GridCellTextRender.FormatDate;
                this.suppressSizeToFit = true;
                this.cellRendererFramework = GridDateFilter;

                this.registerComponents.push({ dateFilter: GridDateFilter });
                break;

            case GridColumnType.DateTime:
                this.width = 170;
                this.filter = GridHelpers.FilterType_Date;
                this.comparator = this.dateSorter;
                this.filterParams = Object.assign(this.filterParams, { suppressAndOrCondition: true, comparator: this.dateFilter, field: this.field });
                this.cellRenderer = GridCellTextRender.FormatDateTime;
                this.suppressSizeToFit = true;
                break;

            case GridColumnType.Email:
                this.width = 170;
                this.cellRenderer = GridCellTextRender.EmailCellRenderer;
                this.suppressSizeToFit = true;
                break;

            case GridColumnType.Link:
                this.cellRenderer = GridCellTextRender.LinkCellRenderer;
                break;

            case GridColumnType.Money:
                this.type = GridHelpers.AlignRight;
                this.width = 125;
                this.filter = GridHelpers.FilterType_Number;
                this.cellRenderer = GridCellTextRender.MoneyCellRenderer
                this.suppressSizeToFit = true;
                break;

            case GridColumnType.Number:
                this.type = GridHelpers.AlignRight;
                this.width = 125;
                this.filter = GridHelpers.FilterType_Number;
                this.cellRenderer = GridCellTextRender.NumberCellRenderer;
                this.suppressSizeToFit = true;
                break;

            case GridColumnType.Decimal:
                this.type = GridHelpers.AlignRight;
                this.width = 125;
                this.filter = GridHelpers.FilterType_Number;
                this.cellRenderer = GridCellTextRender.DecimalCellRenderer
                this.suppressSizeToFit = true;
                break;

            case GridColumnType.Percent:
                this.type = GridHelpers.AlignRight;
                this.width = 100;
                this.filter = GridHelpers.FilterType_Number;
                this.cellRenderer = GridCellTextRender.PercentCellRenderer
                this.suppressSizeToFit = true;
                break;

            case GridColumnType.Phone:
                this.width = 125;
                this.cellRenderer = GridCellTextRender.PhoneCellRenderer
                this.suppressSizeToFit = true;
                break;
        }
    }

    public statusSorter(val1, val2)
    {
        if (Util.isEmpty(val1) || Util.isEmpty(val2))
        {
            return 0;
        }

        if (val1.name == val2.name)
        {
            return 0;
        }

        return val1.name > val2.name ? 1 : -1;
    }

    private dateFilter(selectedDate: Date, cellValue)
    {
        if (Util.isEmpty(cellValue))
        {
            return -1;
        }

        let dateVal = DateHelper.makeDate(cellValue);

        if (dateVal == null)
        {
            return -1;
        }

        dateVal.setHours(0, 0, 0);
        selectedDate.setHours(0, 0, 0);

        let compSel = selectedDate.getTime();
        let compDate = dateVal.getTime();

        if (compDate == compSel)
        {
            return 0;
        }

        return (compDate < compSel ? -1 : 1);
    }

    private dateSorter(val1, val2)
    {
        let comp1 = DateHelper.makeDate(val1);
        let comp2 = DateHelper.makeDate(val2);

        if (comp1 == null && comp2 == null)
        {
            return 0;
        }

        if (comp1 == null)
        {
            return -1;
        }

        if (comp2 == null)
        {
            return 1;
        }

        return comp1.getTime() - comp2.getTime();
    }
}