import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FormBuilder, Validators } from '@angular/forms';
import { Util } from "@app/utils/util";
import { map, mergeMap } from "rxjs/operators";
import { ApiService } from "../api/api.service";
import { OrderStatusType } from "../enums/order-status-type.enum";
import { Transaction } from "../transaction/transaction";
import { TransactionDocument } from "./transaction-document";

@Injectable({
    providedIn: "root"
})
export class TransactionDocumentService extends ApiService<TransactionDocument>
{
    get editorForm()
    {
        let form = new FormBuilder();
        let grpFields = form.group({
            uid: [],
            name: [, Validators.required],
            fileName: [, Validators.required],
            issuedBy: [, Validators.required],
            issuedDate: [],
            statusType: [],
            fileNameUID: [],
            importPermit: [],
            documentOther: [],
            expirationDate: [],
            transactionUID: [],
            documentTypeUID: [, Validators.required],
            documentDesignationType: []
        });

        return grpFields;
    }

    constructor(httpClient: HttpClient)
    {
        super(httpClient, "transaction-document");
    }

    public upload(file: File, data: TransactionDocument)
    {
        const uploadData = new FormData();
        uploadData.append(file.name, file);

        return this.postFile("upload", uploadData).pipe(
            map(d1 =>
            {
                data.fileName = d1.fileName;
                data.fileNameUID = d1.fileNameUID;

                return d1;
            }),
            mergeMap(d2 =>
            {
                return this.save(data);
            })
        );
    }

    public update(data: TransactionDocument)
    {
        return this.save(data);
    }

    public canUploadDocument(order: Transaction)
    {
        return Util.isEmpty(order) ||
            (order.statusType != OrderStatusType.Cancelled &&
             order.statusType != OrderStatusType.Suspended);
    }
}