import { Component, Input } from "@angular/core";
import { Status } from "@app/data/model/status";

@Component({
    selector: "status-badge",
    styleUrls: ["./status-badge.component.scss"],
    templateUrl: "./status-badge.component.html",
})
export class StatusBadgeComponent
{
    @Input()
    sizeLarge: boolean = true;

    @Input()
    status: Status;

    @Input()
    statusWidth: number = null;

    @Input()
    statusType: number;

    @Input()
    statusList: any;

    public statusName: string;
    public statusClass: string;

    constructor()
    {
    }

    public ngOnChanges(): void
    {
        if (this.statusType)
        {
            let myStatus = this.statusList.find(elm => elm.id == this.statusType);

            if (!myStatus)
            {
                return;
            }

            this.statusName = myStatus.name;
            this.statusClass = this.statusName.toLowerCase().replace(" ", "");
        }
        else
        {
            this.statusName = this.status?.name;
            this.statusClass = this.status?.name.toLowerCase().replace(" ", "");
        }
    }
}