import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ApiService } from "../api/api.service";
import { CustomerContact } from "./customer-contact";

@Injectable({
    providedIn: "root"
})
export class CustomerContactService extends ApiService<CustomerContact>
{
    get editorForm()
    {
        let form = new FormBuilder();
        let grpFields = form.group(
            {
                uid: [],
                zip: [],
                city: [],
                title: [],
                street1: [],
                street2: [],
                lastName: [, Validators.required],
                stateUID: [],
                cellPhone: [],
                firstName: [, Validators.required],
                isDefault: [],
                workPhone: [],
                middleName: [],
                statusType: [],
                customerUID: [],
                emailAddress: [],
                contactTypeUID: [, Validators.required]
            });

        return grpFields;
    }

    constructor(httpClient: HttpClient)
    {
        super(httpClient, "customer-contact");
    }
}