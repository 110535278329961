import { Observable } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiService } from "../api/api.service";
import { Dispatch } from "./dispatch";

@Injectable({
    providedIn: "root"
})
export class DispatchService extends ApiService<Dispatch>
{
    constructor(httpClient: HttpClient)
    {
        super(httpClient, "dispatch");
    }

    public upload(file: File): Observable<Dispatch>
    {
        let uploadData = new FormData();

        uploadData.append(file.name, file);

        return this.postFile("upload", uploadData);
    }
}