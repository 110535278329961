import { Injectable } from "@angular/core";
import { GlobalIcons } from "@app/shared/GlobalIcons";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { ConfirmationComponent } from "./confirmation.component";

export interface IConfirmationOptions
{
    Title?: string;
    FunctionClose?: () => void;
    FunctionConfirm?: () => void;
    ButtonCloseIcon?: string;
    ButtonCloseText?: string;
    ButtonCloseColor?: string;
    ButtonConfirmIcon?: string;
    ButtonConfirmText?: string;
    ButtonConfirmColor?: string;
}

@Injectable()
export class ConfirmationService
{
    private readonly YesNoDefaults: IConfirmationOptions =
        {
            Title: "Really?",
            FunctionClose: null,
            FunctionConfirm: null,
            ButtonCloseIcon: GlobalIcons.No,
            ButtonCloseText: "No",
            ButtonCloseColor: "white",
            ButtonConfirmIcon: GlobalIcons.ThumbsUp,
            ButtonConfirmText: "Yes",
            ButtonConfirmColor: "success"
        };

    private readonly DeleteDefaults: IConfirmationOptions =
        {
            Title: "Delete?",
            FunctionClose: null,
            FunctionConfirm: null,
            ButtonCloseIcon: GlobalIcons.No,
            ButtonCloseText: "Cancel",
            ButtonCloseColor: "white",
            ButtonConfirmIcon: GlobalIcons.Trash,
            ButtonConfirmText: "Delete",
            ButtonConfirmColor: "danger"
        };

    private readonly ConfirmDefaults: IConfirmationOptions =
        {
            Title: "Confirm",
            FunctionClose: null,
            FunctionConfirm: null,
            ButtonCloseIcon: GlobalIcons.No,
            ButtonCloseText: "Cancel",
            ButtonCloseColor: "white",
            ButtonConfirmIcon: GlobalIcons.Check,
            ButtonConfirmText: "OK",
            ButtonConfirmColor: "primary"
        };

    constructor(private modalService: NgbModal)
    {
    }

    public YesNo(message: string, options: IConfirmationOptions = this.YesNoDefaults)
    {
        options = { ...this.YesNoDefaults, ...options };

        return this.Open(message, options);
    }

    public Delete(message: string, options: IConfirmationOptions = this.DeleteDefaults)
    {
        options = { ...this.DeleteDefaults, ...options };

        return this.Open(message, options);
    }

    public Open(message: string, options: IConfirmationOptions = this.ConfirmDefaults): Promise<boolean>
    {
        options = { ...this.ConfirmDefaults, ...options };

        let diOpts: NgbModalOptions =
        {
            backdrop: "static",
            centered: true
        };

        let modalRef = this.modalService.open(ConfirmationComponent, diOpts);

        modalRef.componentInstance.Title = options.Title;
        modalRef.componentInstance.Message = message;
        modalRef.componentInstance.FunctionClose = options.FunctionClose;
        modalRef.componentInstance.FunctionConfirm = options.FunctionConfirm;
        modalRef.componentInstance.ButtonCloseIcon = options.ButtonCloseIcon;
        modalRef.componentInstance.ButtonCloseText = options.ButtonCloseText;
        modalRef.componentInstance.ButtonCloseColor = options.ButtonCloseColor;
        modalRef.componentInstance.ButtonConfirmIcon = options.ButtonConfirmIcon;
        modalRef.componentInstance.ButtonConfirmText = options.ButtonConfirmText;
        modalRef.componentInstance.ButtonConfirmColor = options.ButtonConfirmColor;

        return modalRef.result;
    }
}