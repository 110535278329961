import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ApiService } from "../api/api.service";
import { PurchaserDealerConnect } from "./purchaser-dealer-connect";

@Injectable({
    providedIn: "root"
})
export class PurchaserDealerConnectService extends ApiService<PurchaserDealerConnect>
{
    public get dataForm()
    {
        let fb = new FormBuilder();
        let grp = fb.group(
            {
                zip: [],
                city: [],
                email: [, Validators.required],
                state: [],
                website: [, Validators.required],
                street1: [],
                street2: [],
                language: [],
                customerUID: [],
                contactName: [, Validators.required],
                phoneNumber: [, Validators.required],
                annualSales: [, Validators.required],
                heardOfUsUID: [],
                heardOfUsOther: [],
                annualSalesYear: [, Validators.required],
                productsOfInterest: [, Validators.required],
                bestSellingProducts: [, Validators.required],
                businessDescription: [, Validators.required],
                annualSalesCurrencyUID: [, Validators.required]
            });

        return grp;
    }

    constructor(httpClient: HttpClient)
    {
        super(httpClient, "purchaser-dealer-connect");
    }

    public saveApplication(data)
    {
        return this.save(data);
    }
}