import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { BootstrapModuleFn as Bootstrap } from "@ngxs/hmr-plugin";
import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";
import { LicenseManager } from "ag-grid-enterprise";

if (environment.production)
{
    enableProdMode();
}

const options = { preserveWhitespaces: false };
const bootstrap: Bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule, options);

options.preserveWhitespaces = true;
bootstrap().catch(err => console.log(err));

declare module "@angular/core"
{
    interface ModuleWithProviders<T = any>
    {
        ngModule: Type<T>;
        providers?: Provider[];
    }
}

LicenseManager.setLicenseKey("CompanyName=Automated Export Processing, Inc.,LicensedApplication=EasyExport,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-034923,SupportServicesEnd=22_December_2023_[v2]_MTcwMzIwMzIwMDAwMA==0257ec30f7c843b4a874b57b9717263e");