import { Component } from "@angular/core";
import { CommonSettings } from "@app/shared/common-settings";
import { GlobalIcons } from '@app/shared/GlobalIcons';
import { ContactFormatter } from "@app/utils/contact-formatter";
import { Util } from "@app/utils/util";
import { AgRendererComponent } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

@Component({
    styleUrls: ["../../../styles/address.scss"],
    templateUrl: "./grid-cell-address.html",
})
export class GridCellAddressRenderer implements AgRendererComponent
{
    public readonly Icons = GlobalIcons;

    public setWidth: string;
    public googleLink: string;
    public addressText: string;

    private field: string;
    private cellParams: ICellRendererParams;

    constructor()
    {
    }

    agInit(params)
    {
        this.field = params.field;
        this.cellParams = params;

        if (Util.isEmpty(params.width))
        {
            this.setWidth = "100%";
        }
        else
        {
            this.setWidth = params.width + "px";
        }

        let addy = this.field == null ? params.data : params.data[this.field];
        let addForm = new ContactFormatter(addy);

        this.googleLink = addForm.googleLink();
        this.addressText = addForm.getContact();
    }

    refresh(params): boolean
    {
        this.cellParams = params;
        return true;
    }

    public onMap(): void
    {
        CommonSettings.openAddressWindow(this.googleLink);
    }
}