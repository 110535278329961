import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ApiService } from "../api/api.service";
import { PurchaserContact } from "./purchaser-contact";

@Injectable({
    providedIn: "root"
})
export class PurchaserContactService extends ApiService<PurchaserContact>
{
    public get editorForm()
    {
        let form = new FormBuilder();
        let grpFields = form.group(
            {
                uid: [],
                title: [, Validators.required],
                lastName: [, Validators.required],
                cellPhone: [],
                firstName: [, Validators.required],
                isDefault: [],
                workPhone: [, Validators.required],
                middleName: [],
                statusType: [],
                emailAddress: [, [Validators.required, Validators.email]],
                purchaserContactType: []
            });

        return grpFields;
    }

    constructor(httpClient: HttpClient)
    {
        super(httpClient, "purchaser-contact");
    }
}