export const environment =
{
    apiUrl: "https://productfinder.easyexport.net/api",
    production: true,
    experianToken: "62770241-8649-4ed0-bd71-c25e86a24c97",
    auth0:
    {
        domain: "login.easyexport.net",
        clientId: "Fgq260ts0fIGsiagWRE4OpjwRSWWWea7"
    },
    goolgeMaps:
    {
        key: "AIzaSyD8T7gVAJ5fHy3oruNIconmF6_-K8JsLwA"
    }
};
