import { CommonSettings } from '@app/shared/common-settings';
import { Component, OnDestroy, OnInit } from "@angular/core";
import { AuthService, BaseComponent, CustomerService, LookupService, Product, ProductService, ProfileType } from "@app/data";
import { ProductRequestDTO } from "@app/data/product/product-request.dto";
import { NumberValidatorError } from "@app/infrastructure/form-validators/number-validator";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";

@Component({
    selector: "app-product-edit",
    templateUrl: "./product-edit.component.html"
})
export class ProductEditComponent extends BaseComponent<Product> implements OnInit, OnDestroy
{
    public get isAdmin(): boolean
    {
        return (this.userRole == ProfileType.admin);
    }

    private userRole: number;
    private subQuest: Subscription;

    constructor(
        toastrService: ToastrService,
        public merchServ: CustomerService,
        public lookupService: LookupService,
        private prodServ: ProductService,
        private authService: AuthService)
    {
        super(toastrService);

        this.dataForm = this.prodServ.editorForm;
        this.subQuest = this.prodServ.prodQuestion.subscribe((req) => { this.handleQuestion(req) });
        this.apiService = prodServ;
    }

    async ngOnInit()
    {
        let myProfile = await this.authService.getProfile$().toPromise();

        this.userRole = myProfile.profileType;
    }

    //  This is the fix for a race condiction: the HTML is already built before the data is downloaded, so we listen for the data to be changed with this:
    ngOnChanges()
    {
        this.patchValue();

        this.lookupService.getCountryList().add(() => this.fixCountry());
        this.lookupService.getProductActionList();
        this.lookupService.getProductAmmunitionList();
        this.lookupService.getProductCategoryList();
        this.lookupService.getProductSellerCategory();

        if (this.data?.uid)
        {
            if (this.isAdmin)
            {
                this.setValue("customerUID", this.data.customer.uid);
            }

            this.onSellCatChange({ clear: true, uid: this.data.sellerCategoryUID });
            this.onAEPCatChange({ clear: true, uid: this.data.categoryUID });
        }

        if (!this.isAdmin)
        {
            this.makeRequired(["customerDescription"]);
            return;
        }

        this.merchServ.getCustomerLookup();

        this.makeRequired(["customerUID", "description"]);
    }

    ngOnDestroy(): void
    {
        this.subQuest.unsubscribe();
    }

    public getNumberErrors(valErrors: any): string
    {
        let errors = NumberValidatorError.getErrors(valErrors);

        if (errors == null)
        {
            return null;
        }

        return errors.join("<br />");
    }

    public onSellCatChange(evtArg): void
    {
        if (!evtArg.clear)
        {
            this.setValue("sellerSubCategoryUID", null);
        }

        this.lookupService.productSellerSubCategory = null;

        if (evtArg.uid)
        {
            this.lookupService.getProductSellerSubCategory(evtArg.uid);
        }
    }

    public onAEPCatChange(evtArg): void
    {
        if (!evtArg.clear)
        {
            this.setValue("subCategoryUID", null);
        }

        this.lookupService.productSubCategoryList = null;

        if (evtArg.uid)
        {
            this.lookupService.getProductSubCategoryList(evtArg.uid);
        }
    }

    private fixCountry(): void
    {
        let idx = this.lookupService.countryList.findIndex(elm => elm.uid == CommonSettings.USAUID);
        let moveMe = this.lookupService.countryList.splice(idx, 1);

        this.lookupService.countryList.unshift(moveMe[0]);
    }

    private handleQuestion(question: ProductRequestDTO)
    {
        switch (question.requestID)
        {
            case ProductRequestDTO.Request_Save:
                this.saveIt(question.data);
                break;

            case ProductRequestDTO.Request_Close:
            case ProductRequestDTO.Request_TabChanged:
                let ans = new ProductRequestDTO(question.requestID, this.dataForm.dirty);

                this.prodServ.sendAnswer(ans);
                break;
        }
    }

    private saveIt(statusType)
    {
        this.setValue("statusType", statusType);

        let sub = this.submit();

        if (sub)
        {
            sub.add(() => this.submitComplete());
        }
        else
        {
            this.sendSaveComplete(null);
        }
    }

    private submitComplete()
    {
        if (this.submitError)
        {
            this.sendSaveComplete(null);
            return;
        }

        this.sendSaveComplete(this.data);
    }

    private sendSaveComplete(data: Product): void
    {
        let ans = new ProductRequestDTO(ProductRequestDTO.Request_Save, data);

        this.prodServ.sendAnswer(ans);
    }
}