import { Component, TemplateRef, ViewChild } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { BaseComponent, Profile, ProfileService } from "@app/data";
import { CommonSettings } from "@app/shared/common-settings";
import { ConfirmationService, IConfirmationOptions } from "@app/shared/confirmation/confirmation.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: "members-edit",
    templateUrl: "./members-edit.component.html"
})
export class MembersEditComponent extends BaseComponent<Profile>
{
    @ViewChild("dialogMember")
    dialogMember: TemplateRef<any>;

    constructor(
        toastrService: ToastrService,
        public modalService: NgbModal,
        private confirmService: ConfirmationService,
        private profileService: ProfileService)
    {
        super(toastrService);

        let form = new FormBuilder();
        let grpFields = form.group(
            {
                uid: [],
                auth0ID: [],
                lastName: [, Validators.required],
                firstName: [, Validators.required],
                freightUID: [],
                customerUID: [],
                profileType: [],
                emailAddress: [, Validators.required]
            });

        this.dataForm = grpFields;
        this.apiService = profileService;
        this.modalService = modalService;
    }

    public openAdd(parentData: any)
    {
        this.open(this.dialogMember);

        this.resetForm();
        this.setValue("profileType", parentData.profileType);

        switch (parentData.profileType)
        {
            case this.profileTypes.customer:
                this.setValue("customerUID", parentData.parentUID);
                break;

            case this.profileTypes.freightForwarder:
                this.setValue("freightUID", parentData.parentUID);
                break;
        }
    }

    public openEdit(member)
    {
        this.open(this.dialogMember, member);
        this.patchValue();
    }

    public onClose()
    {
        if (!this.dataForm.dirty)
        {
            this.close();
            return;
        }

        let opts: IConfirmationOptions =
        {
            FunctionConfirm: () => this.close()
        };

        this.confirmService.YesNo(CommonSettings.DirtyFormConfirmationMessage, opts);
    }

    public onSave()
    {
        let sub;

        if (this.data?.uid)
        {
            sub = this.submit("save");
        }
        else
        {
            sub = this.submit("invite");
        }

        if (sub)
        {
            sub.add(() => this.doneDidSave());
        }
    }

    private doneDidSave()
    {
        if (!this.submitError)
        {
            this.close();
        }
    }
}