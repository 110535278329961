import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { BaseComponent, LookupService, Purchaser, PurchaserService } from "@app/data";
import { CommonSettings } from "@app/shared/common-settings";
import { ConfirmationService, IConfirmationOptions } from "@app/shared/confirmation/confirmation.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: "purchaser-sub-category-edit",
    templateUrl: "./purchaser-sub-category-edit.component.html"
})
export class PurchaserSubCategoryComponent extends BaseComponent<Purchaser> implements OnInit
{
    @ViewChild("dialogSubCat")
    dialogSubCat: ElementRef;

    constructor(
        toastrService: ToastrService,
        public modalService: NgbModal,
        public lookupService: LookupService,
        private confirmService: ConfirmationService,
        private purchaserService: PurchaserService)
    {
        super(toastrService);

        this.apiService = purchaserService;
        this.modalService = modalService;
    }

    ngOnInit(): void
    {
        let form = new FormBuilder();

        this.dataForm = form.group(
            {
                purchaserSubCategoryUID: []
            });

        this.lookupService.getPurchaserSubCategoryTypeList();
    }

    public openEdit(purch: Purchaser): void
    {
        this.open(this.dialogSubCat, purch);
    }

    public onClose(): void
    {
        if (!this.dataForm.dirty)
        {
            this.close();
            return;
        }

        let opts: IConfirmationOptions =
        {
            FunctionConfirm: () => this.close()
        };

        this.confirmService.YesNo(CommonSettings.DirtyFormConfirmationMessage, opts);
    }

    public onSave(): void
    {
        this.loading = true;
        this.data.purchaserSubCategoryUID = this.getValue("purchaserSubCategoryUID");

        let sub = this.purchaserService.save(this.data).subscribe(
            retRes =>
            {
                if (this.update)
                {
                    this.update.emit(this.data);
                }

                this.close();
            },
            errRes => this.error(errRes));

        sub.add(() => this.loading = false);
    }
}