import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AppService } from "@app/app.service";
import { ProfileType } from "@app/data";
import { ProductURLCheck } from "@app/data/product/product-url-check/product-url-check.model";
import { ProductURLCheckService } from "@app/data/product/product-url-check/product-url-check.service";
import { GridBase } from "@app/shared/ag-grid-helpers/ag-grid-base";
import { GridCellBoolean } from "@app/shared/ag-grid-helpers/models/grid-cell-boolean.model";
import { GridColumnBoolean } from "@app/shared/ag-grid-helpers/models/grid-column-boolean";
import { GridColumnMerchant } from '@app/shared/ag-grid-helpers/models/grid-column-merchant';
import { GridColumnPartNumber } from "@app/shared/ag-grid-helpers/models/grid-column-part-number";
import { GridColumn } from "@app/shared/ag-grid-helpers/models/grid-column.model";
import { ToastrService } from "ngx-toastr";

@Component({
    templateUrl: "product-url-check-list.html"
})
export class ProductURLCheckList extends GridBase<ProductURLCheck>
{
    private userRole: number;

    constructor(
        toastrService: ToastrService,
        private appService: AppService,
        private activeRoute: ActivatedRoute,
        private urlCheckServ: ProductURLCheckService)
    {
        super(toastrService, urlCheckServ);

        this.title = "Link Validation";
        this.userRole = this.activeRoute.snapshot.data.userType;
        this.appService.pageTitle = this.title;

        this.setupGrid();
    }

    public onMyGridReady(evtArg: any): void
    {
        this.filterDefault("isValidURL", false);

        this.onGridReady(evtArg);
    }

    private setupGrid(): void
    {
        this.storageKey = "ProductURLCheck";
        if (this.userRole == ProfileType.admin)
        {
            this.addColumn(new GridColumnMerchant("customer.displayName", "customer.uid"));
        }
        this.addColumn(new GridColumnBoolean("isValidURL", "Valid?", new GridCellBoolean(), 120));
        this.addColumn(new GridColumnPartNumber("product"));
        this.addColumn(new GridColumn("urlTypeName", "Type"));
        this.addColumn(new GridColumn("url", "URL"));
        this.addColumn(new GridColumn("scrapedImageURL", "Scraped Image"));

        this.setServerPath("list");
    }
}