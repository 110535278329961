import { Component, ElementRef, ViewChild } from "@angular/core";
import { HistoryListCommon } from "@app/admin/history-list-common/history-list-common";
import { PurchaserOfficerHistory, PurchaserOfficerHistoryService } from "@app/data";
import { CommonSettings } from "@app/shared/common-settings";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: "purchaser-officer-history-list",
    templateUrl: "./purchaser-officer-history-list.component.html",
})
export class PurchaserOfficerHistoryListComponent extends HistoryListCommon<PurchaserOfficerHistory>
{
    @ViewChild("dialogHistory")
    dialogHistory: ElementRef;

    constructor(
        toastrService: ToastrService,
        public modalService: NgbModal,
        private purchaserOfficerHistoryService: PurchaserOfficerHistoryService)
    {
        super(toastrService, purchaserOfficerHistoryService);

        this.modalService = modalService;

        this.setupGrid("Purchaser-Officer-History");
    }

    public openHistory(offUID: string): void
    {
        this.open(this.dialogHistory, null, CommonSettings.DialogSize_XLarge);
        this.getHistory({ purchaserOfficerUID: offUID });
    }

    public onClose(): void
    {
        this.close();
    }
}