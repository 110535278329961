import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { ApiService } from "../api/api.service";
import { PurchaserReview } from "./purchaser-review";

@Injectable({
    providedIn: "root"
})
export class PurchaserReviewService extends ApiService<PurchaserReview>
{
    public get editorForm()
    {
        let form = new FormBuilder();
        let grpFields = form.group(
            {
                comments: [],
                statusType: [],
                purchaserUID: [],
                isAutoCompleteVettingTask: []
            });

        return grpFields;
    }

    constructor(httpClient: HttpClient)
    {
        super(httpClient, "purchaser-review");
    }
}