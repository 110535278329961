import { GridBooleanFilter } from "../filters/grid-boolean-filter/grid-boolean-filter.component";
import { GridCellBooleanRenderer } from "../renderers/grid-cell-boolean/grid-cell-boolean-renderer";
import { GridCellBoolean } from "./grid-cell-boolean.model";
import { GridColumn } from "./grid-column.model";

export class GridColumnBoolean extends GridColumn
{
    constructor(field: string, header: string, bools: GridCellBoolean = new GridCellBoolean(), width: number = 100)
    {
        super(field, header);

        this.width = width;
        this.filter = "gridBoolFilter";
        this.resizable = false;
        this.filterParams = { field: field, booleans: bools };
        this.suppressSizeToFit = true;
        this.cellRendererParams = { bools: bools };
        this.cellRendererFramework = GridCellBooleanRenderer;

        this.registerComponents.push({ gridBoolFilter: GridBooleanFilter });
    }
}