//  The AgmMap (1.1.0) is pretty buggy and the latest version isn't supported in Angular 10.
//  currentZoom are work-around vars because ViewChild isn't picking up the agm-map and 2-way binding isn't working

import { Component, ElementRef, ViewChild } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { BaseComponent, PurchaserAddress } from "@app/data";
import { PurchaserAddressImageType } from "@app/data/enums/purchaser-address-image-type.enum";
import { FixedImage } from "@app/data/misc/fixed-image";
import { PurchaserAddressImage } from "@app/data/purchaser-address/purchaser-address-image.model";
import { PurchaserAddressImageService } from "@app/data/purchaser-address/purchaser-address-image.service";
import { CommonSettings } from "@app/shared/common-settings";
import { ConfirmationService, IConfirmationOptions } from "@app/shared/confirmation/confirmation.service";
import { GoogleMapsType, GoogleMapsZoom } from "@app/shared/google-maps/google-conts";
import { GoogleMapsHelperService } from "@app/shared/google-maps/google-maps-helper.service";
import { GoogleMapsCoordinates } from "@app/shared/google-maps/models/google-maps-coordinates.model";
import { GoogleMapsMapOption } from "@app/shared/google-maps/models/google-maps-map-option.model";
import { GoogleMapsStreetOption } from "@app/shared/google-maps/models/google-maps-street-option.model";
import { ContactFormatter } from "@app/utils/contact-formatter";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { forkJoin } from "rxjs";
import { PurchaserAddressVerificationStreet } from "../purchaser-address-verification-street/purchaser-address-verification-street";

@Component({
    selector: "purchaser-address-verification-capture",
    styleUrls: ["./purchaser-address-verification-capture.component.scss"],
    templateUrl: "./purchaser-address-verification-capture.component.html"
})
export class PurchaserAddressVerificationCapture extends BaseComponent<PurchaserAddressImage>
{
    @ViewChild("dialogCapture")
    dialogCapture: ElementRef;

    @ViewChild("dialogStreet")
    dialogStreet: PurchaserAddressVerificationStreet;

    public get canSave(): boolean
    {
        return (!this.loading || this.imgRoad != null || this.imgSat != null || this.imgSatWide != null || this.imgStreet != null);
    }

    public get haveImage(): boolean
    {
        return (this.imgRoad != null || this.imgSat != null || this.imgSatWide != null || this.imgStreet != null);
    }

    public readonly imageType_Road = 1;
    public readonly imageType_Street = 2;
    public readonly imageType_Satellite = 3;
    public readonly imageType_SatelliteWide = 4;

    public imgSat: FixedImage;
    public imgRoad: FixedImage;
    public imgStreet: FixedImage;
    public imgSatWide: FixedImage;

    public inSat: string;
    public inRoad: string;
    public inStreet: string;
    public inSatWide: string;

    public addyLat: number;
    public addyLon: number;
    public mapType: string = GoogleMapsType.Hybrid;
    public wideOffset: number = 2;
    public displayAddress: string;

    private addyUID: string;
    private isDirty: boolean;
    private currentZoom: number;

    constructor(
        toastrService: ToastrService,
        public modalService: NgbModal,
        private sanitizer: DomSanitizer,
        private confirmService: ConfirmationService,
        private mapsHelperServ: GoogleMapsHelperService,
        private purchaserImgServ: PurchaserAddressImageService)
    {
        super(toastrService);

        this.dataForm = this.purchaserImgServ.editorForm;
        this.apiService = purchaserImgServ;
        this.modalService = this.modalService;
    }

    public openCapture(purchaserAddy: PurchaserAddress): void
    {
        this.open(this.dialogCapture, null, CommonSettings.DialogSize_XLarge);

        let contForm = new ContactFormatter(purchaserAddy);

        this.inSat = null;
        this.imgSat = null;
        this.inRoad = null;
        this.addyUID = purchaserAddy.uid;
        this.imgRoad = null;
        this.inStreet = null;
        this.imgStreet = null;
        this.inSatWide = null;
        this.imgSatWide = null;
        this.currentZoom = GoogleMapsZoom.Buildings;
        this.displayAddress = contForm.getContact();

        this.mapsHelperServ.getCoordinates(purchaserAddy).subscribe(
            retRes => this.loadMap(retRes),
            errRes => this.error(errRes));
    }

    public onMapClick(evtArg): void
    {
        this.addyLat = evtArg.coords.lat;
        this.addyLon = evtArg.coords.lng;
    }

    public onImageDelete(imgType: number): void
    {
        switch (imgType)
        {
            case this.imageType_Road:
                this.inRoad = null;
                this.imgRoad = null;
                break;

            case this.imageType_Satellite:
                this.inSat = null;
                this.imgSat = null;
                break;

            case this.imageType_Street:
                this.inStreet = null;
                this.imgStreet = null;
                break;

            case this.imageType_SatelliteWide:
                this.inSatWide = null;
                this.imgSatWide = null;
                break;
        }
    }

    public onStreetTweak(): void
    {
        this.dialogStreet.openStreet(this.addyLat, this.addyLon, this.imgStreet);
    }

    public onStreetSave(evtArg): void
    {
        this.imgStreet = evtArg;
    }

    public onMapZoomChange(evtArg: number): void
    {
        this.currentZoom = evtArg;
    }

    public onCapture(): void
    {
        let cords = new GoogleMapsCoordinates(this.addyLat, this.addyLon);
        let optSat = new GoogleMapsMapOption(GoogleMapsType.Hybrid);
        let optRoad = new GoogleMapsMapOption();
        let optSatWde = new GoogleMapsMapOption(GoogleMapsType.Hybrid);
        let optStreet = new GoogleMapsStreetOption();

        optSat.Zoom = this.currentZoom;
        optRoad.Zoom = this.currentZoom
        optSatWde.Zoom = (this.currentZoom - this.wideOffset);

        this.inSat = "Aerial tight";
        this.inRoad = "Map";
        this.isDirty = true;
        this.inStreet = "Street";
        this.inSatWide = "Aerial wide";

        this.mapsHelperServ.getMapImage(cords, optRoad).subscribe(
            retRes => this.imgRoad = retRes,
            errRes => console.error(errRes));

        this.mapsHelperServ.getMapImage(cords, optSat).subscribe(
            retRes => this.imgSat = retRes,
            errRes => console.error(errRes));

        this.mapsHelperServ.getMapImage(cords, optSatWde).subscribe(
            retRes => this.imgSatWide = retRes,
            errRes => console.error(errRes));

        this.mapsHelperServ.getStreetImage(cords, optStreet).subscribe(
            retRes => this.imgStreet = retRes,
            errRes => console.error(errRes));
    }

    public onImageClick(imageType: number): void
    {
        let win = window.open("");
        let img: string;

        switch (imageType)
        {
            case this.imageType_Road:
                img = this.imgRoad.url;
                break;

            case this.imageType_Street:
                img = this.imgStreet.url;
                break;

            case this.imageType_Satellite:
                img = this.imgSat.url;
                break;

            case this.imageType_SatelliteWide:
                img = this.imgSatWide.url;
                break;
        }

        win.document.write(`<img src="${img}" />`);
    }

    public onClose(): void
    {
        if (!this.isDirty)
        {
            this.close();
            return;
        }

        let opts: IConfirmationOptions =
        {
            FunctionConfirm: () => this.close()
        };

        this.confirmService.YesNo(CommonSettings.DirtyFormConfirmationMessage, opts);
    }

    public onSave(): void
    {
        let workers = [];

        if (this.imgSat != null)
        {
            let recSat: PurchaserAddressImage =
            {
                name: this.inSat,
                imageURL: this.imgSat.sourcesURL,
                imageType: PurchaserAddressImageType.AerialView,
                purchaserAddressUID: this.addyUID
            }
            let obSat = this.purchaserImgServ.upload(this.imgSat.rawImage, recSat);

            workers.push(obSat);
        }

        if (this.imgSatWide != null)
        {
            let recSat: PurchaserAddressImage =
            {
                name: this.inSatWide,
                imageURL: this.imgSatWide.sourcesURL,
                imageType: PurchaserAddressImageType.AerialView,
                purchaserAddressUID: this.addyUID
            }
            let obSatWide = this.purchaserImgServ.upload(this.imgSatWide.rawImage, recSat);

            workers.push(obSatWide);
        }

        if (this.imgRoad != null)
        {
            let recRoad: PurchaserAddressImage =
            {
                name: this.inRoad,
                imageURL: this.imgRoad.sourcesURL,
                imageType: PurchaserAddressImageType.Direction,
                purchaserAddressUID: this.addyUID
            }
            let obRoad = this.purchaserImgServ.upload(this.imgRoad.rawImage, recRoad);

            workers.push(obRoad);
        }

        if (this.imgStreet != null)
        {
            let recStreet: PurchaserAddressImage =
            {
                name: this.inStreet,
                imageURL: this.imgStreet.sourcesURL,
                imageType: PurchaserAddressImageType.StreetView,
                purchaserAddressUID: this.addyUID
            }
            let obStreet = this.purchaserImgServ.upload(this.imgStreet.rawImage, recStreet);

            workers.push(obStreet);
        }

        if (workers.length == 0)
        {
            return;
        }

        this.loading = true;

        let sub = forkJoin(workers).subscribe(
            retRes => this.saveComplete(),
            errRes => this.error(errRes));

        sub.add(() => this.loading = false);
    }

    private loadMap(cords: GoogleMapsCoordinates): void
    {
        this.addyLat = cords.latitude;
        this.addyLon = cords.longitude;
    }

    private saveComplete(): void
    {
        this.close();

        if (this.update)
        {
            this.update.emit();
        }
    }
}