import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { CustomerService, License, LicenseItem, LicenseItemService, LicenseService, LicenseTemplate, LicenseTemplateService, LookupService, Purchaser, PurchaserService } from "@app/data";
import { LicenseStatus } from "@app/data/enums/license-status-type.enum";
import { LicenseType } from "@app/data/enums/license-type.enum";
import { PurchaserType } from "@app/data/enums/purchaser-type.enum";
import { FreightForwarderLookupService } from "@app/data/freight/freight-forwarder-lookup.service";
import { LicenseCommon } from "@app/data/license/license-common";
import { QandAData } from "@app/data/misc/q-and-a-data.model";
import { GridBase } from "@app/shared/ag-grid-helpers/ag-grid-base";
import { GridCellNumberEditorComponent } from "@app/shared/ag-grid-helpers/components/grid-cell-number-editor/grid-cell-number-editor.component";
import { GridHelpers } from "@app/shared/ag-grid-helpers/grid-helper";
import { GridColumnIcon } from "@app/shared/ag-grid-helpers/models/grid-column-icon.model";
import { GridColumnMeter } from "@app/shared/ag-grid-helpers/models/grid-column-meter";
import { GridColumn, GridColumnType } from "@app/shared/ag-grid-helpers/models/grid-column.model";
import { DateHelper } from "@app/utils/date-helper";
import { Util } from "@app/utils/util";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";

@Component({
    selector: "app-license-edit",
    styleUrls: ["./license-edit.component.scss"],
    templateUrl: "./license-edit.component.html",
    encapsulation: ViewEncapsulation.None
})
export class LicenseEditComponent extends GridBase<License> implements OnInit, OnDestroy
{
    @Input()
    licenseType: number;

    @ViewChild("tableBody")
    tableBody: ElementRef;

    public readonly licenseStatuses = LicenseStatus;

    public refName: string;
    public commName: string;
    public newLicense: boolean;
    public countryName: string;
    public liceTemplate: string;
    public showQuantityWarning: boolean = false;

    private liceAns: Subscription;
    private isClosing: boolean;
    private liceItems: LicenseItem[];
    private liceQuest: Subscription;

    constructor(
        toastrService: ToastrService,
        public ffLookupServ: FreightForwarderLookupService,
        public lookupService: LookupService,
        public customerService: CustomerService,
        public purchaserService: PurchaserService,
        public licenseTemplateService: LicenseTemplateService,
        private licenseService: LicenseService,
        private licenseItemService: LicenseItemService)
    {
        super(toastrService, licenseItemService);

        this.liceAns = this.licenseService.answerHandeler.subscribe((request) => { this.handleAnswer(request) });
        this.dataForm = this.licenseService.editorForm;
        this.liceQuest = this.licenseService.questionHandler.subscribe((request) => { this.handleQuestion(request) });
        this.apiService = licenseService;
        this.autoHandleLoading = false;
    }

    ngOnInit(): void
    {
        this.newLicense = (this.data == null);

        if (this.licenseType == LicenseType.ear)
        {
            this.refName = "Reference";
            this.commName = "Comments / Conditions / Riders";
        }
        else
        {
            this.refName = "Transaction";
            this.commName = "Comments / Provisos";
        }

        this.setValue("licenseType", this.licenseType);
        this.setupGrid();

        if (this.newLicense)
        {
            this.customerService.getCustomerLookup();
            this.purchaserService.getPurchaserLookup();
            return;
        }

        this.ffLookupServ.getLookup({ countryUID: this.data.destinationCountryUID });

        this.plugLicense();
        this.loadItems({ licenseUID: this.data.uid });
    }

    ngOnDestroy()
    {
        this.liceAns.unsubscribe();
        this.liceQuest.unsubscribe();
    }

    public onMyGridReady(evtArg)
    {
        this.onGridReady(evtArg, false);
    }

    public onGetRowClass(params)
    {
        if ((params.data.quantity - params.data.origValue) < 0)
        {
            return "has-warning";
        }

        return null;
    }

    public onEditStop(evtArg)
    {
        let row = evtArg.data;
        let ogQty = row.origValue;
        let newQty = row.quantity;

        row.amount = newQty * row.unitAmount;
        row.percentage = this.calcPercent(row);

        this.mainGrid.applyTransaction({ update: [row] });
        this.dataForm.markAsDirty();

        if (this.data?.statusType != LicenseStatus.Active)
        {
            this.showQuantityWarning = false;
            return;
        }

        if ((newQty - ogQty) < 0)
        {
            this.showQuantityWarning = true;
        }
    }

    public onPurchaserSelected(evtArg): void
    {
        if (Util.isEmpty(evtArg))
        {
            this.ffLookupServ.lookupList = [];
            this.licenseTemplateService.lookupList = [];

            this.gridPopulate(null);
            this.setValue("destinationCountryUID", null);
            return;
        }

        this.purchaserService.detail({ uid: evtArg.uid })
            .subscribe(data => this.filterPurchaserData(data));
    }

    public onGetLicenseItems(liceItem?: LicenseItem): void
    {
        this.loadItems({ licenseTemplateUID: liceItem.uid });
    }

    private setupGrid()
    {
        let optsOverride =
        {
            filter: null,
            sortable: false,
            resizable: false,
            suppressMenu: true,
        };

        let name = (this.licenseType == LicenseType.ear ? "EAR" : "USML");
        let colAmt = new GridColumn("amount", "Amount", GridColumnType.Money);
        let colCat = new GridColumn("earCategory", name);
        let colQty = new GridColumn("quantity", "Qty", GridColumnType.Number);
        let colPer = new GridColumnMeter("remainingLicensePercent", "Percent");
        let colLine = new GridColumn("exportLineItem", "#", GridColumnType.Number);
        let colUAmt = new GridColumn("unitAmount", "Unit Amount", GridColumnType.Money)
        let colRemAmt = new GridColumn("remainingAmount", "Remain Amt", GridColumnType.Number);
        let colRemQty = new GridColumn("remainingQuantity", "Remain Qty", GridColumnType.Number);
        let gridIcons = GridHelpers.MakeEditIcon(this.onEdit.bind(this));

        this.storageKey = "LicenseItemsList";
        this.gridOpts.sideBar = null;
        this.gridOpts.domLayout = GridHelpers.DOMLayout_Normal;
        this.gridOpts.paginationPageSize = 15;

        colAmt = Object.assign(colAmt, optsOverride);
        colAmt.width = 100;

        colCat = Object.assign(colCat, optsOverride);

        colPer = Object.assign(colPer, optsOverride);
        colPer.width = 150;

        colQty = Object.assign(colQty, optsOverride);
        colQty.width = 100;
        colQty.editable = true;
        colQty.cellEditor = "gridCellNumberEditorComponent";
        colQty.cellEditorParams = { min: 0, max: { data: "remaining" } };

        colLine = Object.assign(colLine, optsOverride);
        colLine.width = 50;

        colUAmt = Object.assign(colUAmt, optsOverride);
        colUAmt.width = 100;

        colRemAmt = Object.assign(colRemAmt, optsOverride);
        colRemAmt.width = 75;

        colRemQty = Object.assign(colRemQty, optsOverride);
        colRemQty.width = 75;

        this.addColumn(colLine);
        this.addColumn(colCat);
        this.addColumn(colQty);
        this.addColumn(colUAmt);
        this.addColumn(colAmt);
        this.addColumn(colRemQty);
        this.addColumn(colRemAmt);
        this.addColumn(colPer);
        this.addColumn(new GridColumnIcon([ gridIcons ]));

        this.rowDoubleClick(this.onEdit.bind(this));

        this.registerCustomComponents({ gridCellNumberEditorComponent: GridCellNumberEditorComponent });
    }

    private onEdit(agData: any)
    {
        let idx = this.liceItems.findIndex(cond => cond.exportLineItem == agData.exportLineItem);

        this.mainGrid.startEditingCell({ colKey: "quantity", rowIndex: idx });
    }

    private loadItems(filter: any): void
    {
        this.licenseItemService.list(filter).subscribe(
            retRes => this.popItems(retRes),
            errRes => this.error(errRes));
    }

    private popItems(retRes): void
    {
        this.liceItems = retRes.results;

        for (let item of this.liceItems)
        {
            item["origValue"] = item.quantity - item.remainingQuantity;

            if (this.newLicense)
            {
                item["quantity"] = 0;
            }
        }

        this.loadingOff();
        this.gridPopulate(this.liceItems);
    }

    private calcPercent(data: LicenseItem): number
    {
        let amt = data.amount;
        let qty = data.quantity;

        if (Util.isEmpty(amt))
        {
            amt = 0;
        }
        else
        {
            amt = Util.roundTo(data.remainingAmount / amt, 2);
        }

        if (Util.isEmpty(qty))
        {
            qty = 0;
        }
        else
        {
            qty = Util.roundTo(data.remainingQuantity / qty, 2);
        }

        return Math.min(qty, amt);
    }

    private handleAnswer(answer: QandAData)
    {
        switch (answer.taskID)
        {
        }
    }

    private handleQuestion(question: QandAData): void
    {
        switch (question.taskID)
        {
            case LicenseCommon.qaData_Save:
            case LicenseCommon.qaData_Release:
                this.saveLicense(question);
                break;

            case LicenseCommon.qaData_Dirty:
                this.sendDirty();
                break;

            case LicenseCommon.qaData_Update:
                this.data = question.data;
                this.plugLicense();
                break;
        }
    }

    private sendDirty(): void
    {
        let ans = new QandAData(LicenseCommon.qaData_Dirty, this.dataForm.dirty);

        this.licenseService.sendAnswer(ans);
    }

    private saveLicense(question: QandAData): void
    {
        let path: string;

        if (question.taskID == LicenseCommon.qaData_Release)
        {
            path = "release";

            this.makeRequired(["issueDate", "licenseNumber", "expirationDate"]);
        }
        else
        {
            path = "save";

            this.makeRequired(["issueDate", "licenseNumber", "expirationDate"], false);
        }

        this.loadingOn("Saving...");

        let sub = this.submit(path);

        this.isClosing = question.data.close;

        if (sub)
        {
            sub.add(() => this.saveItems());
        }
        else
        {
            this.loadingOff();
        }
    }

    private saveItems(): void
    {
        if (this.submitError)
        {
            this.loadingOff();
            return;
        }

        if (Util.isEmpty(this.liceItems))
        {
            this.loadingOff();
            this.sendSaveComplete();
            return;
        }

        this.liceItems.forEach(elm => elm.licenseUID = this.uid);

        this.licenseItemService.saveList(this.liceItems).subscribe(
            retRes => this.saveItemsComplete(),
            errRes => this.error(errRes));
    }

    private saveItemsComplete(): void
    {
        this.sendSaveComplete();

        if (this.isClosing)
        {
            return;
        }

        this.loadItems({ licenseUID: this.uid })
    }

    private sendSaveComplete(): void
    {
        let ans = new QandAData(LicenseCommon.qaData_Save, { uid: this.data.uid });

        this.newLicense = false;
        this.dataForm.markAsPristine();

        this.licenseService.sendAnswer(ans);
    }

    private setFirstFreight(): void
    {
        setTimeout(() =>
        {
            if (Util.isEmpty(this.ffLookupServ.lookupList))
            {
                this.setValue("freightUID", null);
            }
            else
            {
                this.setValue("freightUID", this.ffLookupServ.lookupList[0].uid);
            }
        }, 200);
    }

    private setFirstTemplate(): void
    {
        if (Util.isEmpty(this.licenseTemplateService.lookupList))
        {
            this.liceTemplate = null;
            return;
        }

        setTimeout(() =>
        {
            let uid = this.licenseTemplateService.lookupList[0].uid;

            this.liceTemplate = uid;
            this.onGetLicenseItems({ uid: uid });
        }, 200);
    }

    private filterPurchaserData(purchaser: Purchaser): void
    {
        let merch = this.getValue("customerUID");
        let tempFilter: LicenseTemplate =
        {
            customerUID: merch,
            licenseType: this.licenseType
        };

        switch (purchaser.purchaserType)
        {
            case PurchaserType.Individual:
                tempFilter.isIndividual = true;
                break;

            case PurchaserType.Business:
                tempFilter.isBusiness = true;
                break;

            case PurchaserType.Government:
                tempFilter.isGovernment = true;
                break;
        }

        this.countryName = purchaser.country?.name;

        this.setValue("destinationCountryUID", purchaser.countryUID);

        this.licenseTemplateService.getLookup(tempFilter)
            .add(() => this.setFirstTemplate());

        this.ffLookupServ.getLookup({ countryUID: purchaser.countryUID })
            .add(() => this.setFirstFreight());
    }

    private plugLicense(): void
    {
        this.patchValue();

        this.countryName = this.data.destinationCountry?.name;

        this.setValue("customerUID", this.data.customer.uid);
        this.setValue("purchaserUID", this.data.purchaser.uid);
    }
}