import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiService, TransactionProduct } from "@app/data";
import { Util } from "@app/utils/util";

@Injectable({
    providedIn: "root"
})
export class TransactionProductService extends ApiService<TransactionProduct>
{
    constructor(httpClient: HttpClient)
    {
        super(httpClient, "transactionproduct");
    }

    public makePermitArray(product: TransactionProduct)
    {
        if (Util.isEmpty(product))
        {
            return [];
        }

        let permits = [];

        if (!Util.isEmpty(product.importPermit1))
        {
            permits.push(product.importPermit1);
        }

        if (!Util.isEmpty(product.importPermit2))
        {
            permits.push(product.importPermit2);
        }

        if (!Util.isEmpty(product.importPermit3))
        {
            permits.push(product.importPermit3);
        }

        return permits;
    }

    public makeMessageArray(product: TransactionProduct)
    {
        if (Util.isEmpty(product))
        {
            return [];
        }

        let messages = [];

        for (let lp = 0; lp < 5; lp++)
        {
            if (!Util.isEmpty(product["message" + lp]))
            {
                messages.push({ image: product["urL" + lp], text: product["message" + lp], tooltip: product["altText" + lp] });
            }
        }

        return messages;
    }

    public saveStatus(orderUID, status: number)
    {
        return this.save({ uid: orderUID, statusType: status });
    }
}