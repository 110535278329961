import { Component, OnInit, ViewChild } from "@angular/core";
import { AuthService, CustomerAddress, CustomerAddressService, LookupService } from "@app/data";
import { GridBase } from "@app/shared/ag-grid-helpers/ag-grid-base";
import { GridHelpers } from "@app/shared/ag-grid-helpers/grid-helper";
import { GridColumnAddress } from "@app/shared/ag-grid-helpers/models/grid-column-address";
import { GridColumnCustom } from "@app/shared/ag-grid-helpers/models/grid-column-custom.model";
import { GridColumnIcon } from "@app/shared/ag-grid-helpers/models/grid-column-icon.model";
import { GridColumn, GridColumnType } from '@app/shared/ag-grid-helpers/models/grid-column.model';
import { CommonSettings } from "@app/shared/common-settings";
import { ConfirmationService, IConfirmationOptions } from "@app/shared/confirmation/confirmation.service";
import { AddressDialogComponent } from "@app/shared/misc-components/address-dialog/address-dialog.component";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: "customer-address-list",
    templateUrl: "./customer-address-list.component.html"
})
export class CustomerAddressListComponent extends GridBase<CustomerAddress> implements OnInit
{
    @ViewChild("dialogAddress")
    dialogAddress: AddressDialogComponent;

    constructor(toastrService: ToastrService,
        public lookupService: LookupService,
        private authService: AuthService,
        private addressServ: CustomerAddressService,
        private confirmService: ConfirmationService)
    {
        super(toastrService, addressServ);

        this.setupGrid();
    }

    ngOnInit(): void
    {
        this.lookupService.getCustomerAddressTypeList();
    }

    public onMyGridReady(evtArg)
    {
        this.filterAdd("customerUID", this.parentUID, false);

        this.onGridReady(evtArg);
    }

    public onAdd()
    {
        this.dialogAddress.openAdd(CommonSettings.USAUID);
    }

    public onAddressSave(addressData)
    {
        let saveMe = Object.assign(addressData, { customerUID: this.parentUID });

        this.addressServ.post("save", saveMe).subscribe(
            retRes =>
            {
                this.gridRefresh();
                this.dialogAddress.close();
            },
            errRes => this.error(errRes)
        );
    }

    private setupGrid()
    {
        let gridIcons =
            [
                GridHelpers.MakeEditIcon(this.onEdit.bind(this)),
                GridHelpers.MakeDeleteIcon(this.onDelete.bind(this)),
            ];

        this.storageKey = "Merchant-Address";
        this.gridOpts.domLayout = GridHelpers.DOMLayout_Normal;
        this.gridOpts.rowHeight = 75;
        this.gridOpts.paginationPageSize = 10;

        this.addColumn(new GridColumnCustom("addressType.name", "Type", { width: 125 }));
        this.addColumn(new GridColumnAddress(null, "Address"));
        this.addColumn(new GridColumn("workPhone1", "Phone 1", GridColumnType.Phone));
        this.addColumn(new GridColumn("workPhone2", "Phone 2", GridColumnType.Phone));
        this.addColumn(new GridColumnIcon(gridIcons));

        this.rowDoubleClick(this.onEdit.bind(this));
    }

    private onEdit(agData)
    {
        this.dialogAddress.openEdit(agData);
    }

    private onDelete(agData)
    {
        this.dataForm = this.makeForm(agData);

        let opts: IConfirmationOptions =
        {
            FunctionConfirm: () => this.deleteIt()
        };

        this.confirmService.Delete("Are you sure you want to delete this Address?", opts);
    }

    private deleteIt()
    {
        this.delete().add(() => this.gridRefresh());
    }
}