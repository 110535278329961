export class MerchantRequestDTO
{
    public static readonly Request_Save = 1;
    public static readonly Request_Close = 2;
    public static readonly Request_DirtyNav = 3
    public static readonly Request_DirtyClose = 4;
    public static readonly Request_TabChanged = 5;
    public static readonly Request_LogoChanged = 6;

    data: any;
    requestID: number;

    constructor(requestID: number = 0, data: any = null)
    {
        this.data = data;
        this.requestID = requestID;
    }
}