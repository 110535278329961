import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { GlobalIcons } from '@app/shared/GlobalIcons';
import { Util } from "@app/utils/util";
import { ISortColumn } from "./sort-helper-column";

@Component({
    selector: "sort-helper",
    styleUrls: ["sort-helper.component.scss"],
    templateUrl: "./sort-helper.component.html"
})
export class SortHelperComponent implements OnInit
{
    public readonly Icons = GlobalIcons;

    private static readonly SortDir_Asc = "asc";
    private static readonly SortDir_Desc = "desc";

    @Input("columns")
    columnList: ISortColumn[];

    @Input()
    disabled: boolean;

    @Input()
    showLabel: boolean = true;

    @Input()
    defaultDirection: string = SortHelperComponent.SortDir_Asc;

    @Output()
    sortChanged = new EventEmitter();

    public sortingDir: number = 0;
    public sortingColumn: number = 0;

    public sortDirs =
        [
            { name: "Asc", val: SortHelperComponent.SortDir_Asc, icon: "sort-alpha-down" },
            { name: "Desc", val: SortHelperComponent.SortDir_Desc, icon: "sort-alpha-down-alt" }
        ];

    get columns()
    {
        return this.columnList;
    }

    set columns(val)
    {
        this.columnList = val;

        this.setDefaults();
    }

    ngOnInit(): void
    {
        this.setDefaults();
    }

    public onSortColumn(idx: number)
    {
        this.sortingColumn = idx;
        this.sendSort();
    }

    public onSortDir(idx: number)
    {
        this.sortingDir = idx;
        this.sendSort();
    }

    public onReset()
    {
        this.setDefaults();
        this.sendSort();
    }

    private sendSort()
    {
        let evtArg =
        {
            column: this.columnList[this.sortingColumn].column,
            direction: this.sortDirs[this.sortingDir].val
        };

        this.sortChanged.emit(evtArg);
    }

    private setDefaults()
    {
        if (this.defaultDirection == SortHelperComponent.SortDir_Desc)
        {
            this.sortingDir = 1;
        }
        else
        {
            this.sortingDir = 0;
        }

        if (!Util.isEmpty(this.columnList))
        {
            let defCol = this.columnList.findIndex(cond => cond.default === true);

            if (defCol != -1)
            {
                this.sortingColumn = defCol;
            }
        }
    }
}