export enum OrderProductStatusType
{
    active = 1,
    clearedForShipment = 3,
    complete = 4,
    failed = 5,
    hold = 10,
    suspended = 11,
    cancelled = 13,
    partNumberNotFound = 20,
    productNotActive = 21,
    productIsNotExportable = 22,
    invalidShipCountry = 23,
    productNotImportable = 24,
    extendedCountryNotExportable = 25,
    productPurchaserNotImportable = 26,
    productIsIneligible = 27,
    productNeedFirearmLicense = 28
}