import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { ApiService } from "../api/api.service";
import { OrdersNotificationRequest } from "./order-notification-request.model";

@Injectable({
    providedIn: "root"
})
export class OrderNotificationRequestService extends ApiService<OrdersNotificationRequest>
{
    public get dataForm()
    {
        let fb = new FormBuilder();
        let grp = fb.group(
            {
                statusType: [],
                customerUID: [],
                purchaserUID: []
            });

        return grp;
    }

    constructor(httpClient: HttpClient)
    {
        super(httpClient, "notification-requests");
    }
}