import { Util } from "@app/utils/util";

export class ContactFormatter
{
    public contactData: any;

    public delimiter: string = "<br />";
    public fieldPrefix: string = null;
    public includeName: boolean = true;
    public noValueReturn = null;

    constructor(data: any = null)
    {
        this.contactData = data;
    }

    public getContact(wrapInHTML: boolean = false)
    {
        if (Util.isEmpty(this.contactData))
        {
            return this.noValueReturn;
        }

        let csz = "";
        let zip = this.makeVal("zip");
        let city = this.makeVal("city");
        let name = this.makeVal("name");
        let str1 = this.makeVal("street1");
        let str2 = this.makeVal("street2");
        let state = this.makeVal("state");
        let country = this.makeVal("country");
        let outAddy = "";

        if (this.includeName && !Util.isEmpty(name))
        {
            outAddy = name;
        }

        if (!Util.isEmpty(str1))
        {
            if (outAddy != "")
            {
                outAddy += this.delimiter;
            }

            outAddy += str1;
        }

        if (!Util.isEmpty(str2))
        {
            if (outAddy != "")
            {
                outAddy += this.delimiter;
            }

            outAddy += str2;
        }

        if (!Util.isEmpty(city))
        {
            csz = city;
        }

        if (!Util.isEmpty(state))
        {
            if (csz != "")
            {
                csz += ", ";
            }

            if (typeof state == "string")
            {
                csz += state;
            }
            else
            {
                csz += state.name;
            }
        }

        if (!Util.isEmpty(country))
        {
            csz += " ";

            if (typeof country == "string")
            {
                csz += country;
            }
            else
            {
                csz += country.name;
            }
        }

        if (!Util.isEmpty(zip))
        {
            csz += " " + zip;
        }

        if (csz == "" && outAddy == "")
        {
            return null;
        }

        if (outAddy != "" && csz != "")
        {
            outAddy += this.delimiter;
        }

        if (wrapInHTML)
        {
            return `<address>${outAddy}${csz}</address>`;
        }

        return outAddy + csz;
    }

    public googleLink(): string
    {
        this.delimiter = " ";

        let addy = this.getContact();

        if (Util.isEmpty(this.contactData) || addy == this.noValueReturn)
        {
            return this.noValueReturn;
        }

        return Util.replaceAll(addy, " ", "+");
   }

    private makeVal(field: string)
    {
        if (this.fieldPrefix != null)
        {
            field = this.fieldPrefix + field.charAt(0).toUpperCase() + field.slice(1);
        }

        if (this.contactData.hasOwnProperty(field) && this.contactData[field] != null)
        {
            return this.contactData[field];
        }

        return null;
    }
}