import { Component, ElementRef, ViewChild } from "@angular/core";
import { BaseComponent, Profile } from "@app/data";
import { FeedbackType } from "@app/data/enums/feedback-type.enum";
import { Feedback } from "@app/data/feedback/feedback.model";
import { FeedbackService } from "@app/data/feedback/feedback.service";
import { CommonSettings } from "@app/shared/common-settings";
import { ConfirmationService, IConfirmationOptions } from "@app/shared/confirmation/confirmation.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: "feedback-dialog",
    templateUrl: "./feedback-dialog.html"
})
export class FeedbackDialog extends BaseComponent<Feedback>
{
    @ViewChild("feedbackDialog")
    feedbackDialog: ElementRef;

    public user: Profile;
    public rating: number;
    public rateOver?: number;

    constructor(
        toastrService: ToastrService,
        public modalService: NgbModal,
        private feedbackServ: FeedbackService,
        private confirmService: ConfirmationService)
    {
        super(toastrService);

        this.dataForm = this.feedbackServ.editorForm;
        this.apiService = this.feedbackServ;
        this.modalService = modalService;
    }

    public openAdd(feedbackType: number = FeedbackType.EasyExport): void
    {
        this.rating = 0;
        this.rateOver = undefined;

        this.open(this.feedbackDialog);
        this.resetForm();

        this.setValue("feedbackType", feedbackType);
    }

    public onRateHover(val: any): void
    {
        this.rating = val;
        this.rateOver = val;
    }

    public onRateLeave(): void
    {
        if (this.rating)
        {
            this.rateOver = this.rating;
        }
        else
        {
            this.rateOver = undefined;
        }
    }

    public onClose(): void
    {
        if (!this.dataForm.dirty)
        {
            this.close();
            return;
        }

        let opts: IConfirmationOptions =
        {
            FunctionConfirm: () => this.close()
        };

        this.confirmService.YesNo(CommonSettings.DirtyFormConfirmationMessage, opts);
    }

    public onSave(): void
    {
        let sub = this.submit();

        if (sub)
        {
            sub.add(() => this.submitComplete());
        }
    }

    private submitComplete(): void
    {
        if (this.submitError)
        {
            return;
        }

        this.close();
        this.success("Thanks for your Feedback!");
    }
}