import { Component, ElementRef, HostBinding, Input } from '@angular/core';
import { Router } from "@angular/router";

@Component({
    selector: 'sidenav-menu', // tslint:disable-line
    template: `
<a href="#" class="sidenav-link sidenav-toggle" [ngClass]="linkClass">
  <i class="sidenav-icon" *ngIf="icon" [ngClass]="icon"></i>
  <div [innerHTML]="text"></div>
  <div *ngIf="badge" class="ml-auto pl-1"><div class="badge" [ngClass]="badgeClass">{{badge}}</div></div>
</a>
<div class="sidenav-menu">
  <ng-content></ng-content>
</div>
  `
})
export class SidenavMenuComponent
{
    @HostBinding('class.sidenav-item') hostClassMain = true;
    @HostBinding('class.d-block') hostClassBlock = true;

    @Input() text: string;
    @Input() icon: string;
    @Input() linkClass = '';
    @Input() badge: any = null;
    @Input() badgeClass = '';
    @Input() @HostBinding('class.disabled') disabled = false;
    @Input() @HostBinding('class.active') active = false;
    @Input() @HostBinding('class.open') open = false;

    constructor(private router: Router, private el: ElementRef)
    {
    }

    public isActive(): boolean
    {
        let me = this.el.nativeElement;
        let kids = me.querySelectorAll("sidenav-router-link");

        for (let item of kids)
        {
            let path = item.getAttribute("route");

            if (item.pathname != "/" && this.router.isActive(path, false))
            {
                return true;
            }
        }

        return false;
    }
}