import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { AuthService, LookupService, ProfileType, Transaction } from "@app/data";
import { ShipmentProductStatusType } from "@app/data/enums/shipment-product-status-type.enum";
import { MerchantsFreightForwarderService } from "@app/data/freight/merchants-freight-forwarder.service";
import { ShipmentProductService } from "@app/data/shipment-product/shipment-product.service";
import { Shipment } from "@app/data/shipment/shipment";
import { ShipmentService } from "@app/data/shipment/shipment.service";
import { GridBase } from "@app/shared/ag-grid-helpers/ag-grid-base";
import { GridCellNumberEditorComponent } from "@app/shared/ag-grid-helpers/components/grid-cell-number-editor/grid-cell-number-editor.component";
import { GridHelpers } from "@app/shared/ag-grid-helpers/grid-helper";
import { GridColumnCustom } from "@app/shared/ag-grid-helpers/models/grid-column-custom.model";
import { GridColumnIcon } from "@app/shared/ag-grid-helpers/models/grid-column-icon.model";
import { GridColumn, GridColumnType } from "@app/shared/ag-grid-helpers/models/grid-column.model";
import { CommonSettings } from '@app/shared/common-settings';
import { ConfirmationService, IConfirmationOptions } from "@app/shared/confirmation/confirmation.service";
import { FreightForwarderCommon } from "@app/shared/freight-forwarder/freight-forwarder-common";
import { OrderDataHelper } from '@app/shared/orders/order-data.helper';
import { DateHelper } from "@app/utils/date-helper";
import { Util } from "@app/utils/util";
import { NgbDateAdapter, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { forkJoin } from "rxjs";
import { ShipProductDTO } from "./ship-product.dto";

@Component({
    selector: "order-shipment-detail",
    styleUrls: ["./shipment-detail.component.scss"],
    templateUrl: "./shipment-detail.component.html"
})
export class ShipmentDetailComponent extends GridBase<Shipment> implements OnInit
{
    @Input()
    orderDetails: Transaction;

    @ViewChild("dialogAddProd")
    dialogAddProd: ElementRef<any>;

    @ViewChild("dialogShipment")
    dialogShipment: ElementRef<any>;

    public get canEdit()
    {
        if (this.userRole != ProfileType.customer)
        {
            return false;
        }

        return (this.showType != ShipmentDetailComponent.ShowType_View);
    }

    public get minModify()
    {
        return (OrderDataHelper.userRole == ProfileType.customer || OrderDataHelper.userRole == ProfileType.freightForwarder);
    }

    public static readonly ShowType_New = 0;
    public static readonly ShowType_Edit = 1;
    public static readonly ShowType_View = 2;

    public readonly shipmentStatusTypes = OrderDataHelper.ShipmentStatuses;

    public myFFs;
    public showFF: boolean;
    public showType: number = ShipmentDetailComponent.ShowType_New;
    public userRole: number = OrderDataHelper.userRole;
    public totalValue: number;
    public shipMethods;
    public totalWeight: number;
    public availProducts;
    public productListTitle: string;

    private holdUpdate;
    private prodChanged: boolean;
    private shipProducts;
    private prodDialogRef;
    private missingProduct: boolean;

    constructor(toastrService: ToastrService,
        public lookupServ: LookupService,
        public modalService: NgbModal,
        private authService: AuthService,
        private dateAdapter: NgbDateAdapter<Date>,
        private shipService: ShipmentService,
        private confirmService: ConfirmationService,
        private mercahntFFServ: MerchantsFreightForwarderService,
        private shipProdService: ShipmentProductService)
    {
        super(toastrService, shipProdService);

        this.dataForm = this.shipService.editorForm;
        this.apiService = this.shipService;
    }

    ngOnInit(): void
    {
        this.holdUpdate = this.update;

        this.update = null;
        this.setupGrid();
    }

    public openAdd(): void
    {
        this.open(this.dialogShipment, null, CommonSettings.DialogSize_XLarge);
        this.resetForm();

        this.setValue("transactionUID", this.orderDetails.uid);

        this.setupUI();
    }

    public openEdit(data: Shipment): void
    {
        this.open(this.dialogShipment, data, CommonSettings.DialogSize_XLarge);
        this.patchValue();

        this.setupUI();
    }

    public onMyGridReady(params): void
    {
        this.onGridReady(params, false);

        let show = this.showType != ShipmentDetailComponent.ShowType_View;

        this.columnManager.setColumnVisible("editIcons", show);
    }

    public onShipMethodChange(evtArg): void
    {
        this.showFF = (evtArg.code == FreightForwarderCommon.ShipMethodCode);

        if (this.showFF)
        {
            this.setValue("freightUID", null);
        }
    }

    public onOpenProds(): void
    {
        let opts = CommonSettings.DialogOptions();

        this.prodDialogRef = this.modalService.open(this.dialogAddProd, opts);
    }

    public onProdsClose(): void
    {
        this.prodDialogRef.close();
    }

    public onProdsSave(): void
    {
        let prods = this.availProducts.filter(elm => elm.checked);

        for (let item of prods)
        {
            let addMe = new ShipProductDTO(item);

            this.shipProducts.push(addMe);
        }

        this.prodChanged = true;
        this.availProducts = this.availProducts.filter(elm => !elm.checked);

        this.refreshThatGrid();
        this.prodDialogRef.close();
    }

    public onMainClose(): void
    {
        this.mainGrid = null;
        this.columnManager = null;

        if (!this.dataForm.dirty && !this.prodChanged)
        {
            this.close();
            return;
        }

        let opts: IConfirmationOptions =
        {
            FunctionConfirm: () => this.close()
        };

        this.confirmService.YesNo(CommonSettings.DirtyFormConfirmationMessage, opts);
    }

    public onMainSave(): void
    {
        this.loading = true;

        if (this.invalid())
        {
            return;
        }

        if (this.showType == ShipmentDetailComponent.ShowType_New)
        {
            if (this.shipProducts.length == 0)
            {
                this.loading = false;
                this.missingProduct = true;
                return;
            }
        }
        else
        {
            let actProd = this.shipProducts.filter(elm => elm.statusType != ShipmentProductStatusType.Deleted);

            if (actProd.length == 0)
            {
                let opts: IConfirmationOptions =
                {
                    FunctionConfirm: () => this.deleteShipment()
                };

                this.loading = false;

                this.confirmService.YesNo("You have removed all products from this Shipment.  This will mark this Shipment record as deleted; continue?", opts);
                return;
            }
        }

        this.submit().add(() => this.submitComplete());
    }

    private submitComplete(): void
    {
        if (this.submitError)
        {
            return;
        }

        this.loading = true;
        this.saveProducts();
    }

    private setupUI(): void
    {
        let sDate = (this.data ? this.data.shipmentDate : new Date());
        let dasDate = this.dateAdapter.toModel(DateHelper.makeNGBDate(sDate));

        this.showFF = false;
        this.prodChanged = false;
        this.missingProduct = false;

        this.setValue("shipmentDate", dasDate);

        if (this.showType == ShipmentDetailComponent.ShowType_View)
        {
            this.productListTitle = "Shipped products";

            this.rowDoubleClick(null);
        }
        else
        {
            this.productListTitle = "Product(s) to ship:";

            if (this.minModify)
            {
                this.gridOpts.singleClickEdit = true
                this.gridOpts.stopEditingWhenCellsLoseFocus = true;

                this.rowDoubleClick(this.onEdit.bind(this));
            }
        }

        let meth = this.lookupServ.getList("shipping-method");
        let myFFs = this.mercahntFFServ.myList();

        forkJoin([meth, myFFs]).subscribe((retRes) => this.popCommon(retRes));

        if (this.showType == ShipmentDetailComponent.ShowType_New)
        {
            this.shipProdService.post("list", { transactionUID: this.orderDetails.uid }).subscribe(
                retRes => this.popProducts(retRes),
                errRes => this.error(errRes));
            return;
        }

        let all = this.shipProdService.post("list", { transactionUID: this.orderDetails.uid });
        let mine = this.shipProdService.post("list", { shipmentUID: this.data.uid });

        forkJoin([all, mine]).subscribe((retRes) => this.parseProducts(retRes));
    }

    private setupGrid(): void
    {
        let colTotal = new GridColumn("total", "Value", GridColumnType.Money);
        let colOrdered = new GridColumn("ordered", "Ordered", GridColumnType.Number);

        let editorOpts =
        {
            type: "rightAligned",
            editable: this.minModify,
            cellEditor: "gridCellNumberEditorComponent",
            cellEditorParams: { min: 0, max: { data: "remaining" } }
        };

        colTotal.width = 90;
        colOrdered.width = 95;

        this.storageKey = "ShipmentDetail";
        this.gridOpts.domLayout = GridHelpers.DOMLayout_Normal;
        this.gridOpts.paginationPageSize = 6;

        this.addColumn(new GridColumnCustom("partNumber", "Part #", { width: 200 }));
        this.addColumn(new GridColumn("description", "Description"));
        this.addColumn(new GridColumn("aesCode", "AES"));
        this.addColumn(new GridColumn("exportScheduleB", "Schedule B"));
        this.addColumn(new GridColumn("classification", "ECCN"));
        this.addColumn(new GridColumn("importHTS", "HTS"));
        this.addColumn(new GridColumn("license", "License"));
        this.addColumn(colOrdered);
        this.addColumn(new GridColumnCustom("shipped", "# to Ship", editorOpts));
        this.addColumn(colTotal);
        this.addColumn(new GridColumn("weight", "AES Weight (kg)", GridColumnType.Number));

        if (this.minModify)
        {
            let gridIcons =
                [
                    GridHelpers.MakeEditIcon(this.onEdit.bind(this)),
                    GridHelpers.MakeDeleteIcon(this.onDelete.bind(this))
                ];

            this.addColumn(new GridColumnIcon(gridIcons, -1, "editIcons"));
        }

        this.registerCustomComponents({ gridCellNumberEditorComponent: GridCellNumberEditorComponent });
    }

    private popCommon(retRes): void
    {
        let uid = this.getValue("shipmentMethodUID");

        this.myFFs = retRes[1].results;
        this.shipMethods = retRes[0].results;

        if (!Util.isEmpty(uid))
        {
            let meth = this.shipMethods.find(elm => elm.uid == uid);

            this.onShipMethodChange(meth);

            this.setValue("freightUID", this.data.freight?.uid);
        }
    }

    private parseProducts(retRes): void
    {
        this.popProducts(retRes[1]);

        let all = retRes[0].results;
        let mine = retRes[1].results;

        this.availProducts = [];

        for (let prod of all)
        {
            let item = mine.find(elm => elm.transactionProductUID == prod.transactionProductUID);

            if (!item)
            {
                prod.checked = false;

                this.availProducts.push(prod);
            }
        }
    }

    private popProducts(retRes: any): void
    {
        let data = retRes.results;

        this.shipProducts = [];

        for (let item of data)
        {
            let prod = new ShipProductDTO(item);

            if (prod.shipped >= 0)
            {
                this.shipProducts.push(prod);
            }
        }

        this.refreshThatGrid();
    }

    public onCellEditingStopped(evtArg): void
    {
        let data = evtArg.node.data;
        let weight = data.kilogramsPerItem * data.shipped;

        if (weight < 1 || isNaN(weight))
        {
            weight = 1;
        }

        evtArg.node.data.weight = weight;

        this.prodChanged = true;
        this.refreshThatGrid();
    }

    private onEdit(agData: any): void
    {
        let idx = this.shipProducts.findIndex(cond => cond.productUID == agData.productUID);

        this.mainGrid.startEditingCell({ colKey: "shipped", rowIndex: idx });
    }

    private onDelete(agData: any): void
    {
        if (this.showType == ShipmentDetailComponent.ShowType_New)
        {
            let idx = this.shipProducts.findIndex(cond => cond.productUID == agData.productUID);

            this.shipProducts.splice(idx, 1);

            this.shipProducts = Util.reindexArray(this.shipProducts);
        }
        else
        {
            let dude = this.shipProducts.find(cond => cond.productUID == agData.productUID);

            dude.statusType = ShipmentProductStatusType.Deleted;
        }

        this.prodChanged = true;
        this.refreshThatGrid();
    }

    private refreshThatGrid(): void
    {
        let data = this.shipProducts.filter(elm => elm.statusType != ShipmentProductStatusType.Deleted);

        this.totalValue = 0;
        this.totalWeight = 0;

        for (let item of data)
        {
            this.totalValue += item.total;
            this.totalWeight += item.weightRaw;
        }

        this.gridPopulate(data);
    }

    private deleteShipment(): void
    {
        this.delete().add(() =>
        {
            this.close();

            if (this.holdUpdate)
            {
                this.holdUpdate.emit();
            }
        });
    }

    private saveProducts(): void
    {
        let saveProds = [];

        for (let dude of this.shipProducts)
        {
            let addMe =
            {
                uid: dude.uid,
                statusType: dude.statusType,
                shipmentUID: this.data.uid,
                transactionUID: this.orderDetails.uid,
                quantityShipped: dude.shipped,
                transactionProductUID: dude.productUID
            };

            saveProds.push(addMe);
        }

        this.shipProdService.postList("savelist", saveProds).subscribe(
            retRes =>
            {
                this.close();
                this.success("Shipment create successfully");

                if (this.holdUpdate)
                {
                    this.holdUpdate.emit();
                }
            },
            errRes => this.error(errRes),
            () => this.loading = false);
    }
}