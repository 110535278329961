import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { Profile, ProfileService } from "@app/data";
import { GridBase } from "@app/shared/ag-grid-helpers/ag-grid-base";
import { GridHelpers } from "@app/shared/ag-grid-helpers/grid-helper";
import { GridColumnCustom } from "@app/shared/ag-grid-helpers/models/grid-column-custom.model";
import { GridColumnIcon } from "@app/shared/ag-grid-helpers/models/grid-column-icon.model";
import { GridCellTextRender } from "@app/shared/ag-grid-helpers/renderers/grid-cell-text.renderer";
import { ConfirmationService, IConfirmationOptions } from "@app/shared/confirmation/confirmation.service";
import { ToastrService } from "ngx-toastr";
import { MembersEditComponent } from "../members-edit/members-edit.component";

@Component({
    selector: "members-list",
    styleUrls: ["./members-list.component.scss"],
    templateUrl: "./members-list.component.html"
})
export class MembersListComponent extends GridBase<Profile> implements OnInit
{
    @Input()
    profileType;

    @Input()
    restrictHeight: boolean = false;

    @Input()
    allowNewMembers: boolean = true;

    @ViewChild("dialogMember")
    dialogMember: MembersEditComponent;

    constructor(toastrService: ToastrService,
        private profileServ: ProfileService,
        private confirmService: ConfirmationService)
    {
        super(toastrService, profileServ);
    }

    ngOnInit()
    {
        this.setupGrid();

        this.filterAdd("profileType", this.profileType);

        switch (this.profileType)
        {
            case this.profileTypes.freightForwarder:
                this.filterAdd("freightUID", this.parentUID, false);
                break;

            case this.profileTypes.customer:
                this.filterAdd("customerUID", this.parentUID, false);
                break;
        }
    }

    public onAdd()
    {
        let parentData =
        {
            parentUID: this.parentUID,
            profileType: this.profileType
        };

        this.dialogMember.openAdd(parentData);
    }

    private setupGrid()
    {
        let gridIcons =
            [
                GridHelpers.MakeEditIcon(this.onEdit.bind(this)),
                GridHelpers.MakeDeleteIcon(this.onDelete.bind(this)),
            ];

        this.storageKey = "Members";

        if (this.restrictHeight)
        {
            this.gridOpts.domLayout = GridHelpers.DOMLayout_Normal;
            this.gridOpts.paginationPageSize = 10;
        }

        this.addColumn(new GridColumnCustom("lastName", "Name", { cellRenderer: GridCellTextRender.ContactName }));
        this.addColumn(new GridColumnCustom("emailAddress", "Email", { cellRenderer: GridCellTextRender.EmailCellRenderer }));
        this.addColumn(new GridColumnIcon(gridIcons));

        this.rowDoubleClick(this.onEdit.bind(this));
    }

    private onEdit(agData)
    {
        this.dialogMember.openEdit(agData);
    }

    private onDelete(agData)
    {
        this.dataForm = this.makeForm(agData);

        let opts: IConfirmationOptions =
        {
            FunctionConfirm: () => this.deleteIt()
        };

        this.confirmService.Delete(`Are you sure you want to delete this Member?`, opts);
    }

    private deleteIt()
    {
        this.delete().add(() => this.gridRefresh());
    }
}