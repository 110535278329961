import { GridPurchaserTypeFilter } from "../filters/grid-purchaser-type-filter/grid-purchaser-type-filter.component";
import { GridColumn } from "./grid-column.model";

export class GridColumnPurchaserType extends GridColumn
{
    private rendField: string;

    constructor(field: string = "purchaserTypeName")
    {
        super(field, "Purchaser Type");

        this.filter = "gridPurchaserTypeFilter";
        this.minWidth = 150;
        this.rendField = field;
        this.cellRenderer = this.renderType.bind(this);

        this.registerComponents.push({ gridPurchaserTypeFilter: GridPurchaserTypeFilter });
    }

    private renderType(params: any): string
    {
        let data = params.data;

        if (this.rendField.indexOf(".") == -1)
        {
            return data[this.rendField];
        }

        let brok = this.rendField.split(".");
        let parn = data[brok[0]];

        return parn[brok[1]];
    }
}