import { Component } from "@angular/core";
import { GridCellBoolean } from "@app/shared/ag-grid-helpers/models/grid-cell-boolean.model";
import { GridFilter, GridFilterStorage } from '@app/shared/ag-grid-helpers/models/grid-filter.model';
import { AgFilterComponent } from "ag-grid-angular";
import { IDoesFilterPassParams } from "ag-grid-community";
import { GridFilterCustom } from "../grid-filter-custom";

@Component({
    styleUrls: ["../../../styles/gridfilter.scss"],
    templateUrl: "./grid-boolean-filter.component.html"
})
export class GridBooleanFilter extends GridFilterCustom implements AgFilterComponent
{
    public booleans: GridCellBoolean;
    public boolValue?: boolean;

    private field: string;

    agInit(params: any): void
    {
        this.initFilter(params);

        this.field = this.getParam("field");
        this.booleans = params.booleans;
    }

    isFilterActive(): boolean
    {
        return this.boolValue != null;
    }

    doesFilterPass(params: IDoesFilterPassParams): boolean
    {
        if (this.isServerSide || this.boolValue == null)
        {
            return true;
        }

        if (!params.data.hasOwnProperty(this.field))
        {
            return (this.boolValue == null);
        }

        let myVal = params.data[this.field];

        if (myVal == null || myVal == undefined)
        {
            return (this.boolValue == null);
        }

        let setVal: boolean;

        switch (myVal.toString().toLowerCase())
        {
            case "1":
            case "yes":
            case "true":
                setVal = true;
                break;

            default:
                setVal = false;
                break;
        }

        return (this.boolValue == setVal);
    }

    getModel()
    {
        if (this.boolValue == null)
        {
            return null;
        }

        return new GridFilterStorage(this.field, new GridFilter(this.boolValue));
    }

    setModel(model: any)
    {
        model = model?.gridFilter?.filter;

        if (model == null || model == undefined)
        {
            this.boolValue = null;
            return;
        }

        this.boolValue = model;
    }

    public onFilterClear(): void
    {
        this.boolValue = null;

        this.applyAndClose();
    }

    public onFilterApply(): void
    {
        this.applyAndClose();
    }
}