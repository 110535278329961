export enum LicenseStatus
{
    Active = 1,
    Pending = 2,
    Complete = 4,
    RWA = 6,
    New = 7,
    Expired = 9,
    PendingReview = 10,
    Denial = 12
}