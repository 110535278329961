import { Component, ElementRef, EventEmitter, Output, ViewChild } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { BaseComponent, PurchaserAddress } from "@app/data";
import { FixedImage } from "@app/data/misc/fixed-image";
import { PurchaserAddressImage } from "@app/data/purchaser-address/purchaser-address-image.model";
import { PurchaserAddressImageService } from "@app/data/purchaser-address/purchaser-address-image.service";
import { PurchaserCommon } from "@app/data/purchaser/purchaser-common";
import { CommonSettings } from "@app/shared/common-settings";
import { ConfirmationService, IConfirmationOptions } from "@app/shared/confirmation/confirmation.service";
import { ContactFormatter } from "@app/utils/contact-formatter";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: "purchaser-address-verification-paste",
    styleUrls: ["./purchaser-address-verification-paste.scss"],
    templateUrl: "./purchaser-address-verification-paste.html"
})
export class PurchaserAddressVerificationPaste extends BaseComponent<PurchaserAddressImage>
{
    @ViewChild("dialogPaste")
    dialogPaste: ElementRef;

    public readonly imageTypes = PurchaserCommon.PurchaserAddressImageTypes;

    public imgPaste: FixedImage;
    public displayAddress: string;

    private haveImage: boolean;

    constructor(
        toastrService: ToastrService,
        public modalService: NgbModal,
        private sanitizer: DomSanitizer,
        private addyImageServ: PurchaserAddressImageService,
        private confirmService: ConfirmationService,
        private purchaserImgServ: PurchaserAddressImageService)
    {
        super(toastrService);

        this.dataForm = this.addyImageServ.editorForm;
        this.apiService = purchaserImgServ;
        this.modalService = this.modalService;
    }

    public openPaster(purchaserAddy: PurchaserAddress): void
    {
        let contForm = new ContactFormatter(purchaserAddy);

        this.imgPaste = null;
        this.displayAddress = contForm.getContact(true);

        this.open(this.dialogPaste, null, CommonSettings.DialogSize_XLarge);
        this.resetForm();

        this.setValue("purchaserAddressUID", purchaserAddy.uid);
        this.makeRequired(["fileName"], false);
    }

    public testText: string;

    public onPaste(evtArg: any): void
    {
        this.imgPaste = new FixedImage();

        for (let item of evtArg.clipboardData.items)
        {
            if (item.type.indexOf("image") == 0)
            {
                this.imgPaste.rawImage = item.getAsFile();
                break;
            }
        }

        if (this.imgPaste.rawImage == null)
        {
            return;
        }

        this.imgPaste.url = URL.createObjectURL(this.imgPaste.rawImage);
        this.imgPaste.safeURL = this.sanitizer.bypassSecurityTrustUrl(this.imgPaste.url);
    }

    public onClear(): void
    {
        this.imgPaste = null;
    }

    public onSave(): void
    {
        if (this.invalid() || this.imgPaste == null)
        {
            return;
        }

        this.loading = true;

        this.addyImageServ.upload(this.imgPaste.rawImage, this.dataValue).subscribe(
            retRes => this.saveComplete(),
            errRes => this.error(errRes));
    }

    public onClose(): void
    {
        if (!this.dataForm.dirty && this.imgPaste == null)
        {
            this.close();
            return;
        }

        let opts: IConfirmationOptions =
        {
            FunctionConfirm: () => this.close()
        };

        this.confirmService.YesNo(CommonSettings.DirtyFormConfirmationMessage, opts);
    }

    public saveComplete(): void
    {
        if (this.submitError)
        {
            return;
        }

        this.close();
        this.update.emit();
    }
}