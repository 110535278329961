import { Component } from "@angular/core";
import { LookupService, PurchaserType } from "@app/data";
import { PurchaserCommon } from "@app/data/purchaser/purchaser-common";
import { GridFilterCustom } from "@app/shared/ag-grid-helpers/filters/grid-filter-custom";
import { GridFilter, GridFilterStorage } from "@app/shared/ag-grid-helpers/models/grid-filter.model";
import { Util } from "@app/utils/util";
import { AgFilterComponent } from "ag-grid-angular";
import { IDoesFilterPassParams } from "ag-grid-community";

@Component({
    styleUrls: ["../../../styles/gridfilter.scss"],
    templateUrl: "./grid-purchaser-type-filter.component.html"
})
export class GridPurchaserTypeFilter extends GridFilterCustom implements AgFilterComponent
{
    public readonly purchTypes = PurchaserCommon.PurchaserTypes;

    private readonly fieldType: string = "purchaserType";
    private readonly fieldBusiness: string = "purchaserSubCategoryUID";

    public selectBusi: number;
    public selectPurch: number;
    public showBusiness: boolean = false;

    constructor(public lookupServ: LookupService)
    {
        super();
    }

    agInit(params: any): void
    {
        this.initFilter(params);

        this.lookupServ.getPurchaserSubCategoryTypeList();
    }

    isFilterActive(): boolean
    {
        return !Util.isEmpty(this.selectPurch);
    }

    doesFilterPass(params: IDoesFilterPassParams): boolean
    {
        if (this.isServerSide)
        {
            return true;
        }

        let matchType = (this.selectPurch == params.data[this.fieldType]);

        if (this.selectPurch != PurchaserType.Business)
        {
            return matchType;
        }

        let bus = (params.data[this.fieldBusiness] == this.selectBusi);

        return bus && matchType;
    }

    getModel()
    {
        if (this.selectPurch == null)
        {
            return null;
        }

        let filt = new GridFilterStorage();

        filt.add(this.fieldType, new GridFilter(this.selectPurch));

        if (this.selectBusi != null)
        {
            filt.add(this.fieldBusiness, new GridFilter(this.selectBusi));
        }

        return filt;
    }

    setModel(model: any)
    {
        let filter = model?.multiple;

        if (!filter)
        {
            this.selectBusi = null;
            this.selectPurch = null;
            this.showBusiness = false;
            return;
        }

        for (let name in filter)
        {
            switch (name)
            {
                case this.fieldType:
                    this.selectPurch = filter[name].filter;
                    break;

                case this.fieldBusiness:
                    this.selectBusi = filter[name].filter;
                    break;
            }
        }

        this.showBusiness = (this.selectPurch == PurchaserType.Business);
    }

    public onFilterType()
    {
        this.showBusiness = (this.selectPurch == PurchaserType.Business);

        if (!this.showBusiness)
        {
            this.selectBusi = null;
        }
    }

    public onFilterClear()
    {
        this.selectBusi = null;
        this.selectPurch = null;
        this.applyAndClose();
    }

    public onFilterApply()
    {
        this.applyAndClose();
    }
}