import { TaskEntity } from "@app/data/enums/task-entity.enum";
import { TaskCommon } from '@app/data/tasks/task-common';
import cloneDeep from "lodash-es/cloneDeep";
import { GridCheckListFilter } from "../filters/grid-checklist-filter/checklist-filter.component";
import { GridColumn } from "./grid-column.model";
import { GridFilterDataType } from "./grid-filter.model";

export class GridColumnTaskEntity extends GridColumn
{
    constructor(field: string, header: string = "Area")
    {
        super(field, header);

        this.width = 140;
        this.filter = "checkListFilter";
        this.cellRenderer = this.renderEntity;
        this.filterParams = { field: field, filterDataType: GridFilterDataType.text, items: cloneDeep(TaskCommon.TaskEntities) };
        this.suppressSizeToFit = true;

        this.registerComponents.push({ checkListFilter: GridCheckListFilter });
    }

    private renderEntity(agParam)
    {
        return TaskEntity[agParam.data.entityType];
    }
}