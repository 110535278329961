import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { BaseComponent, CustomerAccount, CustomerAccountService, LookupService } from "@app/data";
import { CommonSettings } from "@app/shared/common-settings";
import { ConfirmationService, IConfirmationOptions } from "@app/shared/confirmation/confirmation.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: "customer-account-edit",
    templateUrl: "./customer-account-edit.component.html"
})
export class CustomerAccountEditComponent extends BaseComponent<CustomerAccount> implements OnInit
{
    @ViewChild("dialogAccount")
    dialogAccount: ElementRef<any>;

    constructor(
        toastrService: ToastrService,
        public lookupService: LookupService,
        private modalServ: NgbModal,
        private confirmService: ConfirmationService,
        private customerAccountService: CustomerAccountService)
    {
        super(toastrService);

        this.dataForm = this.customerAccountService.editorForm;
        this.apiService = customerAccountService;
        this.modalService = this.modalServ;
    }

    ngOnInit(): void
    {
        this.lookupService.getUSACountryStateList();
        this.lookupService.getCustomerAccountTypeList();
    }

    public openAdd(merchantUID)
    {
        this.open(this.dialogAccount);

        this.resetForm();
        this.setValue("customerUID", merchantUID);
    }

    public openEdit(data)
    {
        this.open(this.dialogAccount, data);
        this.patchValue();
    }

    public onClose()
    {
        if (!this.dataForm.dirty)
        {
            this.close();
            return;
        }

        let opts: IConfirmationOptions =
        {
            FunctionConfirm: () => this.close()
        };

        this.confirmService.YesNo(CommonSettings.DirtyFormConfirmationMessage, opts);
    }

    public onSave()
    {
        let sub = this.submit();

        if (sub)
        {
            sub.add(() => this.doneDidSaved());
        }
    }

    private doneDidSaved()
    {
        if (!this.submitError)
        {
            this.close();
        }
    }
}