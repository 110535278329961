import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { BaseComponent, PurchaserAddress, PurchaserAddressService } from "@app/data";
import { CommonSettings } from "@app/shared/common-settings";
import { ContactFormatter } from "@app/utils/contact-formatter";
import { ToastrService } from "ngx-toastr";
import { PurchaserAddressHistoryListComponent } from "../purchaser-address-history-list/purchaser-address-history-list.component";
import { PurchaserAddressVerificationList } from "../purchaser-address-verification/purchaser-address-verification-list/purchaser-address-verification-list.component";

@Component({
    selector: "purchaser-address-list",
    styleUrls: ["./purchaser-address-list.component.scss"],
    templateUrl: "./purchaser-address-list.component.html"
})
export class PurchaserAddressListComponent extends BaseComponent<PurchaserAddress> implements OnInit
{
    @Input()
    addressChangeExplanation: string;

    @ViewChild("dialogHistory")
    dialogHistory: PurchaserAddressHistoryListComponent;

    @ViewChild("dialogVerification")
    dialogVerification: PurchaserAddressVerificationList;

    constructor(
        toastrService: ToastrService,
        private purchaserAddressService: PurchaserAddressService)
    {
        super(toastrService);

        this.apiService = purchaserAddressService;
    }

    ngOnInit(): void
    {
        this.searchForm = this.makeForm({ purchaserUID: this.parentUID });
        this.postGetlist();
    }

    public onMap(address: PurchaserAddress): void
    {
        let contFor = new ContactFormatter(address);

        CommonSettings.openAddressWindow(contFor.googleLink());
    }

    public onHistory(uid: string): void
    {
        this.dialogHistory.openHistory(uid);
    }

    public onVerify(address: PurchaserAddress): void
    {
        this.dialogVerification.openVerification(address);
    }
}