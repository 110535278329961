export class QandAData
{
    data: any;
    taskID: number;

    constructor(taskID: number = 0, data: any = null)
    {
        this.data = data;
        this.taskID = taskID;
    }
}