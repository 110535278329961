import { WikiErrorType, WikiCommon } from "@app/data/wiki/wiki-common";

export class WikiError
{
    fix: string;
    message: string;
    errorType: number;

    constructor(errorType: number, data)
    {
        this.errorType = errorType;

        switch (errorType)
        {
            case WikiErrorType.MissingLink:
                this.fix = WikiCommon.makeInternalLink("linkText", data);
                this.message = "Anchor without link: " + data;
                break;

            case WikiErrorType.MissingAnchor:
                this.fix = WikiCommon.makeInternalAnchor(data);
                this.message = "Internal link missing anchor: " + data;
                break;
        }
    }
}