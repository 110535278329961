import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { AuthService, BaseComponent, Profile } from "@app/data";
import { EntityType } from "@app/data/enums/entity-type.enum";
import { NoteMod } from "@app/data/notes/note.model";
import { NotesService } from "@app/data/notes/notes.service";
import { CommonSettings } from "@app/shared/common-settings";
import { ConfirmationService, IConfirmationOptions } from "@app/shared/confirmation/confirmation.service";
import { Util } from "@app/utils/util";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import clone from "lodash-es/clone";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: "notes-edit",
    templateUrl: "./notes-edit.html"
})
export class NotesEdit extends BaseComponent<NoteMod> implements OnInit
{
    @Input()
    entityUID: string;

    @Input()
    entityType: number;

    @ViewChild("noteDialog")
    noteDialog: ElementRef;

    public user: Profile;

    constructor(
        toastrService: ToastrService,
        public modalService: NgbModal,
        private noteServ: NotesService,
        private authService: AuthService,
        private confirmService: ConfirmationService)
    {
        super(toastrService);

        this.dataForm = this.noteServ.editorForm;
        this.apiService = this.noteServ;
        this.modalService = modalService;
    }

    async ngOnInit()
    {
        this.user = await this.authService.getProfile$().toPromise();
    }

    public openAdd(): void
    {
        this.readOnly = false;

        this.open(this.noteDialog);
        this.resetForm();
    }

    public openEdit(note: NoteMod): void
    {
        note.note = Util.replaceAll(clone(note.note), "<br />", "\n");
        this.readOnly = !(note.isAbleToEdit);

        this.open(this.noteDialog, note);
    }

    public onClose(): void
    {
        if (!this.dataForm.dirty)
        {
            this.close();
            return;
        }

        let opts: IConfirmationOptions =
        {
            FunctionConfirm: () => this.close()
        };

        this.confirmService.YesNo(CommonSettings.DirtyFormConfirmationMessage, opts);
    }

    public onSave(): void
    {
        this.setValue("entityUID", this.entityUID);
        this.setValue("entityType", this.entityType);

        let sub = this.submit();

        if (sub)
        {
            sub.add(() => this.submitComplete());
        }
    }

    private submitComplete(): void
    {
        if (this.submitError)
        {
            return;
        }

        this.close();
        this.success("Note created");
    }
}