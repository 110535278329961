import { AbstractControl, ValidatorFn } from "@angular/forms";
import { Util } from "@app/utils/util";

export class NumberValidatorOption
{
    public maxValue: number = null;
    public minValue: number = null;
    public allowNull: boolean = true;
    public allowDecimal: boolean = true;
}

export class NumberValidatorError
{
    public badMax: number = null;
    public badMin: number = null;
    public isNull: boolean = false;
    public hasDecmial: boolean = false;

    public static getErrors(valErrors: NumberValidatorError): string[]
    {
        if (Util.isEmpty(valErrors))
        {
            return null;
        }

        let errors = [];

        if (valErrors.isNull)
        {
            errors.push("Value is required");
        }

        if (valErrors.badMax != null)
        {
            errors.push(`Maximum value is ${valErrors.badMax}`);
        }

        if (valErrors.badMin != null)
        {
            errors.push(`Minimum value is ${valErrors.badMin}`);
        }

        if (valErrors.hasDecmial)
        {
            errors.push("Only whole numbers are allowed");
        }

        return errors;
    }
}

export function NumberValidator(numOpts: NumberValidatorOption = new NumberValidatorOption()): ValidatorFn
{
    return (control: AbstractControl): NumberValidatorError | null =>
    {
        let val = control.value;
        let errors = new NumberValidatorError();

        if (val == null)
        {
            if (numOpts.allowNull)
            {
                return null;
            }

            errors.isNull = true;
            return errors;
        }

        let hasError = false;

        if (numOpts.minValue != null && val < numOpts.minValue)
        {
            hasError = true;
            errors.badMin = numOpts.minValue;
        }

        if (numOpts.maxValue != null && val > numOpts.maxValue)
        {
            hasError = true;
            errors.badMax = numOpts.maxValue;
        }

        if (!numOpts.allowDecimal && val.toString().indexOf(".") != -1)
        {
            hasError = true;
            errors.hasDecmial = true;
        }

        if (hasError)
        {
            return errors;
        }

        return null;
    };
}