import { Component, Input, OnDestroy, TemplateRef, ViewChild } from "@angular/core";
import { BaseComponent } from "@app/data";
import { TaskEntity } from "@app/data/enums/task-entity.enum";
import { TaskStatus } from "@app/data/enums/task-status.enum";
import { TaskCommon } from "@app/data/tasks/task-common";
import { TaskRequestDTO } from "@app/data/tasks/task-request.dto";
import { TaskWork } from "@app/data/tasks/taskwork.model";
import { TaskService } from "@app/data/tasks/taskwork.service";
import { CommonSettings } from "@app/shared/common-settings";
import { ConfirmationService, IConfirmationOptions } from "@app/shared/confirmation/confirmation.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";

@Component({
    selector: "my-task-dialog",
    templateUrl: "./my-task-dialog.component.html"
})
export class MyTaskDialogComponent extends BaseComponent<TaskWork> implements OnDestroy
{
    @Input()
    entityUID: string;

    @Input()
    entityType: number;

    @ViewChild("dialogTask")
    dialogTask: TemplateRef<any>;

    public get canSave()
    {
        return (this.setTab == TaskCommon.TaskTab_Detail && (this.taskData == null || this.taskData.statusType != TaskStatus.Complete));
    }

    public taskData;
    public entityTitle: string;

    private setTab: number;
    private closing: boolean = false;
    private holdTab: number;
    private taskSubAns: Subscription;
    private taskSubQues: Subscription;
    private closeAfterSave: boolean;

    constructor(
        toastrService: ToastrService,
        public modalService: NgbModal,
        private taskService: TaskService,
        private confirmService: ConfirmationService)
    {
        super(toastrService);

        this.taskSubAns = this.taskService.taskAnswer.subscribe((req) => { this.handleAnswers(req) });
        this.taskSubQues = this.taskService.taskQuestion.subscribe((req) => { this.handleQuestion(req) });
        this.modalService = modalService;
    }

    ngOnDestroy()
    {
        this.taskSubAns.unsubscribe();
        this.taskSubQues.unsubscribe();
    }

    public openAdd()
    {
        this.taskData = null;

        this.setupUI();
    }

    public openEdit(data: TaskWork, gotoTab: number = 0): void
    {
        this.taskData = data;
        this.entityUID = data.entityUID;
        this.entityType = data.entityType;

        this.setupUI();

        if (gotoTab != 0)
        {
            this.holdTab = gotoTab;
            this.gotoTab();
        }
    }

    public onClose()
    {
        this.closing = true;
        this.taskService.sendQuestion(new TaskRequestDTO(TaskRequestDTO.TaskRequest_FormDirty));
    }

    public onSave(closeMe: boolean)
    {
        this.closeAfterSave = closeMe;

        this.taskService.sendQuestion(new TaskRequestDTO(TaskRequestDTO.TaskRequest_Save));
    }

    private setupUI()
    {
        this.setTab = TaskCommon.TaskTab_Detail;
        this.closing = false;
        this.entityTitle = TaskEntity[this.entityType];

        this.open(this.dialogTask, null, CommonSettings.DialogSize_XLarge);
    }

    private handleAnswers(answer: TaskRequestDTO)
    {
        switch (answer.taskID)
        {
            case TaskRequestDTO.TaskRequest_FormDirty:
                this.handleDirty(answer);
                break;

            case TaskRequestDTO.TaskRequest_Save:
                this.saveComplete(answer.data);
                break;
        }
    }

    private handleQuestion(request: TaskRequestDTO)
    {
        switch (request.taskID)
        {
            case TaskRequestDTO.TaskRequest_TabChanged:
                this.handleChangeTab(request.data);
                break;
        }
    }

    private handleDirty(answer)
    {
        if (answer.data)
        {
            this.askToSave();
        }
        else
        {
            this.whatNext();
        }
    }

    private handleChangeTab(tabEvt)
    {
        this.holdTab = tabEvt.nextId;

        tabEvt.preventDefault();

        if (tabEvt.activeId == TaskCommon.TaskTab_Detail)
        {
            let req = new TaskRequestDTO(TaskRequestDTO.TaskRequest_FormDirty);

            this.taskService.sendQuestion(req);
        }
        else
        {
            this.gotoTab();
        }
    }

    private gotoTab()
    {
        let req = new TaskRequestDTO(TaskRequestDTO.TaskRequest_TabChanged, this.holdTab);

        this.setTab = this.holdTab;
        this.taskService.sendAnswer(req);
    }

    private saveComplete(data)
    {
        if (this.update)
        {
            this.update.emit();
        }

        if (this.closeAfterSave)
        {
            this.close();
            return;
        }

        this.taskService.sendQuestion(new TaskRequestDTO(TaskRequestDTO.TaskRequest_Update, data));
    }

    private askToSave()
    {
        let opts: IConfirmationOptions =
        {
            FunctionConfirm: () => this.whatNext()
        };

        this.confirmService.YesNo(CommonSettings.DirtyFormConfirmationMessage, opts);
    }

    private whatNext()
    {
        if (this.closing)
        {
            this.close();
        }
        else
        {
            this.gotoTab();
        }
    }
}