import { GoogleMapsType, GoogleMapsZoom } from "../google-conts";

export class GoogleMapsMapOption
{
    Size: number = 640;
    Zoom: number = GoogleMapsZoom.Default;
    Scale: number = 2;
    MapType: string;

    constructor(type: GoogleMapsType = GoogleMapsType.Road)
    {
        this.MapType = type;
    }
}