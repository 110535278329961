import { Component } from "@angular/core";
import { Customer } from "@app/data";
import { AgRendererComponent } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

@Component({
    templateUrl: "./grid-cell-merchant.html",
})
export class GridCellMerchantRenderer implements AgRendererComponent
{
    public merchant: Customer;

    private cellParams: ICellRendererParams;

    constructor()
    {
    }

    agInit(params)
    {
        this.cellParams = params;

        if (params.node.data.customer)
        {
            this.merchant = params.node.data.customer;
        }
        else
        {
            this.merchant = { displayName: params.value };
        }
    }

    refresh(params): boolean
    {
        this.cellParams = params;
        return true;
    }
}