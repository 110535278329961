import { HttpResponse } from "@angular/common/http";
import { Component, Input } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { GlobalIcons } from "@app/shared/GlobalIcons";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { FileSaverService } from "ngx-filesaver";

@Component({
    selector: "document-preview",
    styleUrls: ["./document-preview.component.scss"],
    templateUrl: "./document-preview.component.html"
})
export class DocumentPreviewComponent
{
    @Input()
    title: string;

    @Input()
    fileLink: string;

    public set setPDF(val)
    {
        this.popPDF(val);
    }

    public set setError(val)
    {
        this.popError(val);
    }

    public readonly Icons = GlobalIcons;

    public loading: boolean = true;
    public zoomLevel: number = 1;
    public sourcePDF: any;
    public sourceImage: any;
    public errorMessage: string = null;

    constructor(private activeModal: NgbActiveModal, private sanitizer: DomSanitizer)
    {
    }

    public onClose(): void
    {
        this.activeModal.close(false);
    }

    private async popPDF(val)
    {
        let testVal: any;

        if (val instanceof HttpResponse)
        {
            testVal = val.body;
        }
        else
        {
            testVal = val;
        }

        let blob = new Blob([testVal], { type: 'application/pdf' })
        let objURL = URL.createObjectURL(blob);
        let imgSafe = this.sanitizer.bypassSecurityTrustResourceUrl(objURL);

        this.loading = false;
        this.sourcePDF = imgSafe;
    }

    private popImage(val): void
    {
        let objURL = URL.createObjectURL(val.body);
        let imgSafe = this.sanitizer.bypassSecurityTrustUrl(objURL);

        this.loading = false;
        this.sourceImage = imgSafe;
    }

    private popError(val): void
    {
        if (val instanceof Blob)
        {
            let reader: FileReader = new FileReader();

            reader.onload = (retRes) => this.errorMessage = retRes.target.result.toString();
            reader.readAsText(val);
        }
        else
        {
            this.errorMessage = val;
        }

        this.loading = false;
    }
}