import { Component, Input } from "@angular/core";

@Component({
    selector: "star-rating",
    templateUrl: "./star-rating.html"
})
export class StarRating
{
    @Input()
    rating: number;

    @Input()
    standAlone: boolean = true;

    public ratingSize: string;

    constructor()
    {
    }
}