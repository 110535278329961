import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ApiService } from '../api/api.service';
import { PurchaserAddressHistory } from './purchaser-address-history';

@Injectable({
  providedIn: 'root'
})
export class PurchaserAddressHistoryService extends ApiService<PurchaserAddressHistory> {

  constructor(httpClient: HttpClient) {
    super(httpClient, 'purchaser-address-history');
  }

}
