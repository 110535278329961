import { GridBase } from "@app/shared/ag-grid-helpers/ag-grid-base";
import { GridHelpers } from "@app/shared/ag-grid-helpers/grid-helper";
import { GridColumn, GridColumnType } from "@app/shared/ag-grid-helpers/models/grid-column.model";
import { DateHelper } from "@app/utils/date-helper";
import { Util } from "@app/utils/util";

export class HistoryListCommon<T> extends GridBase<T>
{
    public setupGrid(gridStorageKey: string, label: string = "Reviewed"): void
    {
        let colName = new GridColumn("who", `${label} by`);
        let colPrev = new GridColumn("prev", "From");
        let changeCols =
            [
                new GridColumn("field", "Field"),
                colPrev,
                new GridColumn("new", "To")
            ];

        colPrev.type = GridHelpers.AlignRight;
        colName.cellRenderer = "agGroupCellRenderer";

        this.storageKey = gridStorageKey;
        this.gridOpts.domLayout = GridHelpers.DOMLayout_Normal;
        this.gridOpts.masterDetail = true;
        this.gridOpts.detailRowHeight = 150;
        this.gridOpts.paginationPageSize = 10;
        this.gridOpts.detailCellRendererParams =
        {
            getDetailRowData: function (params)
            {
                params.successCallback(params.data.what);
            },
            detailGridOptions:
            {
                rowHeight: 25,
                columnDefs: changeCols,
                pagination: false,
                animateRows: true,
                headerHeight: 25,
                paginationPageSize: 50,
                onFirstDataRendered: function (params: any)
                {
                    params.api.resetRowHeights();
                    params.api.sizeColumnsToFit();
                }
            }
        };

        this.addColumn(colName);
        this.addColumn(new GridColumn("when", `${label} on`, GridColumnType.DateTime));
    }

    public getHistory(filterData: any): void
    {
        this.gridGetData(filterData).subscribe(retRes => this.parseHistory(retRes));
    }

    private parseHistory(histData: any): void
    {
        let data = histData.results;

        if (Util.isEmpty(data))
        {
            this.gridPopulate(null);
            return;
        }

        DateHelper.sortArray(data, "createdDate");

        let changes = [];

        for (let histRec of data)
        {
            let addMe = changes.find(elm => elm.when == histRec.createdDate);
            let field =
            {
                new: histRec.toValue,
                prev: histRec.fromValue,
                field: histRec.label,
            };

            if (Util.isEmpty(addMe))
            {
                addMe =
                {
                    who: `${histRec.review.firstName} ${histRec.review.lastName}`,
                    what: [field],
                    when: histRec.createdDate
                };

                changes.push(addMe);
            }
            else
            {
                addMe.what.push(field);
            }
        }

        DateHelper.sortArray(changes, "when", false);

        this.gridPopulate(changes);
    }
}