
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, mergeMap } from "rxjs/operators";
import { AuthService } from "./auth.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor
{
    constructor(private authService: AuthService)
    {
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>>
    {
        //  Don't do anything to Google requests
        if (request.url.indexOf("maps.googleapis.com") != -1)
        {
            return next.handle(request);
        }

        let headersConfig =
        {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "X-XSRF-TOKEN": this.getCookie("XSRF-TOKEN")
        };

        if (request.body instanceof FormData)
        {
            delete headersConfig["Content-Type"];
        }

        if (request.url.endsWith("/profile/signup") ||
            request.url.endsWith("/purchaser/signup") ||
            request.url.endsWith("/antiforgery/token") ||
            request.url.endsWith("/profile/email-confirm") ||
            request.url.endsWith("/lookup/signup-language") ||
            request.url.endsWith("/lookup/signup-timezone") ||
            request.url.endsWith("/purchaser/get-captcha-image") ||
            request.url.endsWith("/purchaser/get-captcha-valid") ||
            request.url.endsWith("/lookup/signup-purchaser-country") ||
            request.url.indexOf("assets/documents/privacy-policies") != -1)
        {
            request = request.clone(
                {
                    setHeaders: headersConfig,
                    withCredentials: true
                });

            return next.handle(request);
        }

        return this.authService.getTokenSilently$().pipe(
            mergeMap(token =>
            {
                headersConfig["Authorization"] = `Bearer ${token}`;

                request = request.clone(
                    {
                        setHeaders: headersConfig,
                        withCredentials: true
                    });

                return next.handle(request);
            }),
            catchError(err => throwError(err)));
    }

    private getCookie(name: string): string
    {
        let allCookies = decodeURIComponent(document.cookie).split("; ");

        for (let lp = 0; lp < allCookies.length; lp++)
        {
            let cookie = allCookies[lp];

            if (cookie.startsWith(name + "="))
            {
                return cookie.substring(name.length + 1);
            }
        }

        return "";
    }
}