import { Component, OnInit } from "@angular/core";
import { AppService } from "@app/app.service";
import { ApiResult, BaseComponent } from "@app/data";
import { Dealer } from "@app/data/dealer/dealer.model";
import { DealerService } from "@app/data/dealer/dealer.service";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: "dealer-list",
    templateUrl: "./dealer-list.html"
})
export class DealerList extends BaseComponent<Dealer> implements OnInit
{
    public dealers: Array<Dealer>;

    constructor(toastrServ: ToastrService, private appService: AppService, private dealerServ: DealerService)
    {
        super(toastrServ);

        this.title = "Local Dealers";
        this.appService.pageTitle = this.title;
    }

    ngOnInit(): void
    {
        this.dealerServ.list().subscribe((retRes) => this.popDealers(retRes));
    }

    private popDealers(retRes: ApiResult<Dealer>): void
    {
        this.dealers = retRes.results;
    }
}