import { DragDropModule } from "@angular/cdk/drag-drop";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule, Title } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AuthInterceptor, ErrorInterceptor } from "@app/data";
import { ConfirmationComponent } from "@app/shared/confirmation/confirmation.component";
import { ConfirmationService } from "@app/shared/confirmation/confirmation.service";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import "ag-grid-enterprise";
import { FileSaverModule } from "ngx-filesaver";
import { ToastrModule } from "ngx-toastr";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AppService } from "./app.service";
import { LayoutModule } from "./layout/layout.module";
import { NotFoundComponent } from "./not-found/not-found.component";
import { DocumentPreviewComponent } from "./shared/document-preview/document-preview.component";
import { DocumentPreviewService } from "./shared/document-preview/document-preview.service";
import { GoogleMapsHelperService } from "./shared/google-maps/google-maps-helper.service";
import { ProductDialogService } from "./shared/products/product-dialog/product-dialog.service";

@NgModule({
    declarations:
        [
            AppComponent,
            NotFoundComponent,
            ConfirmationComponent,
            DocumentPreviewComponent
        ],
    imports:
        [
            FormsModule,
            BrowserModule,
            HttpClientModule,
            ReactiveFormsModule,
            BrowserAnimationsModule,

            LayoutModule,
            AppRoutingModule,

            NgbModule,
            DragDropModule,
            FileSaverModule,

            ToastrModule.forRoot(
                {
                    toastClass: "toast",
                    newestOnTop: true,
                    preventDuplicates: true
                })
        ],
    providers:
        [
            Title,
            AppService,
            ConfirmationService,
            ProductDialogService,
            DocumentPreviewService,
            GoogleMapsHelperService,

            // Interceptors
            { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
            { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        ],
    entryComponents:
        [
            ConfirmationComponent,
            DocumentPreviewComponent
        ],

    bootstrap:
        [
            AppComponent
        ]
})
export class AppModule
{
}