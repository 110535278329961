import { IAfterGuiAttachedParams, ICellRendererParams } from '@ag-grid-enterprise/all-modules';
import { Component } from "@angular/core";
import { GridCellBoolean } from "@app/shared/ag-grid-helpers/models/grid-cell-boolean.model";
import { AgRendererComponent } from "ag-grid-angular";

@Component(
    {
        styleUrls: ["./grid-cell-boolean-renderer.scss"],
        templateUrl: "./grid-cell-boolean-renderer.html"
    })
export class GridCellBooleanRenderer implements AgRendererComponent
{
    public icon: string = null;
    public bools: GridCellBoolean;
    public color: string;
    public label: string;
    public toolTip: string;

    private cellParams: ICellRendererParams;

    agInit(params): void
    {
        this.bools = params.bools;

        if (params.value == true)
        {
            this.icon = this.bools.trueOption.icon;
            this.color = this.bools.trueOption.color;
            this.label = this.bools.trueOption.label;
            this.toolTip = this.bools.trueOption.toolTip;
        }

        if (params.value == false)
        {
            this.icon = this.bools.falseOption.icon;
            this.color = this.bools.falseOption.color;
            this.label = this.bools.falseOption.label;
            this.toolTip = this.bools.falseOption.toolTip;
        }

        this.cellParams = params;
    }

    refresh(params): boolean
    {
        this.cellParams = params;
        return true;
    }

    afterGuiAttached?(params?: IAfterGuiAttachedParams): void
    {
    }
}