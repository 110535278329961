import { Component, Input } from "@angular/core";
import { GlobalIcons } from "../GlobalIcons";

@Component({
    selector: "info-label",
    styleUrls: ["info-label.component.scss"],
    templateUrl: "./info-label.component.html"
})
export class InfoLabel
{
    @Input()
    label: string;

    @Input()
    infotext: string;

    public Icons = GlobalIcons;

    constructor()
    {
    }
}