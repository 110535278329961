import { Component } from "@angular/core";
import { AppService } from "@app/app.service";
import { AuthService } from "@app/data";
import { PurchaserAuthorization } from "@app/data/purchaser-authorization/purchaser-authorization";
import { PurchaserAuthorizationService } from "@app/data/purchaser-authorization/purchaser-authorization.service";
import { GridBase } from "@app/shared/ag-grid-helpers/ag-grid-base";
import { GridColumnPurchaser } from "@app/shared/ag-grid-helpers/models/grid-column-purchaser";
import { GridColumn, GridColumnType } from "@app/shared/ag-grid-helpers/models/grid-column.model";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: "merchant-purchasers",
    styleUrls: ["./merchant-purchasers.component.scss"],
    templateUrl: "./merchant-purchasers.component.html"
})
export class MerchantPurchasersComponent extends GridBase<PurchaserAuthorization>
{
    constructor(toastrService: ToastrService,
        private appService: AppService,
        private authService: AuthService,
        private purchaseAuthServ: PurchaserAuthorizationService)
    {
        super(toastrService, purchaseAuthServ);

        this.title = "Authorized Purchasers";
        this.appService.pageTitle = this.title;

        this.setupGrid();
    }

    public onMyGridReady(evtArg)
    {
        this.filterAdd("customerUID", this.parentUID);

        this.onGridReady(evtArg);
    }

    private setupGrid()
    {
        this.storageKey = "MerchantPurchasers";
        this.addColumn(new GridColumnPurchaser("purchaser.displayName", "purchaser.uid"));
        this.addColumn(new GridColumn("country.name", "Country"));
        this.addColumn(new GridColumn("purchaserContactDisplayName", "Contact"));
        this.addColumn(new GridColumn("country.cellPhone1", "Cell"));
        this.addColumn(new GridColumn("purchaser.emailAddress", "Email", GridColumnType.Email));
        this.addColumn(new GridColumn("purchaser.websiteLink", "Website", GridColumnType.Link));
    }
}