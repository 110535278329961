import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Util } from "@app/utils/util";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { ApiService } from "../api/api.service";
import { FixedImage } from "../misc/fixed-image";
import { ImageRequest } from "../misc/image-request.model";
import { Customer } from "./customer";
import { MerchantRequestDTO } from "./merchant-request.dto";

@Injectable(
{
    providedIn: "root"
})
export class CustomerService extends ApiService<Customer>
{
    public readonly ImgType_Logo = 1;
    public readonly ImgType_Banner = 2;

    public get editorForm()
    {
        let form = new FormBuilder();
        let grpFields = form.group(
            {
                uid: [],
                ceo: [],
                einNumber: [],
                tradeName: [],
                statusType: [],
                customerUID: [],
                companyName: [],
                description: [],
                founderName: [],
                onboardDate: [],
                websiteLink: [],
                logoImageURL: [],
                isOnYellowPage: [],
                productBillCap: [],
                establishedYear: [],
                terminationDate: [],
                cjChargeOverride: [],
                isAbleToPurchase: [],
                isAEPDoingAESFiling: [],
                marketingMessage: [],
                onboardOverrideCharge: [],
                productClassificationCharge: [],
                deferredProductCarryoverCharge: []
            });

        return grpFields;
    }

    public serviceImage: SafeUrl;
    public merchantAnswer: Observable<any>;
    public merchantQuestion: Observable<any>;

    private messageAnswer = new Subject();
    private messageQuestion = new Subject();

    constructor(httpClient: HttpClient, private sanitizer: DomSanitizer)
    {
        super(httpClient, "customer");

        this.merchantAnswer = this.messageAnswer.asObservable();
        this.merchantQuestion = this.messageQuestion.asObservable();
    }

    public sendAnswer(request: MerchantRequestDTO)
    {
        this.messageAnswer.next(request);
    }

    public sendQuestion(request: MerchantRequestDTO)
    {
        this.messageQuestion.next(request);
    }

    public getImage(imgRequest: string | ImageRequest): Observable<FixedImage>
    {
        let url = this.buildUrl("get-image");
        let postReq: ImageRequest;

        if (imgRequest instanceof ImageRequest)
        {
            postReq = imgRequest;
        }
        else
        {
            postReq = new ImageRequest(imgRequest);
        }

        let obby = this.httpClient.post(url, postReq, { observe: "response", responseType: "blob"})
            .pipe(map(resRes =>
            {
                let bin = new Blob([resRes.body]);
                let fixImg = new FixedImage();

                fixImg.url = URL.createObjectURL(bin);
                fixImg.safeURL = this.sanitizer.bypassSecurityTrustUrl(fixImg.url);

                return fixImg;
            }));

        return obby;
    }

    public getCustomerLookup(filter: Customer = null)
    {
        return this.getList("lookup", filter).subscribe(
            retRes =>
            {
                this.lookupList = retRes.results;
            });
    }

    public uploadImage(merchantUID: string, imgType: number, imgFile: File): Observable<string>
    {
        let url: string;
        let uploadData = new FormData();

        uploadData.append("uid", merchantUID);
        uploadData.append(imgFile.name, imgFile);

        switch (imgType)
        {
            case this.ImgType_Logo:
                url = "upload-logo";
                break;

            case this.ImgType_Banner:
                url = "upload-banner";
        }

        return this.httpClient.post<string>(this.buildUrl(url), uploadData);
    }
}