import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ApiService } from "../api/api.service";
import { ShipmentProduct } from "./shipment-product";

@Injectable({
    providedIn: "root"
})
export class ShipmentProductService extends ApiService<ShipmentProduct>
{
    public get editorForm()
    {
        let form = new FormBuilder();
        let grpFields = form.group(
            {
                uid: [],
                statusType: [],
                shipmentDate: [Validators.required],
                transactionUID: [],
                shipmentMethodID: [],
                shippingInstructions: [],
                aesInternalTransactionNumber: []
            });

        return grpFields;
    }

    constructor(httpClient: HttpClient)
    {
        super(httpClient, "shipmentproduct");
    }
}