import { Injectable } from "@angular/core";
import { DateFormatter } from "@app/shared/pipes/date-formatter.pipe";
import { DateHelper } from '@app/utils/date-helper';
import { Util } from "@app/utils/util";

@Injectable()
export class GridCellTextRender
{
    private static FormatMoney = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD", useGrouping: true, minimumFractionDigits: 2 });
    private static FormatNumber = new Intl.NumberFormat("en-US", { style: "decimal", useGrouping: true, minimumFractionDigits: 0, maximumFractionDigits: 0 });
    private static FormatDecimal = new Intl.NumberFormat("en-US", { style: "decimal", useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 4 });
    private static FormatPercent = new Intl.NumberFormat("en-US", { style: "percent", minimumFractionDigits: 2 });

    public static FormatDate(dasDate)
    {
        if (Util.isEmpty(dasDate?.value))
        {
            return "";
        }

        return DateHelper.formatDate(dasDate.value);
    }

    public static FormatDateTime(dasDate)
    {
        if (Util.isEmpty(dasDate?.value))
        {
            return "";
        }

        return DateHelper.formatDate(dasDate.value, DateFormatter.FormatDateTime);
    }

    public static MoneyCellRenderer(params: any, defaultValue = Util.DOM_Space)
    {
        let val = GridCellTextRender.getValue(params);

        if (val == null)
        {
            return defaultValue;
        }

        return GridCellTextRender.FormatMoney.format(val);
    }

    public static NumberCellRenderer(params: any, defaultValue = Util.DOM_Space)
    {
        let val = GridCellTextRender.getValue(params);

        if (val == null)
        {
            return defaultValue;
        }

        return GridCellTextRender.FormatNumber.format(val);
    }

    public static DecimalCellRenderer(params: any, defaultValue = Util.DOM_Space)
    {
        let val = GridCellTextRender.getValue(params);

        if (val == null)
        {
            return defaultValue;
        }

        return GridCellTextRender.FormatDecimal.format(val);
    }

    public static PercentCellRenderer(params: any, defaultValue = Util.DOM_Space)
    {
        let val = GridCellTextRender.getValue(params);

        if (val == null)
        {
            return defaultValue;
        }

        return GridCellTextRender.FormatPercent.format(val);
    }

    public static EmailCellRenderer(params: any, defaultValue = Util.DOM_Space)
    {
        let val = GridCellTextRender.getValue(params);

        if (val == null)
        {
            return defaultValue;
        }

        return `<a href="mailto:${val}" title="${val}">${val}</a>`;
    }

    public static PhoneCellRenderer(params: any, defaultValue = Util.DOM_Space)
    {
        let val = GridCellTextRender.getValue(params);

        if (val == null)
        {
            return defaultValue;
        }

        return `<a href="tel:${val}">${val}</a>`;
    }

    public static LinkCellRenderer(params: any, defaultValue = Util.DOM_Space)
    {
        let val = GridCellTextRender.getValue(params);

        if (val == null)
        {
            return defaultValue;
        }

        return `<a href="${val}" target="_blank">${val}</a>`;
    }

    public static PurchaserCellRenderer(params: any)
    {
        if (params.node.data.purchaser)
        {

            let name = params.node.data.purchaser.displayName;
            let type = params.node.data.purchaser.purchaserTypeName;

            return `<div class="purchaser-type">${name} <i>${type}</i></div>`;
        }

        return params.value;
    }

    public static ContactName(params)
    {
        let contact;

        if (params.hasOwnProperty("data"))
        {
            contact = params.data;
        }
        else
        {
            contact = params.value;
        }

        if (Util.isEmpty(contact))
        {
            return Util.DOM_Space;
        }

        return Util.MakeName(contact);
    }

    private static getValue(params: any)
    {
        if (Util.isEmpty(params))
        {
            return null;
        }

        if (typeof params == "number")
        {
            return params;
        }

        if (params.hasOwnProperty("value"))
        {
            return params.value;
        }

        return null;
    }
}