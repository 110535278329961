import { Component, ViewChild } from "@angular/core";
import { AppService } from "@app/app.service";
import { Freight, FreightService } from "@app/data";
import { FreightForwarderType } from "@app/data/enums/freight-forwarder-type.enum";
import { GridBase } from "@app/shared/ag-grid-helpers/ag-grid-base";
import { GridHelpers } from "@app/shared/ag-grid-helpers/grid-helper";
import { GridColumnAddress } from "@app/shared/ag-grid-helpers/models/grid-column-address";
import { GridColumnContact } from "@app/shared/ag-grid-helpers/models/grid-column-contact";
import { GridColumnCustom } from "@app/shared/ag-grid-helpers/models/grid-column-custom.model";
import { GridColumnGroup } from "@app/shared/ag-grid-helpers/models/grid-column-group.model";
import { GridColumnIcon } from "@app/shared/ag-grid-helpers/models/grid-column-icon.model";
import { GridColumnStatus } from "@app/shared/ag-grid-helpers/models/grid-column-status";
import { GridColumn } from "@app/shared/ag-grid-helpers/models/grid-column.model";
import { FreightForwarderCommon } from "@app/shared/freight-forwarder/freight-forwarder-common";
import { FreightForwarderDialogComponent } from "@app/shared/freight-forwarder/freight-forwarder-edit/freight-forwarder-dialog/freight-forwarder-dialog.component";
import { Util } from "@app/utils/util";
import { ToastrService } from "ngx-toastr";
import { GridColumnFFType } from "./grid-components/grid-column-ff-type";

@Component({
    selector: "freight-forwarder-list",
    styleUrls: ["./freight-forwarder-list.component.scss"],
    templateUrl: "./freight-forwarder-list.component.html"
})
export class FreightForwarderListComponent extends GridBase<Freight>
{
    @ViewChild("dialogFreight")
    dialogFreight: FreightForwarderDialogComponent;

    public readonly recordTypes = FreightForwarderCommon.RecordTypes;

    constructor(
        toastrService: ToastrService,
        private appService: AppService,
        private freightService: FreightService)
    {
        super(toastrService, freightService);

        this.title = "Freight Forwarders & Customs Brokers";
        this.appService.pageTitle = this.title;

        this.setupGrid();
    }

    public onAdd(recordType)
    {
        this.dialogFreight.openAdd(recordType);
    }

    public onGetRowClass(params)
    {
        if (params.data.eodStatus != FreightForwarderCommon.EODNoMatch)
        {
            return "ag-grid-row-eod";
        }

        return null;
    }

    private setupGrid()
    {
        let gridIcons =
            [
                GridHelpers.MakeEditIcon(this.onEdit.bind(this))
            ];

        this.storageKey = "FreightForwarder-Admin";
        this.gridOpts.domLayout = GridHelpers.DOMLayout_Normal;
        this.gridOpts.rowHeight = 75;
        this.gridOpts.paginationPageSize = 20;

        this.addColumn(new GridColumnStatus(FreightForwarderCommon.FreightForwarderStatuses));
        this.addColumn(new GridColumnCustom("companyName", "Name", { minWidth: 200, cellRenderer: this.renderName.bind(this) }));
        this.addColumn(new GridColumnFFType());
        this.addColumn(new GridColumn("description", "Description"));
        this.addColumn(new GridColumnContact("contact"));
        this.addColumn(new GridColumnAddress("address", "Address"));
        this.addColumn(new GridColumn("eodDecisionBy", "EOD Decision By"));
        this.addColumn(new GridColumn("eodKey", "EOD Key"));
        this.addColumn(new GridColumn("eodStatus", "EOD Status"));
        this.addColumn(new GridColumnIcon(gridIcons));

        this.rowDoubleClick(this.onEdit.bind(this));
    }

    private renderName(params)
    {
        let data = params.data;
        let name = data.companyName;
        let site = data.websiteLink;

        if (Util.isEmpty(site))
        {
            return name;
        }

        return `${name} <a href="${site}" title="${site}" target="_blank"><i class="${this.Icons.Link}"></i></a>`;
    }

    private onEdit(agData)
    {
        this.dialogFreight.openEdit(agData);
    }
}