import { Component } from "@angular/core";
import { OrderDataHelper } from '@app/shared/orders/order-data.helper';
import { HoldItemModel } from "@app/shared/orders/order-grid-components/hold-item.model";
import { Util } from "@app/utils/util";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

@Component(
{
    selector: "grid-cell-holds",
    styleUrls: ["./grid-cell-holds.renderer.scss"],
    templateUrl: "./grid-cell-holds.renderer.html"
})
export class GridCellOrderHoldsRenderer implements ICellRendererAngularComp
{
    public holdList: Array<HoldItemModel>;

    private cellParams: ICellRendererParams;

    agInit(params): void
    {
        this.cellParams = params;

        let data = params.data;

        this.holdList = new Array<HoldItemModel>();

        if (!Util.isEmpty(data.isAEPHold))
        {
            this.holdList.push(OrderDataHelper.Hold_AEP);
        }

        if (!Util.isEmpty(data.isEARLicenseHold))
        {
            this.holdList.push(OrderDataHelper.Hold_EAR);
        }

        if (!Util.isEmpty(data.isITARLicenseHold))
        {
            this.holdList.push(OrderDataHelper.Hold_ITAR);
        }

        if (!Util.isEmpty(data.isImportPermitHold))
        {
            this.holdList.push(OrderDataHelper.Hold_Import);
        }
    }

    refresh(params: ICellRendererParams): boolean
    {
        this.cellParams = params;
        return true;
    }
}