import { Component, ElementRef, ViewChild } from "@angular/core";
import { BaseComponent, Purchaser, PurchaserReview, PurchaserReviewService } from "@app/data";
import { PurchaserCommon } from "@app/data/purchaser/purchaser-common";
import { CommonSettings } from "@app/shared/common-settings";
import { ConfirmationService, IConfirmationOptions } from "@app/shared/confirmation/confirmation.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: "purchaser-review-edit",
    templateUrl: "./purchaser-review-edit.component.html"
})
export class PurchaserReviewEditComponent extends BaseComponent<PurchaserReview>
{
    @ViewChild("dialogReview")
    dialogReview: ElementRef;

    public readonly purchStatuses = PurchaserCommon.PurchaserStatuses;

    public newStatus: number;
    public isRequired: boolean;
    public purchDetail: Purchaser;

    constructor(
        toastrService: ToastrService,
        public modalServ: NgbModal,
        private confirmService: ConfirmationService,
        private purchaserReviewService: PurchaserReviewService)
    {
        super(toastrService);

        this.dataForm = this.purchaserReviewService.editorForm;
        this.apiService = purchaserReviewService;
        this.modalService = modalServ;
    }

    public openReview(purch: Purchaser, setStatus: number, required: boolean = true, autoComplete: boolean = false): void
    {
        this.open(this.dialogReview);

        this.newStatus = setStatus;
        this.isRequired = required;
        this.purchDetail = purch;

        this.setValue("statusType", this.newStatus);
        this.setValue("purchaserUID", purch.uid);
        this.setValue("isAutoCompleteVettingTask", autoComplete);

        this.makeRequired(["comments"], required);
    }

    public onSave()
    {
        let sub = this.submit();

        if (sub)
        {
            sub.add(() => this.saveComplete());
        }
    }

    public onClose()
    {
        if (!this.dataForm.dirty)
        {
            this.close();
            return;
        }

        let opts: IConfirmationOptions =
        {
            FunctionConfirm: () => this.close()
        };

        this.confirmService.YesNo(CommonSettings.DirtyFormConfirmationMessage, opts);
    }

    private saveComplete()
    {
        if (!this.submitError)
        {
            this.close();
        }
    }
}