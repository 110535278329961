import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: "popup-menu-item",
    templateUrl: "./popup-menu-item.html"
})
export class PopupMenuItem
{
    @Input()
    icon: string;

    @Input()
    text: string;

    @Input()
    toolTip: string;

    @Output()
    click: EventEmitter<any> = new EventEmitter();
}