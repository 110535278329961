import { Component, EventEmitter, Output, TemplateRef, ViewChild } from "@angular/core";
import { OrderProductStatusType } from "@app/data/enums/order-product-status-type.enum";
import { ExcelHelper } from "@app/data/misc/excel-helper";
import { ShopOrderProduct } from "@app/data/orders/shop-order-product";
import { ShopOrderProductService } from "@app/data/orders/shop-order-product.service";
import { ShopPostMessageService } from "@app/data/orders/shop-post-message.service";
import { GridBase } from "@app/shared/ag-grid-helpers/ag-grid-base";
import { GridHelpers } from "@app/shared/ag-grid-helpers/grid-helper";
import { GridColumnCustom } from "@app/shared/ag-grid-helpers/models/grid-column-custom.model";
import { GridColumnIcon } from '@app/shared/ag-grid-helpers/models/grid-column-icon.model';
import { GridColumn, GridColumnType } from "@app/shared/ag-grid-helpers/models/grid-column.model";
import { CommonSettings } from "@app/shared/common-settings";
import { ConfirmationService, IConfirmationOptions } from "@app/shared/confirmation/confirmation.service";
import { OrderDataHelper } from '@app/shared/orders/order-data.helper';
import { OrderProductComponent } from "@app/shared/orders/order-manual-entry/order-product/order-product.component";
import { Util } from "@app/utils/util";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: "order-product-bulk",
    styleUrls: ["./order-product-bulk.component.scss"],
    templateUrl: "./order-product-bulk.component.html"
})
export class OrderProductBulkComponent extends GridBase<ShopOrderProduct>
{
    @ViewChild("dialogProduct")
    dialogProduct: OrderProductComponent;

    @ViewChild("dialogOrderProduct")
    dialogOrderProduct: TemplateRef<any>;

    @Output()
    productAdded = new EventEmitter();

    public excelFile;
    public merchantUID;
    public existingOrderUID = null;
    public availableProducts;

    private editProd;

    constructor(toastrService: ToastrService,
        public modalService: NgbModal,
        private orderProdServ: ShopOrderProductService,
        private confirmService: ConfirmationService,
        private shopPostMessageServ: ShopPostMessageService)
    {
        super(toastrService, orderProdServ);

        this.setupGrid();
    }

    public openDialog(merchantUID)
    {
        this.gridData = [];
        this.excelFile = null;
        this.merchantUID = merchantUID;

        this.open(this.dialogOrderProduct, null, CommonSettings.DialogSize_XLarge);
    }

    public onBulkTemplate()
    {
        OrderDataHelper.getBulkTemplate();
    }

    public onFileSelect(evtArg)
    {
        this.blockOn("Processing file");

        this.excelFile = evtArg.target.files[0];

        let xHelp = new ExcelHelper();

        xHelp.readFile(evtArg).subscribe(retRes => this.processExcel(retRes));
    }

    public onClearAll()
    {
        this.excelFile = null;

        this.gridPopulate([]);
    }

    public onClose()
    {
        let prods = this.gridData.filter(elm => Util.isEmpty(elm.message) )

        if (prods.length == 0)
        {
            this.close();
            return;
        }

        let opts: IConfirmationOptions =
        {
            FunctionConfirm: () => this.close()
        };

        this.confirmService.YesNo(CommonSettings.DirtyFormConfirmationMessage, opts);
    }

    public onSave()
    {
        if (this.existingOrderUID != null)
        {
            this.saveToExistsOrder();
            return;
        }

        let prods = this.gridData.filter(elm => elm.description != "");

        prods.forEach(elm => elm.description = `${elm.partNumber} - ${elm.description}`);

        this.close();
        this.productAdded.emit(prods);
    }

    public onProductSaved(data)
    {
        let prod = this.gridData.find(elm => elm.uid == data.uid);

        data.total = data.amount * data.quantity;

        if (prod == null)
        {
            let delMe = this.gridData.findIndex(elm => elm.partNumber == this.editProd.partNumber);

            if (delMe != -1)
            {
                delete this.gridData[delMe];
            }

            this.gridData.push(data);

            this.gridData = Util.reindexArray(this.gridData);
        }
        else
        {
            prod.total = data.total;
            prod.amount = data.amount;
            prod.quantity = data.quantity;
        }

        this.gridPopulate(this.gridData);
    }

    private processExcel(data)
    {
        if (Util.isEmpty(data))
        {
            this.blockOff();
            return;
        }

        let cnt = 0;

        for (let item of data)
        {
            let amt = parseFloat(item["Price"]);
            let qty = parseInt(item["Quantity"]);
            let desc = "";
            let error = [];
            let partNum = item["PartNumber"];

            if (Util.isEmpty(partNum))
            {
                partNum = "";
                error.push("Missing Part number");
            }
            else
            {
                partNum = partNum.toString().trim();

                let prod = this.availableProducts.find(elm => elm.partNumber.trim().toLowerCase() == partNum.toLowerCase());

                if (prod == null)
                {
                    error.push("Product not found");
                }
                else
                {
                    desc = prod.customerDescription;
                }
            }

            if (isNaN(qty))
            {
                qty = 0;
                error.push("Invalid or missing Quantity");
            }

            if (isNaN(amt))
            {
                amt = 0;
                error.push("Invalid or missing Price");
            }

            let avail = this.gridData.find(elm => elm.partNumber.toLowerCase() == partNum.toLowerCase() && elm.amount == amt);

            if (avail == null)
            {
                let addMe =
                {
                    uid: "B-" + cnt,
                    total: amt * qty,
                    amount: amt,
                    message: error.join("<br />"),
                    quantity: qty,
                    partNumber: partNum,
                    description: desc,
                    isOrderModification: true
                };

                this.gridData.push(addMe);

                cnt++;
            }
            else
            {
                avail.message += "<br />" + error.join("<br />");
                avail.quantity += qty;
                avail.total = avail.quantity * avail.amount;
            }
        }

        this.gridPopulate(this.gridData);
        this.blockOff();
    }

    private setupGrid()
    {
        let gridIcons =
            [
                GridHelpers.MakeEditIcon(this.onEdit.bind(this), this.canEdit.bind(this)),
                GridHelpers.MakeDeleteIcon(this.onDelete.bind(this))
            ];

        this.gridData = [];
        this.gridOpts.domLayout = GridHelpers.DOMLayout_Normal;
        this.gridOpts.paginationPageSize = 10;

        this.addColumn(new GridColumn("partNumber", "Part #"));
        this.addColumn(new GridColumn("description", "Descrtipion"));
        this.addColumn(new GridColumn("quantity", "Qty", GridColumnType.Number));
        this.addColumn(new GridColumn("amount", "Price", GridColumnType.Money));
        this.addColumn(new GridColumn("total", "Total", GridColumnType.Money));
        this.addColumn(new GridColumnCustom("message", "Message", { cellRenderer: this.renderMessage.bind(this) }));
        this.addColumn(new GridColumnIcon(gridIcons));

        this.rowDoubleClick(this.onEdit.bind(this));
    }

    private canEdit(agData)
    {
        return !Util.isEmpty(agData.description);
    }

    private renderMessage(agData)
    {
        let mess = agData.value;

        if (Util.isEmpty(mess))
        {
            return Util.DOM_Space;
        }

        return `<span title="${mess}">${mess}</span>`;
    }

    private onEdit(agData)
    {
        this.editProd = agData;

        this.dialogProduct.openDialog(agData);
    }

    private onDelete(agData)
    {
        let opts: IConfirmationOptions =
        {
            FunctionConfirm: () => this.deleteIt(agData.partNumber)
        };

        this.confirmService.Delete(`Are you sure you want to delete "${agData.description}?"`, opts);
    }

    private deleteIt(partNum)
    {
        let idx = this.gridData.findIndex(elm => elm.partNumber == partNum);

        if (idx == -1)
        {
            return;
        }

        delete this.gridData[idx];

        this.gridData = Util.reindexArray(this.gridData);

        this.gridPopulate(this.gridData);
    }

    private saveToExistsOrder()
    {
        let saveProds = [];

        for (let prod of this.gridData.filter(elm => elm.description != ""))
        {
            let addMe: ShopOrderProduct =
            {
                amount: prod.amount,
                quantity: prod.quantity,
                partNumber: prod.partNumber,
                transactionUID: this.existingOrderUID,
                isOrderModification: true
            };

            saveProds.push(addMe);
        }

        if (saveProds.length == 0)
        {
            this.close();
            return;
        }

        this.orderProdServ.postList("save-products", saveProds).subscribe(
            retRes => this.processResults(retRes),
            errRes => this.error(errRes));
    }

    private processResults(retData)
    {
        let sendIt = { uid: this.existingOrderUID, stage: OrderDataHelper.OrderState_Validate };
        let haveError = false;

        for (let lp in this.gridData)
        {
            let item = this.gridData[lp];
            let dude = retData.find(elm => elm.partNumber == item.partNumber);

            if (dude.statusType == OrderProductStatusType.active)
            {
                delete this.gridData[lp];
            }
            else
            {
                haveError = true;
                item.message = dude.message;
            }
        }

        this.shopPostMessageServ.post("check", sendIt).subscribe(
            retRes =>
            {
                if ( haveError)
                {
                    this.gridData = Util.reindexArray(this.gridData);
                    this.gridPopulate(this.gridData);
                }
                else
                {
                    this.close();
                }

                this.productAdded.emit();
            },
            errRes => this.error(errRes));
    }
}