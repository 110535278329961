import { Component } from "@angular/core";
import { GridFilter, GridFilterStorage } from "@app/shared/ag-grid-helpers/models/grid-filter.model";
import { AgFilterComponent } from "ag-grid-angular";
import { IDoesFilterPassParams } from "ag-grid-community";
import { GridFilterCustom } from "../grid-filter-custom";

@Component({
    styleUrls: ["../../../styles/gridfilter.scss", "../../../styles/slider.scss"],
    templateUrl: "./grid-filter-meter.component.html"
})
export class GridFilterMeter extends GridFilterCustom implements AgFilterComponent
{
    public meterValue: number = 100;
    public filterOptions =
        {
            ceil: 1,
            step: .1,
            floor: 0,
            maxLabel: "100%",
            minLabel: "0%",
            showTicks: true,
            translate: (value: number): string =>
            {
                return (value * 100) + "%";
            }
        };

    private field;

    agInit(params: any): void
    {
        this.initFilter(params);

        this.field = this.getParam("field");
    }

    isFilterActive(): boolean
    {
        return (this.meterValue != null);
    }

    doesFilterPass(params: IDoesFilterPassParams): boolean
    {
        if (this.isServerSide || this.meterValue == null)
        {
            return true;
        }

        return params.data[this.field] <= (this.meterValue * 100);
    }

    getModel()
    {
        if (this.meterValue == null)
        {
            return null;
        }

        return new GridFilterStorage(this.field, new GridFilter(this.meterValue));
    }

    setModel(model: any)
    {
        this.meterValue = model?.gridFilter?.filter;
    }

    public onFilterClear(): void
    {
        this.meterValue = null;

        this.applyAndClose();
    }

    public onFilterApply(): void
    {
        this.applyAndClose();
    }
}