import { ModuleWithProviders } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "@app/data";
import { Layout1Component } from "./layout/layout-1/layout-1.component";
import { NotFoundComponent } from "./not-found/not-found.component";

const routes: Routes = [
    { path: "", loadChildren: "./account/account.module#AccountModule" },
    { path: "ff", component: Layout1Component, loadChildren: "./freight-forwarder/freight-forwarder.module#FreightForwarderModule", canActivate: [AuthGuard] },
    { path: "master", component: Layout1Component, loadChildren: "./master/master.module#MasterModule", canActivate: [AuthGuard] },
    { path: "purchaser", component: Layout1Component, loadChildren: "./purchaser/purchaser.module#PurchaserModule", canActivate: [AuthGuard] },
    { path: "admin", component: Layout1Component, loadChildren: "./admin/admin.module#AdminModule", canActivate: [AuthGuard] },
    { path: "customer", component: Layout1Component, loadChildren: "./customer/customer.module#CustomerModule", canActivate: [AuthGuard] },

    { path: "**", component: NotFoundComponent }
];

export const AppRoutingModule: ModuleWithProviders = RouterModule.forRoot(routes);