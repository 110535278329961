import { Component, HostBinding, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AppService } from "@app/app.service";
import { AuthService, Profile, ProfileService, ProfileType } from "@app/data";
import { LayoutService } from "@app/layout/layout.service";
import { CommonSettings } from '@app/shared/common-settings';
import { GlobalIcons } from "@app/shared/GlobalIcons";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: "app-layout-navbar",
    styles: [":host { display: block; }"],
    styleUrls: ["./layout-navbar.component.scss", "../../shared/styles/testmode.scss"],
    templateUrl: "./layout-navbar.component.html"
})
export class LayoutNavbarComponent implements OnInit
{
    @Input()
    sidenavToggle = true;

    @HostBinding("class.layout-navbar") hostClassMain = true;

    public readonly Icons = GlobalIcons;
    public readonly ProfileTypes = ProfileType;

    public isRTL: boolean;
    public myProfile: Profile;
    public isExpanded = false;
    public profileURL;

    constructor(
        public authService: AuthService,
        private router: Router,
        private appService: AppService,
        private toastrService: ToastrService,
        private layoutService: LayoutService,
        private profileService: ProfileService)
    {
        this.isRTL = appService.isRTL;
    }

    async ngOnInit()
    {
        this.myProfile = await this.authService.getProfile$().toPromise();

        if (this.myProfile.profileType == this.ProfileTypes.purchaser)
        {
            this.profileURL = "profile";
        }
        else
        {
            this.profileURL = "mydashboard/" + CommonSettings.DashboardTab_Profile;
        }
    }

    currentBg()
    {
        return `bg-${this.appService.layoutNavbarBg}`;
    }

    toggleSidenav()
    {
        this.layoutService.toggleCollapsed();
    }

    info(message, title?: string)
    {
        this.toastrService.info(message, title, CommonSettings.ToastOptions);
    }

    error(message, title?: string)
    {
        this.toastrService.error(message, title, CommonSettings.ToastOptions);
    }

    changePassword()
    {
        this.profileService.resetPassword(this.myProfile).subscribe(
            retRes =>
            {
                this.info(`An email has been sent to ${this.myProfile.emailAddress} with instructions on how to reset your password.`)
            },
            errRes => this.error(errRes));
    }

    public onTestModeToggle()
    {
        this.myProfile.isTestMode = !this.myProfile.isTestMode;

        this.profileService.get(`testmode/${this.myProfile.uid}/${this.myProfile.isTestMode}`).subscribe(
            retRes => this.toggleDone(),
            errRes => this.error(errRes));
    }

    private toggleDone()
    {
        if (window.location.pathname.indexOf("/admin/purchasers") == -1)
        {
            return;
        }

        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = "reload";

        this.router.navigate(["/admin/purchasers"]);
    }
}