import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { MerchantDialogComponent } from "@app/admin/customer/merchant-dialog/merchant-dialog.component";
import { Customer, CustomerService } from "@app/data";
import { GlobalIcons } from "@app/shared/GlobalIcons";

@Component({
    selector: "merchant-link",
    templateUrl: "./merchant-link.html",
})
export class MerchantLink implements OnInit
{
    @Input()
    merchant: Customer;

    @Input()
    merchantUID: string;

    @ViewChild("dialogMerchant")
    dialogMerchant: MerchantDialogComponent;

    public readonly Icons = GlobalIcons;

    public email: string;
    public webLink: string;
    public merchUID: string;
    public merchantName: string;

    constructor(private custServ: CustomerService)
    {
    }

    ngOnInit(): void
    {
        if (this.merchantUID)
        {
            this.custServ.detail({ uid: this.merchantUID }).subscribe(retRes => this.plugMerchant(retRes));
        }
        else
        {
            this.plugMerchant(this.merchant);
        }
    }

    public onDetail()
    {
        this.dialogMerchant.openEdit(this.merchUID);
    }

    public onWebsite()
    {
        window.open(this.webLink, "_blank");
    }

    public onEmail()
    {
        window.location.href = "mailto:" + this.email;
    }

    private plugMerchant(merchant: Customer): void
    {
        if (!merchant)
        {
            this.email = "";
            this.webLink = "";
            this.merchUID = "";
            this.merchantName = "";
            return;
        }

        this.email = merchant.contact?.emailAddress;
        this.webLink = merchant.websiteLink;
        this.merchUID = merchant.uid;
        this.merchantName = merchant.displayName;
    }
}