import { Component, OnInit, ViewChild } from "@angular/core";
import { LicenseDocument, LicenseDocumentService } from "@app/data";
import { GridBase } from "@app/shared/ag-grid-helpers/ag-grid-base";
import { GridHelpers } from "@app/shared/ag-grid-helpers/grid-helper";
import { GridColumnIcon } from "@app/shared/ag-grid-helpers/models/grid-column-icon.model";
import { GridColumn } from "@app/shared/ag-grid-helpers/models/grid-column.model";
import { ConfirmationService, IConfirmationOptions } from "@app/shared/confirmation/confirmation.service";
import { DocumentPreviewService } from "@app/shared/document-preview/document-preview.service";
import { FileSaverService } from "ngx-filesaver";
import { ToastrService } from "ngx-toastr";
import { LicenseDocumentEditComponent } from "../license-document-edit/license-document-edit.component";

@Component({
    selector: "app-license-document-list",
    templateUrl: "./license-document-list.component.html"
})
export class LicenseDocumentListComponent extends GridBase<LicenseDocument> implements OnInit
{
    @ViewChild("dialogDoc")
    dialogDoc: LicenseDocumentEditComponent;

    constructor(
        toastrService: ToastrService,
        private confirmService: ConfirmationService,
        private docViewService: DocumentPreviewService,
        private licenseDocServ: LicenseDocumentService,
        private fileSaverService: FileSaverService)
    {
        super(toastrService, licenseDocServ);

        this.setupGrid();
    }

    ngOnInit()
    {
        this.filterAdd("licenseUID", this.parentUID, false);
    }

    public onAdd()
    {
        this.dialogDoc.openAdd(this.parentUID);
    }

    private setupGrid()
    {
        let gridIcons =
            [
                GridHelpers.MakeEditIcon(this.onEdit.bind(this)),
                GridHelpers.MakeDeleteIcon(this.onDelete.bind(this)),
                GridHelpers.MakeDownloadIcon(this.onDownload.bind(this)),
                GridHelpers.MakeViewIcon(this.onViewDoc.bind(this))
            ];

        this.storageKey = "LicenseDocumentList";
        this.gridOpts.domLayout = GridHelpers.DOMLayout_Normal;
        this.gridOpts.paginationPageSize = 10;

        this.addColumn(new GridColumn("name", "Name"));
        this.addColumn(new GridColumn("documentType.name", "Type"));
        this.addColumn(new GridColumnIcon(gridIcons));

        this.rowDoubleClick(this.onEdit.bind(this));
    }

    private onViewDoc(agData: any): void
    {
        let path = this.licenseDocServ.buildUrl("download-pdf");
        let fileName = `${path}?fileNameUID=${agData.fileNameUID}`;

        this.docViewService.openConvert(fileName, this.title);
    }

    private onDownload(data: LicenseDocument)
    {
        this.licenseDocServ.getFile("download", data.fileNameUID).subscribe(
            retRes => this.fileSaverService.save(retRes, data.fileName),
            errRes => this.error(errRes));
    }

    private onEdit(agData)
    {
        agData.licenseUID = this.parentUID;

        this.dialogDoc.openEdit(agData);
    }

    private onDelete(agData)
    {
        this.dataForm = this.makeForm(agData);

        let opts: IConfirmationOptions =
        {
            FunctionConfirm: () => this.deleteIt()
        };

        this.confirmService.Delete(`Are you sure you want to delete ${agData.name}?`, opts);
    }

    private deleteIt()
    {
        this.delete().add(() => this.gridRefresh());
    }
}