import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ApiService } from '../api/api.service';
import { ProductCountry } from './product-country';

@Injectable({
  providedIn: 'root'
})
export class ProductCountryService extends ApiService<ProductCountry> {

  constructor(httpClient: HttpClient) {
    super(httpClient, 'product-country');
  }

}
