import { Component } from "@angular/core";
import { FreightForwarderType } from "@app/data/enums/freight-forwarder-type.enum";
import { GridFilterCustom } from "@app/shared/ag-grid-helpers/filters/grid-filter-custom";
import { GridFilter, GridFilterStorage } from "@app/shared/ag-grid-helpers/models/grid-filter.model";
import { CommonSettings } from '@app/shared/common-settings';
import { FreightForwarderCommon } from '@app/shared/freight-forwarder/freight-forwarder-common';
import { Util } from "@app/utils/util";
import { AgFilterComponent } from "ag-grid-angular";
import { IDoesFilterPassParams } from "ag-grid-community";
import clone from "lodash-es/clone";

@Component({
    styleUrls: ["../../../../styles/gridfilter.scss"],
    templateUrl: "./grid-ff-record-type-filter.component.html"
})
export class GridFFRecordTypeFilter extends GridFilterCustom implements AgFilterComponent
{
    private readonly fieldField: string = "countryUID";

    public recordTypes;
    public selectedType: number;

    agInit(params: any): void
    {
        this.initFilter(params);

        this.recordTypes = clone(FreightForwarderCommon.RecordTypes);
        this.recordTypes.unshift({ id: null, name: "All" });
    }

    isFilterActive(): boolean
    {
        return !Util.isEmpty(this.selectedType);
    }

    doesFilterPass(params: IDoesFilterPassParams): boolean
    {
        if (this.isServerSide || this.selectedType == null)
        {
            return true;
        }

        if (this.selectedType == FreightForwarderType.FreightFordwarder)
        {
            return params.data[this.fieldField] == CommonSettings.USAUID;
        }

        return params.data[this.fieldField] != CommonSettings.USAUID;
    }

    getModel()
    {
        if (this.selectedType == null)
        {
            return null;
        }

        return new GridFilterStorage(this.fieldField, new GridFilter(this.selectedType));
    }

    setModel(model: any)
    {
        this.selectedType = model?.gridFilter?.filter;
    }

    public onFilterApply(): void
    {
        this.applyAndClose();
    }

    public onFilterClear(): void
    {
        this.selectedType = null;
        this.applyAndClose();
    }
}