import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { LookupService } from "@app/data";
import { FixedImage } from "@app/data/misc/fixed-image";
import { TaskDocumentService } from "@app/data/tasks/task-document.service";
import { CommonSettings } from "@app/shared/common-settings";
import { ConfirmationService, IConfirmationOptions } from "@app/shared/confirmation/confirmation.service";
import { TaskDocumentCommon } from "@app/shared/users/my-tasks/my-task-edit/my-task-doc-common";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: "my-task-doc-paste",
    styleUrls: ["./my-task-doc-paste.scss"],
    templateUrl: "./my-task-doc-paste.html"
})
export class TaskDocumentPaste extends TaskDocumentCommon implements OnInit
{
    @ViewChild("dialogPaste")
    dialogPaste: ElementRef;

    public readonly PasteType_HTML = "text/html";
    public readonly PasteType_Text = "text/plain";

    public imgPaste?: FixedImage;
    public textPaste?: string;

    constructor(
        toastrService: ToastrService,
        public lookupService: LookupService,
        private modalServ: NgbModal,
        private sanitizer: DomSanitizer,
        private taskDocServ: TaskDocumentService,
        private confirmService: ConfirmationService)
    {
        super(toastrService);

        this.dataForm = this.taskDocServ.editorForm;
        this.apiService = taskDocServ;
        this.modalService = this.modalServ;
    }

    ngOnInit(): void
    {
        this.lookupService.getTaskDocumentTypeList();
    }

    public openPaster(taskUID: string): void
    {
        this.imgPaste = undefined;
        this.textPaste = undefined;

        this.open(this.dialogPaste, null, CommonSettings.DialogSize_XLarge);
        this.resetForm();

        this.setValue("taskUID", taskUID);
        this.makeRequired(["fileName"], false);
    }

    public onPaste(evtArg: any): void
    {
        for (let item of evtArg.clipboardData.items)
        {
            if (item.type.indexOf("image/") == 0)
            {
                this.imgPaste = new FixedImage();
                this.imgPaste.rawImage = item.getAsFile();
                break;
            }
        }

        if (this.imgPaste)
        {
            this.imgPaste.url = URL.createObjectURL(this.imgPaste.rawImage!);
            this.imgPaste.safeURL = this.sanitizer.bypassSecurityTrustUrl(this.imgPaste.url);
            return;
        }

        let haveIt = false;

        for (let item of evtArg.clipboardData.items)
        {
            if (item.type == this.PasteType_HTML)
            {
                haveIt = true;
                item.getAsString((str: string) => this.textPaste = str);
                break;
            }
        }

        //  Lame alert!  I spent way too much time figuring this out.
        //  For some reason textPaste won't show until you click somewhere on the screen.  Maybe because it's in a callback function?
        //  This is my fix?!?!?!
        setTimeout(() => this.textPaste = this.textPaste, 200);

        if (haveIt)
        {
            return;
        }

        for (let item of evtArg.clipboardData.items)
        {
            if (item.type == this.PasteType_Text)
            {
                item.getAsString((str: string) => this.textPaste = str);
                break;
            }
        }

        setTimeout(() => this.textPaste = this.textPaste, 200);
    }

    public onClear(): void
    {
        this.imgPaste = undefined;
        this.textPaste = undefined;
    }

    public onSave(): void
    {
        if (this.invalid() || (this.textPaste == null && this.imgPaste == null))
        {
            return;
        }

        this.loading = true;

        if (this.imgPaste)
        {
            this.taskDocServ.uploadImage(this.imgPaste.rawImage!, this.dataValue).subscribe(
                {
                    next: (retRes) => this.saveComplete(),
                    error: (errRes) => this.error(errRes)
                });
            return;
        }

        this.setValue("documentText", this.textPaste);

        let sub = this.submit("doc-save");

        if (sub)
        {
            sub.add(() => this.saveComplete());
        }
    }

    public onClose(): void
    {
        if (!this.dataForm.dirty && this.textPaste == null)
        {
            this.close();
            return;
        }

        let opts: IConfirmationOptions =
        {
            FunctionConfirm: () => this.close()
        };

        this.confirmService.YesNo(CommonSettings.DirtyFormConfirmationMessage, opts);
    }
}