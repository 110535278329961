import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { AuthService, BaseComponent, Freight, FreightService, ProfileType } from "@app/data";
import { FreightForwarderType } from "@app/data/enums/freight-forwarder-type.enum";
import { TaskEntity } from "@app/data/enums/task-entity.enum";
import { FreightForwarderRequestDTO } from "@app/data/freight/freight-forwarder-request.dto";
import { CommonSettings } from "@app/shared/common-settings";
import { ConfirmationService, IConfirmationOptions } from "@app/shared/confirmation/confirmation.service";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";
import { FreightForwarderDetailsComponent } from "../freight-forwarder-details/freight-forwarder-details.component";

@Component({
    selector: "freight-forwarder-tabs",
    templateUrl: "./freight-forwarder-tabs.component.html"
})
export class FreightForwarderTabsComponent extends BaseComponent<Freight> implements OnInit, OnDestroy
{
    @ViewChild("ffDetail")
    ffDetail: FreightForwarderDetailsComponent;

    @Input()
    recordType: number;

    @Input() set freightData(freight)
    {
        this.data = freight;
    }

    public get canModify()
    {
        //  Temp: until we test as Admin
        //return (this.authService.profile.profileType == ProfileType.admin || this.authService.profile.profileType == ProfileType.freightForwarder);

        return this.authService.profile.profileType == ProfileType.admin;
    }

    public get canMember()
    {
        return (this.recordType != FreightForwarderType.CustomersBroker);
    }

    public readonly Tab_Tasks = 1;
    public readonly Tab_Detail = 2;
    public readonly Tab_Address = 3;
    public readonly Tab_Members = 4;
    public readonly Tab_Contacts = 5;
    public readonly TaskEntityType = TaskEntity.FreightForwarder;

    public setTab;

    private subAns: Subscription;
    private holdTab: number;
    private subQues: Subscription;

    constructor(toastrService: ToastrService,
        public authService: AuthService,
        public freightServ: FreightService,
        private confirmService: ConfirmationService)
    {
        super(toastrService);

        this.subAns = freightServ.freightAnswer.subscribe((req) => { this.handleAnswer(req) });
        this.subQues = freightServ.freightQuestion.subscribe((req) => { this.handleQuestion(req) });
    }

    ngOnInit(): void
    {
        this.authService.getProfile$().subscribe();
    }

    ngOnDestroy()
    {
        this.subAns.unsubscribe();
        this.subQues.unsubscribe();
    }

    public onTabChange(tabEvt)
    {
        if (tabEvt.activeId != this.Tab_Detail || !this.ffDetail.haveChanges)
        {
            this.sendTabChange(tabEvt.nextId);
            return;
        }

        this.holdTab = tabEvt.nextId;

        tabEvt.preventDefault();

        let ques = new FreightForwarderRequestDTO(FreightForwarderRequestDTO.FreightRequest_DirtyTab);

        this.handleDirty(ques);
    }

    private handleAnswer(answer: FreightForwarderRequestDTO)
    {
        switch (answer.taskID)
        {
            case FreightForwarderRequestDTO.FreightRequest_Update:
                this.data = answer.data;
                break;
        }
    }

    private handleQuestion(question: FreightForwarderRequestDTO)
    {
        switch (question.taskID)
        {
            case FreightForwarderRequestDTO.FreightRequest_DirtyClose:
                this.handleDirty(question);
                break;
        }
    }

    private sendTabChange(tabID: number)
    {
        let ans = new FreightForwarderRequestDTO(FreightForwarderRequestDTO.FreightRequest_TabChanged, tabID);

        this.freightServ.sendAnswer(ans);
    }

    private handleDirty(question: FreightForwarderRequestDTO)
    {
        if (this.setTab != this.freightServ.Tab_Detail)
        {
            this.sendDirty(question, true);
            return;
        }

        if (!this.ffDetail.haveChanges)
        {
            this.sendDirty(question, true);
            return;
        }

        let opts: IConfirmationOptions =
        {
            FunctionClose: () => this.sendDirty(question, false),
            FunctionConfirm: () => this.sendDirty(question, true)
        };

        this.confirmService.YesNo(CommonSettings.DirtyFormConfirmationMessage, opts);
    }

    private sendDirty(question: FreightForwarderRequestDTO, moveOn: boolean)
    {
        if (question.taskID == FreightForwarderRequestDTO.FreightRequest_DirtyClose)
        {
            let ans = new FreightForwarderRequestDTO(question.taskID, moveOn);

            this.freightServ.sendAnswer(ans);
            return;
        }

        if (moveOn)
        {
            this.setTab = this.holdTab;
            this.sendTabChange(this.holdTab);
        }
    }
}