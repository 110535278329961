import { Component, OnDestroy, OnInit } from "@angular/core";
import { AuthService, BaseComponent, Product, ProductService, ProfileType } from "@app/data";
import { ProductStatus } from "@app/data/enums/product-status-type.enum";
import { TaskEntity } from "@app/data/enums/task-entity.enum";
import { ProductCommon } from '@app/data/product/product-common';
import { ProductRequestDTO } from "@app/data/product/product-request.dto";
import { CommonSettings } from "@app/shared/common-settings";
import { ConfirmationService, IConfirmationOptions } from "@app/shared/confirmation/confirmation.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";

@Component(
{
    selector: "product-dialog",
    templateUrl: "./product-dialog.component.html",
})
export class ProductDialogComponent extends BaseComponent<Product> implements OnInit, OnDestroy
{
    public get isAdmin(): boolean
    {
        return (this.userRole == ProfileType.admin);
    }

    public get canClassify(): boolean
    {
        if (!this.isAdmin)
        {
            return false;
        }

        return (this.data?.statusType == ProductStatus.active || this.data?.statusType == ProductStatus.pending || this.data?.statusType == ProductStatus.failed || this.data?.statusType == ProductStatus.pendingRelease);
    }

    public set product(prod: Product)
    {
        this.setEdit(prod);
    }

    public set productUID(prodUID: string)
    {
        this.setEditByUID(prodUID);
    }

    public readonly Tab_Task = 0;
    public readonly Tab_Detail = 1;
    public readonly Tab_Country = 2;
    public readonly Tab_History = 3;
    public readonly Tab_ImportHTS = 4;

    public readonly statusList = ProductCommon.ProductStatuses;
    public readonly productStatues = ProductStatus;
    public readonly taskEntityType = TaskEntity.Product;

    public setTab = this.Tab_Detail;
    public userRole: number;
    public prodStatus: number;
    public description: string;
    public merchantName: string;
    public custDescription: string;

    private subAns: Subscription;
    private closeIt: boolean;
    private holdTab: number;

    constructor(
        toastrService: ToastrService,
        private prodServ: ProductService,
        private activeModal: NgbActiveModal,
        private authService: AuthService,
        private confirmService: ConfirmationService)
    {
        super(toastrService);

        this.subAns = this.prodServ.prodAnswer.subscribe((req) => { this.handleAnswers(req) });
    }

    async ngOnInit()
    {
        let myProfile = await this.authService.getProfile$().toPromise();

        this.userRole = myProfile.profileType;
    }

    ngOnDestroy(): void
    {
        this.subAns.unsubscribe();
    }

    public opendAdd(): void
    {
        this.setTab = this.Tab_Detail;
    }

    private setEdit(prod: Product): void
    {
        this.uid = prod.uid;
        this.data = prod;
        this.setTab = this.Tab_Detail;
        this.prodStatus = prod.statusType;
        this.description = prod.description;
        this.merchantName = prod.customer?.displayName;
        this.custDescription = prod.customerDescription;
    }

    public setEditByUID(productUID): void
    {
        this.prodServ.detail({ uid: productUID }).subscribe(
            retRes => this.setEdit(retRes),
            errRes => this.error(errRes));
    }

    public onClose(): void
    {
        if (this.setTab != this.Tab_Detail)
        {
            this.activeModal.close();
            return;
        }

        let quest = new ProductRequestDTO(ProductRequestDTO.Request_Close);

        this.prodServ.sendQuestion(quest);
    }

    public onTabChanged(evtArg): void
    {
        switch (evtArg.activeId)
        {
            case this.Tab_Detail:
                let quest = new ProductRequestDTO(ProductRequestDTO.Request_TabChanged);

                evtArg.preventDefault();

                this.holdTab = evtArg.nextId;
                this.prodServ.sendQuestion(quest);
                break;
        }
    }

    public onSave(statusType: number, closeIt = false): void
    {
        let quest = new ProductRequestDTO(ProductRequestDTO.Request_Save, statusType);

        this.loading = true;
        this.closeIt = closeIt;
        this.prodServ.sendQuestion(quest);
    }

    private handleAnswers(answer: ProductRequestDTO): void
    {
        switch (answer.requestID)
        {
            case ProductRequestDTO.Request_Save:
                this.saveDone(answer.data);
                break;

            case ProductRequestDTO.Request_Close:
            case ProductRequestDTO.Request_TabChanged:
                this.forRealize(answer);
                break;
        }
    }

    private saveDone(data: Product): void
    {
        if (data == null)
        {
            this.loading = false;
            return;
        }

        this.loading = false;

        if (this.closeIt)
        {
            this.activeModal.close();
            return;
        }

        this.data = data;
        this.prodStatus = data.statusType;
        this.description = data.description;
        this.custDescription = data.customerDescription;
    }

    private forRealize(answer: ProductRequestDTO): void
    {
        if (!answer.data)
        {
            this.doIt(answer);
            return;
        }

        let opts: IConfirmationOptions =
        {
            FunctionConfirm: () => this.doIt(answer)
        };

        this.confirmService.YesNo(CommonSettings.DirtyFormConfirmationMessage, opts);
    }

    private doIt(answer): void
    {
        if (answer.requestID == ProductRequestDTO.Request_Close)
        {
            this.activeModal.close();
        }
        else
        {
            this.setTab = this.holdTab;
        }
    }
}