import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { FreightForwarderLookup } from "./freight-forwarder-lookup";

@Injectable({
    providedIn: 'root'
})
export class FreightForwarderLookupService extends ApiService<FreightForwarderLookup>
{
    constructor(httpClient: HttpClient)
    {
        super(httpClient, "freight-forwarder");
    }
}